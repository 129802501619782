import moment from 'moment'
import { makeActionCreator } from '../../config/store/utils'
import { getNewAnomalies, getNewAnomaliesSummary, getNewAnomaliesReport } from '../../services/newanomaliesServices'
import { batch } from 'react-redux'
import { getMonthString } from '../../utils'
import theme from '../../config/styles/theme'

export const GET_NEW_ANOMALIES = 'GET_NEW_ANOMALIES'
export const GET_NEW_ANOMALIES_ERROR = 'GET_NEW_ANOMALIES_ERROR'
export const GET_NEW_ANOMALIES_SUCCESS = 'GET_NEW_ANOMALIES_SUCCESS'
export const onGetNewAnomalies = makeActionCreator(GET_NEW_ANOMALIES)
export const onGetNewAnomaliesError = makeActionCreator(GET_NEW_ANOMALIES_ERROR, 'payload')
export const onGetNewAnomaliesSuccess = makeActionCreator(GET_NEW_ANOMALIES_SUCCESS, 'payload')
export const applyFilters = allAnomalies => {
  const anomalies = allAnomalies.sort((a, b) => (a.anomalyDate > b.anomalyDate ? 1 : -1))

  const firstDate = anomalies[0].anomalyDate
  const lastDate = anomalies[anomalies.length - 1].anomalyDate

  var totalAnomalies = 0
  var totalFuelLiters = 0
  var totalExceden = 0
  var totalCargaPequena = 0

  var graph = {}

  for (var key in anomalies) {
    const month = anomalies[key].anomalyDate.format('MMMM')
    const loadType = anomalies[key].fuelLoadType
    if (!(month in graph)) {
      const type = {
        Exceden: 0,
        CargaPequena: 0,
        quantityTotal: 0,
        fuelLitresTotal: 0,
        fuelExceden: 0,
        fuelCargaPequena: 0
      }
      graph[month] = type
    }

    graph[month].fuelLitresTotal += anomalies[key].fuelLitres
    graph[month].quantityTotal += 1

    if (loadType === 'Excede capacidad') {
      totalExceden += 1
      graph[month].Exceden += 1
      graph[month].fuelExceden += anomalies[key].fuelLitres
    } else {
      totalCargaPequena += 1
      graph[month].CargaPequena += 1
      graph[month].fuelCargaPequena += anomalies[key].fuelLitres
    }
    totalAnomalies += 1
    totalFuelLiters += anomalies[key].fuelLitres
  }

  const labels = []
  const dataSets = []
  const dataSetsFuelType = []

  const primaryColor = theme.palette.brand.primary[50]
  const secondaryColor = theme.palette.brand.primary[95]

  const dataSetsFilter1 = [
    {
      label: 'Total Combustible',
      data: [],
      backgroundColor: primaryColor
    }
  ]

  const dataSetsQuantityTotal = [
    {
      label: 'Total anomalías',
      data: [],
      backgroundColor: primaryColor
    }
  ]

  const dataSetsFuelNormal = {
    label: 'Total Combustible Excede',
    data: [],
    backgroundColor: primaryColor
  }

  const dataSetsFuelConcatenada = {
    label: 'Total Combustible Carga Pequena',
    data: [],
    backgroundColor: secondaryColor
  }

  const dataSetNormal = {
    label: 'Total Anomalías Excede',
    data: [],
    backgroundColor: primaryColor
  }

  const dataSetConcatenada = {
    label: 'Total Anomalías Pequena',
    data: [],
    backgroundColor: secondaryColor
  }

  for (var graphKey in graph) {
    labels.push(graphKey)
    dataSetNormal.data.push(graph[graphKey].Exceden)
    dataSetConcatenada.data.push(graph[graphKey].CargaPequena)
    dataSetsFilter1[0].data.push(graph[graphKey].fuelLitresTotal)
    dataSetsQuantityTotal[0].data.push(graph[graphKey].quantityTotal)
    dataSetsFuelNormal.data.push(graph[graphKey].fuelExceden)
    dataSetsFuelConcatenada.data.push(graph[graphKey].fuelCargaPequena)
  }

  dataSets.push(dataSetNormal, dataSetConcatenada)
  dataSetsFuelType.push(dataSetsFuelNormal, dataSetsFuelConcatenada)

  const anomaliesQuantityTotal = {
    labels: labels,
    datasets: dataSetsQuantityTotal
  }

  const anomaliesQuantitySeparated = {
    labels: labels,
    datasets: dataSets
  }

  const anomaliesFuelTotal = {
    labels: labels,
    datasets: dataSetsFilter1
  }

  const anomaliesFuelSeparated = {
    labels: labels,
    datasets: dataSetsFuelType
  }

  return {
    newAnomalies: {
      anomalies
    },
    anomaliesQuantityTotal,
    anomaliesQuantitySeparated,
    anomaliesFuelTotal,
    anomaliesFuelSeparated,
    totalAnomalies,
    totalFuelLiters,
    totalExceden,
    totalCargaPequena,
    firstDate,
    lastDate
  }
}
export const onGetNewAnomaliesThunk = () => async (dispatch, getState) => {
  const { newAnomalies } = getState().newAnomaly

  if (newAnomalies.anomalies.length > 0) {
    return
  }

  dispatch(onGetNewAnomalies())

  try {
    const {
      apis: {
        base: { apiKey, apiName }
      }
    } = getState().auth

    const { data } = await getNewAnomalies({
      actionType: GET_NEW_ANOMALIES,
      apiKey,
      apiName
    })

    const originalAnomalies = data.map(item => {
      const {
        equipo: truck,
        date: truckDate,
        lts_anomalos: fuelLitres,
        transacciones: transactions,
        cantidades: quantity,
        estaciones: stations,
        horas: hourAnomaly,
        diff_between_loads: diffBetweenLoads,
        fuel_tank_capacity: tankCapacity,
        fuel_load_type: fuelLoadType,
        concatenated: isConcatenated
      } = item

      return {
        truck,
        anomalyDate: moment(truckDate, 'YYYY/MM/DD HH:mm:ss'),
        fuelLitres: Math.round(fuelLitres),
        transactions: transactions.split(','),
        quantity: quantity.split(','),
        stations: stations.split(','),
        hourAnomaly: hourAnomaly.split(','),
        diffBetweenLoads,
        tankCapacity,
        fuelLoadType: fuelLoadType === 'small_anomaly' ? 'Carga Pequeña' : 'Excede capacidad',
        isConcatenated: isConcatenated ? 'Concatenada' : 'Normal',
        truckDate:
          moment(truckDate).format('YYYY') + '-' + moment(truckDate).format('MM') + '-' + moment(truckDate).format('DD')
      }
    })

    const {
      newAnomalies,
      anomaliesQuantityTotal,
      anomaliesQuantitySeparated,
      anomaliesFuelTotal,
      anomaliesFuelSeparated,
      totalAnomalies,
      totalFuelLiters,
      totalExceden,
      totalCargaPequena,
      firstDate,
      lastDate
    } = applyFilters(originalAnomalies)
    const tableFilter = 'value-1'

    const tableFiltered = newAnomalies.anomalies
    const anomaliesTable = tableFiltered.sort((a, b) => (a.anomalyDate > b.anomalyDate ? -1 : 1))

    return batch(() => {
      dispatch(
        onGetNewAnomaliesSuccess({
          originalAnomalies,
          newAnomalies,
          anomaliesTable,
          anomaliesQuantityTotal,
          anomaliesQuantitySeparated,
          anomaliesFuelTotal,
          anomaliesFuelSeparated,
          totalAnomalies,
          totalFuelLiters,
          totalExceden,
          totalCargaPequena,
          firstDate,
          lastDate,
          tableFilter
        })
      )
    })
  } catch (error) {
    console.log(error)
    return dispatch(onGetNewAnomaliesError({ error }))
  }
}

export const GET_NEW_ANOMALIES_SUMMARY = 'GET_NEW_ANOMALIES_SUMMARY'
export const GET_NEW_ANOMALIES_SUMMARY_ERROR = 'GET_NEW_ANOMALIES_SUMMARY_ERROR'
export const GET_NEW_ANOMALIES_SUMMARY_SUCCESS = 'GET_NEW_ANOMALIES_SUMMARY_SUCCESS'
export const onGetNewAnomaliesSummary = makeActionCreator(GET_NEW_ANOMALIES_SUMMARY)
export const onGetNewAnomaliesSummaryError = makeActionCreator(GET_NEW_ANOMALIES_SUMMARY_ERROR, 'payload')
export const onGetNewAnomaliesSummarySuccess = makeActionCreator(GET_NEW_ANOMALIES_SUMMARY_SUCCESS, 'payload')
export const onGetNewAnomaliesSummaryThunk = () => async (dispatch, getState) => {
  dispatch(onGetNewAnomaliesSummary())

  try {
    const {
      apis: {
        base: { apiKey, apiName }
      }
    } = getState().auth

    const { data } = await getNewAnomaliesSummary({
      actionType: GET_NEW_ANOMALIES_SUMMARY,
      apiKey,
      apiName
    })

    const anomaliesSummary = data.map(item => {
      const { month: anomalieMonth, total_lts: totalLts, number_of_anomalies: anomalieQuantity } = item

      return {
        anomalieMonth: getMonthString(anomalieMonth),
        totalLts: Math.round(totalLts),
        anomalieQuantity
      }
    })

    const labels = []
    const datasets = []
    const datasetsFilter2 = []

    for (var key in anomaliesSummary) {
      labels.push(anomaliesSummary[key].anomalieMonth)
      datasets.push(anomaliesSummary[key].anomalieQuantity)
      datasetsFilter2.push(anomaliesSummary[key].totalLts)
    }

    const anomaliesBarFilter1 = {
      labels: labels,
      datasets: [{ values: datasets }]
    }

    const anomaliesBarFilter2 = {
      labels: labels,
      datasets: [{ values: datasetsFilter2 }]
    }

    return batch(() => {
      dispatch(
        onGetNewAnomaliesSummarySuccess({
          anomaliesSummary,
          anomaliesBarFilter1,
          anomaliesBarFilter2
        })
      )
    })
  } catch (error) {
    return dispatch(onGetNewAnomaliesSummaryError({ error }))
  }
}

export const TOGGLE_NEW_ANOMALY_MODAL_SUCCESS = 'TOGGLE_NEW_ANOMALY_MODAL_SUCCESS'
export const onToggleNewAnomalyModalSuccess = makeActionCreator(TOGGLE_NEW_ANOMALY_MODAL_SUCCESS)

export const ADD_NEW_ANOMALY_MODAL = 'ADD_NEW_ANOMALY_MODAL'
export const onAddNewAnomalyModalSuccess = makeActionCreator(ADD_NEW_ANOMALY_MODAL, 'payload')
export const onAddNewAnomalyModalThunk = anomaly => async dispatch => {
  if (anomaly !== undefined) {
    return dispatch(
      onAddNewAnomalyModalSuccess({
        selectedAnomaly: [anomaly]
      })
    )
  }
}

// actions for graphs, if this component goes green everything down here will be put into another script
export const TOGGLE_ANOMALY_GRAPH = 'TOGGLE_ANOMALY_GRAPH'
export const onToggleAnomalyGraphSuccess = makeActionCreator(TOGGLE_ANOMALY_GRAPH)

export const GET_NEW_ANOMALIES_REPORT = 'GET_NEW_ANOMALIES_REPORT'
export const GET_NEW_ANOMALIES_REPORT_ERROR = 'GET_NEW_ANOMALIES_REPORT_ERROR'
export const GET_NEW_ANOMALIES_REPORT_SUCCESS = 'GET_NEW_ANOMALIES_REPORT_SUCCESS'
export const onGetNewAnomaliesReport = makeActionCreator(GET_NEW_ANOMALIES_REPORT)
export const onGetNewAnomaliesReportError = makeActionCreator(GET_NEW_ANOMALIES_REPORT_ERROR, 'payload')
export const onGetNewAnomaliesReportSuccess = makeActionCreator(GET_NEW_ANOMALIES_REPORT_SUCCESS, 'payload')
export const onGetNewAnomaliesReportThunk = () => async (dispatch, getState) => {
  dispatch(onGetNewAnomaliesReport())

  try {
    const {
      apis: {
        base: { apiKey, apiName }
      }
    } = getState().auth

    const { data } = await getNewAnomaliesReport({
      actionType: GET_NEW_ANOMALIES_REPORT,
      apiKey,
      apiName
    })

    const csvArray = [data]

    return batch(() => {
      dispatch(
        onGetNewAnomaliesReportSuccess({
          csvArray
        })
      )
    })
  } catch (error) {
    console.log(error)
    return dispatch(onGetNewAnomaliesReportError({ error }))
  }
}

export const filterTableType = value => {
  var anomaliesTable = []
  if (value[0] === 'value-1') {
    anomaliesTable = value[1]
  } else if (value[0] === 'value-2') {
    anomaliesTable = value[1].filter(anomaly => anomaly.fuelLoadType === 'Excede capacidad')
  } else {
    anomaliesTable = value[1].filter(anomaly => anomaly.fuelLoadType === 'Carga Pequeña')
  }

  return anomaliesTable
}

export const onGetNewAnomaliesTableType = value => async (dispatch, getState) => {
  dispatch(onGetNewAnomalies())

  try {
    const tableFiltered = filterTableType(value)
    const anomaliesTable = tableFiltered.sort((a, b) => (a.anomalyDate > b.anomalyDate ? -1 : 1))

    const totalAnomalies = anomaliesTable.length
    const totalFuelLiters = anomaliesTable.reduce((a, b) => {
      return a + b.fuelLitres
    }, 0)
    const anomalies = value[1]

    const {
      originalAnomalies,
      anomaliesQuantityTotal,
      anomaliesQuantitySeparated,
      anomaliesFuelTotal,
      anomaliesFuelSeparated,
      totalExceden,
      totalCargaPequena,
      firstDate,
      lastDate
    } = getState().newAnomaly
    const tableFilter = value[0]

    return batch(() => {
      dispatch(
        onGetNewAnomaliesSuccess({
          originalAnomalies,
          newAnomalies: {
            anomalies: anomalies
          },
          anomaliesTable,
          anomaliesQuantityTotal,
          anomaliesQuantitySeparated,
          anomaliesFuelTotal,
          anomaliesFuelSeparated,
          totalAnomalies,
          totalFuelLiters,
          totalExceden,
          totalCargaPequena,
          firstDate,
          lastDate,
          tableFilter
        })
      )
    })
  } catch (error) {
    return dispatch(onGetNewAnomaliesError({ error }))
  }
}
