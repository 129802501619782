// FIXME: Need to be refactored
import React from 'react'
import PropTypes from 'prop-types'
import { Grid, Paper, Typography } from '@material-ui/core'
import iconAll from '../../constants/icons/allCAEX.svg'
import stationFix from '../../constants/icons/stationFix.svg'
import stationTime from '../../constants/icons/stationTime.svg'
import { CurrentShiftItem } from '../../components/CurrentShiftItem'
import useStyles from '../../styles/stationStyle'
import { RowResumenComponent } from './RowResumenComponent'

/**
 * The ResumenFixStations' container.
 */
const ResumenFixStations = ({ total, chartsData }) => {
  const classes = useStyles()

  const tooltipCAEX = (
    <Typography style={{ fontSize: 12, fontWeight: 'bold' }}>
      Todos los abastecimientos de <strong>CAEX</strong> del periodo observado, incluyendo los <strong>4 grupos</strong>
      , las <strong>estaciones fijas y móviles</strong>.
    </Typography>
  )

  const tooltipRepostaje = (
    <Typography style={{ fontSize: 12, fontWeight: 'bold' }}>
      Abastecimientos del periodo observado, realizados en <strong>estaciones fijas</strong>.
    </Typography>
  )

  const tooltipTime = (
    <Typography style={{ fontSize: 12, fontWeight: 'bold' }}>
      <strong>Tiempo promedio en cola</strong> en abastecimientos realizados en estaciones fijas durante el periodo
      observado.
    </Typography>
  )

  const resumenContentCard = () => {
    return (
      <Paper elevation={0} variant="outlined">
        <Grid container direction="column" justify="center" alignItems="stretch" style={{ padding: '10px' }}>
          <Grid item xs={12} style={{ textAlign: 'center' }}>
            <RowResumenComponent
              title="Número de abastecimientos totales CAEX (Móviles y fijas)"
              tooltipContent={tooltipCAEX}
              icon={iconAll}
              measurement={total.truck_total_charges}
            />
          </Grid>
          <Grid item xs={12} style={{ textAlign: 'center' }}>
            <RowResumenComponent
              title="Número de abastecimientos CAEX en estaciones fijas"
              tooltipContent={tooltipRepostaje}
              icon={stationFix}
              measurement={total.truck_fixed_station_charge}
            />
          </Grid>
          <Grid item xs={12} style={{ textAlign: 'center' }}>
            <RowResumenComponent
              title="Tiempo promedio en cola"
              tooltipContent={tooltipTime}
              icon={stationTime}
              measurement={`${parseInt(total.mean_queue_time)} min`}
            />
          </Grid>
        </Grid>
      </Paper>
    )
  }

  const resumenContentCircle = () => {
    return (
      <Paper elevation={0}>
        <Grid container direction="row" justify="center" alignItems="center">
          {chartsData.map(station => (
            <Grid key={station.station} item xs={4}>
              <CurrentShiftItem type="station" data={station} />
            </Grid>
          ))}
        </Grid>
      </Paper>
    )
  }

  return (
    <div style={{ marginTop: '10px', marginBottom: '50px', marginLeft: '20px', marginRight: '20px' }}>
      <Typography className={classes.titleResumen}>Todos los abastecimientos en estaciones fijas</Typography>
      <hr />
      <Grid container direction="row" justify="space-evenly" alignItems="center" style={{ marginTop: '20px' }}>
        <Grid item xs={4}>
          {resumenContentCard()}
        </Grid>
        <Grid item xs={7}>
          {resumenContentCircle()}
          <Typography style={{ textAlign: 'center', marginTop: '20px', fontSize: '13px', fontStyle: 'italic' }}>
            Comparador de número de cargas CAEX según estaciones
          </Typography>
        </Grid>
      </Grid>
    </div>
  )
}

ResumenFixStations.propTypes = {
  total: PropTypes.object,
  chartsData: PropTypes.array
}

export default ResumenFixStations
