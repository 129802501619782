import React from 'react'
import routes from '../config/settings/routes'
import ManagementSectionContainer from '../containers/ManagementMenu/ManagementMenu.container'
import NewAnomalyContent from '../containers/NewAnomaly/NewAnomalyContent.container'
import NewAnomalieDispatchOrpak from '../containers/dispatchOrpak/NewAnomalyDispatchOrpak.container'
import NewAnomalyTable from '../containers/NewAnomaly/NewAnomalyTable.container'
import NewAnomalyTopbar from '../containers/NewAnomalyTopbar/NewAnomalyTopbar.container'
import NavbarContainer from '../containers/Navbar/Navbar.container'
import BrandLayout from '../components/Layouts/BrandLayout'
import Heading from '../components/Layouts/Heading'
import ProtectByFeatures from '../components/Security/ProtectByFeatures'
import ProtectBySession from '../components/Security/ProtectBySession'
import NotFoundPage from './NotFound'
import Tab from '../components/Navigation/Tab'
import Tabs from '../components/Navigation/Tabs'
import { useTabs } from '../components/Navigation/Tab.hooks'
import { Wrapper, Root } from './NewAnomaly.styles'

/**
 * The AnomayDetection' page.
 */
const NewAnomaly = () => {
  const title = 'Abastecimientos anómalos'
  const { value: optionSelected, onChange: handleChangeTab } = useTabs({ initialValue: 'dashboard' })

  const renderTopbar = () => {
    return (
      <>
        <Root>
          <Wrapper>
            <Tabs
              aria-label="Example tabs"
              scrollButtons="on"
              value={optionSelected}
              variant="scrollable"
              onChange={handleChangeTab}
            >
              <Tab label="Dashboard" value="dashboard" />
              <Tab label="Registro" value="registro" />
              <Tab label="Jigsaw vs Orpak" value="orpak" />
            </Tabs>
          </Wrapper>
          <Wrapper>
            <NewAnomalyTopbar />
          </Wrapper>
        </Root>
      </>
    )
  }

  return (
    <ProtectBySession rule={(hasSession, signedIn) => !!(hasSession && signedIn)}>
      <ProtectByFeatures rule={features => features['com.cosmos/new.anomaly']} fallback={() => <NotFoundPage />}>
        <BrandLayout
          navbar={<NavbarContainer />}
          header={<Heading title={title} topbar={renderTopbar} />}
          sidebar={<ManagementSectionContainer current={routes.newAnomaly} />}
        >
          {optionSelected === 'dashboard' && <NewAnomalyContent />}
          {optionSelected === 'registro' && <NewAnomalyTable />}
          {optionSelected === 'orpak' && <NewAnomalieDispatchOrpak />}
        </BrandLayout>
      </ProtectByFeatures>
    </ProtectBySession>
  )
}

export default NewAnomaly
