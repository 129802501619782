import React from 'react'
import PropTypes from 'prop-types'
import { Bar, Line } from 'react-chartjs-2'
import Paper from '@material-ui/core/Paper'

const AnomalyGraph = props => {
  const { dataset, showbar } = props

  const isEmpty = Object.keys(dataset).length === 0

  const options = {
    maintainAspectRatio: false,
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true
          }
        }
      ]
    }
  }
  return (
    <>
      <Paper>
        {isEmpty && (
          <>
            <p2>No hay anomalías en el periodo seleccionado</p2>
          </>
        )}
        {showbar && <Bar options={options} height={450} data={dataset} redraw />}
        {!showbar && <Line options={options} height={450} data={dataset} redraw />}
      </Paper>
    </>
  )
}

AnomalyGraph.propTypes = {
  dataset: PropTypes.object,
  showbar: PropTypes.bool
}

export default AnomalyGraph
