import React from 'react'
import { useTheme } from 'styled-components'
import SankeyGraph from '../../components/Graphs/sankey.container'
import { Root } from './Examples.styles'

/**
 * The SankeyExample's container
 */

const SankeyExample = () => {
  const theme = useTheme()
  const source = [
    'Tiempo Nominal',
    'Tiempo Nominal',
    'Tiempo Disponible',
    'Tiempo Disponible',
    'Reservas',
    'Reservas',
    'Reservas',
    'Reservas',
    'Tiempo Operativo',
    'Tiempo Operativo',
    'Tiempo Operativo',
    'Tiempo Fuera de Servicio',
    'Tiempo Fuera de Servicio'
  ]
  const target = [
    'Tiempo Disponible',
    'Tiempo Fuera de Servicio',
    'Reservas',
    'Tiempo Operativo',
    'Tiempo Carga Combustible',
    'Tiempo en Colación',
    'Tiempo cambio de turno',
    'Tiempo recepción',
    'Perdidas Operacionales',
    'Demoras',
    'Tiempo Efectivo',
    'TMP',
    'TMNP'
  ]
  const weight = [537, 134, 163, 374, 50, 63, 30, 20, 56, 18, 300, 100, 34]
  const data = source.map((value, index) => {
    return [value, target[index], weight[index]]
  })
  const sankeyData = [['From', 'To', 'Horas'], ...data]
  const options = {
    sankey: {
      iterations: 0,
      link: { color: { fill: theme.palette.brand.primary[40] } },
      node: {
        colors: [theme.palette.brand.primary[50]],
        label: { color: '#871b47', fontName: theme.palette.brand.black }
      }
    }
  }
  return (
    <Root>
      <SankeyGraph chartType="Sankey" width="100%" height="500px" data={sankeyData} options={options} />
    </Root>
  )
}

export default SankeyExample
