import styled from 'styled-components'
import { CircularProgress } from '@material-ui/core'
import { Link } from 'react-router-dom'
import { hexToRGBA } from '../../modules/utils/color'

export const Loading = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  width: 100%;
`

export const LoadingIcon = styled(CircularProgress)`
  &.MuiCircularProgress-root {
    color: inherit;
  }
`

const BaseLinkButton = styled(Link)`
  align-items: center;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 4px;
  box-shadow: 0px 0px 2px ${({ disabled, theme }) => hexToRGBA(theme.palette.brand.black, disabled ? 0.06 : 0.12)},
    0px 2px 2px ${({ disabled, theme }) => hexToRGBA(theme.palette.brand.black, disabled ? 0.12 : 0.24)};
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  display: inline-flex;
  font-family: ${props => props.theme.typography.fontFamily};
  font-size: ${({ size }) => {
    if (size === 'large') {
      return '18px'
    } else if (size === 'small') {
      return '12px'
    } else {
      return '14px'
    }
  }};
  font-weight: ${props => props.theme.fontWeights[500]};
  height: ${({ size }) => {
    if (size === 'large') {
      return '48px'
    } else if (size === 'small') {
      return '28px'
    } else {
      return '38px'
    }
  }};
  justify-content: center;
  letter-spacing: 1.25px;
  line-height: 1.75;
  margin: ${props => props.margin};
  padding: ${({ size }) => {
    if (size === 'large') {
      return '0 3.983rem'
    } else if (size === 'small') {
      return '0 2.112rem'
    } else {
      return '0 2.79rem'
    }
  }};
  pointer-events: ${props => (props.disabled ? 'none' : 'auto')};
  text-decoration: none;
  text-transform: uppercase;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
    color 0.15s ease-in-out;
  user-select: none;
  width: ${props => props.width};

  &:focus {
    box-shadow: 0 0 0 0.2rem ${({ color, theme }) => hexToRGBA(theme.palette.brand[color][50], 0.4)};
    outline: 0;
  }

  &:active {
    transform: scale(0.98);
  }
`

export const ContainedButton = styled(BaseLinkButton)`
  background-color: ${({ color, disabled, theme }) => theme.palette.brand[color][disabled ? 75 : 50]};
  border-color: ${({ color, disabled, theme }) => theme.palette.brand[color][disabled ? 75 : 50]};
  color: ${props => props.theme.palette.brand.white};

  &:hover {
    background-color: ${({ color, disabled, theme }) => theme.palette.brand[color][disabled ? 75 : 35]};
    border-color: ${({ color, disabled, theme }) => theme.palette.brand[color][disabled ? 75 : 35]};
  }
`

export const OutlinedButton = styled(BaseLinkButton)`
  background-color: ${({ color, disabled, theme }) => theme.palette.brand[color][disabled ? 75 : 95]};
  border-color: ${({ color, disabled, theme }) => theme.palette.brand[color][disabled ? 75 : 50]};
  box-shadow: ${({ disabled, theme }) =>
    disabled
      ? `box-shadow: 0px 0px 2px ${hexToRGBA(theme.palette.brand.black, 0.06)}, 0px 2px 2px ${hexToRGBA(
          theme.palette.brand.black,
          0.12
        )}`
      : 'none'};
  color: ${({ color, disabled, theme }) => (disabled ? theme.palette.brand.white : theme.palette.brand[color][50])};

  &:hover {
    background-color: ${({ color, disabled, theme }) => theme.palette.brand[color][disabled ? 75 : 50]};
    border-color: ${({ color, disabled, theme }) => theme.palette.brand[color][disabled ? 75 : 50]};
    box-shadow: 0px 0px 2px ${({ disabled, theme }) => hexToRGBA(theme.palette.brand.black, disabled ? 0.06 : 0.12)},
      0px 2px 2px ${({ disabled, theme }) => hexToRGBA(theme.palette.brand.black, disabled ? 0.12 : 0.24)};
    color: ${props => props.theme.palette.brand.white};
  }
`

export const TextButton = styled(BaseLinkButton)`
  background-color: ${({ color, disabled, loading, theme }) => {
    if (disabled) {
      return theme.palette.brand[color][75]
    }
    if (loading) {
      return theme.palette.brand[color][95]
    }
    return 'transparent'
  }};
  border: ${({ color, disabled, theme }) => (disabled ? theme.palette.brand[color][75] : 'none')};
  box-shadow: ${({ disabled, theme }) =>
    disabled
      ? `box-shadow: 0px 0px 2px ${hexToRGBA(theme.palette.brand.black, 0.06)}, 0px 2px 2px ${hexToRGBA(
          theme.palette.brand.black,
          0.12
        )}`
      : 'none'};
  color: ${({ color, disabled, theme }) => (disabled ? theme.palette.brand.white : theme.palette.brand[color][50])};

  &:hover {
    background-color: ${({ color, disabled, theme }) => theme.palette.brand[color][disabled ? 75 : 95]};
  }
`
