import Cookie from 'js-cookie'
import Logger from '../debuggers/logger'

const logger = Logger.getLogger('Cookies')

/**
 * To get a value from cookie.
 */
export const getFromCookie = (key, options = {}) => {
  try {
    let entry = Cookie.get(key)

    if (entry && options.base64) {
      entry = window.atob(entry)
    }

    if (entry && options.json) {
      entry = JSON.parse(entry)
    }

    return entry
  } catch (error) {
    logger.warn(`Unable to get data from cookie ${key}`, error)
  }
}

/**
 * To put a value into cookie.
 */
export const putIntoCookie = (key, value, options = {}) => {
  const { base64, json, ...customConfig } = options
  const config = { ...customConfig, path: '/' }

  try {
    let entry = json ? JSON.stringify(value) : value

    if (base64) {
      entry = window.btoa(entry)
    }

    Cookie.set(key, entry, config)
  } catch (error) {
    logger.warn(`Unable to put data into cookie ${key}`, error)
  }
}

/**
 * To remove a cookie.
 */
export const removeCookie = key => {
  try {
    Cookie.remove(key)
  } catch (error) {
    logger.warn(`Unable to remove cookie ${key}`, error)
  }
}
