// FIXME: Need to be refactored
import React from 'react'
import PropTypes from 'prop-types'
import GroupStation from './GroupStation.container'
import gas from '../../constants/icons/gasFill.svg'
import time from '../../constants/icons/timeOutline.svg'
import { GridList, GridListTile, Paper, Grid } from '@material-ui/core'

/**
 * The FixStationContent' container.
 */
const FixStationContent = ({ groups }) => {
  return (
    <div>
      {/* {symbology()} */}
      <GridList cellHeight="auto" spacing={10}>
        {groups.map(group => {
          return (
            <GridListTile cols={1} rows={1} key={'card-' + group.name}>
              <Paper style={{ padding: 10 }} elevation={5} square={false} variant="outlined">
                <Grid container direction="row" justify="center" alignItems="center">
                  <Grid item xs={12} style={{ margin: '5px' }}>
                    <h2 style={{ marginBottom: '10px' }}>
                      <strong>{group.name}</strong>
                    </h2>
                    <h4 style={{ marginBottom: '10px', marginTop: '0px', display: 'flex' }}>
                      <img src={gas} alt="gas" style={{ marginRight: '5px' }} /> {group.truck_total_charges}{' '}
                      abastecimientos <img src={time} alt="time" style={{ marginLeft: '10px', marginRight: '5px' }} />{' '}
                      {parseInt(group.mean_queue_time)} min promedio en cola
                    </h4>
                  </Grid>
                </Grid>
                <GroupStation key={'Card-' + group.name} fixStations={group} />
              </Paper>
            </GridListTile>
          )
        })}
      </GridList>
    </div>
  )
}

FixStationContent.propTypes = {
  groups: PropTypes.array
}

export default FixStationContent
