import { ERROR } from '../actions/types'

const initialError = {
  error: false,
  type: null,
  data: null
}

export default function (state = initialError, action) {
  switch (action.type) {
    case ERROR:
      return {
        ...state,
        error: true,
        type: action.payload.type,
        data: action.payload.data
      }
    default:
      return state
  }
}
