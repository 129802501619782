class TankerModel {
  constructor() {
    this.patent = ''
    this.fuel_level = 0.0
    this.percent = 0.0
    this.last_charge_day = ''
    this.last_charge_hour = ''
    this.active = false
  }

  fromV1 = tanker => {
    this.patent = tanker.tanker_patent
    this.fuel_level = tanker.tanker_actual_litres
    this.percent = tanker.tanker_percent
    this.last_charge_day = tanker.tanker_date_last_charge_day
    this.last_charge_hour = tanker.tanker_date_last_charge_time
    this.active = tanker.tanker_status === 1
    return this
  }
}

export default TankerModel
