import React, { useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector, batch } from 'react-redux'
import { addMonths } from 'date-fns'
import PropTypes from 'prop-types'
import { useViewportSize } from '../../modules/hooks/viewport'
import { formatDate } from '../../modules/utils/formatters'
import {
  onChangeHardBrakingGroupSelectedThunk,
  onGetHardBrakingThunk,
  onGetHardBrakeReportThunk
} from './HardBraking.actions'
import ConsumptionOperatorAlert from '../ConsumptionOperatorAlert/ConsumptionOperatorAlert.component'
import HardBrakingTable from './HardBraking.table'
import Accordion from '../../components/Surfaces/Accordion'
import AccordionSummary from '../../components/Surfaces/AccordionSummary'
import Alert from '../../components/Basics/Alert'
import Skeleton from '../../components/Placeholders/Skeleton'
import Tag from '../../components/Basics/Tag'
import Typography from '../../components/Basics/Typography'
import Button from '../../components/Buttons/Button'
import { CSVLink } from 'react-csv'
import { AccordionDetails, Arrow, Root, Separator, Strong, SummaryWrapper, Wrapper } from './HardBraking.styles'

/**
 * The Hard braking' container.
 */
const HardBraking = props => {
  const dispatch = useDispatch()
  const { onToggleModal } = props
  const { isTiny } = useViewportSize()
  const csvLink = useRef()
  const {
    consultationDate,
    error,
    groups,
    loading,
    selectedGroupId,
    reportLoading,
    reportSuccess,
    reportData
  } = useSelector(state => state.hardBraking)
  const [expanded, setExpanded] = useState(selectedGroupId)
  const currentDate = addMonths(new Date(), -1)

  useEffect(() => {
    if (!loading) {
      return
    }

    batch(() => {
      dispatch(onGetHardBrakingThunk())
      dispatch(onGetHardBrakeReportThunk())
    })
  }, [])

  useEffect(() => {
    setExpanded(selectedGroupId)
  }, [selectedGroupId])

  const handleChange = panel => (_, isExpanded) => {
    setExpanded(isExpanded ? panel : false)
    dispatch(onChangeHardBrakingGroupSelectedThunk({ groupId: isExpanded ? panel : false }))
  }

  return (
    <Root>
      {consultationDate && formatDate(consultationDate, 'yyyy-MM') !== formatDate(currentDate, 'yyyy-MM') && (
        <ConsumptionOperatorAlert />
      )}

      <Wrapper>
        <Typography margin="0 0 10px" variant="h3">
          <Strong>Frenados Bruscos -</Strong> Operadores con cantidad de frenados bruscos superior a la media
        </Typography>
        <Typography fontSize="12px" lineHeight="16px" variant="body2">
          Se considera frenado brusco cuando hay una reducción importante o total de velocidad y, en un par de segundos,
          ésta vuelve a elevarse. La evaluación está definida por el percentil 80, y la tabla a continuación muestra a
          los operadores que exceden dichas cantidades.
        </Typography>
      </Wrapper>
      <Separator />

      {loading && !error && (
        <Wrapper>
          <Skeleton height="63px" margin="0 0 10px" variant="rect" />
          <Skeleton height="63px" margin="0 0 10px" variant="rect" />
          <Skeleton height="63px" margin="0 0 10px" variant="rect" />
          <Skeleton height="63px" margin="0 0 10px" variant="rect" />
        </Wrapper>
      )}

      {!loading && !error && (
        <Wrapper>
          {groups.map((group, index) => {
            return (
              <Accordion
                key={`accordeon-${index}`}
                expanded={expanded === `group-${group.id}`}
                onChange={handleChange(`group-${group.id}`)}
              >
                <AccordionSummary
                  aria-controls={`group-content-${group.id}`}
                  flexWrap="wrap"
                  id={`group-content-${group.id}`}
                >
                  <Typography fontWeight="bold" variant="h4">
                    {group.name.toUpperCase()}
                  </Typography>

                  <Arrow />

                  {!isTiny && (
                    <>
                      <SummaryWrapper margin="2px 30px 2px 0">
                        <Tag color="primary" margin="0 10px 0 0" text={group.p8QuantityHardBraking} />
                        <Typography variant="body2">P80 cantidad de frenados bruscos</Typography>
                      </SummaryWrapper>

                      <SummaryWrapper margin="2px 0">
                        <Tag color="primary" margin="0 10px 0 0" text={group.sumLitersOverConsumtion} />
                        <Typography variant="body2">Litros sobreconsumo</Typography>
                      </SummaryWrapper>
                    </>
                  )}
                </AccordionSummary>

                <AccordionDetails>
                  <HardBrakingTable group={group} groupId={`group-${group.id}`} onToggleModal={onToggleModal} />
                </AccordionDetails>
              </Accordion>
            )
          })}
          {!reportLoading && reportSuccess && (
            <CSVLink
              style={{ textDecoration: 'none' }}
              data={reportData[0]}
              filename="base consolidada frenados.csv"
              ref={csvLink}
              target="_blank"
            >
              <Button color="primary" size="small" variant="outlined">
                Descargar
              </Button>
            </CSVLink>
          )}
          {reportLoading && (
            <Button loading color="primary" size="small" variant="outlined" margin="5px 650px 0 0">
              Cargando
            </Button>
          )}
        </Wrapper>
      )}

      {!loading && error && (
        <Alert severity="default">
          <Strong>Lo sentimos, estamos presentando problemas con nuestro servidor</Strong>
          <br />
          En estos momentos tenemos problemas para disponibilizar la información. Por favor, consulta en unos minutos
          más.
        </Alert>
      )}
    </Root>
  )
}

HardBraking.defaultProps = {
  onToggleModal: () => undefined
}
HardBraking.propTypes = {
  onToggleModal: PropTypes.func
}

export default HardBraking
