import React from 'react'
import { useSelector, useDispatch, batch } from 'react-redux'
import { usePagination } from '../../components/Tables/Pagination.hooks'
import Pagination from '../../components/Tables/Pagination'
import { Root } from './Tires.styles'
import Table from '../../components/Tables/Table'
import TableCell from '../../components/Tables/TableCell'
import TableRow from '../../components/Tables/TableRow'
import TireOperatorAnomalyModal from './TireOperatorAnomalyModal.container'
import { onAddOperatorRowModalThunk, onToggleOperatorRowModalSuccess } from './Tires.actions'

/**
 * The Tire Anomalie's container
 */

const TireOperatorContainer = () => {
  const dispatch = useDispatch()
  const { groupedOperator, showOperatorRowModal } = useSelector(state => state.anomalyTires)
  const { page, totalPages, onChangePage, onGetCurrentPage } = usePagination({
    data: groupedOperator,
    initialPage: 1,
    rowsPerPage: 15
  })

  const onToggleModal = row => {
    batch(() => {
      dispatch(onAddOperatorRowModalThunk(row))
      dispatch(onToggleOperatorRowModalSuccess())
    })
  }
  return (
    <>
      <Root>
        <Table accessibility={{ label: 'Caex grouped' }}>
          <TableRow head>
            <TableCell>Operador</TableCell>
            <TableCell>Total Anomalías</TableCell>
            <TableCell>Camion/es</TableCell>
          </TableRow>

          {onGetCurrentPage().map((row, index) => {
            return (
              <TableRow onClick={() => onToggleModal(row)} key={`operador-${index}`} hover>
                <TableCell align="center">{row.operator}</TableCell>
                <TableCell align="center">{row.total}</TableCell>
                <TableCell align="center">{row.trucks}</TableCell>
              </TableRow>
            )
          })}
        </Table>
        {showOperatorRowModal && <TireOperatorAnomalyModal />}
        <Pagination alignment="right" count={totalPages} page={page} onChange={onChangePage} />
      </Root>
    </>
  )
}

export default TireOperatorContainer
