import styled from 'styled-components'
import { ButtonBase, CircularProgress } from '@material-ui/core'
import { hexToRGBA } from '../../modules/utils/color'

export const Loading = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  width: 100%;
`

export const LoadingIcon = styled(CircularProgress)`
  &.MuiCircularProgress-root {
    color: inherit;
  }
`

const BaseButton = styled(ButtonBase)`
  &.MuiButtonBase-root {
    align-items: center;
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: 4px;
    box-shadow: 0px 0px 2px ${props => hexToRGBA(props.theme.palette.brand.black, 0.12)},
      0px 2px 2px ${props => hexToRGBA(props.theme.palette.brand.black, 0.24)};
    cursor: pointer;
    display: inline-flex;
    font-family: ${props => props.theme.typography.fontFamily};
    font-size: ${({ size }) => {
      if (size === 'large') {
        return '18px'
      } else if (size === 'small') {
        return '12px'
      } else {
        return '14px'
      }
    }};
    font-weight: ${props => props.theme.fontWeights[500]};
    height: ${({ size }) => {
      if (size === 'large') {
        return '50px'
      } else if (size === 'small') {
        return '30px'
      } else {
        return '40px'
      }
    }};
    justify-content: center;
    letter-spacing: 1.25px;
    line-height: 1.75;
    margin: ${props => props.margin};
    min-width: ${props => props.width};
    padding: ${({ size }) => {
      if (size === 'large') {
        return '.91rem 4rem'
      } else if (size === 'small') {
        return '.28rem 2.13rem'
      } else {
        return '.56rem 2.81rem'
      }
    }};
    text-transform: uppercase;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
      color 0.15s ease-in-out;
    user-select: none;
    width: ${props => props.width};

    &.Mui-disabled {
      background-color: ${({ color, theme }) => theme.palette.brand[color][75]};
      border-color: ${({ color, theme }) => theme.palette.brand[color][75]};
      box-shadow: 0px 0px 2px ${props => hexToRGBA(props.theme.palette.brand.black, 0.06)},
        0px 2px 2px ${props => hexToRGBA(props.theme.palette.brand.black, 0.12)};
      cursor: not-allowed;
      pointer-events: none;
      user-select: none;
    }

    &:focus {
      box-shadow: 0 0 0 0.2rem ${({ color, theme }) => hexToRGBA(theme.palette.brand[color][50], 0.4)};
      outline: 0;
    }

    &:active {
      transform: scale(0.98);
    }

    &.MuiTouchRipple-root {
      display: none;
    }
  }
`

export const ContainedButton = styled(BaseButton)`
  &.MuiButtonBase-root {
    background-color: ${({ color, theme }) => theme.palette.brand[color][50]};
    border-color: ${({ color, theme }) => theme.palette.brand[color][50]};
    color: ${props => props.theme.palette.brand.white};

    &:hover {
      background-color: ${({ color, theme }) => theme.palette.brand[color][35]};
      border-color: ${({ color, theme }) => theme.palette.brand[color][35]};
      color: ${props => props.theme.palette.brand.white};
    }
  }
`

export const OutlinedButton = styled(BaseButton)`
  &.MuiButtonBase-root {
    background-color: ${({ color, theme }) => theme.palette.brand[color][95]};
    border: 1px solid ${({ color, theme }) => theme.palette.brand[color][50]};
    box-shadow: none;
    color: ${({ color, theme }) => theme.palette.brand[color][50]};

    &.Mui-disabled {
      color: ${props => props.theme.palette.brand.white};
    }

    &:hover {
      background-color: ${({ color, theme }) => theme.palette.brand[color][50]};
      border-color: ${({ color, theme }) => theme.palette.brand[color][50]};
      box-shadow: 0px 0px 2px ${props => hexToRGBA(props.theme.palette.brand.black, 0.12)},
        0px 2px 2px ${props => hexToRGBA(props.theme.palette.brand.black, 0.24)};
      color: ${props => props.theme.palette.brand.white};
    }
  }
`

export const TextButton = styled(BaseButton)`
  &.MuiButtonBase-root {
    background-color: ${({ color, loading, theme }) => (loading ? theme.palette.brand[color][95] : 'transparent')};
    border: none;
    box-shadow: none;
    color: ${({ color, theme }) => theme.palette.brand[color][50]};

    &.Mui-disabled {
      color: ${props => props.theme.palette.brand.white};
    }

    &:hover {
      background-color: ${({ color, theme }) => theme.palette.brand[color][95]};
    }
  }
`
