import React from 'react'
import { usePagination } from '../../components/Tables/Pagination.hooks'
import Pagination from '../../components/Tables/Pagination'
import Table from '../../components/Tables/Table'
import TableCell from '../../components/Tables/TableCell'
import TableRow from '../../components/Tables/TableRow'
import { Root } from './Examples.styles'

/**
 * The TableExample's container.
 */
const TableExample = () => {
  const operadores = [
    {
      nombre: 'Juan Antonio Perez García',
      turnos: '7 turnos',
      horasMotor: '43 horas',
      horasRalenti: '13 horas',
      tipoOperativoRalenti: '30%',
      impactoLitros: '624 litros',
      reforzamiento: true
    },
    {
      nombre: 'Manuel Chandía Campos',
      turnos: '5 turnos',
      horasMotor: '35 horas',
      horasRalenti: '11.6 horas',
      tipoOperativoRalenti: '33%',
      impactoLitros: ' 606 litros',
      reforzamiento: true
    },
    {
      nombre: 'José Miguel Herrera Pereira',
      turnos: '7 turnos',
      horasMotor: '48 horas',
      horasRalenti: '13.3 horas',
      tipoOperativoRalenti: '28%',
      impactoLitros: '540 litros',
      reforzamiento: false
    },
    {
      nombre: 'Armando Cáceres Aguilera',
      turnos: '8 turnos',
      horasMotor: '44 horas',
      horasRalenti: '12 horas',
      tipoOperativoRalenti: '27%',
      impactoLitros: '468 litros',
      reforzamiento: false
    },
    {
      nombre: 'Martín Mena Gómez',
      turnos: '5 turnos',
      horasMotor: '38.6 horas',
      horasRalenti: '10.3 horas',
      tipoOperativoRalenti: '27%',
      impactoLitros: '394 litros',
      reforzamiento: false
    },
    {
      nombre: 'Juan Pablo Peña Arellano',
      turnos: '8 turnos',
      horasMotor: '48 horas',
      horasRalenti: '10.8 horas',
      tipoOperativoRalenti: '22%',
      impactoLitros: '259 litros',
      reforzamiento: false
    },
    {
      nombre: 'Manuel Carvajal Godoy',
      turnos: '1 turno',
      horasMotor: '9.5 horas',
      horasRalenti: '2.9 horas',
      tipoOperativoRalenti: '31%',
      impactoLitros: '145 litros',
      reforzamiento: false
    }
  ]

  const { page, totalPages, onChangePage, onGetCurrentPage } = usePagination({
    data: operadores,
    initialPage: 1,
    rowsPerPage: 5
  })

  return (
    <Root>
      <Table accessibility={{ label: 'Grupo 1' }}>
        <TableRow head>
          <TableCell>Operador</TableCell>
          <TableCell>Sum Turnos Operativos</TableCell>
          <TableCell>Sum hrs motor encendido</TableCell>
          <TableCell>Sum Horas Ralentí</TableCell>
          <TableCell>% Tpo operativo en ralentí</TableCell>
          <TableCell>Impacto Litros fuera de media</TableCell>
          <TableCell>Reforzamiento</TableCell>
        </TableRow>

        {onGetCurrentPage().map((row, index) => {
          return (
            <TableRow key={`operador-${index}`}>
              <TableCell align="center">{row.nombre}</TableCell>
              <TableCell align="center">{row.turnos}</TableCell>
              <TableCell align="center">{row.horasMotor}</TableCell>
              <TableCell align="center">{row.horasRalenti}</TableCell>
              <TableCell align="center">{row.tipoOperativoRalenti}</TableCell>
              <TableCell align="center">{row.impactoLitros}</TableCell>
              <TableCell align="center">{row.reforzamiento ? 'true' : 'false'}</TableCell>
            </TableRow>
          )
        })}
      </Table>

      <Pagination alignment="right" count={totalPages} page={page} onChange={onChangePage} />
    </Root>
  )
}

export default TableExample
