import { useEffect, useState } from 'react'

/**
 * To apply a bunch of formatters to the given value.
 */
const makeFormat = (callbacks, value) => callbacks.reduce((updatedValue, callback) => callback(updatedValue), value)

/**
 * To apply a bunch of validators to the given value.
 */
const makeError = (callbacks, value) =>
  callbacks.reduce((message, callback) => {
    if (message !== '') {
      return message
    }

    return callback(value)
  }, '')

/**
 * The Autocomplete' custom hook.
 */
export const useAutocomplete = ({
  changeCallback = () => undefined,
  clearAfterSelect = false,
  errorCallbacks = [],
  formatCallbacks = [],
  initialOption = null,
  initialValue = '',
  selectCallback = () => undefined
} = {}) => {
  const [error, setError] = useState('')
  const [option, setOption] = useState(initialOption)
  const [value, setValue] = useState(initialValue)
  const handleChange = event => {
    const updatedValue = makeFormat(formatCallbacks, event?.target?.value || '')
    const updatedError = makeError(errorCallbacks, updatedValue)

    setError(updatedError)
    setValue(updatedValue)

    if (updatedValue === value) {
      return
    }

    changeCallback(updatedValue)
  }
  const handleSelect = (_, updatedOption) => {
    const updatedError = makeError(errorCallbacks, updatedOption?.name || '')

    if (!clearAfterSelect) {
      setValue(updatedOption?.name || '')
    }

    setError(updatedError)
    setOption(updatedOption)
    selectCallback(updatedOption)
  }

  useEffect(() => {
    setOption(initialOption)
  }, [initialOption])

  useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  return { error, option, value, onChange: handleChange, onSelect: handleSelect }
}

/**
 * The CheckBox's custom hook.
 */
export const useCheckBox = ({ initialValue = false, changeCallback = () => undefined } = {}) => {
  const [value, setValue] = useState(initialValue)
  const handleChange = event => {
    changeCallback(event?.target?.checked, event?.target?.name)
    setValue(event?.target?.checked)
  }

  useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  return { value, onChange: handleChange }
}

/**
 * The DatePicker's custom hook.
 */
export const useDatePicker = ({
  changeCallback = () => undefined,
  errorCallbacks = [],
  formatCallbacks = [],
  initialValue = new Date()
} = {}) => {
  const [error, setError] = useState('')
  const [value, setValue] = useState(initialValue)
  const handleChange = date => {
    const updatedValue = makeFormat(formatCallbacks, date === null ? '' : date)
    const updatedError = makeError(errorCallbacks, updatedValue)

    setError(updatedError)
    setValue(updatedValue)

    if (updatedValue === value) {
      return
    }

    changeCallback(updatedValue)
  }

  return { error, value, setValue, onChange: handleChange }
}

/**
 * The File's custom hook.
 */
export const useFile = ({ changeCallback = () => undefined, errorCallbacks = [], initialValue = '' } = {}) => {
  const [error, setError] = useState('')
  const [value, setValue] = useState(initialValue)
  const checkError = updatedValue => {
    const updatedError = makeError(errorCallbacks, updatedValue)

    setError(updatedError)
  }
  const handleChange = files => {
    const [updatedValue] = files

    checkError(updatedValue)
    setValue(updatedValue)

    if (updatedValue === value) {
      return
    }

    changeCallback(updatedValue)
  }

  useEffect(() => {
    checkError(initialValue)
    setValue(initialValue)
  }, [initialValue])

  return { error, value, setValue, onChange: handleChange }
}

/**
 * The Input's custom hook.
 */
export const useInput = ({
  changeCallback = () => undefined,
  errorCallbacks = [],
  formatCallbacks = [],
  initialValue = '',
  pasteCallback = () => undefined
} = {}) => {
  const [error, setError] = useState('')
  const [value, setValue] = useState(initialValue)
  const handleChange = event => {
    const updatedValue = makeFormat(formatCallbacks, event?.target?.value || '')
    const updatedError = makeError(errorCallbacks, updatedValue)

    setError(updatedError)
    setValue(updatedValue)

    if (updatedValue === value) {
      return
    }

    changeCallback(updatedValue)
  }
  const handlePaste = event => pasteCallback(event)

  const executeErrors = () => {
    const updatedError = makeError(errorCallbacks, value)
    setError(updatedError)
  }

  useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  return { error, value, setValue, onChange: handleChange, onPaste: handlePaste, executeErrors }
}

/**
 * The Radio's custom hook.
 */
export const useRadio = ({ initialValue = '' } = {}) => {
  const [value, setValue] = useState(initialValue)
  const handleChange = event => setValue(event?.target?.value)

  return { value, onChange: handleChange }
}

/**
 * The Select' custom hook.
 */
export const useSelect = ({ changeCallback = () => undefined, errorCallbacks = [], initialValue = '' } = {}) => {
  const [error, setError] = useState('')
  const [value, setValue] = useState(initialValue)
  const handleChange = event => {
    const updatedValue = event?.target?.value || ''
    const updatedError = makeError(errorCallbacks, updatedValue)

    setError(updatedError)
    setValue(updatedValue)

    if (updatedValue === value) {
      return
    }

    changeCallback(updatedValue)
  }
  const handleReset = () => setValue(initialValue)

  useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  return { error, value, onChange: handleChange, onReset: handleReset }
}
