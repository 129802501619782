import React from 'react'
import Mybadge from './Basics/Badge'
import { Paper, Chip, Typography, Badge } from '@material-ui/core'
import Tooltip from './Basics/Tooltip'
import PropTypes from 'prop-types'
import { capitalize } from '../modules/utils/helpers'
import moment from 'moment'
// eslint-disable-next-line
import { default as truckImg } from '../constants/images/truck.svg'
// eslint-disable-next-line
import { default as truckImgFront } from '../constants/images/front.svg'
// eslint-disable-next-line
import { default as truckOffImg } from '../constants/images/GroupTruckDisabled.svg'
// eslint-disable-next-line
import { default as truckFrontOffImg } from '../constants/images/frontOff.svg'
// eslint-disable-next-line
import { default as gas } from '../constants/icons/gasStation.svg'
// eslint-disable-next-line
import { default as gasStation } from '../constants/icons/gasStationFill.svg'
// eslint-disable-next-line
import { default as symbology8Out } from '../constants/icons/tankSymbology8.svg'

const TruckVehicle = ({ truckData, color }) => {
  const [, setAnchorEl] = React.useState(null)
  const [, setOpen] = React.useState(false)
  const [placement, setPlacement] = React.useState()
  // const [style, setStyle] = useState({color: 'white', backgroundColor: color});
  // const [variant, setVariant] = useState('default');

  let variant = 'default'
  let style = {}
  // eslint-disable-next-line
  let icon = ''
  let hiddenBadge = false
  let truck = ''
  const optionTooltip = 'connected'

  const onHover = newPlacement => event => {
    setAnchorEl(event.currentTarget)
    setOpen(prev => placement !== newPlacement || !prev)
    setPlacement(newPlacement)
  }

  const tooltipTruck = (option, truckData) => {
    const now = moment()
    const lastCharge = truckData.last_charge_day + ' ' + truckData.last_charge_hour
    const lastChargeTime = moment(lastCharge, 'DD/MM/YYYY HH:mm:ss')
    const daysDiff = now.diff(lastChargeTime, 'days')
    const hoursDiff = now.diff(lastChargeTime, 'hours')
    const pluralDay = daysDiff > 1 ? ' días' : ' día'
    const pluralHour = hoursDiff <= 1 ? ' hora' : ' horas'
    const lastChargeString = daysDiff > 0 ? 'Hace ' + daysDiff + pluralDay : 'Hace ' + hoursDiff + pluralHour
    const lastChargeStation =
      truckData.charge_station.length > 0 ? 'Lugar de abastecimiento: ' + truckData.charge_station[0] : ''
    switch (option) {
      default:
        return (
          <>
            <Paper style={{ padding: 10, color: 'white', background: 'black' }}>
              <Typography style={{ fontSize: 12 }}>
                <strong>{'Camión: ' + truckData.patent}</strong>
                <br />
                <span>
                  Nivel de combustible: <b>{Math.round(truckData.percent)} %</b> -
                </span>
                {/* 'Nivel de combustible: ' + Math.round(truckData.percent) + '% -' */}
                <br />
                <span>
                  Tasa de consumo: <b>{Math.round(truckData.fuel_rate)} L/H</b> en el último ciclo -
                </span>
                <br />
                <span>
                  <b>{Math.round(truckData.fuel_level)}</b> litros en estanque.
                </span>
                {/* Math.round(truckData.fuel_level) + ' litros en estanque.' */}
                <br />
                <span>
                  Última carga: <b>{lastChargeString}</b> -
                </span>
                {/* 'Última carga: ' + lastChargeString */}
                <br />
                Cantidad litros última carga: {truckData.truck_last_charge}
                <br />
                {truckData.last_charge_day + ' a las ' + truckData.last_charge_hour + '.'}
                <br />
                {lastChargeStation}
                <br />
                Fuente: {capitalize(truckData.source)}
              </Typography>
            </Paper>
          </>
        )
    }
  }

  const renderTruck = (optionTooltip, truckData) => {
    // if (truckData.disconnected_sources) {
    //   icon = offline;
    //   hiddenBadge = false;
    //   optionTooltip = 'anyDisconnected';
    // }
    // if (truckData.all_disconnected_sources) {
    //   icon = cloudOff;
    //   hiddenBadge = false;
    //   optionTooltip = 'allDisconnected';
    // }
    // if (truckData.loading) {
    //   icon = sync;
    //   hiddenBadge = false;
    //   optionTooltip = 'sync';
    // }

    // set truck active status
    if (truckData.active) {
      truck = truckData.percent < 25 ? truckImgFront : truckImg
    } else {
      truck = truckData.percent < 25 ? truckFrontOffImg : truckOffImg
    }

    if (truckData.percent < 25) {
      icon = symbology8Out
      hiddenBadge = false
    }

    // set truck supply assignment tooltip
    if (truckData.go_supply) {
      optionTooltip = 'goSupply'
      hiddenBadge = false
      // hiddenBadge = false
      // icon = gasStation
    }

    if (truckData.active && truckData.go_supply) {
      style = { color: 'rgb(51, 51, 51)', borderColor: 'rgb(51, 51, 51)', backgroundColor: 'transparent' }
      variant = 'outlined'
    } else if (!truckData.active && truckData.go_supply) {
      style = { color: '#BDBDBD' }
    } else if (truckData.active && !truckData.go_supply) {
      variant = 'outlined'
      style = { color: color, borderColor: color }
    } else if (!truckData.active && !truckData.go_supply) {
      variant = 'outlined'
      style = { color: '#BDBDBD', borderColor: '#BDBDBD' }
      optionTooltip = 'disconnected'
    }

    // none of the trucks in the last truck show badges or different colors
    if (truckData.percent >= 50) {
      hiddenBadge = false
      variant = 'outlined'
      style = { color: 'rgb(51, 51, 51)', borderColor: 'rgb(51, 51, 51)', backgroundColor: 'transparent' }
    }

    // // asignado a abastecimiento
    // if (truckData.active && truckData.go_supply) {
    //   variant = "default";
    //   style = { color: 'white', backgroundColor: color };
    //   // truck = bounceTruck;
    //   truck = truckImg;
    //   optionTooltip = 'goSupply';
    //   icon = gasStation;
    //   hiddenBadge = false;
    // }

    // // asignado a abastecimiento y no activo
    // if (!truckData.active && truckData.go_supply) {
    //   variant = "default";
    //   style = { color: '#BDBDBD' };
    //   truck = truckOffImg;
    //   optionTooltip = 'goSupply';
    //   icon = gasStation;
    //   hiddenBadge = false;
    // }

    // if (truckData.active && !truckData.go_supply) {
    //   variant = "outlined";
    //   style = { color: color, borderColor: color };
    //   truck = truckImg;
    // }

    // //Ciclo extenso de combustible
    // if (!truckData.active && !truckData.go_supply) {
    //   variant = "outlined";
    //   style = { color: '#BDBDBD', borderColor: '#BDBDBD' };
    //   truck = truckOffImg;
    //   optionTooltip = 'disconnected';
    // }
    const badgePercentage = Math.round(truckData.percent) + '%'
    const isAnimated = truckData.percent < 25 ? 'animate__animated animate__flash animate__infinite infinite' : ''
    const badgeColorDistance = () => {
      const distanceGps = truckData.distancia_gps
      if (distanceGps < 240) {
        return 'primary'
      } else if ((distanceGps >= 240) & (distanceGps < 255)) {
        return 'secondary'
      } else if (distanceGps >= 255) {
        return 'error'
      }
    }

    const badgeColorTime = () => {
      const timeOn = truckData.t_encendido
      if (timeOn < 19) {
        return 'primary'
      } else if ((timeOn >= 19) & (timeOn < 20)) {
        return 'secondary'
      } else if (timeOn >= 20) {
        return 'error'
      }
    }

    return (
      <>
        <div
          onMouseOver={onHover('bottom')}
          onMouseOut={() => {
            setOpen(false)
          }}
        >
          <Badge invisible={hiddenBadge} color="secondary" badgeContent={badgePercentage}>
            <Tooltip content={tooltipTruck(optionTooltip, truckData)} arrow>
              <Chip
                variant={variant}
                style={{ ...style, marginLeft: '10px', marginTop: '5px' }}
                size="small"
                label={truckData.patent}
              />
            </Tooltip>
          </Badge>
          {/* <Tooltip title={tooltipTruck(optionTooltip, truckData)} arrow={true}>
            <Chip
              variant={variant}
              style={{ ...style, marginLeft: '10px' }}
              size="small"
              label={truckData.patent}
            />

          </Tooltip> */}
          <div className={isAnimated}>
            <Mybadge
              color={badgeColorTime()}
              badgeContent={`${truckData.t_encendido}h`}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              max={25}
            >
              <Mybadge
                color={badgeColorDistance()}
                badgeContent={`${truckData.distancia_gps}km`}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                max={300}
              >
                <img src={truck} alt={truckData.patent} style={{ width: '6vh' }} />
              </Mybadge>
            </Mybadge>
          </div>
        </div>
      </>
    )

    // if (truckData.active) {
    //   if (truckData.dataLoss) {
    //     // truck with data loss
    //     return(
    //       <div onMouseOver={onHover('bottom')} onMouseOut={()=>{setOpen(false)}}>
    //         <Chip
    //           variant={open ? "default":"outlined"}
    //           style={open ? {color:'white', backgroundColor:color} : {color: color, borderColor: color}}
    //           size="small"
    //           label={truckData.patent} />
    //         <div className={'animate__animated animate__flash animate__infinite infinite animate__slower'}><img src={truckImg} alt={truckData.patent} style={{width:'45px'}}/></div>
    //       </div>
    //     )
    //   } else {
    //     // truck active
    //     return(
    //       <div onMouseOver={onHover('bottom')} onMouseOut={()=>{setOpen(false)}}>
    //         <Chip
    //           variant={open ? "default":"outlined"}
    //           style={open ? {color:'white', backgroundColor:color} : {color: color, borderColor: color}}
    //           size="small"
    //           label={truckData.patent}
    //         />
    //         <div><img src={truckImg} alt={truckData.patent} style={{width:'45px'}}/></div>
    //       </div>
    //     )
    //   }
    // } else {
    //   // truck inactive
    //   return(
    //     <div onMouseOver={onHover('bottom')} onMouseOut={()=>{setOpen(false)}}>
    //       <Chip
    //         variant={open ? "default":"outlined"}
    //         style={open ? {color:'white', backgroundColor:color} : {color: color, borderColor: color}}
    //         size="small"
    //         label={truckData.patent}
    //         disabled={true} />
    //       <div><img src={truckOffImg} alt={truckData.patent} style={{width:'45px'}}/></div>
    //     </div>
    //   )
    // }
  }

  return (
    <div style={{ paddingLeft: '6%', paddingTop: '3%' }}>
      {renderTruck(optionTooltip, truckData)}
      {/* <Popper
        open={open}
        anchorEl={anchorEl}
        placement={placement}
        transition
      >
        {tooltipTruck(optionTooltip, truckData)}
      </Popper> */}
    </div>
  )
}

TruckVehicle.propTypes = {
  truckData: PropTypes.object,
  color: PropTypes.string
}

export default TruckVehicle
