import React from 'react'
import AutoSizer from 'react-virtualized/dist/commonjs/AutoSizer'
import KeplerGl from 'kepler.gl'
import { Root } from './TiresVelocity.styles'

const MAPBOX_TOKEN = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN

const TireVelocityContainer = () => {
  return (
    <>
      <Root>
        <>
          <AutoSizer>
            {({ height, width }) => (
              <KeplerGl mapboxApiAccessToken={MAPBOX_TOKEN} id="my_data" width={width} height={height} />
            )}
          </AutoSizer>
        </>
      </Root>
    </>
  )
}

export default TireVelocityContainer
