import React from 'react'
import { useHistory, useRouteMatch } from 'react-router'
import { useTabs } from '../../components/Navigation/Tab.hooks'
import {
  REACT_APP_FEATURE_FLAG_TIRES_ANOMALY_TABLE,
  REACT_APP_FEATURE_FLAG_TIRES_VELOCITY
} from '../../config/settings/environment'
import { Root } from './TiresTopbar.styles'
import routes from '../../config/settings/routes'
import Tab from '../../components/Navigation/Tab'
import Tabs from '../../components/Navigation/Tabs'

/**
 * The anomaly detection topbar' container.
 */
const TiresTopbar = () => {
  const prefix = 'tires'
  const history = useHistory()

  const options = [
    (REACT_APP_FEATURE_FLAG_TIRES_ANOMALY_TABLE === true || REACT_APP_FEATURE_FLAG_TIRES_ANOMALY_TABLE === 'true') && {
      label: 'Tabla',
      current: useRouteMatch(routes.tireTabla)?.isExact || false,
      route: routes.tireTabla
    },
    (REACT_APP_FEATURE_FLAG_TIRES_VELOCITY === true || REACT_APP_FEATURE_FLAG_TIRES_VELOCITY === 'true') && {
      label: 'Velocidad',
      current: useRouteMatch(routes.tireVelocity)?.isExact || false,
      route: routes.tireVelocity
    }
  ].filter(Boolean)

  const { value, onChange: handleChangeTab } = useTabs({
    initialValue: options.findIndex(tab => tab.current),
    changeCallback: newValue => history.push(options[newValue].route)
  })

  const tabProps = index => {
    return {
      id: `${prefix}-tab-${index}`,
      'aria-controls': `${prefix}-tabpanel-${index}`,
      key: `${prefix}-tab-${index}`,
      value: index
    }
  }

  return (
    <Root>
      <Tabs aria-label="Maps tabs" value={value} variant="scrollable" onChange={handleChangeTab}>
        {options.map((option, index) => {
          return <Tab label={option.label} key={`${prefix}-tab-${index}`} {...tabProps(index)} />
        })}
      </Tabs>
    </Root>
  )
}

export default TiresTopbar
