import {
  CHANGE_RPM_DOWNLOAD_GROUP_PAGE,
  CHANGE_RPM_DOWNLOAD_GROUP_SELECTED,
  CHANGE_RPM_DOWNLOAD_GROUP_SELECTED_ERROR,
  CHANGE_RPM_DOWNLOAD_GROUP_SELECTED_SUCCESS,
  GET_RPM_DOWNLOAD,
  GET_RPM_DOWNLOAD_ERROR,
  GET_RPM_DOWNLOAD_GROUP,
  GET_RPM_DOWNLOAD_GROUP_ERROR,
  GET_RPM_DOWNLOAD_GROUP_SUCCESS,
  GET_RPM_DOWNLOAD_SUCCESS,
  UPDATE_OPERATORS_RPM_DOWNLOAD
} from './RpmDownload.actions'

const rpmDownloadState = {
  consultationDate: '',
  data: [],
  error: false,
  groups: [],
  loading: true,
  selectedGroupId: false
}

/**
 * The rpm download's reducer.
 */
const rpmDownloadReducer = (state = rpmDownloadState, { payload, type }) => {
  switch (type) {
    case GET_RPM_DOWNLOAD: {
      return { ...state, loading: true, error: false }
    }
    case GET_RPM_DOWNLOAD_ERROR: {
      return { ...state, loading: false, error: payload.error }
    }
    case GET_RPM_DOWNLOAD_SUCCESS: {
      return { ...state, loading: false, ...payload }
    }
    case CHANGE_RPM_DOWNLOAD_GROUP_PAGE: {
      return {
        ...state,
        [payload.groupId]: {
          ...state[payload.groupId],
          page: payload.page
        }
      }
    }
    case GET_RPM_DOWNLOAD_GROUP:
    case CHANGE_RPM_DOWNLOAD_GROUP_SELECTED:
    case CHANGE_RPM_DOWNLOAD_GROUP_SELECTED_SUCCESS: {
      return { ...state, selectedGroupId: payload.selectedGroupId }
    }
    case GET_RPM_DOWNLOAD_GROUP_ERROR:
    case CHANGE_RPM_DOWNLOAD_GROUP_SELECTED_ERROR: {
      return {
        ...state,
        [payload.selectedGroupId]: {
          ...state[payload.selectedGroupId],
          error: payload.error,
          loading: false
        }
      }
    }
    case GET_RPM_DOWNLOAD_GROUP_SUCCESS: {
      return {
        ...state,
        [payload.selectedGroupId]: {
          loading: false,
          error: false,
          page: 1,
          data: payload.data
        }
      }
    }
    case UPDATE_OPERATORS_RPM_DOWNLOAD: {
      return {
        ...state,
        [payload.selectedGroupId]: {
          ...state[payload.selectedGroupId],
          data: payload.data
        }
      }
    }
    default: {
      return state
    }
  }
}

export default rpmDownloadReducer
