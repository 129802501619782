import React from 'react'
import PropTypes from 'prop-types'
import { Chart } from 'react-google-charts'

/**
 * The Sankey graph component
 */
const SankeyGraph = props => {
  const { data, width, height, options } = props
  return <Chart chartType="Sankey" width={width} height={height} data={data} options={options} />
}
SankeyGraph.defaultProps = {
  options: {}
}

SankeyGraph.propTypes = {
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  data: PropTypes.array,
  options: PropTypes.object
}

export default SankeyGraph
