import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { Root, Label, Required, Group, Skeleton, Item, BaseRadio, Error } from './Radio.styles'

/**
 * The Radio's component.
 */
const Radio = props => {
  const { direction, error, disabled, label, loading, margin, options, padding, required, value, onChange } = props

  return (
    <Root margin={margin} padding={padding}>
      {label && (
        <Label>
          {`${label} `}
          {required && <Required />}
        </Label>
      )}
      <Group direction={direction} value={value} onChange={onChange}>
        {options.map(datum => (
          <Fragment key={datum.value}>
            {loading && <Skeleton direction={direction} height={22} variant="rect" width={150} />}
            {!loading && (
              <Item
                control={<BaseRadio disableFocusRipple disableRipple disableTouchRipple size="small" />}
                direction={direction}
                disabled={disabled}
                label={datum.label}
                value={datum.value}
              />
            )}
          </Fragment>
        ))}
      </Group>
      <Error>{error}</Error>
    </Root>
  )
}

Radio.defaultProps = {
  disabled: false,
  direction: 'vertical',
  error: '',
  label: '',
  loading: false,
  margin: 0,
  padding: 0,
  required: false,
  onChange: () => undefined
}

Radio.propTypes = {
  disabled: PropTypes.bool,
  direction: PropTypes.oneOf(['horizontal', 'vertical']),
  error: PropTypes.string,
  label: PropTypes.string,
  loading: PropTypes.bool,
  margin: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    })
  ),
  padding: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  required: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  onChange: PropTypes.func
}

export default Radio
