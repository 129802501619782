import React from 'react'
import styled from 'styled-components'
import { hexToRGBA } from '../../modules/utils/color'
import { Close } from '@material-ui/icons'
import { NavLink as BaseLink } from 'react-router-dom'
import { ButtonBase, Drawer, IconButton as BaseIconButton } from '@material-ui/core'

export const CloseIcon = styled(Close)`
  color: ${props => props.theme.palette.brand.white};
  cursor: pointer;
  font-size: 1.5rem;
`

export const HeaderTitleWrapper = styled(({ children, isTiny, sidebarMini, ...props }) => (
  <div {...props}>{children}</div>
))`
  background-color: ${props => props.theme.palette.brand.black};
  color: ${props => props.theme.palette.brand.white};
  display: flex;
  padding: ${props => (props.isTiny ? '37px 0 25px' : props.sidebarMini ? '37px 0 98.88px' : '37px 0 66px 22px')};
`

export const IconButton = styled(BaseIconButton)`
  background-color: ${props => props.theme.palette.brand.black};
  border-radius: 4px;
  height: 40px;
  position: absolute;
  right: 13px;
  top: 12px;
  width: 40px;

  &:hover {
    background-color: ${props => props.theme.palette.brand.primary[70]};
  }
`

export const IconWrapper = styled.div`
  color: ${props => props.theme.palette.brand.white};
  display: inline-flex;
  min-width: 56px;
  flex-shrink: 0;

  > img {
    width: 35px;
  }
`

export const Item = styled.li`
  list-style: none;
  margin-right: 11px;
  overflow: hidden;
`

export const Link = styled(BaseLink)`
  align-items: center;
  background-color: ${({ active, theme }) => (active ? theme.palette.brand.primary[50] : theme.palette.brand.black)};
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  color: ${props => props.theme.palette.brand.white};
  display: flex;
  font-size: 14px;
  font-weight: normal;
  height: 45px;
  padding: 0.7rem 1.5rem;
  position: relative;
  text-decoration: none;

  &:hover {
    background-color: ${props => props.theme.palette.brand.primary[50]};
    color: ${props => props.theme.palette.brand.white};
    text-decoration: none;
  }

  &::before {
    background-color: ${props => props.theme.palette.brand.white};
    content: '';
    left: 0;
    height: 100%;
    opacity: ${props => (props.active ? 1 : 0)};
    position: absolute;
    top: 0;
    transform: ${props => (props.active ? 'translateX(0)' : 'translateX(-0.4rem)')};
    transition: 0.3s;
    width: 0.5rem;
  }

  &:hover::before {
    opacity: 1;
    transform: translateX(0);
  }
`

export const LogoIcon = styled(({ alt, sidebarMini, ...props }) => <img alt={alt} {...props} />)`
  margin: ${props => props.sidebarMini && '0 auto'};
  width: ${props => props.sidebarMini && '50px'};
`

export const LogoutButton = styled(ButtonBase)`
  &.MuiButtonBase-root {
    align-items: center;
    background-color: ${({ active, theme }) => (active ? theme.palette.brand.primary[50] : theme.palette.brand.black)};
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    color: ${props => props.theme.palette.brand.white};
    display: flex;
    font-size: 14px;
    font-weight: normal;
    height: 45px;
    justify-content: flex-start;
    padding: 0.7rem 1.5rem;
    position: relative;
    text-decoration: none;
    width: 100%;

    &:hover {
      background-color: ${props => props.theme.palette.brand.primary[50]};
      color: ${props => props.theme.palette.brand.white};
      text-decoration: none;
    }

    &::before {
      background-color: ${props => props.theme.palette.brand.primary[40]};
      content: '';
      left: 0;
      height: 100%;
      opacity: ${props => (props.active ? 1 : 0)};
      position: absolute;
      top: 0;
      transform: ${props => (props.active ? 'translateX(0)' : 'translateX(-0.4rem)')};
      transition: 0.3s;
      width: 0.5rem;
    }

    &:hover::before {
      opacity: 1;
      transform: translateX(0);
    }
  }
`

export const MenuText = styled.span``

export const Panel = styled(({ children, isTiny, sidebarMini, ...props }) => <Drawer {...props}>{children}</Drawer>)`
  background-color: ${({ isTiny, theme }) => (isTiny ? 'initial' : theme.palette.brand.black)};
  border-right: 1px solid ${props => hexToRGBA(props.theme.palette.brand.black, 0.12)};
  box-shadow: 0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 5px 8px 0px rgb(0 0 0 / 14%), 1px 1px 14px 0px rgb(0 0 0 / 12%);
  height: 100%;

  & .MuiDrawer-paper {
    width: ${props => (props.sidebarMini ? '73px' : '320px')};

    &.MuiPaper-root {
      background-color: ${props => props.theme.palette.brand.black};
      box-shadow: 0px -5px 5px -1px rgb(0 0 0 / 20%), 1px -8px 8px 0px rgb(0 0 0 / 14%),
        1px -14px 14px 0px rgb(0 0 0 / 12%);
      border-right: 1px solid ${props => hexToRGBA(props.theme.palette.brand.black, 0.12)};
      position: absolute;

      li {
        margin-right: ${props => props.sidebarMini && '0'};

        a,
        button {
          padding: ${props => props.sidebarMini && '0.7rem 0'};

          div {
            width: ${props => props.sidebarMini && '100%'};

            img {
              margin: ${props => props.sidebarMini && '0 auto'};
            }
          }

          span {
            display: ${props => props.sidebarMini && 'none'};
          }
        }
      }
    }
  }
`

export const Separator = styled.hr`
  border: 0;
  border-top: 1px solid ${props => props.theme.palette.brand.white};
  box-sizing: content-box;
  height: 0;
  margin: 24px auto;
  overflow: visible;
  width: 85%;
`

export const Title = styled.p`
  font-size: 16px;
  line-height: 24px;
  margin-left: 16px;
  text-transform: uppercase;
`
