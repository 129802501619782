import React from 'react'
import { useDispatch } from 'react-redux'
import { Root, Wrapper, Dates } from './DiningDriver.styles'
import DatePicker from '../../components/Inputs/DatePicker'
import Button from '../../components/Buttons/Button'
import { onGetGeneralDataThunk } from './DiningDriver.actions'
import { useDatePicker } from '../../components/Inputs/Inputs.hooks'
import PropTypes from 'prop-types'

const DiningTopbar = props => {
  const { currentTab } = props
  const dispatch = useDispatch()
  const yesterday = new Date()
  yesterday.setDate(yesterday.getDate() - 1)

  const { error: pastDateError, value: pastDateValue, onChange: handlePastDateChange } = useDatePicker({
    initialValue: yesterday
  })

  const fetchData = () => {
    console.log('Fetching general data...') // Debugging: Print when fetching data
    dispatch(onGetGeneralDataThunk(pastDateValue))
  }
  return (
    <Root>
      <Wrapper>
        {currentTab === 'historic' && (
          <>
            <Dates>
              <DatePicker
                error={pastDateError}
                value={pastDateValue}
                label="Seleccione día"
                width="100%"
                onChange={handlePastDateChange}
                disableFuture
                maxDate={yesterday}
              />
            </Dates>
            <Button color="primary" onClick={fetchData} size="small">
              Go
            </Button>
          </>
        )}
      </Wrapper>
    </Root>
  )
}

DiningTopbar.propTypes = {
  currentTab: PropTypes.string.isRequired
}

export default DiningTopbar
