import { API, Auth } from 'aws-amplify'
import {
  REACT_APP_BASE_KEY,
  REACT_APP_BASE_NAME,
  REACT_APP_CLIENT_NAME,
  REACT_APP_API_BASE_URL,
  REACT_APP_BASE_SUB_PATH
} from '../config/settings/environment'
import TruckModel from '../store/models/truck'
import TankerModel from '../store/models/tanker'
import StatusModel from '../store/models/status'
import axios from 'axios'
import GroupsEfficiencyModel from '../store/models/groupsEfficiency'
import { format } from 'date-fns'

export const getTrucksAWS = () => {
  const paths = {
    amsa: '/fuel_levels/fuel_level_truck',
    anglo: '/fuel_levels/fuel_level_truck',
    zaldivar: '/v2/fuel_levels/fuel_level_truck'
  }
  const path = paths[REACT_APP_CLIENT_NAME] || '/v2/fuel_levels/fuel_level_truck'

  const init = {
    headers: {
      'x-api-key': REACT_APP_BASE_KEY
    },
    response: true
  }

  return API.get(REACT_APP_BASE_NAME, path, init)
    .then(response => {
      const rawTrucks = response.data
      const trucks = rawTrucks.map(truck => new TruckModel().fromV1(truck))
      return trucks
    })
    .catch(error => {
      console.error('[API][GET][TRUCKS] ' + JSON.stringify(error))
    })
}

export const getTankersAWS = () => {
  const paths = {
    amsa: '/fuel_levels/fuel_level_tanker',
    anglo: '/fuel_levels/fuel_level_tanker',
    zaldivar: '/v2/fuel_levels/fuel_level_tanker'
  }
  const path = paths[REACT_APP_CLIENT_NAME] || '/v2/fuel_levels/fuel_level_tanker'

  const init = {
    headers: {
      'x-api-key': REACT_APP_BASE_KEY
    },
    response: true
  }

  return API.get(REACT_APP_BASE_NAME, path, init)
    .then(response => {
      const rawData = response.data
      const tankers = rawData.map(item => new TankerModel().fromV1(item))
      return tankers
    })
    .catch(error => {
      console.error('[API][GET][TANKERS] ' + JSON.stringify(error))
    })
}

export const getServicesStateAWS = () => {
  const paths = {
    amsa: '/fuel_levels/alerts',
    anglo: '/fuel_levels/alerts',
    zaldivar: '/v2/fuel_levels/alerts'
  }
  const path = paths[REACT_APP_CLIENT_NAME] || '/v2/fuel_levels/alerts'

  const init = {
    headers: {
      'x-api-key': REACT_APP_BASE_KEY
    },
    response: true
  }

  return API.get(REACT_APP_BASE_NAME, path, init)
    .then(response => {
      const rawData = response.data
      const services = new StatusModel().fromV1(rawData).services
      return services
    })
    .catch(error => {
      console.error('[API][GET][SERVICES] ' + JSON.stringify(error))
    })
}

// GET fuel_levels/last_modification
export const getLastUpdateAWS = () => {
  const paths = {
    amsa: '/fuel_levels/last_modification',
    anglo: '/fuel_levels/last_modification',
    zaldivar: '/v2/fuel_levels/last_modification'
  }
  const path = paths[REACT_APP_CLIENT_NAME] || '/v2/fuel_levels/last_modification'

  const init = {
    headers: {
      'x-api-key': REACT_APP_BASE_KEY
    },
    response: true
  }

  return API.get(REACT_APP_BASE_NAME, path, init)
    .then(response => {
      return response.data
    })
    .catch(error => {
      console.error('[API][GET][LAST_UPDATE] ' + JSON.stringify(error))
    })
}

export const getHistoryDispach = () => {
  const paths = {
    amsa: '/fuel_levels/dispatch/available',
    anglo: '/v2/fuel_levels/dispatch/available',
    zaldivar: '/v2/fuel_levels/dispatch/available'
  }
  const path = paths[REACT_APP_CLIENT_NAME] || '/v2/fuel_levels/dispatch/available'

  const init = {
    headers: {
      'x-api-key': REACT_APP_BASE_KEY
    },
    response: true
  }

  return API.get(REACT_APP_BASE_NAME, path, init)
    .then(response => {
      const rawData = response.data
      const dataProcess = []
      for (const data of rawData) {
        const month = data.month < 10 ? `0${data.month}` : data.month
        data.value = `${month}/${data.year}`
        dataProcess.push(data)
      }
      dataProcess.push({ value: 'actual' })
      return dataProcess
    })
    .catch(error => {
      console.error('[API][GET][DISPATCH] ' + JSON.stringify(error))
    })
}

export const getEfficiencyStadistics = (month = null, year = null) => {
  const paths = {
    amsa: '/fuel_levels/dispatch_v1',
    anglo: '/fuel_levels/dispatch_v1',
    zaldivar: '/v2/fuel_levels/dispatch'
  }
  const basePath = paths[REACT_APP_CLIENT_NAME] || '/v2/fuel_levels/dispatch'
  const path = month && year ? `${basePath}?month=${month}&year=${year}` : basePath

  const init = {
    headers: {
      'x-api-key': REACT_APP_BASE_KEY
    },
    response: true
  }

  return API.get(REACT_APP_BASE_NAME, path, init)
    .then(response => {
      const rawData = response.data
      const groupNames = Object.keys(rawData)
      const groups = groupNames.map(groupName => {
        if (groupName !== 'month' && groupName !== 'year') {
          const groupId = groupName.replace('g', '')
          return new GroupsEfficiencyModel().fromV1(groupId, rawData[groupName], rawData.month, rawData.year)
        }
      })
      return groups
    })
    .catch(error => {
      console.error('[API][GET][DISPATCH] ' + JSON.stringify(error))
    })
}

/**
 * To get the historical orpak information.
 */
export const getHistoricalOrpak = async ({ apiKey, apiName, lastMonth, startDate, endDate }) => {
  const paths = {
    antucoya: '/v2/orpak-historic'
  }
  const api = paths[REACT_APP_CLIENT_NAME] || '/v2/orpak-historic'
  let path = `${REACT_APP_API_BASE_URL}${REACT_APP_BASE_SUB_PATH}${api}`
  console.log(lastMonth)
  if (lastMonth) {
    console.log('monthly')
    path = `${REACT_APP_API_BASE_URL}${REACT_APP_BASE_SUB_PATH}${api}?monthly=true`
  }
  console.log(startDate, endDate)
  if (startDate) {
    console.log('bothdates')
    const stringDateFrom = format(startDate, 'yyyy-MM-dd')
    const stringDateTo = format(endDate, 'yyyy-MM-dd')
    path = `${REACT_APP_API_BASE_URL}${REACT_APP_BASE_SUB_PATH}${api}?start_date=${stringDateFrom}&end_date=${stringDateTo}`
  }
  console.log(path)
  const config = {
    headers: {
      Authorization: `Bearer ${(await Auth.currentSession())?.getIdToken()?.getJwtToken()}`,
      'Access-Control-Allow-Headers':
        'x-api-key,Content-Type,X-Amz-Date,X-Amz-Security-Token,Authorization,X-Api-Key,X-Requested-With,Accept,Access-Control-Allow-Methods,Access-Control-Allow-Origin,Access-Control-Allow-Headers',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': 'DELETE,GET,HEAD,OPTIONS,PATCH,POST,PUT',
      'X-Requested-With': '*',
      'x-api-key': apiKey
    }
  }

  const response = await axios
    .get(path, config)
    .then(response => {
      return response
    })
    .catch(e => {
      console.log(e)
    })

  return {
    data: response.data,
    body: response.body,
    headers: response.headers,
    request: response.config,
    status: response.status
  }
}
