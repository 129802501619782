import { makeStyles } from '@material-ui/styles'
import colors from './colors'

const useStyles = makeStyles(theme => ({
  root: {
    background: colors.white
  },
  paper: {
    backgroundColor: colors.white,
    borderWidth: 0,
    borderColor: 'transparent',
    borderRadius: '10px',
    boxShadow: theme.shadows[5],
    padding: 30,
    outline: 'none'
  },
  headerText: {
    fontSize: '24px',
    fontWeight: '600'
  },
  headerTextSelect: {
    fontSize: '16px',
    fontWeight: '400'
  },
  headerMinorText: {
    fontSize: '1rem',
    fontWeight: 'bold',
    textAlign: 'right',
    marginRight: '10px'
  },
  subtitleText: {
    fontSize: '0.8rem',
    textAlign: 'right',
    marginRight: '10px'
  },
  onlyText: {
    fontSize: '2rem'
  },
  section: {
    paddingTop: 10,
    fontWeight: 'bold'
  },
  name: {
    fontSize: '1.5rem'
  },
  scoreChip: {
    color: colors.white,
    fontSize: '1.8rem',
    fontWeight: 'bold'
  },
  legend: {
    color: colors.dark,
    fontSize: '0.9rem',
    margin: 5
  },
  primaryButton: {
    background: colors.primary,
    color: colors.white,
    margin: 10,
    width: '120px'
  },
  secondaryButton: {
    background: colors.secondary_gray,
    color: colors.white,
    margin: 10,
    width: '120px'
  },
  disableButton: {
    background: '#FDC39D',
    color: colors.white,
    margin: 10,
    width: '120px'
  },
  textField: {
    margin: 0
  },
  scoreButton: {
    color: colors.white,
    fontSize: '1.2rem',
    fontWeight: 'bold'
  }
}))

export default useStyles
