import { makeActionCreator } from '../../config/store/utils'
import { getFuelLevelGraphs } from '../../services/graphsServices'
import { batch } from 'react-redux'

export const GET_GRAPH_DATA = 'GET_GRAPH_DATA'
export const GET_GRAPH_DATA_ERROR = 'GET_GRAPH_DATA_ERROR'
export const GET_GRAPH_DATA_SUCCESS = 'GET_GRAPH_DATA_SUCCESS'
export const onGetGraph = makeActionCreator(GET_GRAPH_DATA)
export const onGetGraphError = makeActionCreator(GET_GRAPH_DATA_ERROR, 'payload')
export const onGetGraphSuccess = makeActionCreator(GET_GRAPH_DATA_SUCCESS, 'payload')
export const onGetGraphSuccessThunk = () => async (dispatch, getState) => {
  dispatch(onGetGraph())

  try {
    const {
      apis: {
        base: { apiKey, apiName }
      }
    } = getState().auth

    const { data } = await getFuelLevelGraphs({
      apiKey: apiKey,
      apiName: apiName
    })
    const orpak = data.orpak
    const fuelRate = data.fuel_rate
    const outliers = data.fuel_rate_outliers
    const orpakGraphData = data.fuel_orpak
    const turnFuelLevelA = data.nivel_estanque_A
    const turnFuelLevelB = data.nivel_estanque_B
    const nivelEstanqueTabla = data.nivel_estanque_tabla

    return batch(() => {
      dispatch(
        onGetGraphSuccess({
          orpak,
          fuelRate,
          outliers,
          turnFuelLevelA,
          turnFuelLevelB,
          orpakGraphData,
          nivelEstanqueTabla
        })
      )
    })
  } catch (error) {
    console.log(error)
    return dispatch(onGetGraphError({ error }))
  }
}
