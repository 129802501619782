import styled from 'styled-components'
import { Grid as BaseGrid } from '@material-ui/core'
import { Fullscreen, FullscreenExit, VerticalAlignBottom } from '@material-ui/icons'

export const AlertWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 150px;
  justify-content: center;
`

export const DownloadIcon = styled(VerticalAlignBottom)``

export const Expand = styled.button`
  align-items: center;
  background: none;
  border: none;
  color: ${props => props.theme.palette.brand.primary[50]};
  cursor: pointer;
  display: flex;
  flex-direction: row;
  font-size: 14px;
  font-weight: ${props => props.theme.fontWeights[400]};
  line-height: 18px;
`

export const ExpandInIcon = styled(Fullscreen)``

export const ExpandOutIcon = styled(FullscreenExit)``

export const Grid = styled(BaseGrid)`
  margin: ${props => props.margin};
  width: ${props => props.width};
`

export const PdfView = styled.object`
  height: ${props => (props.fullScreen ? '95vh' : '70vh')};
  overflow: auto;
  text-align: center;
  width: 100%;
`

export const Strong = styled.span`
  font-weight: bold;
`
