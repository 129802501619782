import React from 'react'
import Accordion from '../../components/Surfaces/Accordion'
import AccordionSummary from '../../components/Surfaces/AccordionSummary'
import Typography from '../../components/Basics/Typography'
import { AccordionDetails, Root } from './Examples.styles'

/**
 * The AccordeonExample's container.
 */
const AccordeonExample = () => {
  const [expanded, setExpanded] = React.useState(false)

  const handleChange = panel => (_, isExpanded) => {
    setExpanded(isExpanded ? panel : false)
  }

  return (
    <Root>
      <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
        <AccordionSummary aria-controls="panel1a-content" id="panel1a-header">
          Titulo del Accordion 1
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="body1">
            1.- Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet
            blandit leo lobortis eget.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
        <AccordionSummary aria-controls="panel2a-content" id="panel2a-header">
          Titulo del Accordion 2
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="body1">
            2.- Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet
            blandit leo lobortis eget.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
        <AccordionSummary aria-controls="panel3a-content" id="panel3a-header">
          Titulo del Accordion 3
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="body1">
            3.- Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet
            blandit leo lobortis eget.
          </Typography>
        </AccordionDetails>
      </Accordion>
    </Root>
  )
}

export default AccordeonExample
