import React from 'react'
import routes from '../config/settings/routes'
import { useTabs } from '../components/Navigation/Tab.hooks'
import KpiDesktop from '../containers/GlobalCompare/KpiDesktop.container'
import ManagementSectionContainer from '../containers/ManagementMenu/ManagementMenu.container'
import NavbarContainer from '../containers/Navbar/Navbar.container'
import RefillEfficiency from '../containers/GlobalCompare/RefillEfficiency.container'
import BrandLayout from '../components/Layouts/BrandLayout'
import Heading from '../components/Layouts/Heading'
import ProtectByFeatures from '../components/Security/ProtectByFeatures'
import ProtectBySession from '../components/Security/ProtectBySession'
import Tab from '../components/Navigation/Tab'
import Tabs from '../components/Navigation/Tabs'
import NotFoundPage from './NotFound'
import { Container } from '@material-ui/core'

/**
 * The GlobalCompare' page.
 */
const GlobalCompare = () => {
  const title = 'Comparativo global'
  const { value: pageSelected, onChange: handleChangeTab } = useTabs({ initialValue: 'efficiency' })

  const renderTopbar = () => {
    return (
      <Tabs value={pageSelected} onChange={handleChangeTab} aria-label="Global compare tabs" variant="scrollable">
        <Tab label="Eficiencia Despachadores" value="efficiency" />
        <Tab label="KPI por turno" value="kpi" />
      </Tabs>
    )
  }

  const renderContent = () => {
    return (
      <Container style={{ background: 'white', padding: 10 }}>
        {pageSelected === 'kpi' && <KpiDesktop title={pageSelected} />}
        {pageSelected === 'efficiency' && <RefillEfficiency title={pageSelected} />}
      </Container>
    )
  }

  return (
    <ProtectBySession rule={(hasSession, signedIn) => !!(hasSession && signedIn)}>
      <ProtectByFeatures rule={features => features['com.cosmos/global.comparative']} fallback={() => <NotFoundPage />}>
        <BrandLayout
          navbar={<NavbarContainer />}
          header={<Heading title={title} topbar={renderTopbar} />}
          sidebar={<ManagementSectionContainer current={routes.globalComparative} />}
        >
          {renderContent()}
        </BrandLayout>
      </ProtectByFeatures>
    </ProtectBySession>
  )
}

export default GlobalCompare
