import { makeActionCreator } from '../../config/store/utils'
import { batch } from 'react-redux'
import { getPredictData } from '../../services/predictionsGraph'

export const GET_PREDICTIONS_DATA = 'GET_PREDICTIONS_DATA'
export const GET_PREDICTIONS_DATA_ERROR = 'GET_PREDICTIONS_DATA_ERROR'
export const GET_PREDICTIONS_DATA_SUCCESS = 'GET_PREDICTIONS_DATA_SUCCESS'
export const onGetPredictions = makeActionCreator(GET_PREDICTIONS_DATA)
export const onGetPredictionsError = makeActionCreator(GET_PREDICTIONS_DATA_ERROR, 'payload')
export const onGetPredictionsSuccess = makeActionCreator(GET_PREDICTIONS_DATA_SUCCESS, 'payload')
export const onGetPredictionsSuccessThunk = (startDate, endDate, truck) => async (dispatch, getState) => {
  dispatch(onGetPredictions())

  try {
    const {
      apis: {
        base: { apiKey, apiName }
      }
    } = getState().auth

    const { data } = await getPredictData({
      apiKey: apiKey,
      apiName: apiName,
      startDate: startDate,
      endDate: endDate,
      truck: truck
    })

    const predictions = data.predictions
    const orpak = data.orpak
    const dispatc = data.dispatch

    const predictionSerie = []
    const orpakSerie = []
    const dispatchSerie = []
    const distanceSerie = []

    predictions.forEach(pred => {
      const currDict = {
        time: new Date(pred[0]),
        prediccion: pred[1]
      }
      const distanceDict = {
        time: new Date(pred[0]),
        distancia: pred[2]
      }
      predictionSerie.push(currDict)
      distanceSerie.push(distanceDict)
    })

    orpak.forEach(fuel => {
      const currFuel = {
        time: new Date(fuel[0]),
        cantidad: fuel[1]
      }
      orpakSerie.push(currFuel)
    })

    dispatc.forEach(fuel => {
      const currFuel = {
        time: new Date(fuel[0]),
        cantidad: fuel[1]
      }
      dispatchSerie.push(currFuel)
    })

    return batch(() => {
      dispatch(
        onGetPredictionsSuccess({
          predictionSerie,
          orpakSerie,
          dispatchSerie,
          distanceSerie,
          truck
        })
      )
    })
  } catch (error) {
    console.log(error)
    return dispatch(onGetPredictionsError({ error }))
  }
}
