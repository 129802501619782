// FIXME: Need to be refactored
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { REACT_APP_PATH_WEB_SOCKET } from '../../config/settings/environment'
import { w3cwebsocket as W3CWebSocket } from 'websocket'
import routes from '../../config/settings/routes'
import moment from 'moment'
import 'moment/locale/es'
import { getLastUpdateAWS } from '../../services/fuelLevelService'
import { useHistory } from 'react-router'
import { onSetMissingTimeSuccess, onWsDataTrucksThunk, onGetOrpakLatestThunk } from './Countdown.actions'
import { Typography } from '@material-ui/core'
import useStyles from '../../styles/levelsStyle'
import TruckModel from '../../store/models/truck'
import { onLogOutThunk } from '../Authentication/Authentication.actions'

moment.locale('es')
let last = moment()

/**
 * The Countdown's container.
 */
const Countdown = () => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const history = useHistory()
  const { missingTime } = useSelector(state => state.countdown)
  let client = null

  const websocketComunication = () => {
    client = new W3CWebSocket(REACT_APP_PATH_WEB_SOCKET)
    client.onopen = e => {
      console.log('Conectado al websocket')
    }
    client.onmessage = message => {
      const { data } = message
      const dataParse = JSON.parse(data)
      switch (dataParse.type) {
        case 'latest_orpak': {
          const orpak = dataParse.value
          const tankLevelsMean = dataParse.nivel_estanque_mean
          const weeklyTank = dataParse.nivel_estanque_week_mean
          dispatch(onGetOrpakLatestThunk(orpak, tankLevelsMean, weeklyTank))
          break
        }
        default: {
          const trucks = dataParse.map(truck => new TruckModel().fromV1(truck))
          dispatch(onWsDataTrucksThunk(trucks))
        }
      }
      reloadView()
    }

    client.onclose = e => {
      console.error('comunicación cerrada: ', e.code, e.reason)
      if (e.code === 1001 || e.code === 1006) {
        setTimeout(websocketComunication, 10000)
      }
    }
  }

  useEffect(() => {
    websocketComunication()
    const myInterval = setInterval(() => {
      const now = moment()
      if (now.diff(last, 'minutes') > 10) {
        reloadView()
      }
      dispatch(onSetMissingTimeSuccess({ missingTime: last.fromNow() }))
    }, 60000)

    return () => {
      clearTimeout(myInterval)
      client.close()
    }
  }, [])

  const fetchData = async () => {
    // eslint-disable-next-line
    let last_update_date, last_update_time
    try {
      // eslint-disable-next-line
      ;({ last_update_date, last_update_time } = await getLastUpdateAWS())
    } catch (error) {
      console.log(error)
      return dispatch(onLogOutThunk({ onCallback: () => history.push(routes.login) }))
    }
    const dateAux = last_update_date.split('/')
    const timeAux = last_update_time.split(':')
    last = moment().set({
      year: dateAux[2],
      month: dateAux[1] - 1,
      date: dateAux[0],
      hour: timeAux[0],
      minute: timeAux[1],
      second: timeAux[2]
    })
    return last.fromNow()
  }

  const reloadView = async () => {
    const missing = await fetchData()
    dispatch(onSetMissingTimeSuccess({ missingTime: missing }))
    last = moment()
  }

  return (
    <Typography className={classes.label} style={{ textAlign: 'right' }}>
      última actualización: {missingTime}.
    </Typography>
  )
}

export default Countdown
