/**
 * To round a number that also takes precision. The precision may be a value between 0 and 20, inclusive.
 * If this argument is omitted, it is treated as 0.
 */
export const roundWithPrecision = (value, precision = 0) => {
  const number = Number.parseFloat(value)
  if (precision > 0 && number.toString().includes('.')) {
    precision = Math.min(number.toString().split('.')[1].length, precision)
  } else {
    precision = 0
  }
  return number.toFixed(precision)
}
