import React from 'react'
import PropTypes from 'prop-types'
import Breadcrumbs from '../Navigation/Breadcrumbs'
import Typography from '../Basics/Typography'
import { Root } from './Heading.styles'

/**
 * The Heading's container.
 */
const Heading = ({ breadcrumbs, title, subtitle, topbar = () => undefined }) => {
  return (
    <Root>
      {breadcrumbs && <Breadcrumbs breadcrumbs={breadcrumbs} />}
      <Typography
        color="white"
        fontWeight="bold"
        margin={topbar() === undefined ? '0.9em 0 0.6em' : '0.2em 0 0.6em'}
        variant="h1"
      >
        {title}
      </Typography>
      {subtitle && (
        <Typography color="white" variant="body2">
          {subtitle}
        </Typography>
      )}
      {topbar()}
    </Root>
  )
}

Heading.defaultProps = {
  title: ''
}
Heading.propTypes = {
  breadcrumbs: PropTypes.arrayOf(
    PropTypes.shape({
      isExternal: PropTypes.bool,
      link: PropTypes.string,
      text: PropTypes.string.isRequired
    })
  ),
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  topbar: PropTypes.func
}

export default Heading
