import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import AutoSizer from 'react-virtualized/dist/commonjs/AutoSizer'
import KeplerGl from 'kepler.gl'
import { onGetMapboxFrenadosThunk } from './VisualizadorEspacialFrenados.actions'
import LoadingContent from '../../components/Placeholders/LoadingContent'
import NoData from '../../components/Placeholders/NodataContent'
import DisconnectedData from '../FuelLevel/DisconnectedData.container'
import { Root } from './VisualizadorEspacialFrenados.styles'
// Kepler.gl Data processing APIs
// import Processors from kepler.gl/processors
// Kepler.gl actions
import { addDataToMap } from 'kepler.gl/actions'
// sample data
// import gps from ../../data/gps.csv
import gpsConfig from '../../data/gps-hard-brake-config.json'
import Processors from 'kepler.gl/processors'

const MAPBOX_TOKEN = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN

const VisualizadorEspacialContainerFrenados = () => {
  const dispatch = useDispatch()
  const { loading, error, urlData } = useSelector(state => state.mapboxHardBraking)

  useEffect(() => {
    if (urlData.length === 0) {
      dispatch(onGetMapboxFrenadosThunk())
    }
  }, [])

  useEffect(() => {
    if (urlData.length > 0) {
      const data = Processors.processCsvData(urlData)
      // Create dataset structure
      const dataset = {
        data,
        info: {
          id: 'my_data_hard_brake',
          label: 'historic'
        }
      }
      dispatch(addDataToMap({ datasets: dataset, config: gpsConfig }))
    }
  }, [urlData])

  return (
    <>
      {loading && !error && <LoadingContent />}
      {error && !loading && <DisconnectedData />}
      {!loading && !error && (
        <Root>
          <>
            {urlData.length === 0 ? (
              <NoData />
            ) : (
              <AutoSizer>
                {({ height, width }) => (
                  <KeplerGl mapboxApiAccessToken={MAPBOX_TOKEN} id="my_data_historic" width={width} height={height} />
                )}
              </AutoSizer>
            )}
          </>
        </Root>
      )}
    </>
  )
}

export default VisualizadorEspacialContainerFrenados
