const routes = {
  anomalyDetection: '/reporte-anomalias',
  newAnomaly: '/nuevas-anomalias',
  consumptionPerOperator: '/consumo-operador/:type(ralenti|rpm|frenados)',
  example: '/ejemplos',
  fuelLevel: '/nivel-combustible',
  globalComparative: '/comparativo-global',
  hardBraking: '/consumo-operador/frenados',
  help: '/ayuda',
  home: '/',
  idleRpm: '/consumo-operador/ralenti',
  login: '/login',
  operatorsReport: '/reporte-operadores',
  operatorsReportDetails: '/reporte-operadores/:id',
  reinforcement: '/consumo-operador/:type(ralenti|rpm|frenados)/reforzamiento',
  root: '/nivel-combustible',
  rpmDownload: '/consumo-operador/rpm',
  showReinforcement: '/consumo-operador/:type(ralenti|rpm|frenados)/reforzamiento/:id',
  stationManagement: '/gestion-estaciones',
  quicksight: '/quicksight',
  visualizadorOperaciones: '/mapa/:type(dia|historico|frenados|gpsralenti)',
  visualizador: '/mapa/dia',
  visualizadorHistorico: '/mapa/historico',
  visualizadorHardBraking: '/mapa/frenados',
  visualizadorRalenti: '/mapa/gpsralenti',
  predictionsGraphs: '/predicciones',
  tireOperaciones: '/neumaticos/:type(tabla|velocidad)',
  tireTabla: '/neumaticos/tabla',
  tireVelocity: '/neumaticos/velocidad',
  fuelManagement: '/fuel-management',
  passwordRecovery: '/recover-password',
  dining: '/casino'
}

export default routes
