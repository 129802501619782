import React from 'react'
import PropTypes from 'prop-types'
import { addMonths } from 'date-fns'
import { batch, useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { usePagination } from '../../components/Tables/Pagination.hooks'
import { useViewportSize } from '../../modules/hooks/viewport'
import { capitalize } from '../../modules/utils/helpers'
import { formatDate } from '../../modules/utils/formatters'
import { onChangeHardBrakingGroupPage } from './HardBraking.actions'
import {
  onGetReinforcementThunk,
  onLoadOperatorsThunk,
  onSetInitialState
} from '../Reinforcement/Reinforcement.actions'
import Alert from '../../components/Basics/Alert'
import Pagination from '../../components/Tables/Pagination'
import Skeleton from '../../components/Placeholders/Skeleton'
import Table from '../../components/Tables/Table'
import TableCell from '../../components/Tables/TableCell'
import TableRow from '../../components/Tables/TableRow'
import TablePlaceholder from '../../components/Placeholders/TablePlaceholder'
import Tag from '../../components/Basics/Tag'
import Typography from '../../components/Basics/Typography'
import { FooterWrapper, IconButton, Strong, SummaryWrapper, TableContainer, WarningIcon } from './HardBraking.styles'

/**
 * The Hard Braking Table's container.
 */
const HardBrakingTable = props => {
  const { group, groupId, onToggleModal } = props
  const dispatch = useDispatch()
  const history = useHistory()
  const { isExtraTiny, isTiny } = useViewportSize()
  const {
    consultationDate,
    [groupId]: { data, error, loading, page }
  } = useSelector(state => state.hardBraking)
  const currentDate = addMonths(new Date(), -1)

  const { page: currentPage, totalPages, onChangePage, onGetCurrentPage } = usePagination({
    changeCallback: page => dispatch(onChangeHardBrakingGroupPage({ groupId, page })),
    data,
    initialPage: page,
    rowsPerPage: 5
  })

  const handleShowReinforcement = (operatorId, operatorName) => () => {
    if (isTiny) {
      batch(() => {
        dispatch(
          onSetInitialState({ groupId, reportPeriod: formatDate(consultationDate, 'yyyy-MM'), type: 'FRENADOS' })
        )
        dispatch(onLoadOperatorsThunk({ currentOperator: { operatorId, operatorName }, groupId, type: 'FRENADOS' }))
        history.push('/consumo-operador/frenados/reforzamiento')
      })
    } else {
      batch(() => {
        dispatch(
          onSetInitialState({ groupId, reportPeriod: formatDate(consultationDate, 'yyyy-MM'), type: 'FRENADOS' })
        )
        dispatch(onLoadOperatorsThunk({ currentOperator: { operatorId, operatorName }, groupId, type: 'FRENADOS' }))
        onToggleModal()
      })
    }
  }

  const handleGetReinforcement = reinforcementId => () => {
    if (isTiny) {
      history.push(`/consumo-operador/frenados/reforzamiento/${reinforcementId}`)
    } else {
      batch(() => {
        dispatch(onGetReinforcementThunk({ reinforcementId, type: 'FRENADOS' }))
        onToggleModal()
      })
    }
  }

  return (
    <>
      {isTiny && (
        <SummaryWrapper margin="-10px 0 10px">
          <SummaryWrapper margin="2px 24px 2px 0">
            <Tag color="primary" margin="0 10px 0 0" text={group.p8QuantityHardBraking} />
            <Typography variant="body2">P80 cantidad de frenados bruscos</Typography>
          </SummaryWrapper>

          <SummaryWrapper margin="2px 0">
            <Tag color="primary" margin="0 10px 0 0" text={group.sumLitersOverConsumtion} />
            <Typography variant="body2">Litros sobreconsumo</Typography>
          </SummaryWrapper>
        </SummaryWrapper>
      )}

      {loading && !error && <TablePlaceholder />}

      {!loading && !error && (
        <TableContainer>
          <Table accessibility={{ label: 'Grupo 1' }}>
            <TableRow head>
              <TableCell minWidth="150px">Operador</TableCell>
              <TableCell minWidth="150px">Sum frenados bruscos</TableCell>
              <TableCell minWidth="150px">Frenados cargado / Frenados vacío</TableCell>
              <TableCell minWidth="150px">Impacto lts frenado cargado</TableCell>
              <TableCell minWidth="155px">Impacto lts frenado vacío</TableCell>
              <TableCell minWidth="155px">Impacto total sobreconsumo</TableCell>
              <TableCell minWidth="150px">Registrar reforzamiento</TableCell>
            </TableRow>

            {onGetCurrentPage().map((row, index) => {
              return (
                <TableRow key={`operador-${index}`}>
                  <TableCell align="center" minWidth="150px">
                    {row.operatorName}
                  </TableCell>
                  <TableCell align="center" minWidth="150px">
                    {`${row.sumHardBraking} ${row.sumHardBraking === '1' ? 'frenado' : 'frenados'}`}
                  </TableCell>
                  <TableCell align="center" minWidth="150px">
                    {`${row.quantityHardBrakingLoaded} / ${row.quantityHardBrakingEmpty} frenados`}
                  </TableCell>
                  <TableCell align="center" minWidth="150px">
                    {`${row.impactLitersConsumedLoaded} ${row.impactLitersConsumedLoaded === '1' ? 'litro' : 'litros'}`}
                  </TableCell>
                  <TableCell align="center" minWidth="155px">
                    {`${row.impactLitersConsumedEmpty} ${row.impactLitersConsumedEmpty === '1' ? 'litro' : 'litros'}`}
                  </TableCell>
                  <TableCell align="center" minWidth="155px">
                    {`${row.impactLitersConsumedTotal} ${row.impactLitersConsumedTotal === '1' ? 'litro' : 'litros'}`}
                  </TableCell>
                  <TableCell align="center" minWidth="150px">
                    {row.hasReinforcement && (
                      <IconButton onClick={handleGetReinforcement(row.reinforcementId)}>
                        <img src="/assets/icons/vectorReportDone.svg" alt="Reportar Recomendación" />
                      </IconButton>
                    )}
                    {!row.hasReinforcement && (
                      <IconButton onClick={handleShowReinforcement(row.operatorId, row.operatorName)}>
                        <img src="/assets/icons/vectorMustReport.svg" alt="Reportar Recomendación" />
                      </IconButton>
                    )}
                  </TableCell>
                </TableRow>
              )
            })}
          </Table>
        </TableContainer>
      )}

      {!error && (
        <FooterWrapper margin={data.length > 5 ? '4px 0 0' : '9px 0 0'}>
          {loading && <Skeleton variant="text" width={isExtraTiny ? '100%' : '300px'} />}
          {!loading && (
            <Typography color="disabled-gray" margin="0 12px" variant="subtitle">
              {consultationDate && formatDate(consultationDate, 'yyyy-MM') !== formatDate(currentDate, 'yyyy-MM') && (
                <WarningIcon />
              )}
              {capitalize(formatDate(consultationDate, 'MMMM'))} - Operadores que sobrepasan las cantidades óptimas de
              frenados bruscos.
            </Typography>
          )}

          {!loading && data.length > 5 && (
            <Pagination alignment="right" count={totalPages} page={currentPage} onChange={onChangePage} />
          )}
        </FooterWrapper>
      )}

      {!loading && error && (
        <Alert severity="default">
          <Strong>Lo sentimos, estamos presentando problemas con nuestro servidor</Strong>
          <br />
          En estos momentos tenemos problemas para disponibilizar la información. Por favor, consulta en unos minutos
          más.
        </Alert>
      )}
    </>
  )
}

HardBrakingTable.defaultProps = {
  onToggleModal: () => undefined
}
HardBrakingTable.propTypes = {
  group: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    name: PropTypes.string,
    p8QuantityHardBraking: PropTypes.string.isRequired,
    sumLitersOverConsumtion: PropTypes.string.isRequired
  }).isRequired,
  groupId: PropTypes.string.isRequired,
  onToggleModal: PropTypes.func
}

export default HardBrakingTable
