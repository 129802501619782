import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Chart from 'react-apexcharts'
import { Wrapper, Graphdiv, TableWrapper, Tablediv } from './BoxPlotGraph.styles'
import LoadingContent from '../../components/Placeholders/LoadingContent'
import DisconnectedData from '../FuelLevel/DisconnectedData.container'
import { onGetGraphSuccessThunk } from './BoxPlotGraph.actions'
import theme from '../../config/styles/theme'
import moment from 'moment'
import { usePagination } from '../../components/Tables/Pagination.hooks'
import Table from '../../components/Tables/Table'
import TableCell from '../../components/Tables/TableCell'
import TableRow from '../../components/Tables/TableRow'

/**
 * The BoxPlots's container.
 */
const FuelLevelBoxPlotContent = () => {
  const dispatch = useDispatch()
  const { error, loading, turnFuelLevelA, turnFuelLevelB, nivelEstanqueTablaA, nivelEstanqueTablaB } = useSelector(
    state => state.fuelLevelGraphs
  )

  const { onGetCurrentPage } = usePagination({
    data: nivelEstanqueTablaA,
    initialPage: 1,
    rowsPerPage: 15
  })

  const { onGetCurrentPage: onGetCurrentPageB } = usePagination({
    data: nivelEstanqueTablaB,
    initialPage: 1,
    rowsPerPage: 15
  })

  useEffect(() => {
    if (turnFuelLevelA.length === 0) {
      dispatch(onGetGraphSuccessThunk())
    }
  }, [])

  const state = {
    optionsA: {
      plotOptions: {
        boxPlot: {
          colors: {
            upper: theme.palette.brand.primary[90],
            lower: theme.palette.brand.primary[50]
          }
        }
      },
      chart: {
        type: 'boxPlot'
      },
      title: {
        text: '% nivel de estanque turno A',
        align: 'middle',
        style: {
          fontSize: '16px',
          fontWeight: 'bold',
          fontFamily: theme.typography.fontFamily
        }
      },
      xaxis: {
        labels: {
          formatter: value => {
            const thisDate = moment(value)
            return thisDate.format('dddd D [de] MMM')
          }
        }
      }
    },
    seriesA: [
      {
        name: '% nivel estanque',
        type: 'boxPlot',
        data: turnFuelLevelA
      }
    ],
    optionsB: {
      plotOptions: {
        boxPlot: {
          colors: {
            upper: theme.palette.brand.primary[90],
            lower: theme.palette.brand.primary[50]
          }
        }
      },
      chart: {
        type: 'boxPlot'
      },
      title: {
        text: '% nivel de estanque turno B',
        align: 'middle',
        style: {
          fontSize: '16px',
          fontWeight: 'bold',
          fontFamily: theme.typography.fontFamily
        }
      },
      xaxis: {
        labels: {
          formatter: value => {
            const thisDate = moment(value)
            return thisDate.format('dddd D [de] MMM')
          }
        }
      }
    },
    seriesB: [
      {
        name: '% nivel estanque',
        type: 'boxPlot',
        data: turnFuelLevelB
      }
    ]
  }

  return (
    <>
      {loading && !error && <LoadingContent />}
      {error && !loading && <DisconnectedData />}
      {!loading && !error && (
        <>
          <Wrapper>
            <Graphdiv>
              <Chart options={state.optionsA} series={state.seriesA} type="boxPlot" height="100%" width="100%" />
            </Graphdiv>
            <Graphdiv>
              <Chart options={state.optionsB} series={state.seriesB} type="boxPlot" height="100%" width="100%" />
            </Graphdiv>
          </Wrapper>
          <Tablediv>
            <TableWrapper>
              <Table accesibility={{ label: 'Nivel estanque' }}>
                <TableRow head>
                  <TableCell>Turno</TableCell>
                  <TableCell>Cantidad abastecimientos</TableCell>
                  <TableCell>Media</TableCell>
                  <TableCell>Desviación estandar</TableCell>
                  <TableCell>Mínimo</TableCell>
                  <TableCell>25%</TableCell>
                  <TableCell>50%</TableCell>
                  <TableCell>75%</TableCell>
                  <TableCell>Máximo</TableCell>
                </TableRow>
                {onGetCurrentPage().map((row, index) => {
                  return (
                    <TableRow key={`nivel-estanque-${index}`}>
                      <TableCell align="center" minWidth="50px">
                        {row.turn}
                      </TableCell>
                      <TableCell align="center" minWidth="50px">
                        {row.count}
                      </TableCell>
                      <TableCell align="center" minWidth="50px">
                        {row.mean}
                      </TableCell>
                      <TableCell align="center" minWidth="50px">
                        {row.std}
                      </TableCell>
                      <TableCell align="center" minWidth="50px">
                        {row.min}
                      </TableCell>
                      <TableCell align="center" minWidth="50px">
                        {row['25%']}
                      </TableCell>
                      <TableCell align="center" minWidth="50px">
                        {row['50%']}
                      </TableCell>
                      <TableCell align="center" minWidth="50px">
                        {row['75%']}
                      </TableCell>
                      <TableCell align="center" minWidth="50px">
                        {row.max}
                      </TableCell>
                    </TableRow>
                  )
                })}
              </Table>
            </TableWrapper>
            <TableWrapper>
              <Table accesibility={{ label: 'Nivel estanque' }}>
                <TableRow head>
                  <TableCell>Turno</TableCell>
                  <TableCell>Cantidad abastecimientos</TableCell>
                  <TableCell>Media</TableCell>
                  <TableCell>Desviación estandar</TableCell>
                  <TableCell>Mínimo</TableCell>
                  <TableCell>25%</TableCell>
                  <TableCell>50%</TableCell>
                  <TableCell>75%</TableCell>
                  <TableCell>Máximo</TableCell>
                </TableRow>
                {onGetCurrentPageB().map((row, index) => {
                  return (
                    <TableRow key={`nivel-estanque-${index}`}>
                      <TableCell align="center" minWidth="50px">
                        {row.turn}
                      </TableCell>
                      <TableCell align="center" minWidth="50px">
                        {row.count}
                      </TableCell>
                      <TableCell align="center" minWidth="50px">
                        {row.mean}
                      </TableCell>
                      <TableCell align="center" minWidth="50px">
                        {row.std}
                      </TableCell>
                      <TableCell align="center" minWidth="50px">
                        {row.min}
                      </TableCell>
                      <TableCell align="center" minWidth="50px">
                        {row['25%']}
                      </TableCell>
                      <TableCell align="center" minWidth="50px">
                        {row['50%']}
                      </TableCell>
                      <TableCell align="center" minWidth="50px">
                        {row['75%']}
                      </TableCell>
                      <TableCell align="center" minWidth="50px">
                        {row.max}
                      </TableCell>
                    </TableRow>
                  )
                })}
              </Table>
            </TableWrapper>
          </Tablediv>
        </>
      )}
    </>
  )
}

export default FuelLevelBoxPlotContent
