import {
  GET_GENERAL_DATA,
  GET_GENERAL_DATA_ERROR,
  GET_GENERAL_DATA_SUCCESS,
  FETCH_LATEST_DATA,
  FETCH_LATEST_DATA_ERROR,
  FETCH_LATEST_DATA_SUCCESS
} from './DiningDriver.actions'

const generalDataState = {
  loading: false,
  error: null,
  loadingHistoric: false,
  errorHistoric: null,
  drivers: [],
  latestDrivers: []
}

const generalDataReducer = (state = generalDataState, { payload, type }) => {
  switch (type) {
    // Historical data actions
    case GET_GENERAL_DATA: {
      return { ...state, loadingHistoric: true, errorHistoric: false }
    }
    case GET_GENERAL_DATA_SUCCESS: {
      return {
        ...state,
        loadingHistoric: false,
        errorHistoric: false,
        drivers: payload.drivers
      }
    }
    case GET_GENERAL_DATA_ERROR: {
      return { ...state, loadingHistoric: false, errorHistoric: true }
    }

    // Latest data actions
    case FETCH_LATEST_DATA: {
      return { ...state, loading: true, error: false }
    }
    case FETCH_LATEST_DATA_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        latestDrivers: payload.drivers
      }
    }
    case FETCH_LATEST_DATA_ERROR: {
      return { ...state, loading: false, error: payload.error }
    }

    default: {
      return state
    }
  }
}

export default generalDataReducer
