import React from 'react'
import PropTypes from 'prop-types'
import Skeleton from '../Placeholders/Skeleton'
import {
  BaseAutocomplete,
  BottomMessage,
  ClearIcon,
  BaseInput,
  Error,
  Info,
  Label,
  Required,
  Root
} from './Autocomplete.styles'

/**
 * The Autocomplete' component.
 */
const Autocomplete = props => {
  const {
    disabled,
    error,
    info,
    label,
    loading,
    margin,
    maxWidth,
    option: selectedOption,
    options,
    padding,
    placeholder,
    required,
    textAlign,
    value,
    waiting,
    onChange,
    onUpdate
  } = props
  const handleBlur = event => onChange(event)

  return (
    <Root margin={margin} padding={padding}>
      {label && (
        <Label>
          {`${label} `}
          {required && <Required />}
        </Label>
      )}
      {loading && <Skeleton height={38} variant="rect" />}
      {!loading && (
        <BaseAutocomplete
          clearOnBlur
          clearOnEscape
          freeSolo
          closeIcon={<ClearIcon />}
          disabled={disabled}
          getOptionLabel={option => option.name}
          loading={waiting}
          loadingText="Cargando..."
          maxWidth={maxWidth}
          options={options}
          renderInput={params => (
            <BaseInput
              {...params.InputProps}
              disabled={params.disabled}
              error={Boolean(error)}
              inputProps={params.inputProps}
              placeholder={placeholder}
              textAlign={textAlign}
              value={value}
            />
          )}
          renderOption={option => option.name}
          value={selectedOption}
          onBlur={handleBlur}
          onChange={onUpdate}
          onInputChange={onChange}
        />
      )}
      <BottomMessage>
        {!error && <Info>{info}</Info>}
        {error && <Error>{error}</Error>}
      </BottomMessage>
    </Root>
  )
}

Autocomplete.defaultProps = {
  disabled: false,
  error: '',
  info: '',
  label: '',
  loading: false,
  margin: 0,
  padding: 0,
  placeholder: '',
  required: false,
  textAlign: 'initial',
  waiting: false,
  onChange: () => undefined,
  onUpdate: () => undefined
}

Autocomplete.propTypes = {
  disabled: PropTypes.bool,
  error: PropTypes.string,
  info: PropTypes.string,
  label: PropTypes.string,
  loading: PropTypes.bool,
  margin: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  maxWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  option: PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  }),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    })
  ).isRequired,
  padding: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  textAlign: PropTypes.oneOf(['center', 'end', 'initial', 'start']),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  waiting: PropTypes.bool,
  onChange: PropTypes.func,
  onUpdate: PropTypes.func
}

export default Autocomplete
