import styled from 'styled-components'

export const Separator = styled.hr`
  border: 0;
  border-top: 1px solid ${props => props.theme.palette.brand.gray[85]};
  box-sizing: content-box;
  height: 0;
  margin: 16px 0 18px;
  overflow: visible;
`
