import React from 'react'
import PropTypes from 'prop-types'
import { Root } from './Tabs.styles'

/**
 * The Tabs' component.
 */
const Tabs = props => {
  const { children, scrollButtons, value, variant, onChange } = props

  return (
    <Root scrollButtons={scrollButtons} value={value} variant={variant} onChange={onChange}>
      {children}
    </Root>
  )
}

Tabs.defaultProps = {
  scrollButtons: 'auto',
  variant: 'standard',
  onClick: () => undefined
}
Tabs.propTypes = {
  children: PropTypes.node.isRequired,
  scrollButtons: PropTypes.oneOf(['auto', 'desktop', 'off', 'on']),
  value: PropTypes.any.isRequired,
  variant: PropTypes.oneOf(['fullWidth', 'scrollable', 'standard']),
  onChange: PropTypes.func
}

export default Tabs
