// FIXME: Need to be refactored
import React from 'react'
import PropTypes from 'prop-types'
import LoadingContent from '../../components/Placeholders/LoadingContent'
import FixStationContent from './FixStationContent.container'
// import { ResumenFixStations } from './ResumenFixStations';
// import { getDataStation } from '../../services/stationsManagementServices';
import useStyles from '../../styles/stationStyle'
import { Typography } from '@material-ui/core'

/**
 * The FixStationScreen' container.
 */
const FixStationScreen = ({ data, loading }) => {
  // const [loading, setLoading] = useState(true);
  // const [fixData, setFixData] = useState({});

  // useEffect(() => {
  //   getDataMobile();
  // }, [])

  // const getDataMobile = async () => {
  //   const response = await getDataStation('fija');
  //   setFixData(response);
  //   setLoading(false);
  // }

  const classes = useStyles()

  return (
    <>
      {loading && <LoadingContent />}
      {!loading && (
        <div style={{ margin: '20px' }}>
          {/* <Grid container direction='row' justify='flex-end' alignItems='flex-end'>
            <p><strong>Periodo observado: </strong> {`${data.start_date} al ${data.end_date}`} </p>
          </Grid> */}
          {/* <ResumenFixStations total={data.totals} chartsData={data.pie_chart} /> */}
          <Typography className={classes.titleResumen}>
            <strong>Gestión de estaciones fijas por grupo: </strong>abastecimiento de CAEX y tiempo en cola
          </Typography>
          <hr />
          <FixStationContent groups={data.group_tables} />
        </div>
      )}
    </>
  )
}

FixStationScreen.propTypes = {
  data: PropTypes.object,
  loading: PropTypes.bool
}

export default FixStationScreen
