import React from 'react'
import styled from 'styled-components'
import { AccordionSummary } from '@material-ui/core'

export const Root = styled(({ alignItems, children, flexWrap, ...props }) => (
  <AccordionSummary {...props}>{children}</AccordionSummary>
))`
  &.MuiAccordionSummary-root {
    align-items: baseline;
    margin: ${props => props.margin};
    padding: ${props => props.padding};

    .MuiAccordionSummary-content {
      align-items: ${props => props.alignItems};
      flex-wrap: ${props => props.flexWrap};
    }
  }

  .MuiAccordionSummary-expandIcon {
    .MuiSvgIcon-root {
      font-size: 0.9rem;
      fill: ${props => props.theme.palette.brand.black};
    }

    &.Mui-expanded {
      transform: rotate(90deg);
    }
  }
`
