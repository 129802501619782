import React from 'react'
import PropTypes from 'prop-types'
import { Grid } from '@material-ui/core'
import ReactEcharts from 'echarts-for-react'

export const CurrentShiftItem = ({ type, data, groupSelected = false }) => {
  let primary = ''
  let group = ''
  let secondary = ''
  let percent = 0
  let name = ''

  switch (type) {
    case 'anomaly':
      secondary = `${Intl.NumberFormat('de-DE').format(data.anomaly_count)} anomalías`
      group = data.anomaly_group
      primary = `${Intl.NumberFormat('de-DE').format(parseInt(data.anomaly_lts))} litros`
      percent = data.anomaly_percent
      name = data.name
      break
    case 'station':
      primary = <strong>{Intl.NumberFormat('de-DE').format(data.total_fuel_charges)} abastecimientos</strong>
      group = ''
      secondary = `${Intl.NumberFormat('de-DE').format(parseInt(data.total_fuel_litres))} litros`
      percent = data.percent
      name = data.type === 'LB Petrolera San Francisco' ? 'LB San Francisco' : data.type
      break
    default:
      break
  }

  let colorArray = []
  let opacity = 1
  if (!groupSelected) {
    colorArray = ['#FA6A09', '#E6E3DF']
  } else {
    if (groupSelected === group) {
      colorArray = ['#FA6A09', '#E6E3DF']
    } else {
      colorArray = ['#E6E3DF', '#E6E3DF']
      opacity = 0.4
    }
  }

  return (
    <div>
      <Grid container direction="row" justify="space-evenly" alignItems="center" style={{ opacity }}>
        <Grid item xs={4}>
          <ReactEcharts
            style={{ height: 130 }}
            option={{
              title: {
                text: `${Math.round(percent)}%`,
                left: 'center',
                top: 'center',
                textStyle: {
                  fontSize: 22,
                  color: '#263238',
                  fontWeight: 200,
                  fontFamily: 'Titillium Web',
                  fontStyle: 'normal',
                  lineHeight: 50,
                  letterSpacing: -0.01
                }
              },
              color: colorArray,
              series: [
                {
                  zlevel: -1,
                  hoverAnimation: false,
                  type: 'pie',
                  radius: ['60%', '70%'],
                  avoidLabelOverlap: false,
                  label: {
                    show: false
                  },
                  data: [
                    { value: percent, name: group },
                    { value: 100 - percent, name: 'resto' }
                  ]
                }
              ]
            }}
          />
        </Grid>

        <Grid item xs={8}>
          <p style={{ margin: '2px', marginBottom: '15px', fontSize: '16px' }}>
            <strong>{name}</strong>
          </p>
          <p style={{ margin: '2px' }}>{primary}</p>
          <p style={{ margin: '2px' }}>{secondary}</p>
        </Grid>
      </Grid>
    </div>
  )
}

CurrentShiftItem.propTypes = {
  type: PropTypes.string,
  data: PropTypes.object,
  groupSelected: PropTypes.any
}
