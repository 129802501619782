import styled from 'styled-components'
import { Pagination, PaginationItem as BasePaginationItem } from '@material-ui/lab'

export const Root = styled(Pagination)`
  &.MuiPagination-root {
    margin: ${({ alignment }) => {
      switch (alignment) {
        case 'right':
          return '0 0 0 auto'
        case 'center':
          return '0 auto'
        case 'left':
          return '0 auto 0 0'
        default:
          return '0 auto 0 0'
      }
    }};
    width: fit-content;
  }

  & .MuiPagination-ul {
    margin: 5px 0 0 auto;

    li {
      margin: 0;

      & .MuiPaginationItem-root.MuiPaginationItem-page {
        border-bottom: 1px solid ${props => props.theme.palette.brand.gray[85]};
        border-left: 1px solid ${props => props.theme.palette.brand.gray[85]};
        border-top: 1px solid ${props => props.theme.palette.brand.gray[85]};
        border-radius: 0;
      }

      & .MuiPaginationItem-page.Mui-selected,
      & .MuiPaginationItem-page.Mui-selected:hover,
      & .MuiPaginationItem-page.Mui-selected.Mui-focusVisible {
        background-color: ${props => props.theme.palette.brand.primary[65]};
        border: 1px solid ${props => props.theme.palette.brand.primary[50]};
        color: ${props => props.theme.palette.brand.white};
        cursor: default;
      }

      & .MuiPaginationItem-page:hover {
        background-color: ${props => props.theme.palette.brand.gray[85]};
      }
    }

    li:first-child {
      & .MuiPaginationItem-root.MuiPaginationItem-page {
        border-bottom-left-radius: 4px;
        border-top-left-radius: 4px;
      }
    }

    li:last-child {
      & .MuiPaginationItem-root.MuiPaginationItem-page {
        border-bottom-right-radius: 4px;
        border-right: 1px solid ${props => props.theme.palette.brand.gray[85]};
        border-top-right-radius: 4px;
      }
    }
  }

  & .MuiPaginationItem-ellipsis {
    color: ${props => props.theme.palette.brand.primary[50]};
  }
`

export const PaginationItem = styled(BasePaginationItem)`
  &.MuiPaginationItem-root {
    background-color: ${props => props.theme.palette.brand.white};
    border-radius: inherit;
    color: ${props => props.theme.palette.brand.gray[20]};
    font-size: 13px;
    font-weight: bold;
    height: 32px;
    margin: 0;
    width: 32px;

    &.MuiPaginationItem-page.MuiPaginationItem-sizeSmall {
      height: 26px;
      width: 26px;
    }
    &.MuiPaginationItem-page.MuiPaginationItem-sizeMedium {
      height: 32px;
      width: 32px;
    }
    &.MuiPaginationItem-page.MuiPaginationItem-sizeLarge {
      height: 40px;
      width: 40px;
    }
  }
`
