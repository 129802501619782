import React from 'react'
import PropTypes from 'prop-types'
import { Root, PaginationItem } from './Pagination.styles'

/**
 * The Pagination's component.
 */
const Pagination = (
  { defaultPage, page, onChange, ...rest },
  { renderItem = item => <PaginationItem shape="rounded" {...item} /> }
) => {
  return <Root page={page ?? defaultPage} onChange={onChange ?? onChange} {...rest} renderItem={renderItem} />
}

Pagination.defaultProps = {
  alignment: 'left',
  count: 1,
  defaultPage: 1,
  size: 'medium',
  getItemAriaLabel: () => undefined,
  onChange: () => undefined,
  renderItem: item => <PaginationItem {...item} />
}
Pagination.propTypes = {
  alignment: PropTypes.oneOf(['center', 'left', 'right']),
  count: PropTypes.number,
  defaultPage: PropTypes.number,
  page: PropTypes.number,
  rest: PropTypes.object,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  getItemAriaLabel: PropTypes.func,
  onChange: PropTypes.func,
  renderItem: PropTypes.func
}

export default Pagination
