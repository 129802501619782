import styled from 'styled-components'
import { TableRow } from '@material-ui/core'

export const Root = styled(TableRow)`
  &.MuiTableRow-root {
    background-color: ${({ head, theme }) => (head ? theme.palette.brand.primary[75] : theme.palette.brand.white)};
    width: 100%;

    .MuiTableCell-root {
      border-bottom: none;
      border-left: 1px solid ${props => props.theme.palette.brand.gray[85]};
      border-top: 1px solid ${props => props.theme.palette.brand.gray[85]};
      color: ${({ head, theme }) => (head ? theme.palette.brand.white : theme.palette.brand.gray[20])};
      font-size: ${props => (props.head ? '16px' : '14px')};
      font-weight: ${({ head, theme }) => (head ? theme.fontWeights[700] : theme.fontWeights[400])};
      line-height: ${props => (props.head ? '24px' : '17px')};
      text-align: ${props => (props.head ? 'center' : 'left')};
      vertical-align: middle;

      &:last-child {
        border-right: 1px solid ${props => props.theme.palette.brand.gray[85]};
      }
    }

    &:first-child {
      .MuiTableCell-root:first-child {
        border-top-left-radius: 4px;
      }

      .MuiTableCell-root:last-child {
        border-top-right-radius: 4px;
      }
    }

    &:last-child {
      .MuiTableCell-root {
        border-bottom: 1px solid ${props => props.theme.palette.brand.gray[85]};
      }

      .MuiTableCell-root:first-child {
        border-bottom-left-radius: 4px;
      }

      .MuiTableCell-root:last-child {
        border-bottom-right-radius: 4px;
      }
    }

    &.MuiTableRow-hover {
      &:hover {
        background-color: ${props => props.theme.palette.brand.primary[35]};
      }
    }
  }
`
