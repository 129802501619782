import { makeActionCreator } from '../../config/store/utils'
import { getDispatchOrpakGraph } from '../../services/newanomaliesServices'

export const GET_ORPAK_DISPATCH_GRAPH = 'GET_ORPAK_DISPATCH_GRAPH'
export const GET_ORPAK_DISPATCH_GRAPH_ERROR = 'GET_ORPAK_DISPATCH_GRAPH_ERROR'
export const GET_ORPAK_DISPATCH_GRAPH_SUCCESS = 'GET_ORPAK_DISPATCH_GRAPH_SUCCESS'
export const onGetOrpakDispatchGraph = makeActionCreator(GET_ORPAK_DISPATCH_GRAPH)
export const onGetOrpakDispatchGraphError = makeActionCreator(GET_ORPAK_DISPATCH_GRAPH_ERROR, 'payload')
export const onGetOrpakDispatchGraphSuccess = makeActionCreator(GET_ORPAK_DISPATCH_GRAPH_SUCCESS, 'payload')
export const onGetOrpakDispatchGraphThunk = (startDate, endDate) => async (dispatch, getState) => {
  dispatch(onGetOrpakDispatchGraph())

  try {
    const {
      apis: {
        base: { apiKey, apiName }
      }
    } = getState().auth

    const payload = {
      apiKey: apiKey,
      apiName: apiName,
      startDate: startDate,
      endDate: endDate
    }

    const { data } = await getDispatchOrpakGraph(payload)

    const orpak = data.orpak
    const thisDispatch = data.dispatch

    const orpakSerie = orpak.map(item => {
      return {
        fecha: new Date(item[0]),
        quantity: item[1],
        truck: item[2]
      }
    })

    const dispatchSerie = thisDispatch.map(item => {
      return {
        fecha: new Date(item[0]),
        quantity: item[1],
        truck: item[2]
      }
    })

    return dispatch(onGetOrpakDispatchGraphSuccess({ orpakSerie, dispatchSerie }))
  } catch (error) {
    return dispatch(onGetOrpakDispatchGraphError({ error }))
  }
}
