import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { onGetManualThunk, onSetFullscreen } from './Help.actions'
import Alert from '../../components/Basics/Alert'
import Button from '../../components/Buttons/Button'
import Skeleton from '../../components/Placeholders/Skeleton'
import {
  AlertWrapper,
  DownloadIcon,
  Expand,
  ExpandInIcon,
  ExpandOutIcon,
  Grid,
  PdfView,
  Strong
} from './ManualContent.styles'

/**
 * The Manual Content' container.
 */
const ManualContent = () => {
  const dispatch = useDispatch()
  const { error, fullScreen, linkUserGuide, loading } = useSelector(state => state.help)

  useEffect(() => {
    if (!loading && linkUserGuide !== '') {
      return
    }

    dispatch(onGetManualThunk())
  }, [])

  const handleFullscreen = () => dispatch(onSetFullscreen())
  const openNewTab = () => window.open(linkUserGuide, '_blank')

  return (
    <>
      {loading && !error && (
        <Grid container justify="center">
          <Grid container alignItems="center" direction="row" justify="flex-end" margin="0 0 15px">
            <Skeleton height="30px" margin="0 10px 0 0" variant="react" width="150px" />
            <Skeleton height="50px" variant="react" width="200px" />
          </Grid>
          <Skeleton height="400px" variant="react" width="95%" />
        </Grid>
      )}
      {!loading && !error && (
        <>
          <Grid container alignItems="center" direction="row" justify="flex-end" margin="0 0 15px" width="95%">
            <Expand onClick={handleFullscreen}>
              {!fullScreen && (
                <>
                  Expandir&nbsp;
                  <ExpandInIcon />
                </>
              )}
              {fullScreen && (
                <>
                  Contraer&nbsp;
                  <ExpandOutIcon />
                </>
              )}
            </Expand>
            <Button color="primary" variant="contained" onClick={openNewTab}>
              Descargar&nbsp;
              <DownloadIcon />
            </Button>
          </Grid>

          <Grid container alignItems="center" direction="row" justify="center">
            <Grid item xs={12} md={10}>
              <PdfView data={linkUserGuide} fullScreen={fullScreen} type="application/pdf" />
            </Grid>
          </Grid>
        </>
      )}
      {!loading && error && (
        <AlertWrapper>
          <Alert severity="default">
            <Strong>Lo sentimos, estamos presentando problemas con nuestro servidor</Strong>
            <br />
            En estos momentos tenemos problemas para disponibilizar la información. Por favor, consulta en unos minutos
            más.
          </Alert>
        </AlertWrapper>
      )}
    </>
  )
}

export default ManualContent
