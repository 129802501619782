import React, { useEffect } from 'react'
import { batch, useDispatch, useSelector } from 'react-redux'
import LoadingContent from '../../components/Placeholders/LoadingContent'
import DisconnectedData from '../FuelLevel/DisconnectedData.container'
import NewAnomalyFilter from '../NewAnomalyFilter/NewAnomalyFilterContent.container'
import Button from '../../components/Buttons/Button'
import { Box } from '@material-ui/core'
import Tag from '../../components/Basics/Tag'
import Typography from '../../components/Basics/Typography'
import { Separator, Frame, SummaryWrapper, Arrow, Summary } from './NewAnomaly.styles'
import { onGetNewAnomaliesThunk, onToggleAnomalyGraphSuccess, onGetNewAnomaliesReportThunk } from './NewAnomaly.actions'
import AnomalyGraph from './NewAnomalyGraphs'

/**
 * The New AnomalyContent's container.
 */
const NewAnomalyContent = () => {
  const dispatch = useDispatch()
  const {
    loadingNew,
    error,
    anomaliesQuantityTotal,
    anomaliesQuantitySeparated,
    anomaliesFuelTotal,
    anomaliesFuelSeparated,
    showBar,
    totalAnomalies,
    totalFuelLiters,
    totalExceden,
    totalCargaPequena
  } = useSelector(state => state.newAnomaly)
  const { filter1, filter2 } = useSelector(state => state.newAnomalyFilter)
  const { emptyAnomalies } = useSelector(state => state.newAnomalyTopbar)

  useEffect(() => {
    batch(() => {
      dispatch(onGetNewAnomaliesThunk())
      dispatch(onGetNewAnomaliesReportThunk())
    })
  }, [])

  // code for graph, should be in another script if this component goes green
  const colorBar = showBar ? 'primary' : 'default'
  const colorLine = showBar ? 'default' : 'primary'

  const toggleLines = () => {
    if (!showBar) {
      dispatch(onToggleAnomalyGraphSuccess())
    }
  }

  const toggleBars = () => {
    if (showBar) {
      dispatch(onToggleAnomalyGraphSuccess())
    }
  }

  const case1 = filter1 === '1' && filter2 === '1'
  const case2 = filter1 === '1' && filter2 === '2'
  const case3 = filter1 === '2' && filter2 === '1'
  const case4 = filter1 === '2' && filter2 === '2'

  const margin = '0px 10px 0px 0px'

  return (
    <>
      {loadingNew && <LoadingContent />}
      {!loadingNew && error && <DisconnectedData />}
      {!loadingNew && !error && (
        <>
          {emptyAnomalies ? (
            <>
              <Frame>
                <Typography variant="body2">No hay anomalías en el período seleccionado</Typography>
              </Frame>
            </>
          ) : (
            <>
              <NewAnomalyFilter />
              <Separator />
              <Box>
                <Summary>
                  <Typography fontWeight="bold" variant="h4">
                    RESUMEN ANOMALÍAS
                  </Typography>

                  <Arrow />
                  {filter1 === '1' && (
                    <>
                      <SummaryWrapper margin="2px 30px 2px 0">
                        <Tag color="primary" margin={margin} text={totalAnomalies} />
                        <Typography variant="body2" align="center" lineHeight="40px" margin={margin}>
                          Total de Anomalías
                        </Typography>
                      </SummaryWrapper>

                      <SummaryWrapper margin="2px 0">
                        <Tag color="primary" margin={margin} text={totalExceden} />
                        <Typography variant="body2" align="center" lineHeight="40px" margin={margin}>
                          Exceden capacidad
                        </Typography>
                      </SummaryWrapper>

                      <SummaryWrapper margin="2px 0">
                        <Tag color="primary" margin={margin} text={totalCargaPequena} />
                        <Typography variant="body2" align="center" lineHeight="40px" margin={margin}>
                          Cargas pequeñas
                        </Typography>
                      </SummaryWrapper>
                    </>
                  )}
                  {filter1 === '2' && (
                    <>
                      <SummaryWrapper margin="2px 30px 2px 0">
                        <Tag color="primary" margin={margin} text={totalFuelLiters} />
                        <Typography variant="body2" align="center" lineHeight="40px" margin={margin}>
                          Total litros anómalos
                        </Typography>
                      </SummaryWrapper>
                    </>
                  )}
                  <SummaryWrapper margin="0 0 0 auto">
                    <Button width="50px" color={colorBar} size="small" variant="outlined" onClick={toggleLines}>
                      <img src="/assets/icons/barChart.svg" alt="table" />
                    </Button>
                    <Button width="50px" color={colorLine} size="small" variant="outlined" onClick={toggleBars}>
                      <img src="/assets/icons/lineChart.svg" alt="line" />
                    </Button>
                  </SummaryWrapper>
                </Summary>
                {case1 && <AnomalyGraph dataset={anomaliesQuantityTotal} showbar={showBar} />}
                {case2 && <AnomalyGraph dataset={anomaliesQuantitySeparated} showbar={showBar} />}
                {case3 && <AnomalyGraph dataset={anomaliesFuelTotal} showbar={showBar} />}
                {case4 && <AnomalyGraph dataset={anomaliesFuelSeparated} showbar={showBar} />}
              </Box>
            </>
          )}
        </>
      )}
    </>
  )
}

export default NewAnomalyContent
