import { hotjar } from 'react-hotjar'
import { REACT_APP_HOTJAR_ID, REACT_APP_HOTJAR_SNIPPET_VERSION } from './environment'

/**
 * To initialize the analytics tools.
 */
const initializeAnalytics = () => {
  if (REACT_APP_HOTJAR_ID) {
    hotjar.initialize(REACT_APP_HOTJAR_ID, REACT_APP_HOTJAR_SNIPPET_VERSION)
  }
}

export default initializeAnalytics
