// FIXME: Need to be refactored
import React, { useEffect, useState } from 'react'
import { REACT_APP_CLIENT_NAME } from '../../config/settings/environment'
import { Grid, Paper, Typography } from '@material-ui/core'
import { LensRounded } from '@material-ui/icons'
import Countdown from '../Countdown/Countdown.container'
import useStyles from '../../styles/levelsStyle'
import { getLastUpdateAWS, getServicesStateAWS } from '../../services/fuelLevelService'

/**
 * The Server status' container.
 */
const ServerStatus = () => {
  const [, setServices] = useState([])
  const [, setDay] = useState('')
  const [, setTime] = useState('')
  const [status, setStatus] = useState(true)
  const [, setAnchorEl] = React.useState(null)
  const [, setOpen] = React.useState(false)
  const [placement, setPlacement] = React.useState()
  const classes = useStyles()

  useEffect(() => {
    fetchData()
    return () => {
      setServices([])
      setStatus(true)
    }
  }, [])

  const fetchData = async () => {
    const response = await getServicesStateAWS()
    setServices(response)
    // eslint-disable-next-line
    let accumulativeState = true
    response.map(serv => {
      if (!serv.online) {
        accumulativeState = false
      }
    })
    // TODO: eliminar medida parche
    // setStatus(accumulativeState);
    // const orpak = response.find(source => source.servicesId === 'orpak');
    const service = response.find(source =>
      (source.servicesId === REACT_APP_CLIENT_NAME) === 'zaldivar' ? 'orpak' : 'global_service'
    )
    // console.log(!service.online);

    setStatus(service.online)
    const timeUpdate = await getLastUpdateAWS()
    try {
      setDay(timeUpdate.last_update_date)
      setTime(timeUpdate.last_update_time)
    } catch (error) {
      console.log(error, 'out')
    }
  }

  // eslint-disable-next-line
  const onHover = newPlacement => event => {
    setAnchorEl(event.currentTarget)
    setOpen(prev => placement !== newPlacement || !prev)
    setPlacement(newPlacement)
  }

  // eslint-disable-next-line
  const renderService = (id, online, name) => {
    return (
      <Grid container direction="row" key={id} alignItems="center">
        <LensRounded
          className={online ? classes.statusConnected : classes.statusDisconnected}
          style={{ fontSize: '10px' }}
        />
        <Typography className={classes.serviceBadge}>{name}</Typography>
      </Grid>
    )
  }

  const indicatorGraphCircle = () => {
    return (
      <>
        {/* TODO: utilizar el porcentaje de fuentes conectadas */}
        {/* { status &&
          <CircularProgress variant="static" value={45} size={40} style={{ position: "absolute" }} color={'inherit'} />
        } */}
        <LensRounded
          className={status ? classes.statusConnected : classes.statusDisconnected}
          style={{ fontSize: '30px', marginLeft: '5px', marginTop: '5px' }}
        />
      </>
    )
  }

  // eslint-disable-next-line
  const getTooltip = () => {
    return (
      <Paper style={{ padding: 5, color: 'white', background: 'black', margin: 3 }}>
        {indicatorGraphCircle()} {`ESTADO: ${status ? 'Online' : 'Offline'}`}
        <hr />
        <Typography className={classes.tooltipFontLight}>Base de datos en uso:</Typography>
        <Typography className={classes.tooltipFontMedium}>GPS + MEMS</Typography>
        <Typography className={classes.tooltipFontLight}>Presición: (Actualizado al xx-xx-xxxx):</Typography>
        <Typography className={classes.tooltipFontMedium}>77 - 277 litros de error.</Typography>
        {/* <Grid container direction='column' justify={"center"}>
          <Chip
            variant="default"
            style={status ? { color: 'white', backgroundColor: colors.green_full } : { color: 'white', backgroundColor: colors.red_critical }}
            label={status ? 'Estado: En línea' : 'Estado: Intermitencia'}
          />
          <Typography className={classes.serviceBadge} aria-disabled={true}>{day + ' las ' + time}</Typography>
        </Grid>
        <hr />
        <GridList cols={2} cellHeight={"auto"}>
          <GridListTile cols={1} rows={1}>
            {services.length >= 1 && renderService(services[0].servicesId, services[0].online, services[0].serviceName)}
          </GridListTile>
          <GridListTile cols={1} rows={1}>
            {services.length > 1 && renderService(services[1].servicesId, services[1].online, services[1].serviceName)}
          </GridListTile>
          <GridListTile cols={1} rows={1}>
            {services.length > 2 && renderService(services[2].servicesId, services[2].online, services[2].serviceName)}
          </GridListTile>
          <GridListTile cols={1} rows={1}>
            {services.length > 3 && renderService(services[3].servicesId, services[3].online, services[3].serviceName)}
          </GridListTile>
        </GridList> */}
      </Paper>
    )
  }
  return (
    <Grid container direction="row" justify="space-evenly" alignItems="flex-end" style={{ paddingBottom: '10px' }}>
      <Grid item xs={10} style={{ textAlign: 'right' }}>
        <Typography className={classes.label}>{`ESTADO: ${status ? 'Online' : 'Offline'}`}</Typography>
        <Countdown />
      </Grid>
      <Grid item xs={1}>
        {indicatorGraphCircle()}
      </Grid>
    </Grid>
  )
}

export default ServerStatus
