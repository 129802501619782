import { CONSUMPTION_OPERATOR_TYPE } from '../../config/settings/constant'
import { batch } from 'react-redux'
import { makeActionCreator } from '../../config/store/utils'
import { formatDate } from '../../modules/utils/formatters'
import { getInstructors, getReinforcement, sendReinforcement } from '../../services/reinforcement'
import { onUpdateOperatorsHardBraking } from '../HardBraking/HardBraking.actions'
import { onUpdateOperatorsIdleRpm } from '../IdleRpm/IdleRpm.actions'
import { onUpdateOperatorsRpmDownload } from '../RpmDownload/RpmDownload.actions'

export const ADD_OPERATOR_SUCCESS = 'ADD_OPERATOR_SUCCESS'
export const onAddOperatorSuccess = makeActionCreator(ADD_OPERATOR_SUCCESS, 'payload')
export const onAddOperatorThunk = ({ operator }) => (dispatch, getState) => {
  if (operator !== undefined) {
    const { selectedOperators } = getState().reinforcement
    return dispatch(
      onAddOperatorSuccess({
        selectedOperators: [...selectedOperators, { operatorId: operator.value, operatorName: operator.name }]
      })
    )
  }
}

export const CLOSE_FEEDBACK_MODAL = 'CLOSE_FEEDBACK_MODAL'
export const onCloseFeedbackModal = makeActionCreator(CLOSE_FEEDBACK_MODAL)

export const GET_INSTRUCTORS = 'GET_INSTRUCTORS'
export const GET_INSTRUCTORS_ERROR = 'GET_INSTRUCTORS_ERROR'
export const GET_INSTRUCTORS_SUCCESS = 'GET_INSTRUCTORS_SUCCESS'
export const onGetInstructors = makeActionCreator(GET_INSTRUCTORS)
export const onGetInstructorsError = makeActionCreator(GET_INSTRUCTORS_ERROR, 'payload')
export const onGetInstructorsSuccess = makeActionCreator(GET_INSTRUCTORS_SUCCESS, 'payload')
export const onGetInstructorsThunk = () => async dispatch => {
  dispatch(onGetInstructors())

  try {
    const { data } = await getInstructors({ actionType: GET_INSTRUCTORS })

    return dispatch(
      onGetInstructorsSuccess({
        instructors: data?.users.map(item => {
          return {
            instructorEmail: item.email,
            instructorGroup: item.group,
            instructorName: item.name
          }
        })
      })
    )
  } catch (error) {
    return dispatch(onGetInstructorsError({ error }))
  }
}

export const GET_REINFORCEMENT = 'GET_REINFORCEMENT'
export const GET_REINFORCEMENT_ERROR = 'GET_REINFORCEMENT_ERROR'
export const GET_REINFORCEMENT_SUCCESS = 'GET_REINFORCEMENT_SUCCESS'
export const onGetReinforcement = makeActionCreator(GET_REINFORCEMENT, 'payload')
export const onGetReinforcementError = makeActionCreator(GET_REINFORCEMENT_ERROR, 'payload')
export const onGetReinforcementSuccess = makeActionCreator(GET_REINFORCEMENT_SUCCESS, 'payload')
export const onGetReinforcementThunk = ({ reinforcementId }) => async dispatch => {
  dispatch(onGetReinforcement({ reinforcementId }))

  try {
    const { data } = await getReinforcement({
      actionType: GET_REINFORCEMENT,
      reinforcementId
    })

    const selectedOperators = data?.operators.map(operator => {
      return {
        operatorId: parseInt(operator.operatorId),
        operatorName: operator.operatorName
      }
    })

    return dispatch(
      onGetReinforcementSuccess({
        groupId: `group-${data?.reinforcementGroup.replace('g', '')}`,
        groupReinforcement: data?.operators.length > 1,
        instructorEmail: data?.instructorEmail,
        instructorName: data?.instructorName,
        reinforcementDate: new Date(`${data?.reinforcementDate}T00:00:00`),
        reinforcementId: data?.id,
        reportPeriod: data?.reportDate.substring(0, 7),
        selectedOperators,
        type: data?.reinforcementType
      })
    )
  } catch (error) {
    return dispatch(onGetReinforcementError({ error }))
  }
}

export const LOAD_OPERATORS = 'LOAD_OPERATORS'
export const LOAD_OPERATORS_ERROR = 'LOAD_OPERATORS_ERROR'
export const LOAD_OPERATORS_SUCCESS = 'LOAD_OPERATORS_SUCCESS'
export const onLoadOperators = makeActionCreator(LOAD_OPERATORS)
export const onLoadOperatorsError = makeActionCreator(LOAD_OPERATORS_ERROR, 'payload')
export const onLoadOperatorsSuccess = makeActionCreator(LOAD_OPERATORS_SUCCESS, 'payload')
export const onLoadOperatorsThunk = ({ currentOperator, groupId, type }) => async (dispatch, getState) => {
  dispatch(onLoadOperators())

  try {
    let reducer
    switch (type) {
      case 'RALENTI':
        reducer = 'idleRpm'
        break
      case 'RPM':
        reducer = 'rpmDownload'
        break
      case 'FRENADOS':
        reducer = 'hardBraking'
        break
    }
    const {
      [groupId]: { data }
    } = getState()[reducer]

    return dispatch(
      onLoadOperatorsSuccess({
        operators: data
          .filter(operator => !operator.hasReinforcement)
          .map(item => {
            return {
              name: item.operatorName,
              value: item.operatorId
            }
          }),
        selectedOperators: [currentOperator]
      })
    )
  } catch (error) {
    return dispatch(onLoadOperatorsError({ error }))
  }
}

export const REMOVE_OPERATOR_SUCCESS = 'REMOVE_OPERATOR_SUCCESS'
export const onRemoveOperatorSuccess = makeActionCreator(REMOVE_OPERATOR_SUCCESS, 'payload')
export const onRemoveOperatorThunk = ({ operatorId }) => (dispatch, getState) => {
  if (operatorId !== undefined) {
    const { selectedOperators } = getState().reinforcement
    return dispatch(
      onRemoveOperatorSuccess({
        selectedOperators: selectedOperators.filter(item => item.operatorId !== operatorId)
      })
    )
  }
}

export const SET_INITIAL_STATE = 'SET_INITIAL_STATE'
export const onSetInitialState = makeActionCreator(SET_INITIAL_STATE, 'payload')

export const UPDATE_FORM_VALUE = 'UPDATE_FORM_VALUE'
export const onUpdateFormValue = makeActionCreator(UPDATE_FORM_VALUE, 'payload')

export const SEND_REINFORCEMENT = 'SEND_REINFORCEMENT'
export const SEND_REINFORCEMENT_ERROR = 'SEND_REINFORCEMENT_ERROR'
export const SEND_REINFORCEMENT_SUCCESS = 'SEND_REINFORCEMENT_SUCCESS'
export const onSendReinforcement = makeActionCreator(SEND_REINFORCEMENT)
export const onSendReinforcementError = makeActionCreator(SEND_REINFORCEMENT_ERROR, 'payload')
export const onSendReinforcementSuccess = makeActionCreator(SEND_REINFORCEMENT_SUCCESS, 'payload')
export const onSendReinforcementThunk = ({ callback = () => undefined }) => async (dispatch, getState) => {
  dispatch(onSendReinforcement())

  try {
    const {
      groupId,
      instructors,
      instructorEmail,
      reinforcementDate,
      reportPeriod,
      selectedOperators,
      type
    } = getState().reinforcement

    // Prepare data for service
    const operators = selectedOperators.map(operator => {
      return {
        operatorId: operator.operatorId.toString(),
        operatorName: operator.operatorName
      }
    })

    const { data: response } = await sendReinforcement({
      actionType: SEND_REINFORCEMENT,
      instructorEmail: instructorEmail,
      instructorName: instructors.find(x => x.instructorEmail === instructorEmail)?.instructorName,
      operators,
      reinforcementDate: formatDate(reinforcementDate, 'yyyy-MM-dd'),
      reinforcementGroup: `g${groupId.replace('group-', '')}`,
      reinforcementType: CONSUMPTION_OPERATOR_TYPE[type].toLowerCase(),
      reportDate: `${reportPeriod}-01`
    })

    let actionName
    let reducerName = ''
    if (CONSUMPTION_OPERATOR_TYPE[type] === CONSUMPTION_OPERATOR_TYPE.RALENTI) {
      actionName = onUpdateOperatorsIdleRpm
      reducerName = 'idleRpm'
    } else if (CONSUMPTION_OPERATOR_TYPE[type] === CONSUMPTION_OPERATOR_TYPE.RPM) {
      actionName = onUpdateOperatorsRpmDownload
      reducerName = 'rpmDownload'
    } else if (CONSUMPTION_OPERATOR_TYPE[type] === CONSUMPTION_OPERATOR_TYPE.FRENADOS) {
      actionName = onUpdateOperatorsHardBraking
      reducerName = 'hardBraking'
    }

    // Update the actual data operators with the reinforcementId
    const {
      [groupId]: { data }
    } = getState()[reducerName]

    const operatorsGroup = data.map(operator => {
      if (selectedOperators.find(x => x.operatorId === operator.operatorId) !== undefined) {
        return {
          ...operator,
          hasReinforcement: true,
          reinforcementId: response?.id
        }
      } else {
        return {
          ...operator
        }
      }
    })

    return batch(() => {
      dispatch(onSendReinforcementSuccess())
      dispatch(actionName({ data: operatorsGroup, selectedGroupId: groupId }))
      callback()
    })
  } catch (error) {
    return batch(() => {
      dispatch(onSendReinforcementError({ error }))
      callback()
    })
  }
}
