import React from 'react'
import moment from 'moment'
import { Paper, Chip, Typography, Badge } from '@material-ui/core'
import Tooltip from './Basics/Tooltip'
import PropTypes from 'prop-types'
// eslint-disable-next-line
import { default as truckImg } from '../constants/images/truck.svg'
// eslint-disable-next-line
import { default as truckOffImg } from '../constants/images/GroupTruckDisabled.svg'
// eslint-disable-next-line
import { default as gas } from '../constants/icons/gasStation.svg'
// eslint-disable-next-line
import { default as gasStation } from '../constants/icons/gasStationFill.svg'
// eslint-disable-next-line
import { default as symbology8Out } from '../constants/icons/tankSymbology8.svg'

const TruckVehicle = ({ truckData, color }) => {
  const [, setAnchorEl] = React.useState(null)
  const [, setOpen] = React.useState(false)
  const [placement, setPlacement] = React.useState()
  // const [style, setStyle] = useState({color: 'white', backgroundColor: color});
  // const [variant, setVariant] = useState('default');

  let variant = 'default'
  let style = {}
  let icon = ''
  let hiddenBadge = true
  let truck = ''
  const optionTooltip = 'connected'

  const onHover = newPlacement => event => {
    setAnchorEl(event.currentTarget)
    setOpen(prev => placement !== newPlacement || !prev)
    setPlacement(newPlacement)
  }

  const tooltipTruck = (option, truckData) => {
    const now = moment()
    const lastCharge = truckData.last_charge_day + ' ' + truckData.last_charge_hour
    const lastChargeTime = moment(lastCharge, 'DD/MM/YYYY HH:mm:ss')
    const daysDiff = now.diff(lastChargeTime, 'days')
    const hoursDiff = now.diff(lastChargeTime, 'hours')
    const pluralDay = daysDiff > 1 ? ' días' : ' día'
    const pluralHour = hoursDiff <= 1 ? ' hora' : ' horas'
    const lastChargeString = daysDiff > 0 ? 'Hace ' + daysDiff + pluralDay : 'Hace ' + hoursDiff + pluralHour
    switch (option) {
      case 'goSupply':
        return (
          <>
            <Paper style={{ padding: 10, color: 'white', background: 'black' }}>
              <Typography
                style={{
                  backgroundColor: '#ffffff',
                  color: '#333333',
                  fontSize: '14px',
                  borderRadius: '5px',
                  marginBottom: '15px'
                }}
              >
                <img src={gas} alt="iconTank" style={{ alignSelf: 'center', marginLeft: '5px' }} />
                Asignado para abastecimiento
              </Typography>
              <Typography style={{ fontSize: 12 }}>
                <strong>{'Camión: ' + truckData.patent}</strong>
                <br />
                {'Nivel de combustible: ' + Math.round(truckData.percent) + '% -'}
                <br />
                {Math.round(truckData.fuel_level) + ' litros en estanque.'}
                <br />
                {'Última carga: ' + lastChargeString}
                <br />
                {truckData.last_charge_day + ' a las ' + truckData.last_charge_hour}
                {/* <br />
                {'Abastecer combustible en estación: '} */}
                <strong>{truckData.charge_station.map(station => `${station}, `)}</strong>
              </Typography>
            </Paper>
          </>
        )
      case 'disconnected':
        return (
          <>
            <Paper style={{ padding: 10, color: 'white', background: '#828282' }}>
              <Typography style={{ fontSize: 12 }}>
                Han pasado <strong>más de {truckData.diff_hrs}</strong> horas
                <br />
                sin abastecimiento.
                <br />
                <br />
                {'Camión: ' + truckData.patent}
                <br />
                {'Última carga: ' + lastChargeString}
              </Typography>
            </Paper>
          </>
        )

      case 'allDisconnected':
        return (
          <>
            <Paper style={{ padding: 10, color: 'white', background: '#828282' }}>
              <Typography style={{ fontSize: 12 }}>
                <strong>OFFLINE</strong>
                <br />
                Todas las fuentes de datos se
                <br />
                encuentran desconectadas.
                <br />
                {'Camión: ' + truckData.patent}
                <br />
                {'Última carga: ' + lastChargeString}
              </Typography>
            </Paper>
          </>
        )

      case 'sync':
        return (
          <>
            <Paper style={{ padding: 10, color: 'white', background: '#828282' }}>
              <Typography style={{ fontSize: 12 }}>
                <strong>SINCRONIZANDO...</strong>
                <br />
                {'Camión: ' + truckData.patent}
                <br />
                {Math.round(truckData.percent) + '%'}
                <br />
                {Math.round(truckData.fuel_level) + ' litros en estanque.'}
                <br />
                {'Última carga: ' + lastChargeString}
              </Typography>
            </Paper>
          </>
        )

      case 'anyDisconnected':
        return (
          <>
            <Paper style={{ padding: 10, color: 'white', background: '#828282' }}>
              <Typography style={{ fontSize: 12 }}>
                <strong>La fuente de datos</strong>
                <br />
                <strong>{truckData.name_disconnected_source.map(source => `${source}, `)}</strong>
                <br />
                <strong>se encuentra desconectada</strong>
                <br />
                contactarse con personal encargado
                <br />
                para restaurar la conexión.
                <br />
                <br />
                {'Camión: ' + truckData.patent}
                <br />
                {Math.round(truckData.percent) + '%'}
                <br />
                {Math.round(truckData.fuel_level) + ' litros en estanque.'}
                <br />
                {'Última carga: ' + lastChargeString}
              </Typography>
            </Paper>
          </>
        )

      default:
        return (
          <>
            <Paper style={{ padding: 10, color: 'white', background: 'black' }}>
              <Typography style={{ fontSize: 12 }}>
                <strong>{'Camión: ' + truckData.patent}</strong>
                <br />
                <span>
                  Nivel de combustible: <b>{Math.round(truckData.percent)} %</b> -
                </span>
                {/* 'Nivel de combustible: ' + Math.round(truckData.percent) + '% -' */}
                <br />
                <span>
                  <b>{Math.round(truckData.fuel_level)}</b> litros en estanque.
                </span>
                {/* Math.round(truckData.fuel_level) + ' litros en estanque.' */}
                <br />
                <span>
                  Última carga: <b>{lastChargeString}</b> -
                </span>
                {/* 'Última carga: ' + lastChargeString */}
                <br />
                {truckData.last_charge_day + ' a las ' + truckData.last_charge_hour + '.'}
                <br />
                {truckData.charge_station ? 'Lugar de abastecimiento: ' + truckData.charge_station[0] : ''}
              </Typography>
            </Paper>
          </>
        )
    }
  }

  const renderTruck = (optionTooltip, truckData) => {
    // if (truckData.disconnected_sources) {
    //   icon = offline;
    //   hiddenBadge = false;
    //   optionTooltip = 'anyDisconnected';
    // }
    // if (truckData.all_disconnected_sources) {
    //   icon = cloudOff;
    //   hiddenBadge = false;
    //   optionTooltip = 'allDisconnected';
    // }
    // if (truckData.loading) {
    //   icon = sync;
    //   hiddenBadge = false;
    //   optionTooltip = 'sync';
    // }

    // asignado a abastecimiento
    if (truckData.active && truckData.go_supply) {
      variant = 'default'
      style = { color: 'white', backgroundColor: color }
      // truck = bounceTruck;
      truck = truckImg
      optionTooltip = 'goSupply'
      icon = gasStation
      hiddenBadge = false
    }

    // asignado a abastecimiento y no activo
    if (!truckData.active && truckData.go_supply) {
      variant = 'default'
      style = { color: '#BDBDBD' }
      truck = truckOffImg
      optionTooltip = 'goSupply'
      icon = gasStation
      hiddenBadge = false
    }

    if (truckData.active && !truckData.go_supply) {
      variant = 'outline'
      style = { color: color, borderColor: color }
      truck = truckImg
    }

    // Ciclo extenso de combustible
    if (!truckData.active && !truckData.go_supply) {
      variant = 'outline'
      style = { color: '#BDBDBD', borderColor: '#BDBDBD' }
      truck = truckOffImg
      optionTooltip = 'disconnected'
    }

    if (truckData.percent < 26) {
      icon = symbology8Out
      hiddenBadge = false
    }

    return (
      <>
        <div
          onMouseOver={onHover('bottom')}
          onMouseOut={() => {
            setOpen(false)
          }}
        >
          <Badge
            invisible={hiddenBadge}
            color="secondary"
            badgeContent={<img src={icon} alt="icono" style={{ width: '16px', height: '16px' }} />}
          >
            <Tooltip content={tooltipTruck(optionTooltip, truckData)} arrow>
              <Chip variant={variant} style={{ ...style, marginLeft: '10px' }} size="small" label={truckData.patent} />
            </Tooltip>
          </Badge>
          {/* <Tooltip title={tooltipTruck(optionTooltip, truckData)} arrow={true}>
            <Chip
              variant={variant}
              style={{ ...style, marginLeft: '10px' }}
              size="small"
              label={truckData.patent}
            />

          </Tooltip> */}
          <div>
            <img src={truck} alt={truckData.patent} style={{ width: '45px' }} />
          </div>
        </div>
      </>
    )

    // if (truckData.active) {
    //   if (truckData.dataLoss) {
    //     // truck with data loss
    //     return(
    //       <div onMouseOver={onHover('bottom')} onMouseOut={()=>{setOpen(false)}}>
    //         <Chip
    //           variant={open ? "default":"outlined"}
    //           style={open ? {color:'white', backgroundColor:color} : {color: color, borderColor: color}}
    //           size="small"
    //           label={truckData.patent} />
    //         <div className={'animate__animated animate__flash animate__infinite infinite animate__slower'}><img src={truckImg} alt={truckData.patent} style={{width:'45px'}}/></div>
    //       </div>
    //     )
    //   } else {
    //     // truck active
    //     return(
    //       <div onMouseOver={onHover('bottom')} onMouseOut={()=>{setOpen(false)}}>
    //         <Chip
    //           variant={open ? "default":"outlined"}
    //           style={open ? {color:'white', backgroundColor:color} : {color: color, borderColor: color}}
    //           size="small"
    //           label={truckData.patent}
    //         />
    //         <div><img src={truckImg} alt={truckData.patent} style={{width:'45px'}}/></div>
    //       </div>
    //     )
    //   }
    // } else {
    //   // truck inactive
    //   return(
    //     <div onMouseOver={onHover('bottom')} onMouseOut={()=>{setOpen(false)}}>
    //       <Chip
    //         variant={open ? "default":"outlined"}
    //         style={open ? {color:'white', backgroundColor:color} : {color: color, borderColor: color}}
    //         size="small"
    //         label={truckData.patent}
    //         disabled={true} />
    //       <div><img src={truckOffImg} alt={truckData.patent} style={{width:'45px'}}/></div>
    //     </div>
    //   )
    // }
  }

  return (
    <div style={{ paddingLeft: 5 }}>
      {renderTruck(optionTooltip, truckData)}
      {/* <Popper
        open={open}
        anchorEl={anchorEl}
        placement={placement}
        transition
      >
        {tooltipTruck(optionTooltip, truckData)}
      </Popper> */}
    </div>
  )
}

TruckVehicle.propTypes = {
  truckData: PropTypes.object,
  color: PropTypes.string
}

export default TruckVehicle
