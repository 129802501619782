import React from 'react'
import styled, { createGlobalStyle } from 'styled-components'
import { hexToRGBA } from '../../modules/utils/color'
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'

export const Root = styled.div`
  display: inline-flex;
  flex-direction: column;
  font-family: ${props => props.theme.typography.fontFamily};
  margin: ${props => props.margin};
  padding: ${props => props.padding};
  width: ${props => props.width || '100%'};
`

export const BasePickerProvider = styled(MuiPickersUtilsProvider)``

export const GlobalStyles = createGlobalStyle`
  .MuiPickersBasePicker-container {
    .MuiTypography-subtitle1, .MuiTypography-body1 {
      font-size: 16px;
    }
    .MuiTypography-h4 {
      font-size: 34px;
    }
    .MuiTypography-caption {
      font-size: 12px;
    }
    .MuiTypography-body2 {
      font-size: 14px;
    }
    .MuiTypography-h5 {
      font-size: 24px;
    }
    .MuiPickersCalendarHeader-iconButton .MuiSvgIcon-root {
      font-size: 24px;
    }
  }
  .MuiDialogActions-root {
    display: none;
  }

  .MuiPickersDay-day {
    color: ${props => props.theme.palette.brand.gray[20]};
  }
  .MuiPickersDay-dayDisabled,
  .MuiPickersCalendarHeader-dayLabel {
    color: ${props => hexToRGBA(props.theme.palette.brand.gray[20], 0.5)};
  }
  .MuiPickersDay-daySelected {
    color: ${props => props.theme.palette.brand.white};
  }
  .MuiPickersCalendarHeader-transitionContainer .MuiTypography-body1 {
    color: ${props => props.theme.palette.brand.gray[20]};
  }
  .MuiPickersCalendarHeader-iconButton {
    color: ${props => props.theme.palette.brand.gray[50]};
  }
  .MuiIconButton-root.Mui-disabled {
    color: ${props => hexToRGBA(props.theme.palette.brand.gray[50], 0.5)};
  }
  .MuiPickersYear-root {
    color: ${props => props.theme.palette.brand.gray[20]};
  }
  .MuiPickersYear-yearDisabled {
    color: ${props => hexToRGBA(props.theme.palette.brand.gray[20], 0.5)};
  }
  .MuiPickersYear-yearSelected {
    color: ${props => props.theme.palette.brand.primary[50]};
  }
  .MuiPickersToolbarText-toolbarBtnSelected,
  .MuiPickersToolbarText-toolbarTxt {
    color: ${props => props.theme.palette.brand.white};
  }
`

export const BaseDatePicker = styled(KeyboardDatePicker)`
  & .MuiInputBase-root {
    background-clip: padding-box;
    background-color: ${props => props.theme.palette.brand.white};
    border: 1px solid ${props => props.theme.palette.brand.gray[85]};
    border-radius: 0.25rem;
    box-sizing: border-box;
    color: ${props => props.theme.palette.brand.gray[20]};
    display: flex;
    font-size: 15px;
    height: calc(1.5em + 0.75rem + 2px);
    line-height: 1.5;
    overflow: hidden;
    -webkit-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

    & input {
      box-sizing: border-box;
      flex-grow: 1;
      height: 100%;
      overflow: hidden;
      padding: 0.375rem 0.75rem;
      text-align: ${props => props.textAlign};
      text-overflow: ellipsis;
      white-space: nowrap;
      width: auto;

      &::placeholder {
        color: ${props => props.theme.palette.brand.gray[50]};
        font-family: ${props => props.theme.typography.fontFamily};
        font-size: 15px;
        line-height: 1.5;
        opacity: 0.42;
        transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      }
    }

    &.Mui-focused {
      box-shadow: 0 0 0 0.2rem ${props => hexToRGBA(props.theme.palette.brand.primary[50], 0.25)};
    }

    &.Mui-disabled {
      background-color: rgba(46, 91, 255, 0.05);
      border: 1px solid ${props => props.theme.palette.brand.gray[85]};
      color: ${props => props.theme.palette.brand.gray[50]};
      cursor: not-allowed;
    }

    &.Mui-disabled input {
      cursor: not-allowed;
    }

    &.Mui-error {
      border: 1px solid ${props => props.theme.palette.brand.pomegranate};
    }

    &.Mui-error.Mui-focused {
      box-shadow: 0 0 0 0.2rem ${props => hexToRGBA(props.theme.palette.brand.pomegranate, 0.5)};
    }

    &.MuiInput-underline:before,
    &.MuiInput-underline:after {
      border: none;
    }

    & .MuiIconButton-root {
      color: ${props => props.theme.palette.brand.gray[50]};

      & .MuiSvgIcon-root {
        font-size: 24px;
      }
    }
  }

  & .MuiFormHelperText-root {
    font-size: 12px;
    line-height: 14px;

    &.Mui-error {
      color: ${props => props.theme.palette.brand.pomegranate};
    }
  }
`

export const Label = styled.label`
  color: ${props => props.theme.palette.brand.black};
  font-size: 16px;
  font-weight: ${props => props.theme.fontWeights[600]};
  letter-spacing: 1px;
  line-height: 17px;
  margin: 0 0 0.5rem;
`

export const Required = styled(({ ...props }) => <span {...props}>*</span>)`
  color: ${props => props.theme.palette.brand.gray[50]};
  font-size: 1rem;
`

export const BottomMessage = styled.section`
  font-family: ${props => props.theme.typography.fontFamily};
  font-size: 12px;
  line-height: 14px;
  min-height: 20px;
`

export const Info = styled.section`
  color: ${props => props.theme.palette.brand.gray[50]};
  font-style: italic;
`

export const Error = styled.section`
  color: ${props => props.theme.palette.brand.pomegranate};
`
