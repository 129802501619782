import React, { useState } from 'react'
import { ADDRESS_CHECK } from '../../config/settings/regexes'
import {
  checkErrorEmail,
  checkErrorMaxLength,
  checkErrorMinLength,
  checkErrorOnlyNumbers,
  checkErrorRegEx,
  checkErrorRequired
} from '../../modules/errors/validations'
import { formatToAllowOnlyNumbers, formatDNI } from '../../modules/utils/formatters'
import { useInput } from '../../components/Inputs/Inputs.hooks'
import Input from '../../components/Inputs/Input'
import Typography from '../../components/Basics/Typography'
import { Form, PhoneNumberPrefix, Root, Search, SearchIcon, SearchLoadingIcon } from './Examples.styles'

/**
 * The InputsExample's container.
 */
const InputsExample = () => {
  const [searching, setSearching] = useState(false)
  const [name, setName] = useState('')
  const { value: textoValue, onChange: handleTextoChange } = useInput({
    initialValue: ''
  })

  const { error: usernameError, value: usernameValue, onChange: handleUsernameChange } = useInput({
    initialValue: '',
    errorCallbacks: [checkErrorRequired(), checkErrorEmail()]
  })

  const { error: passwordError, value: passwordValue, onChange: handlePasswordChange } = useInput({
    initialValue: '',
    errorCallbacks: [checkErrorRequired()]
  })

  const { error: phoneError, value: phoneValue, onChange: handlePhoneChange } = useInput({
    errorCallbacks: [
      checkErrorRequired(),
      checkErrorOnlyNumbers(),
      checkErrorMinLength(8, 'Este campo permite 8 dígitos.'),
      checkErrorMaxLength(8, 'Este campo permite 8 dígitos.')
    ],
    formatCallbacks: [formatToAllowOnlyNumbers]
  })

  const { error: counterError, value: counterValue, onChange: handleCounterChange } = useInput({
    errorCallbacks: [checkErrorMaxLength(10)]
  })

  const { error: addressError, value: addressValue, onChange: handleAddressChange } = useInput({
    errorCallbacks: [checkErrorRequired(), checkErrorRegEx(ADDRESS_CHECK, 'Este campo tiene un formato incorrecto.')]
  })

  const { error: dniError, value: dniValue, onChange: handleDniChange } = useInput({
    initialValue: '',
    errorCallbacks: [checkErrorRequired()],
    formatCallbacks: [formatDNI]
  })

  const handleSearch = event => {
    event.preventDefault()
    setSearching(true)
    setTimeout(() => {
      setName('No existe el servicio para buscar, pero si existiera diria el nombre')
      setSearching(false)
    }, 5000)
  }

  return (
    <Root>
      <Typography variant="body1">
        El input puede llevar label o no. Los ejemplos a continuación tienen validación. Mirar focus, color de error.
      </Typography>
      <br />
      <div style={{ width: '200px' }}>
        <Input placeholder="Texto" value={textoValue} onChange={handleTextoChange} />
        <Input
          error={usernameError}
          label="Usuario"
          placeholder="Usuario"
          required
          value={usernameValue}
          onChange={handleUsernameChange}
        />
        <Input
          error={passwordError}
          label="Contraseña"
          placeholder="Contraseña"
          required
          type="password"
          value={passwordValue}
          onChange={handlePasswordChange}
        />
      </div>
      <br />

      <Typography variant="body1">Con prefix.. que podria ser un icono tambien :)</Typography>
      <br />

      <div style={{ width: '350px' }}>
        <Input
          prefix={<PhoneNumberPrefix>+56 9</PhoneNumberPrefix>}
          error={phoneError}
          label="Número de teléfono móvil "
          onChange={handlePhoneChange}
          placeholder="81234567"
          required
          value={phoneValue}
        />
      </div>
      <br />

      <Typography variant="body1">Un ejemplo con contador de caracteres</Typography>
      <br />

      <div style={{ width: '350px' }}>
        <Input
          showCounter
          error={counterError}
          label="Input counter"
          maxCharactersForCounter={10}
          maxLength={15}
          placeholder="Ingrese algo..."
          value={counterValue}
          onChange={handleCounterChange}
        />
      </div>
      <br />

      <Typography variant="body1">Un ejemplo con info y con tooltip</Typography>
      <br />

      <div style={{ width: '500px' }}>
        <Input
          error={addressError}
          info="Caracteres permitidos: letras (A-Z), números (0-9), espacios y símbolos (.,-º#)."
          label="Dirección"
          required
          tooltip="Caracteres permitidos: letras (A-Z), números (0-9), espacios y símbolos (.,-º#)."
          value={addressValue}
          onChange={handleAddressChange}
        />
      </div>
      <br />

      <Typography variant="body1">Ejemplo de un input de busqueda</Typography>
      <br />
      <div style={{ width: '300px' }}>
        <Form onSubmit={handleSearch}>
          <Input
            disabled={searching}
            error={dniError}
            label="RUT"
            maxLength={15}
            suffix={
              <Search disabled={dniValue === '' || searching} type="submit">
                {!searching && <SearchIcon />}
                {searching && <SearchLoadingIcon />}
              </Search>
            }
            value={dniValue}
            width="100%"
            onChange={handleDniChange}
          />
        </Form>
        {name}
      </div>
    </Root>
  )
}

export default InputsExample
