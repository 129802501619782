import styled from 'styled-components'
import { Grid as BaseGrid } from '@material-ui/core'

export const Grid = styled(BaseGrid)``

export const Wrapper = styled.div`
  align-items: flex-start;
  display: flex;
  margin-top: 20px;
  justify-content: center;
  margin: ${props => props.margin};
  height: 40vw;
  > * {
    margin-left: 10px;
  }
`

export const Graphdiv = styled.div`
  height: 100%;
  width: 80%;
`

export const Dates = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
`
