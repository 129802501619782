import React from 'react'
import PropTypes from 'prop-types'
import CheckBox from '../Inputs/CheckBox'
import Skeleton from './Skeleton'
import Typography from '../Basics/Typography'
import { Grid } from './CheckBoxPlaceholder.styles'

/**
 * The CheckBoxPlaceholder's component.
 */
const CheckBoxPlaceholder = props => {
  const { margin, rows } = props

  return (
    <Grid container margin={margin}>
      <Grid item margin="0 -94px 0 0" xs={12} sm={1}>
        <CheckBox disabled />
      </Grid>
      <Grid item xs={12} sm={11}>
        <Typography fontSize="15px" variant="body1">
          <Skeleton width="100%" />
          {rows > 2 && <Skeleton width="100%" />}
          {rows > 3 && <Skeleton width="100%" />}
          {rows > 1 && <Skeleton width="30%" />}
        </Typography>
      </Grid>
    </Grid>
  )
}

CheckBoxPlaceholder.defaultProps = {
  margin: '0',
  rows: 1
}
CheckBoxPlaceholder.propTypes = {
  margin: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  rows: PropTypes.oneOf([1, 2, 3, 4])
}

export default CheckBoxPlaceholder
