import React from 'react'
import styled from 'styled-components'
import { Grid as BaseGrid, TextField } from '@material-ui/core'
import { hexToRGBA } from '../../modules/utils/color'

export const Form = styled.form`
  .MuiFormLabel-root.Mui-error {
    color: ${props => props.theme.palette.brand.pomegranate};
  }

  .MuiInput-underline.Mui-error:after {
    border-bottom-color: ${props => props.theme.palette.brand.pomegranate};
  }

  .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline {
    border-color: ${props => props.theme.palette.brand.pomegranate};
  }

  .MuiFormHelperText-root.Mui-error {
    color: ${props => props.theme.palette.brand.pomegranate};
  }
`

export const Grid = styled(({ backgroundColor, children, textAlign, ...props }) => (
  <BaseGrid {...props}>{children}</BaseGrid>
))`
  background-color: ${({ backgroundColor, theme }) =>
    backgroundColor ? hexToRGBA(theme.palette.brand[backgroundColor], 0.9) : 'transparent'};
  height: ${props => props.height};
  margin: ${props => props.margin};
  padding: ${props => props.padding};
  text-align: ${props => props.textAlign};
`

export const Input = styled(TextField)`
  width: 100%;
`

export const Root = styled.section`
  background-image: url(/assets/images/background-contact.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`
