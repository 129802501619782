import { makeActionCreator } from '../../config/store/utils'
import { batch } from 'react-redux'
import { getKeplerHistoricalData, getKeplerUrl } from '../../services/keplerServices'

export const GET_MAPBOX_HISTORIC = 'GET_MAPBOX_HISTORIC'
export const GET_MAPBOX_HISTORIC_ERROR = 'GET_MAPBOX_HISTORIC_ERROR'
export const GET_MAPBOX_HISTORIC_SUCCESS = 'GET_MAPBOX_HISTORIC_SUCCESS'
export const onGetMapbox = makeActionCreator(GET_MAPBOX_HISTORIC)
export const onGetMapboxError = makeActionCreator(GET_MAPBOX_HISTORIC_ERROR, 'payload')
export const onGetMapboxSuccess = makeActionCreator(GET_MAPBOX_HISTORIC_SUCCESS, 'payload')
export const onGetMapboxHistoricThunk = date => async (dispatch, getState) => {
  dispatch(onGetMapbox())

  try {
    const {
      apis: {
        base: { apiKey, apiName }
      }
    } = getState().auth
    var functionData = { apiKey, apiName }

    const { url } = await getKeplerHistoricalData(functionData)
    if (url.message === 'No data available for this day.') {
      const urlData = []
      return batch(() => {
        dispatch(onGetMapboxSuccess({ urlData }))
      })
    }
    const { data: urlData } = await getKeplerUrl({
      apiKey,
      apiName,
      url: url.urls[0]
    })

    return batch(() => {
      dispatch(onGetMapboxSuccess({ urlData }))
    })
  } catch (error) {
    return dispatch(onGetMapboxError({ error }))
  }
}
