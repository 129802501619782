import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { REACT_APP_BASE_URL } from '../../config/settings/environment'
import { checkErrorRequired } from '../../modules/errors/validations'
import { useInput } from '../../components/Inputs/Inputs.hooks'
import { getFromCookie } from '../../modules/storages/cookies'
import routes from '../../config/settings/routes'
import Input from '../../components/Inputs/Input'
import { Container, Loading, LoadingIcon, LogInForm, Logo, Root, Title, Wrapper } from './Login.styles'
import Button from '../../components/Buttons/Button'
import { screenResolution } from '../../store/actions/screenActions'
import { onRecoverPasswordThunk } from './Authentication.actions'
import Typography from '../../components/Basics/Typography'

/**
 * The Password Recovery' container.
 */
const PasswordRecoveryContainer = () => {
  const dispatch = useDispatch()
  const { error, loading, resetMessage } = useSelector(state => state.auth)
  const redirectPath = getFromCookie('redirect_path') || routes.root

  const { error: usernameError, value: usernameValue, onChange: handleUsernameChange } = useInput({
    initialValue: '',
    errorCallbacks: [checkErrorRequired()]
  })
  const { error: passwordError, value: passwordValue, onChange: handlePasswordChange } = useInput({
    initialValue: '',
    errorCallbacks: [checkErrorRequired()]
  })
  const { error: codeError, value: codeValue, onChange: handlecodeChange } = useInput({
    initialValue: '',
    errorCallbacks: [checkErrorRequired()]
  })

  const canNotSubmit = useCallback(() => {
    return passwordError !== '' || usernameError !== '' || codeError !== ''
  }, [passwordError, usernameError, codeError])
  const disabledSubmit = canNotSubmit()

  const handlePasswordSubmit = event => {
    event.preventDefault()
    screenResolution(window.screen.width)
    dispatch(
      onRecoverPasswordThunk({
        username: usernameValue,
        code: codeValue,
        newPassword: passwordValue,
        onSuccessCallback: () =>
          setTimeout(() => {
            window.open(`${REACT_APP_BASE_URL}${redirectPath}`, '_top')
          }, 3000)
      })
    )
  }

  return (
    <Root>
      <Container maxWidth="md">
        <Wrapper container direction="column" justify="center" alignItems="center">
          <Logo src="/assets/images/cosmos-h.svg" alt="Universal Intelligence" />
          <LogInForm onSubmit={handlePasswordSubmit} autoComplete="off">
            <Title>
              Bienvenido a Cosmos, <br />
              ingrese su nueva contraseña
            </Title>
            <Input
              error={usernameError}
              margin="0 0 4px"
              placeholder="Usuario"
              reverse
              value={usernameValue}
              onChange={handleUsernameChange}
            />
            <Input
              error={codeError}
              margin="0 0 16px"
              placeholder="Código recuperación"
              reverse
              type="password"
              value={codeValue}
              onChange={handlecodeChange}
            />
            <Input
              error={passwordError}
              margin="0 0 16px"
              placeholder="Nueva contraseña"
              reverse
              type="password"
              value={passwordValue}
              onChange={handlePasswordChange}
            />

            {!loading && error && error.reason === 'INCORRECT_USERNAME_OR_PASSWORD_ERROR' && (
              <Typography align="center" color="white" padding="0 0 18px" variant="body2">
                Nombre de usuario incorrecto
              </Typography>
            )}

            {!loading && error && error.reason === 'SOMETHING_WENT_WRONG_ERROR' && (
              <Typography align="center" color="white" padding="0 0 18px" variant="body2">
                Lo sentimos, estamos presentando problemas con nuestro servidor
              </Typography>
            )}

            {!loading && error && error.reason === 'USERNAME_CANNOT_BE_EMPTY' && (
              <Typography align="center" color="white" padding="0 0 18px" variant="body2">
                Por favor ingrese su nombre de usuario
              </Typography>
            )}

            {!loading && error && error.reason === 'PASSWORD_DOES_NOT_CONFORM_TO_POLICY' && (
              <Typography align="center" color="white" padding="0 0 18px" variant="body2">
                Contraseña nueva debe contener minimo 8 caracteres
              </Typography>
            )}

            {!loading && error && error.reason === 'EXPIRED_CODE' && (
              <Typography align="center" color="white" padding="0 0 18px" variant="body2">
                Código expirado, favor solicite un nuevo codigo
              </Typography>
            )}

            {!loading && error && error.reason === 'LIMIT_EXCEEDED_EXCEPTION' && (
              <Typography align="center" color="white" padding="0 0 18px" variant="body2">
                Limite excedido, por favor inténtelo de nuevo más tarde
              </Typography>
            )}

            {!loading && error && error.reason === 'CODE_MISMATCH' && (
              <Typography align="center" color="white" padding="0 0 18px" variant="body2">
                Código invalido, por favor intente de nuevo
              </Typography>
            )}

            {!loading && error && error.reason === 'POR FAVOR CREE UNA NUEVA CONTRASEÑA' && (
              <Typography align="center" color="white" padding="0 0 18px" variant="body2">
                Por favor crear una nueva contraseña
              </Typography>
            )}

            {!loading && !error && (
              <Typography align="center" color="white" padding="0 0 18px" variant="body2">
                {resetMessage}
              </Typography>
            )}

            <Button color="primary" disabled={disabledSubmit} type="submit" variant="contained">
              {loading && (
                <Loading>
                  <LoadingIcon size={22} />
                </Loading>
              )}
              {!loading && 'INGRESAR'}
            </Button>
          </LogInForm>
        </Wrapper>
      </Container>
    </Root>
  )
}

export default PasswordRecoveryContainer
