import { API } from 'aws-amplify'
import {
  REACT_APP_BASE_KEY,
  REACT_APP_BASE_NAME,
  REACT_APP_CLIENT_NAME,
  REACT_APP_REINFORCEMENT_KEY,
  REACT_APP_REINFORCEMENT_NAME
} from '../config/settings/environment'

/**
 * To get the reinforcement detail per id.
 */
export const getReinforcement = async ({ reinforcementId }) => {
  const path = `/reinforcements/${reinforcementId}`

  const response = await API.get(REACT_APP_REINFORCEMENT_NAME, path, {
    headers: { 'x-api-key': REACT_APP_REINFORCEMENT_KEY },
    response: true
  })

  return {
    data: response.data,
    headers: response.headers,
    request: response.config,
    status: response.status
  }
}

/**
 * To create a reinforcement.
 */
export const sendReinforcement = async ({
  instructorEmail,
  instructorName,
  operators,
  reinforcementDate,
  reinforcementGroup,
  reinforcementType,
  reportDate
}) => {
  const path = '/reinforcements/'
  const body = {
    instructorEmail,
    instructorName,
    operators,
    reinforcementDate,
    reinforcementGroup,
    reinforcementType,
    reportDate
  }

  const response = await API.post(REACT_APP_REINFORCEMENT_NAME, path, {
    headers: { 'x-api-key': REACT_APP_REINFORCEMENT_KEY },
    response: true,
    body
  })

  return {
    data: response.data,
    headers: response.headers,
    request: response.config,
    status: response.status
  }
}

/**
 * To get all instructors.
 */
export const getInstructors = async () => {
  const paths = {
    amsa: '/users/role/list?role=instructor',
    anglo: '/v2/users/role/list?role=instructor',
    zaldivar: '/v2/users/role/list?role=instructor'
  }
  const path = paths[REACT_APP_CLIENT_NAME] || '/v2/users/role/list?role=instructor'

  const response = await API.get(REACT_APP_BASE_NAME, path, {
    headers: { 'x-api-key': REACT_APP_BASE_KEY },
    response: true
  })

  return {
    data: response.data,
    headers: response.headers,
    request: response.config,
    status: response.status
  }
}
