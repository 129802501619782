import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import LoadingContent from '../../components/Placeholders/LoadingContent'
import DisconnectedData from '../FuelLevel/DisconnectedData.container'
import Typography from '../../components/Basics/Typography'
import DriverDataContent from './DiningTable.container'
import { onFetchLatestDataThunk } from './DiningDriver.actions'

/**
 * The Dining's container.
 */
const DiningContent = () => {
  const dispatch = useDispatch()

  const { loading, error, latestDrivers } = useSelector(state => state.dining)

  const fetchData = () => {
    console.log('Fetching latest data...') // Debugging: Print when fetching data
    dispatch(onFetchLatestDataThunk())
  }

  // Handle mounting and polling separately from other effects
  useEffect(() => {
    console.log('Component mounted') // Debugging: Check if the component is mounting multiple times

    // Fetch data immediately when the component mounts
    fetchData()

    // Set up polling to fetch data every 6 minutes (360,000 milliseconds)
    const intervalId = setInterval(() => {
      console.log('Polling: Fetching latest data at', new Date().toLocaleString()) // Debugging: Print when polling
      fetchData()
    }, 360000) // 6 minutes in milliseconds

    // Clean up the interval on component unmount
    return () => {
      console.log('Component unmounted, clearing interval') // Debugging: Confirm cleanup
      clearInterval(intervalId)
    }
  }, []) // Empty dependency array ensures this effect only runs once on mount

  console.log(error)

  return (
    <>
      {loading && <LoadingContent />}
      {!loading && error && (
        <>
          {error.code === 404 ? (
            <Typography align="center" color="primary">
              No hay data de conductores en colación, intente más tarde.
            </Typography>
          ) : (
            <DisconnectedData />
          )}
        </>
      )}
      {!loading && !error && (
        <>
          {latestDrivers.length === 0 ? (
            <Typography align="center" color="primary">
              Por favor seleccione fecha
            </Typography>
          ) : (
            <DriverDataContent title="Conductores" data={latestDrivers} />
          )}
        </>
      )}
    </>
  )
}

export default DiningContent
