import {
  GET_TIRE_ANOMALY_DATA,
  GET_TIRE_ANOMALY_DATA_ERROR,
  GET_TIRE_ANOMALY_DATA_SUCCESS,
  TOGGLE_TIRE_ANOMALY_MODAL_SUCCESS,
  ADD_TIRE_ANOMALY_MODAL,
  GET_TIRE_MAP,
  GET_TIRE_MAP_ERROR,
  GET_TIRE_MAP_SUCCESS,
  OPERATOR_ROW_MODAL_SUCCESS,
  ADD_OPERATOR_ROW_MODAL
} from './Tires.actions'

const anomalyTiresState = {
  loading: false,
  error: false,
  mapLoading: false,
  mapError: false,
  showTireAnomalyModal: false,
  anomalyselected: [],
  anomalyData: [],
  groupedCaex: [],
  groupedOperator: [],
  imagesData: '',
  showOperatorRowModal: false,
  rowOperatorSelected: {},
  rowOperator: ''
}

const anomalyTiresReducer = (state = anomalyTiresState, { payload, type }) => {
  switch (type) {
    case GET_TIRE_ANOMALY_DATA: {
      return { ...state, loading: true, error: false }
    }
    case GET_TIRE_ANOMALY_DATA_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        anomalyData: payload.anomalyData,
        groupedCaex: payload.groupedCaex,
        groupedOperator: payload.groupedOperator
      }
    }
    case GET_TIRE_ANOMALY_DATA_ERROR: {
      return { ...state, loading: false, error: true }
    }
    case ADD_TIRE_ANOMALY_MODAL: {
      return { ...state, anomalyselected: payload.anomalyselected }
    }
    case TOGGLE_TIRE_ANOMALY_MODAL_SUCCESS: {
      return { ...state, showTireAnomalyModal: !state.showTireAnomalyModal }
    }
    case GET_TIRE_MAP: {
      return { ...state, mapLoading: true, mapError: false }
    }
    case GET_TIRE_MAP_SUCCESS: {
      return {
        ...state,
        mapLoading: false,
        mapError: false,
        imagesData: payload.imagesData
      }
    }
    case GET_TIRE_MAP_ERROR: {
      return { ...state, mapLoading: false, mapError: true }
    }
    case OPERATOR_ROW_MODAL_SUCCESS: {
      return { ...state, showOperatorRowModal: !state.showOperatorRowModal }
    }
    case ADD_OPERATOR_ROW_MODAL: {
      return { ...state, rowOperatorSelected: payload.rowOperatorSelected, rowOperator: payload.rowOperator }
    }
    default: {
      return state
    }
  }
}

export default anomalyTiresReducer
