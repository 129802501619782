export const CONSUMPTION_OPERATOR_TYPE = {
  RALENTI: 'IDLE_RPM',
  RPM: 'RPM_DOWNLOAD',
  FRENADOS: 'HARD_BRAKING'
}

export const VISUALIZADOR_TYPE = {
  DIA: 'DIA',
  HISTORICO: 'HISTORICO',
  FRENADOS: 'FRENADOS',
  GPSRALENTI: 'GPSRALENTI'
}

export const TIRE_TYPE = {
  TABLA: 'TABLA',
  VELOCIDAD: 'VELOCIDAD'
}

export const CONSUMPTION_OPERATOR_MODAL_TITLE = {
  RALENTI: 'Ralentí',
  RPM: 'RPM Descarga',
  FRENADOS: 'Frenados Bruscos'
}

export const ANOMALIE_DEFINITIONS = {
  dtdt_up: 'Aumento brusco temperatura',
  dtdt_down: 'Disminución brusca temperatura',
  dpdt_up: 'Aumento brusco presión',
  dpdt_down: 'Disminución brusca presión',
  dkdt_up: 'Disminución brusca volumen',
  dkdt_down: 'Aumento brusco volumen',
  diff_T_up: 'Aumento sostenido temperatura',
  diff_T_down: 'Disminución sostenida temperatura',
  diff_P_up: 'Aumento sostenido presión',
  diff_P_down: 'Disminución sostenida presión',
  diff_K_up: 'Disminución sostenida volumen',
  diff_K_down: 'Aumento sostenido volumen',
  sensor_downtime: 'Sensor fuera de servicio',
  sensor_recovery: 'Sensor volviendo de servicio'
}
