import styled from 'styled-components'
import { Tabs } from '@material-ui/core'

export const Root = styled(Tabs)`
  .MuiTab-root {
    min-height: initial;
    max-width: initial;
  }

  .MuiTabs-indicator {
    background: none;
  }

  .MuiTabScrollButton-root {
    color: ${props => props.theme.palette.brand.gray[30]};

    .MuiSvgIcon-root {
      font-size: 2.2rem;
    }
  }
`
