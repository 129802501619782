import React from 'react'
import PropTypes from 'prop-types'
import { addMonths } from 'date-fns'
import { batch, useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { usePagination } from '../../components/Tables/Pagination.hooks'
import { useViewportSize } from '../../modules/hooks/viewport'
import { capitalize } from '../../modules/utils/helpers'
import { formatDate } from '../../modules/utils/formatters'
import { onChangeRpmDownloadGroupPage } from './RpmDownload.actions'
import {
  onGetReinforcementThunk,
  onLoadOperatorsThunk,
  onSetInitialState
} from '../Reinforcement/Reinforcement.actions'
import Alert from '../../components/Basics/Alert'
import Pagination from '../../components/Tables/Pagination'
import Skeleton from '../../components/Placeholders/Skeleton'
import Table from '../../components/Tables/Table'
import TableCell from '../../components/Tables/TableCell'
import TableRow from '../../components/Tables/TableRow'
import TablePlaceholder from '../../components/Placeholders/TablePlaceholder'
import Tag from '../../components/Basics/Tag'
import Typography from '../../components/Basics/Typography'
import { FooterWrapper, IconButton, Strong, SummaryWrapper, TableContainer, WarningIcon } from './RpmDownload.styles'

/**
 * The Rpm Download Table's container.
 */
const RpmDownloadTable = props => {
  const { group, groupId, onToggleModal } = props
  const dispatch = useDispatch()
  const history = useHistory()
  const { isExtraTiny, isTiny } = useViewportSize()
  const {
    consultationDate,
    [groupId]: { data, error, loading, page }
  } = useSelector(state => state.rpmDownload)
  const currentDate = addMonths(new Date(), -1)

  const { page: currentPage, totalPages, onChangePage, onGetCurrentPage } = usePagination({
    changeCallback: page => dispatch(onChangeRpmDownloadGroupPage({ groupId, page })),
    data,
    initialPage: page,
    rowsPerPage: 5
  })

  const handleShowReinforcement = (operatorId, operatorName) => () => {
    if (isTiny) {
      batch(() => {
        dispatch(onSetInitialState({ groupId, reportPeriod: formatDate(consultationDate, 'yyyy-MM'), type: 'RPM' }))
        dispatch(onLoadOperatorsThunk({ currentOperator: { operatorId, operatorName }, groupId, type: 'RPM' }))
        history.push('/consumo-operador/rpm/reforzamiento')
      })
    } else {
      batch(() => {
        dispatch(onSetInitialState({ groupId, reportPeriod: formatDate(consultationDate, 'yyyy-MM'), type: 'RPM' }))
        dispatch(onLoadOperatorsThunk({ currentOperator: { operatorId, operatorName }, groupId, type: 'RPM' }))
        onToggleModal()
      })
    }
  }

  const handleGetReinforcement = reinforcementId => () => {
    if (isTiny) {
      history.push(`/consumo-operador/rpm/reforzamiento/${reinforcementId}`)
    } else {
      batch(() => {
        dispatch(onGetReinforcementThunk({ reinforcementId, type: 'RPM' }))
        onToggleModal()
      })
    }
  }

  return (
    <>
      {isTiny && (
        <SummaryWrapper margin="-10px 0 10px">
          <SummaryWrapper margin="2px 24px 2px 0">
            <Tag color="primary" margin="0 10px 0 0" text={`${group.rpmRecommendedDownload} RPM`} />
            <Typography variant="body2">Descarga recomendada</Typography>
          </SummaryWrapper>

          <SummaryWrapper margin="2px 24px 2px 0">
            <Tag color="primary" margin="0 10px 0 0" text={group.p8QuantityHighDownload} />
            <Typography variant="body2">P80 cantidad descargas elevadas</Typography>
          </SummaryWrapper>

          <SummaryWrapper margin="2px 0">
            <Tag color="primary" margin="0 10px 0 0" text={group.sumLitersOverConsumtion} />
            <Typography variant="body2">Litros sobreconsumo</Typography>
          </SummaryWrapper>
        </SummaryWrapper>
      )}

      {loading && !error && <TablePlaceholder />}

      {!loading && !error && (
        <TableContainer>
          <Table accessibility={{ label: 'Grupo 1' }}>
            <TableRow head>
              <TableCell minWidth="150px">Operador</TableCell>
              <TableCell minWidth="150px">Sum descargas</TableCell>
              <TableCell minWidth="155px">Sum descargas RPM elevada</TableCell>
              <TableCell minWidth="155px">Impacto Litros fuera de media</TableCell>
              <TableCell minWidth="150px">Registrar reforzamiento</TableCell>
            </TableRow>

            {onGetCurrentPage().map((row, index) => {
              return (
                <TableRow key={`operador-${index}`}>
                  <TableCell align="center" minWidth="150px">
                    {row.operatorName}
                  </TableCell>
                  <TableCell align="center" minWidth="150px">
                    {`${row.sumDownload} ${row.sumDownload === '1' ? 'descarga' : 'descargas'}`}
                  </TableCell>
                  <TableCell align="center" minWidth="155px">
                    {`${row.sumDownloadRpmElevate} ${row.sumDownloadRpmElevate === '1' ? 'descarga' : 'descargas'}`}
                  </TableCell>
                  <TableCell align="center" minWidth="155px">
                    {`${row.impactLitersConsumed} ${row.impactLitersConsumed === '1' ? 'litro' : 'litros'}`}
                  </TableCell>
                  <TableCell align="center" minWidth="150px">
                    {row.hasReinforcement && (
                      <IconButton onClick={handleGetReinforcement(row.reinforcementId)}>
                        <img src="/assets/icons/vectorReportDone.svg" alt="Reportar Recomendación" />
                      </IconButton>
                    )}
                    {!row.hasReinforcement && (
                      <IconButton onClick={handleShowReinforcement(row.operatorId, row.operatorName)}>
                        <img src="/assets/icons/vectorMustReport.svg" alt="Reportar Recomendación" />
                      </IconButton>
                    )}
                  </TableCell>
                </TableRow>
              )
            })}
          </Table>
        </TableContainer>
      )}

      {!error && (
        <FooterWrapper margin={data.length > 5 ? '4px 0 0' : '9px 0 0'}>
          {loading && <Skeleton width={isExtraTiny ? '100%' : '300px'} />}
          {!loading && (
            <Typography color="disabled-gray" margin="0 12px" variant="subtitle">
              {consultationDate && formatDate(consultationDate, 'yyyy-MM') !== formatDate(currentDate, 'yyyy-MM') && (
                <WarningIcon />
              )}
              {capitalize(formatDate(consultationDate, 'MMMM'))} - Operadores que más sobrepasan las RPM óptimas durante
              la descarga.
            </Typography>
          )}

          {!loading && data.length > 5 && (
            <Pagination alignment="right" count={totalPages} page={currentPage} onChange={onChangePage} />
          )}
        </FooterWrapper>
      )}

      {!loading && error && (
        <Alert severity="default">
          <Strong>Lo sentimos, estamos presentando problemas con nuestro servidor</Strong>
          <br />
          En estos momentos tenemos problemas para disponibilizar la información. Por favor, consulta en unos minutos
          más.
        </Alert>
      )}
    </>
  )
}

RpmDownloadTable.defaultProps = {
  onToggleModal: () => undefined
}
RpmDownloadTable.propTypes = {
  group: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    name: PropTypes.string,
    rpmRecommendedDownload: PropTypes.string.isRequired,
    p8QuantityHighDownload: PropTypes.string.isRequired,
    sumLitersOverConsumtion: PropTypes.string.isRequired
  }).isRequired,
  groupId: PropTypes.string.isRequired,
  onToggleModal: PropTypes.func
}

export default RpmDownloadTable
