// FIXME: Need to be refactored
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import useStyles from '../../styles/modalsStyle'
import ReactEcharts from 'echarts-for-react'
// import { parameters } from './KpiDesktop'
import CloseIcon from '@material-ui/icons/Close'
import { Modal, Grid, Paper, FormControl, Select, MenuItem, Typography, Button } from '@material-ui/core'

/**
 * The KpiHistoryModal' container.
 */
const KpiHistoryModal = ({ metric, values, setOpen, open }) => {
  const classes = useStyles()
  const { data } = values
  const info = data.find(arg => arg.metric === metric)
  const periods = [
    { text: '12 últimos meses', value: -12 },
    { text: '6 últimos meses', value: -6 },
    { text: '3 últimos meses', value: -3 }
    // { text: 'último mes', value: -1 }
  ]

  const [periodSelected, setPeriodSelected] = useState(periods[0])
  const [dataGraph, setDataGraph] = useState(info)
  const [averages, setAverages] = useState([])
  const [months, setMonths] = useState(values.months)

  useEffect(() => {
    setOpen(open)
    init()
    return () => {
      setOpen(false)
    }
  }, [open])

  useEffect(() => {
    init()
  }, [periodSelected])

  const init = () => {
    const newValues = []
    for (const value of info.values) {
      const newCount = value.count.slice(periodSelected.value)
      newValues.push({
        ...value,
        count: newCount
      })
    }

    setDataGraph({
      ...info,
      values: newValues
    })

    const newAverage = []
    for (const value of newValues) {
      const newValue = getAverage(value.count)
      newAverage.push({ group: value.group, average: newValue })
    }

    setMonths(values.months.slice(periodSelected.value))
    setAverages(newAverage)
  }

  const getAverage = array => {
    let sum = 0
    for (const item of array) {
      sum += item
    }
    return sum / array.length
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleChangePeriod = e => {
    const periodSelected = periods.find(period => period.value === e.target.value)
    setPeriodSelected(periodSelected)
  }

  const header = (
    <Grid container direction="row" justify="space-around" alignItems="center">
      <Grid item xs={12} md={3}>
        <p className={classes.headerText}>{metric}</p>
      </Grid>
      <Grid item xs={12} md={5}>
        <Grid container direction="row" justify="flex-end" alignItems="center">
          <Grid item xs={3}>
            <p className={classes.headerTextSelect}>Tiempo resumen:</p>
          </Grid>
          <Grid item xs={7}>
            <FormControl className={classes.formControl} style={{ marginLeft: '5px' }}>
              {/* <InputLabel id="demo-simple-select-outlined-label">{periodSelected.text}</InputLabel> */}
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={periodSelected.value}
                onChange={handleChangePeriod}
              >
                {periods.map(period => {
                  return (
                    <MenuItem key={`item-${period.value}`} value={period.value}>
                      {period.text}
                    </MenuItem>
                  )
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={1}>
            <CloseIcon onClick={handleClose} style={{ cursor: 'pointer' }} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )

  const body = (
    <>
      <Grid container direction="row" justify="center" alignItems="center" style={{ marginBottom: '20px' }}>
        <Grid item xs={12} md={8} style={{ marginTop: '50px' }}>
          <ReactEcharts
            option={{
              color: ['#7f3503', '#BE4F04', '#FA6A09', '#FDB584'],
              tooltip: {
                show: true,
                trigger: 'item',
                padding: 10,
                backgroundColor: '#000000',
                formatter: function (params) {
                  return `<div style="text-align:center;">
                            <strong>${Intl.NumberFormat('de-DE').format(params.data)}</strong><br />
                            ${params.seriesName}, ${params.name}
                            </div>`
                },
                textStyle: {
                  color: '#ffffff'
                }
              },
              xAxis: {
                type: 'category',
                data: months
              },
              yAxis: {
                type: 'value'
              },
              legend: {
                data: [
                  dataGraph.values[0].group,
                  dataGraph.values[1].group,
                  dataGraph.values[2].group,
                  dataGraph.values[3].group
                ]
              },
              series: [
                {
                  data: dataGraph.values[0].count,
                  name: dataGraph.values[0].group,
                  type: 'line',
                  smooth: true
                },
                {
                  data: dataGraph.values[1].count,
                  name: dataGraph.values[1].group,
                  type: 'line',
                  smooth: true
                },
                {
                  data: dataGraph.values[2].count,
                  name: dataGraph.values[2].group,
                  type: 'line',
                  smooth: true
                },
                {
                  data: dataGraph.values[3].count,
                  name: dataGraph.values[3].group,
                  type: 'line',
                  smooth: true
                }
              ]
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography style={{ textAlign: 'center', margin: '40px', fontSize: '20px', fontWeight: '400' }}>
            {`Promedio ${periodSelected.text}`}
          </Typography>
        </Grid>
        <Grid item xs={12} md={8}>
          <Grid container direction="row" justify="center" alignItems="center">
            {averages.map(group => {
              return (
                <Grid item key="" xs={10} md={2}>
                  <div
                    style={{
                      background: '#f2f2f2',
                      borderRadius: '50%',
                      textAlign: 'center',
                      width: '100px',
                      height: '100px',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center'
                    }}
                  >
                    <Typography style={{ fontSize: '16px', color: '#828282', fontWeight: '300' }}>
                      {Intl.NumberFormat('de-DE').format(parseInt(group.average))}
                    </Typography>
                    <Typography style={{ fontSize: '14px', color: '#828282', fontWeight: '600' }}>
                      {group.group}
                    </Typography>
                  </div>
                </Grid>
              )
            })}
          </Grid>
        </Grid>
      </Grid>
    </>
  )

  const footer = (
    <>
      <Grid
        container
        direction="row"
        justify="flex-end"
        alignItems="center"
        style={{ marginTop: '20px', marginBottom: '20px' }}
      >
        <Grid item xs={2}>
          <Button onClick={handleClose} variant="contained">
            Cerrar
          </Button>
        </Grid>
      </Grid>
    </>
  )

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          overflowY: 'scroll',
          paddingTop: '5%',
          paddingBottom: '5%'
        }}
      >
        <Paper style={{ width: '70%' }}>
          {header}
          <hr />
          {body}
          <hr />
          {footer}
        </Paper>
      </Modal>
    </>
  )
}

KpiHistoryModal.propTypes = {
  metric: PropTypes.any,
  values: PropTypes.any,
  open: PropTypes.bool,
  setOpen: PropTypes.func
}

export default KpiHistoryModal
