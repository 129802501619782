import React, { useCallback } from 'react'
import { batch, useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import routes from '../../config/settings/routes'
import { useViewportSize } from '../../modules/hooks/viewport'
import {
  REACT_APP_FEATURE_FLAG_HARD_BRAKING,
  REACT_APP_FEATURE_FLAG_IDLE_RPM,
  REACT_APP_FEATURE_FLAG_RPM_DOWNLOAD,
  REACT_APP_FEATURE_FLAG_VISUALIZADOR_DIA,
  REACT_APP_FEATURE_FLAG_VISUALIZADOR_HISTORICO,
  REACT_APP_FEATURE_FLAG_VISUALIZADOR_HARD_BRAKING,
  REACT_APP_FEATURE_FLAG_TIRES_ANOMALY_TABLE,
  REACT_APP_FEATURE_FLAG_TIRES_VELOCITY,
  REACT_APP_FEATURE_FLAG_VISUALIZADOR_RALENTI
} from '../../config/settings/environment'
import { onHideManagementPanelSuccess } from './ManagementMenu.actions'
import { onToggleLogoutModalSuccess } from '../Authentication/Authentication.actions'
import ProtectByFeatures from '../../components/Security/ProtectByFeatures'
import ViewportLayout from '../../components/Layouts/ViewportLayout'
import {
  CloseIcon,
  HeaderTitleWrapper,
  IconButton,
  IconWrapper,
  Item,
  Link,
  LogoIcon,
  LogoutButton,
  MenuText,
  Panel,
  Separator,
  Title
} from './ManagementMenu.styles'

/**
 * The ManagementMenu's container.
 */
const ManagementMenu = props => {
  const { current } = props
  const dispatch = useDispatch()
  const { isTiny } = useViewportSize()
  const { collapsedManagmentPanel, showManagementPanel } = useSelector(state => state.managementMenu)

  const getWrapper = useCallback(
    children => {
      return (
        <ViewportLayout
          tinyMatched={() => (
            <Panel
              anchor="left"
              isTiny={isTiny}
              ModalProps={{
                keepMounted: true // Better open performance on mobile.
              }}
              open={showManagementPanel}
              variant="temporary"
              onClose={handleCloseSmallScreenPanel}
            >
              {children}
            </Panel>
          )}
        >
          <Panel sidebarMini={collapsedManagmentPanel} variant="permanent">
            {children}
          </Panel>
        </ViewportLayout>
      )
    },
    [collapsedManagmentPanel, showManagementPanel]
  )

  const handleCloseSmallScreenPanel = () => dispatch(onHideManagementPanelSuccess())
  const handleLogout = () =>
    batch(() => {
      dispatch(onHideManagementPanelSuccess())
      dispatch(onToggleLogoutModalSuccess())
    })

  const consumptionPerOperatorOption = features =>
    features['com.cosmos/hard.braking'] || features['com.cosmos/idle.rpm'] || features['com.cosmos/rpm.download']

  const handleClickConsumptionPerOperator = () => {
    if (REACT_APP_FEATURE_FLAG_IDLE_RPM === true || REACT_APP_FEATURE_FLAG_IDLE_RPM === 'true') {
      return routes.idleRpm
    } else if (REACT_APP_FEATURE_FLAG_RPM_DOWNLOAD === true || REACT_APP_FEATURE_FLAG_RPM_DOWNLOAD === 'true') {
      return routes.rpmDownload
    } else if (REACT_APP_FEATURE_FLAG_HARD_BRAKING === true || REACT_APP_FEATURE_FLAG_HARD_BRAKING === 'true') {
      return routes.hardBraking
    }
  }

  const visualizadorPerOption = features =>
    features['com.cosmos/visualizador.espacial.dia'] ||
    features['com.cosmos/visualizador.espacial.historico'] ||
    features['com.cosmos/visualizador.espacial.frenados'] ||
    features['com.cosmos/visualizador.espacial.ralenti']

  const handleClickVisualizador = () => {
    if (REACT_APP_FEATURE_FLAG_VISUALIZADOR_DIA === true || REACT_APP_FEATURE_FLAG_VISUALIZADOR_DIA === 'true') {
      return routes.visualizador
    } else if (
      REACT_APP_FEATURE_FLAG_VISUALIZADOR_HISTORICO === true ||
      REACT_APP_FEATURE_FLAG_VISUALIZADOR_HISTORICO === 'true'
    ) {
      return routes.visualizadorHistorico
    } else if (
      REACT_APP_FEATURE_FLAG_VISUALIZADOR_HARD_BRAKING === true ||
      REACT_APP_FEATURE_FLAG_VISUALIZADOR_HARD_BRAKING === 'true'
    ) {
      return routes.visualizadorHardBraking
    } else if (
      REACT_APP_FEATURE_FLAG_VISUALIZADOR_RALENTI === true ||
      REACT_APP_FEATURE_FLAG_VISUALIZADOR_RALENTI === 'true'
    ) {
      return routes.visualizadorRalenti
    }
  }

  const tiresPerOption = features => features['com.cosmos/tires_table'] || features['com.cosmos/tires_velocity']

  const handleClickTires = () => {
    if (REACT_APP_FEATURE_FLAG_TIRES_ANOMALY_TABLE === true || REACT_APP_FEATURE_FLAG_TIRES_ANOMALY_TABLE === 'true') {
      return routes.tireTabla
    } else if (REACT_APP_FEATURE_FLAG_TIRES_VELOCITY === true || REACT_APP_FEATURE_FLAG_TIRES_VELOCITY === 'true') {
      return routes.tireVelocity
    }
  }

  return getWrapper(
    <>
      {isTiny && (
        <IconButton onClick={handleCloseSmallScreenPanel}>
          <CloseIcon />
        </IconButton>
      )}
      <Item>
        <HeaderTitleWrapper isTiny={isTiny} sidebarMini={collapsedManagmentPanel}>
          {!isTiny && (
            <LogoIcon
              sidebarMini={collapsedManagmentPanel}
              src="/assets/images/cosmos.svg"
              alt="Universal Intelligence"
            />
          )}
          {(!collapsedManagmentPanel || isTiny) && <Title>Sistema Cosmos</Title>}
        </HeaderTitleWrapper>
      </Item>

      <ProtectByFeatures rule={features => features['com.cosmos/fuel.level']}>
        <Item>
          <Link
            active={current === routes.fuelLevel ? 1 : 0}
            title="Nivel de combustible"
            to={routes.fuelLevel}
            onClick={handleCloseSmallScreenPanel}
          >
            <IconWrapper>
              <img src="/assets/icons/menu-levels.svg" alt="Nivel de combustible" />
            </IconWrapper>
            <MenuText>Nivel de combustible</MenuText>
          </Link>
        </Item>
      </ProtectByFeatures>

      <ProtectByFeatures rule={consumptionPerOperatorOption}>
        <Item>
          <Link
            active={current === routes.consumptionPerOperator ? 1 : 0}
            title="Consumo por operador"
            to={handleClickConsumptionPerOperator}
            onClick={handleCloseSmallScreenPanel}
          >
            <IconWrapper>
              <img src="/assets/icons/consumo-operadores.svg" alt="Consumo por operador" />
            </IconWrapper>
            <MenuText>Consumo por operador</MenuText>
          </Link>
        </Item>
      </ProtectByFeatures>

      <ProtectByFeatures rule={features => features['com.cosmos/operators.report']}>
        <Item>
          <Link
            active={current === routes.operatorsReport ? 1 : 0}
            title="Consumo por operador"
            to={routes.operatorsReport}
            onClick={handleCloseSmallScreenPanel}
          >
            <IconWrapper>
              <img src="/assets/icons/menu-operatorReport.svg" alt="Consumo por operador" />
            </IconWrapper>
            <MenuText>Consumo por operador</MenuText>
          </Link>
        </Item>
      </ProtectByFeatures>

      <ProtectByFeatures rule={features => features['com.cosmos/station.managment']}>
        <Item>
          <Link
            active={current === routes.stationManagement ? 1 : 0}
            title="Gestión de estaciones"
            to={routes.stationManagement}
            onClick={handleCloseSmallScreenPanel}
          >
            <IconWrapper>
              <img src="/assets/icons/menu-stationManagement.svg" alt="Gestión de estaciones" />
            </IconWrapper>
            <MenuText>Gestión de estaciones</MenuText>
          </Link>
        </Item>
      </ProtectByFeatures>

      <ProtectByFeatures rule={features => features['com.cosmos/new.anomaly']}>
        <Item>
          <Link
            active={current === routes.newAnomaly ? 1 : 0}
            title="Detección de anomalías"
            to={routes.newAnomaly}
            onClick={handleCloseSmallScreenPanel}
          >
            <IconWrapper>
              <img src="/assets/icons/menu-anomalie.svg" alt="New Anomaly" />
            </IconWrapper>
            <MenuText>Detección de anomalías</MenuText>
          </Link>
        </Item>
      </ProtectByFeatures>

      <ProtectByFeatures rule={features => features['com.cosmos/quicksight']}>
        <Item>
          <Link
            active={current === routes.quicksight ? 1 : 0}
            title="Dashboard"
            to={routes.quicksight}
            onClick={handleCloseSmallScreenPanel}
          >
            <IconWrapper>
              <img src="/assets/icons/menu-anomalie.svg" alt="New Anomaly" />
            </IconWrapper>
            <MenuText>Quicksight Dashboard</MenuText>
          </Link>
        </Item>
      </ProtectByFeatures>

      <ProtectByFeatures rule={features => features['com.cosmos/predictions']}>
        <Item>
          <Link
            active={current === routes.predictionsGraphs ? 1 : 0}
            title="Predicciones"
            to={routes.predictionsGraphs}
            onClick={handleCloseSmallScreenPanel}
          >
            <IconWrapper>
              <img src="/assets/icons/seguimiento-predicciones.svg" alt="Predictions" />
            </IconWrapper>
            <MenuText>Gráfico Predicciones</MenuText>
          </Link>
        </Item>
      </ProtectByFeatures>

      <ProtectByFeatures rule={features => features['com.cosmos/dining']}>
        <Item>
          <Link
            active={current === routes.dining ? 1 : 0}
            title="Casino"
            to={routes.dining}
            onClick={handleCloseSmallScreenPanel}
          >
            <IconWrapper>
              <img src="/assets/icons/casino.svg" alt="Casino" />
            </IconWrapper>
            <MenuText>Casino</MenuText>
          </Link>
        </Item>
      </ProtectByFeatures>

      <ProtectByFeatures rule={features => features['com.cosmos/fuel_management']}>
        <Item>
          <Link
            active={current === routes.fuelManagement ? 1 : 0}
            title="Combustible"
            to={routes.fuelManagement}
            onClick={handleCloseSmallScreenPanel}
          >
            <IconWrapper>
              <img src="/assets/icons/menu-stationManagement.svg" alt="Combustible" />
            </IconWrapper>
            <MenuText>Combustible</MenuText>
          </Link>
        </Item>
      </ProtectByFeatures>

      <ProtectByFeatures rule={tiresPerOption}>
        <Item>
          <Link
            active={current === routes.tireOperaciones ? 1 : 0}
            title="Neumáticos"
            to={handleClickTires}
            onClick={handleCloseSmallScreenPanel}
          >
            <IconWrapper>
              <img src="/assets/icons/neumaticos.svg" alt="Neumáticos" />
            </IconWrapper>
            <MenuText>Neumáticos</MenuText>
          </Link>
        </Item>
      </ProtectByFeatures>

      <ProtectByFeatures rule={visualizadorPerOption}>
        <Item>
          <Link
            active={current === routes.visualizadorOperaciones ? 1 : 0}
            title="Mapa"
            to={handleClickVisualizador}
            onClick={handleCloseSmallScreenPanel}
          >
            <IconWrapper>
              <img src="/assets/icons/visualizador-mapa.svg" alt="mapa" />
            </IconWrapper>
            <MenuText>Mapa</MenuText>
          </Link>
        </Item>
      </ProtectByFeatures>

      <ProtectByFeatures rule={features => features['com.cosmos/global.comparative']}>
        <Item>
          <Link
            active={current === routes.globalComparative ? 1 : 0}
            title="Comparativo global"
            to={routes.globalComparative}
            onClick={handleCloseSmallScreenPanel}
          >
            <IconWrapper>
              <img src="/assets/icons/menu-compareGlobal.svg" alt="Comparativo global" />
            </IconWrapper>
            <MenuText>Comparativo global</MenuText>
          </Link>
        </Item>
      </ProtectByFeatures>

      <ProtectByFeatures rule={features => features['com.cosmos/help']}>
        <Item>
          <Link
            active={current === routes.help ? 1 : 0}
            title="Ayuda"
            to={routes.help}
            onClick={handleCloseSmallScreenPanel}
          >
            <IconWrapper>
              <img src="/assets/icons/menu-userGuide.svg" alt="Ayuda" />
            </IconWrapper>
            <MenuText>Ayuda</MenuText>
          </Link>
        </Item>
      </ProtectByFeatures>

      <ProtectByFeatures rule={features => features['com.cosmos/examples']}>
        <Item>
          <Link
            active={current === routes.example ? 1 : 0}
            title="Ejemplos"
            to={routes.example}
            onClick={handleCloseSmallScreenPanel}
          >
            <IconWrapper>
              <img src="/assets/icons/menu-userGuide.svg" alt="Ejemplos" />
            </IconWrapper>
            <MenuText>Ejemplos</MenuText>
          </Link>
        </Item>
      </ProtectByFeatures>

      {isTiny && <Separator />}

      {isTiny && (
        <Item>
          <LogoutButton onClick={handleLogout}>
            <IconWrapper>
              <img src="/assets/icons/logoff.svg" alt="Cerrar sesión" />
            </IconWrapper>
            <MenuText>Cerrar sesión</MenuText>
          </LogoutButton>
        </Item>
      )}
    </>
  )
}

ManagementMenu.propTypes = {
  current: PropTypes.string.isRequired
}

export default ManagementMenu
