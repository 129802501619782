import { makeActionCreator } from '../../config/store/utils'
import { getGerenciaGraphs } from '../../services/graphsServices'
import { batch } from 'react-redux'
import { onGetGraphError } from '../FuelLevelBoxplots/BoxPlotGraph.actions'

export const GET_GERENCIA_DATA = 'GET_GERENCIA_DATA'
export const GET_GERENCIA_DATA_ERROR = 'GET_GERENCIA_DATA_ERROR'
export const GET_GERENCIA_DATA_SUCCESS = 'GET_GERENCIA_DATA_SUCCESS'
export const onGetGerencia = makeActionCreator(GET_GERENCIA_DATA)
export const onGetGerenciaError = makeActionCreator(GET_GERENCIA_DATA_ERROR, 'payload')
export const onGetGerenciaSuccess = makeActionCreator(GET_GERENCIA_DATA_SUCCESS, 'payload')
export const onGetGerenciaThunk = start => async (dispatch, getState) => {
  dispatch(onGetGerencia())
  try {
    const {
      apis: {
        base: { apiKey, apiName }
      }
    } = getState().auth

    const { data } = await getGerenciaGraphs({
      apiKey: apiKey,
      apiName: apiName,
      date: start
    })

    return batch(() => {
      dispatch(
        onGetGerenciaSuccess({
          data: data
        })
      )
    })
  } catch (error) {
    console.log(error)
    return dispatch(onGetGraphError({ error }))
  }
}
