import React from 'react'
import PropTypes from 'prop-types'
import Skeleton from './../Placeholders/Skeleton'
import {
  BaseInput,
  BottomMessage,
  Counter,
  Details,
  Error,
  Info,
  Label,
  Required,
  Root,
  TooltipIcon
} from './Input.styles'
import Tooltip from '../Basics/Tooltip'
export { Prefix as InputPrefix, Suffix as InputSuffix } from './Input.styles'

/**
 * The Input's component.
 */
const Input = props => {
  const {
    autoFocus,
    showCounter,
    disabled,
    error,
    info,
    label,
    loading,
    margin,
    maxLength,
    maxCharactersForCounter,
    padding,
    placeholder,
    prefix,
    required,
    reverse,
    suffix,
    textAlign,
    tooltip,
    type,
    value,
    onChange,
    ...restProps
  } = props

  const handleBlur = () => {
    if (value) {
      return
    }

    onChange({ type: 'blur' })
  }

  return (
    <Root margin={margin} padding={padding}>
      {label && (
        <Label>
          {label}
          {required && <Required />}
          {tooltip && (
            <Tooltip content={tooltip} maxWidth="260px" placement="right">
              <TooltipIcon />
            </Tooltip>
          )}
        </Label>
      )}
      {loading && <Skeleton variant="rect" height={38} />}
      {!loading && (
        <BaseInput
          {...restProps}
          autoFocus={autoFocus}
          disabled={disabled}
          endAdornment={suffix}
          error={Boolean(error)}
          placeholder={placeholder}
          reverse={reverse ? 1 : 0}
          startAdornment={prefix}
          inputProps={{ maxLength }}
          textAlign={textAlign}
          type={type}
          value={value}
          onBlur={handleBlur}
          onChange={onChange}
        />
      )}
      <Details>
        <BottomMessage>
          {!error && <Info>{info}</Info>}
          {error && <Error reverse={reverse ? 1 : 0}>{error}</Error>}
        </BottomMessage>
        {showCounter && (
          <Counter>
            {value.length}/{maxCharactersForCounter}
          </Counter>
        )}
      </Details>
    </Root>
  )
}

Input.defaultProps = {
  autoFocus: false,
  showCounter: false,
  disabled: false,
  error: '',
  info: '',
  label: '',
  loading: false,
  margin: 0,
  maxCharactersForCounter: 30,
  padding: 0,
  placeholder: '',
  prefix: null,
  required: false,
  reverse: false,
  suffix: null,
  textAlign: 'initial',
  tooltip: '',
  type: 'text',
  onChange: () => undefined
}

Input.propTypes = {
  autoFocus: PropTypes.bool,
  showCounter: PropTypes.bool,
  disabled: PropTypes.bool,
  error: PropTypes.string,
  info: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  label: PropTypes.string,
  loading: PropTypes.bool,
  margin: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  maxCharactersForCounter: PropTypes.number,
  maxLength: PropTypes.number,
  padding: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  placeholder: PropTypes.string,
  prefix: PropTypes.node,
  required: PropTypes.bool,
  reverse: PropTypes.bool,
  suffix: PropTypes.node,
  textAlign: PropTypes.oneOf(['center', 'end', 'initial', 'start']),
  tooltip: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  type: PropTypes.oneOf(['email', 'number', 'password', 'text']),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  onChange: PropTypes.func
}

export default Input
