import { API, Auth } from 'aws-amplify'
import {
  REACT_APP_REINFORCEMENT_KEY,
  REACT_APP_REINFORCEMENT_NAME,
  REACT_APP_CLIENT_NAME,
  REACT_APP_API_BASE_URL
} from '../config/settings/environment'
import axios from 'axios'

/**
 * To get the summary consumption operator information per group.
 */
export const getSummaryConsumptionOperator = async ({ month, type, year }) => {
  let path = `/${type}/summary`
  if (month && year) {
    path = `${path}?year=${year}&month=${month}`
  }

  const response = await API.get(REACT_APP_REINFORCEMENT_NAME, path, {
    headers: { 'x-api-key': REACT_APP_REINFORCEMENT_KEY },
    response: true
  })

  return {
    data: response.data,
    headers: response.headers,
    request: response.config,
    status: response.status
  }
}

/**
 * To get the monthly consumption operator information for a single group.
 */
export const getMonthlyConsumptionOperator = async ({ groupId, month, type, year }) => {
  let path = `/${type}/monthly?group_id=${groupId}`
  if (month && year) {
    path = `${path}&year=${year}&month=${month}`
  }

  const response = await API.get(REACT_APP_REINFORCEMENT_NAME, path, {
    headers: { 'x-api-key': REACT_APP_REINFORCEMENT_KEY },
    response: true
  })

  return {
    data: response.data,
    headers: response.headers,
    request: response.config,
    status: response.status
  }
}

export const getCsvDownloadData = async () => {
  const paths = {
    antucoya: '/idle_rpm/download'
  }

  const api = paths[REACT_APP_CLIENT_NAME] || '/idle_rpm/download'
  const path = `${REACT_APP_API_BASE_URL}/${REACT_APP_REINFORCEMENT_NAME}${api}`

  const config = {
    headers: {
      Authorization: `Bearer ${(await Auth.currentSession())?.getIdToken()?.getJwtToken()}`,
      'Access-Control-Allow-Headers':
        'x-api-key,Content-Type,X-Amz-Date,X-Amz-Security-Token,Authorization,X-Api-Key,X-Requested-With,Accept,Access-Control-Allow-Methods,Access-Control-Allow-Origin,Access-Control-Allow-Headers',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': 'DELETE,GET,HEAD,OPTIONS,PATCH,POST,PUT',
      'X-Requested-With': '*',
      'x-api-key': REACT_APP_REINFORCEMENT_KEY
    }
  }

  const response = await axios
    .get(path, config)
    .then(response => {
      return response
    })
    .catch(e => {
      console.log(e)
    })

  return {
    data: response.data,
    headers: response.headers,
    request: response.config,
    status: response.status
  }
}

export const getCsvDownloadDataHard = async () => {
  const paths = {
    antucoya: '/hard_braking/download'
  }

  const api = paths[REACT_APP_CLIENT_NAME] || '/hard_braking/download'
  const path = `${REACT_APP_API_BASE_URL}/${REACT_APP_REINFORCEMENT_NAME}${api}`

  const config = {
    headers: {
      Authorization: `Bearer ${(await Auth.currentSession())?.getIdToken()?.getJwtToken()}`,
      'Access-Control-Allow-Headers':
        'x-api-key,Content-Type,X-Amz-Date,X-Amz-Security-Token,Authorization,X-Api-Key,X-Requested-With,Accept,Access-Control-Allow-Methods,Access-Control-Allow-Origin,Access-Control-Allow-Headers',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': 'DELETE,GET,HEAD,OPTIONS,PATCH,POST,PUT',
      'X-Requested-With': '*',
      'x-api-key': REACT_APP_REINFORCEMENT_KEY
    }
  }

  const response = await axios
    .get(path, config)
    .then(response => {
      return response
    })
    .catch(e => {
      console.log(e)
    })

  return {
    data: response.data,
    headers: response.headers,
    request: response.config,
    status: response.status
  }
}
