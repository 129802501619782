import React, { useEffect } from 'react'
import { useDispatch, useSelector, batch } from 'react-redux'
import { onGetGerenciaThunk } from './Gerencia.actions'
import LoadingContent from '../../components/Placeholders/LoadingContent'
import DisconnectedData from '../FuelLevel/DisconnectedData.container'
import { Wrapper, Graphdiv, Highlight, IconButton } from './Gerencia.styles'
import Chart from 'react-apexcharts'
import MyGroupedColumnChart from './Gerencia.chart'
import DatePicker from '../../components/Inputs/DatePicker'
import { useDatePicker } from '../../components/Inputs/Inputs.hooks'
import { startOfWeek, endOfWeek, isSameDay, isWithinInterval, format } from 'date-fns'

/**
 * The Gerencia's container.
 */
const GerenciaContainer = () => {
  const dispatch = useDispatch()
  const { loading, error, data } = useSelector(state => state.gerencia)

  const getData = event => {
    const start = startOfWeek(event, { weekStartsOn: 1 })
    batch(() => {
      dispatch(onGetGerenciaThunk(start))
    })
  }

  const { error: pastDateError, value: pastDateValue, onChange: handlePastDateChange } = useDatePicker({
    initialValue: new Date(),
    changeCallback: getData
  })

  const renderWrapWeekDay = (date, selectedDate, dayInCurrentMonth, disableFuture) => {
    const dateClone = new Date(date)
    const selectedDateClone = new Date(selectedDate)

    const start = startOfWeek(selectedDateClone, { weekStartsOn: 1 })
    const end = endOfWeek(selectedDateClone, { weekStartsOn: 1 })

    const dayIsBetween = isWithinInterval(dateClone, { start, end })
    const isFirstDay = isSameDay(dateClone, start)
    const isLastDay = isSameDay(dateClone, end)

    const currmonthbetween = !dayInCurrentMonth && dayIsBetween

    return (
      <>
        <Highlight dayIsBetween={dayIsBetween} isFirstDay={isFirstDay} isLastDay={isLastDay}>
          <IconButton
            dayInCurrentMonth={!dayInCurrentMonth}
            currmonthbetween={currmonthbetween}
            dayIsBetween={dayIsBetween}
            futureDisable={disableFuture.props.disabled}
          >
            <span> {format(dateClone, 'd')} </span>
          </IconButton>
        </Highlight>
      </>
    )
  }

  useEffect(() => {
    if (!data) {
      return
    }

    dispatch(onGetGerenciaThunk())
  }, [])
  const presupuesto = [
    { x: '2024-1', y: 1891 },
    { x: '2024-2', y: 1776 },
    { x: '2024-3', y: 1935 },
    { x: '2024-4', y: 1874 },
    { x: '2024-5', y: 2124 },
    { x: '2024-6', y: 2329 },
    { x: '2024-7', y: 2425 },
    { x: '2024-8', y: 2436 },
    { x: '2024-9', y: 2419 },
    { x: '2024-10', y: 2467 },
    { x: '2024-11', y: 2318 },
    { x: '2024-12', y: 2322 }
  ]

  const currentDate = new Date()
  const currentMonth = currentDate.getMonth() + 1
  const currentYear = currentDate.getFullYear()

  // Filter to include only months before the current one
  const filteredPresupuesto = presupuesto.filter(item => {
    const [year, month] = item.x.split('-').map(Number)
    return year < currentYear || (year === currentYear && month <= currentMonth)
  })
  const lineOptions = {
    options: {
      chart: {
        height: 350,
        type: 'line' // Default type, we will override per series
      },
      plotOptions: {
        bar: {
          dataLabels: {
            position: 'top'
          }
        }
      },
      dataLabels: {
        enabled: true,
        offsetY: -20,
        style: {
          fontSize: '12px',
          colors: ['#304758']
        }
      },
      stroke: {
        width: [0, 4] // Assuming first series is bar, second is line
      },
      title: {
        text: 'Consumo de combustible total de la flota por mes Vs target (m3)',
        align: 'center'
      }
      // Additional configurations as needed (e.g., xaxis, yaxis)
    },
    series: [
      {
        name: 'volumen consumido',
        type: 'bar',
        data: data.orpak_per_month // Your existing bar chart data
      },
      {
        name: 'volumen target',
        type: 'line',
        data: filteredPresupuesto // The line chart data converted earlier
      }
    ]
  }

  const options = {
    seriesOptions: {
      type: 'bar',
      plotOptions: {
        bar: {
          dataLabels: {
            position: 'top'
          }
        }
      },
      dataLabels: {
        enabled: true,
        offsetY: -20,
        style: {
          fontSize: '12px',
          colors: ['#304758']
        }
      },
      title: {
        text: 'Volumen de combustible cargado por CAEX (L)',
        align: 'center'
      }
    },
    perPeriodOptions: {
      type: 'bar',
      plotOptions: {
        bar: {
          dataLabels: {
            position: 'top'
          }
        }
      },
      dataLabels: {
        enabled: true,
        offsetY: -20,
        style: {
          fontSize: '12px',
          colors: ['#304758']
        }
      },
      title: {
        text: 'Rendimiento efectivo combustible medio por CAEX (L/h)',
        align: 'center'
      }
    },
    perOrpakOptions: {
      type: 'bar',
      plotOptions: {
        bar: {
          dataLabels: {
            position: 'top'
          }
        }
      },
      dataLabels: {
        enabled: true,
        offsetY: -20,
        style: {
          fontSize: '12px',
          colors: ['#304758']
        }
      },
      title: {
        text: 'Consumo de combustible total de la flota por mes Vs target (m3)',
        align: 'center'
      }
    },
    series: [
      {
        data: data.volume_per_truck
      }
    ],
    perPeriod: [
      {
        data: data.consumption_per_period
      }
    ],
    perOrpak: [
      {
        data: data.orpak_per_month
      }
    ]
  }

  return (
    <>
      {loading && !error && <LoadingContent />}
      {error && !loading && <DisconnectedData />}
      {!loading && !error && (
        <>
          <DatePicker
            error={pastDateError}
            value={pastDateValue}
            label="Seleccione semana"
            width="100%"
            onChange={handlePastDateChange}
            renderDay={renderWrapWeekDay}
            disableFuture
          />
          <Wrapper>
            <Graphdiv>
              <Chart options={options.seriesOptions} series={options.series} type="bar" height="100%" width="100%" />
            </Graphdiv>
            <Graphdiv>
              <MyGroupedColumnChart consumptionData={data.consumption_per_truck} />
            </Graphdiv>
          </Wrapper>
          <Wrapper>
            <Graphdiv>
              <Chart options={lineOptions.options} series={lineOptions.series} type="bar" height="100%" width="100%" />
            </Graphdiv>
            <Graphdiv>
              <Chart
                options={options.perPeriodOptions}
                series={options.perPeriod}
                type="bar"
                height="100%"
                width="100%"
              />
            </Graphdiv>
          </Wrapper>
        </>
      )}
    </>
  )
}

export default GerenciaContainer
