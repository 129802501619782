import {
  GET_MAPBOX_FRENADOS,
  GET_MAPBOX_FRENADOS_SUCCESS,
  GET_MAPBOX_FRENADOS_ERROR
} from './VisualizadorEspacialFrenados.actions'

const mapboxState = {
  loading: true,
  error: false,
  urlData: []
}

const mapboxFrenadosReducer = (state = mapboxState, { payload, type }) => {
  switch (type) {
    case GET_MAPBOX_FRENADOS: {
      return { ...state, loading: true, error: false }
    }
    case GET_MAPBOX_FRENADOS_SUCCESS: {
      return { ...state, loading: false, error: false, urlData: payload.urlData }
    }
    case GET_MAPBOX_FRENADOS_ERROR: {
      return { ...state, loading: false, error: payload.error }
    }
    default: {
      return state
    }
  }
}

export default mapboxFrenadosReducer
