import React from 'react'
import PropTypes from 'prop-types'
import { Root, Subtitle, Title } from './Alert.styles'

/**
 * The Alert's component.
 */
const Alert = props => {
  const {
    action,
    align,
    border,
    children,
    disableBackground,
    icon,
    margin,
    mode,
    padding,
    severity,
    title,
    width
  } = props

  return (
    <Root
      action={action}
      align={align}
      border={border ? 1 : 0}
      disableBackground={disableBackground ? 1 : 0}
      icon={icon === undefined ? false : icon}
      margin={margin}
      mode={mode}
      padding={padding}
      severity={severity === 'info' ? 'primary' : severity === 'default' ? 'gray' : severity}
      width={width}
    >
      {title && <Title mode={mode}>{title}</Title>}
      <Subtitle mode={mode}>{children}</Subtitle>
    </Root>
  )
}

Alert.defaultProps = {
  align: 'left',
  border: false,
  disableBackground: false,
  mode: 'light',
  width: 'auto'
}
Alert.propTypes = {
  action: PropTypes.node,
  align: PropTypes.oneOf(['inherit', 'left', 'center', 'right', 'justify']),
  border: PropTypes.bool,
  children: PropTypes.node.isRequired,
  disableBackground: PropTypes.bool,
  icon: PropTypes.node,
  margin: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  mode: PropTypes.oneOf(['dark', 'light']),
  padding: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  severity: PropTypes.oneOf(['error', 'info', 'success', 'warning', 'default']).isRequired,
  title: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
}

export default Alert
