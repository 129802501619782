import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import routes from '../../config/settings/routes'
import { useViewportSize } from '../../modules/hooks/viewport'
import { onLogOutThunk, onToggleLogoutModalSuccess } from './Authentication.actions'
import Button from '../../components/Buttons/Button'
import Modal from '../../components/Basics/Modal'
import Typography from '../../components/Basics/Typography'
import { Separator, Wrapper } from './Logout.styles'

/**
 * The Logout Modal's container.
 */
const LogoutModal = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { loading, showLogoutModal } = useSelector(state => state.auth)
  const { isTiny } = useViewportSize()

  const handleClose = () => dispatch(onToggleLogoutModalSuccess())
  const handleLogout = () => {
    dispatch(
      onLogOutThunk({
        onCallback: () => history.push(routes.login)
      })
    )
  }

  return (
    <Modal maxWidth={isTiny ? '90%' : '726px'} open={showLogoutModal} onClose={handleClose}>
      <Typography fontSize={isTiny ? '18px' : '20px'} fontWeight="bold" variant="h3">
        Cerrar sesión
      </Typography>

      <Separator />

      <Wrapper container alignItems="center" direction="column" margin="50px 0">
        <img src="/assets/icons/vectorLogout.svg" alt="Cerrar sesión" />
        <Typography align="center" fontSize={isTiny ? '22px' : '25px'} lineHeight="33px" margin="20px 0 0" variant="h4">
          ¿Desea continuar y cerrar su sesión?
        </Typography>
      </Wrapper>

      <Separator />

      <Wrapper container justify="flex-end">
        <Button size={isTiny ? 'small' : 'medium'} onClick={handleClose}>
          Volver
        </Button>
        <Button
          color="primary"
          loading={loading}
          margin={isTiny ? '0 0 0 10px' : '0 0 0 30px'}
          size={isTiny ? 'small' : 'medium'}
          onClick={handleLogout}
        >
          Cerrar
        </Button>
      </Wrapper>
    </Modal>
  )
}

export default LogoutModal
