import styled from 'styled-components'
import { Checkbox, FormControlLabel } from '@material-ui/core'
import { hexToRGBA } from '../../modules/utils/color'

export const BaseCheckBox = styled(Checkbox)`
  color: ${props => props.theme.palette.brand.gray[50]};
  margin: -9px 0;

  & .MuiSvgIcon-root {
    font-size: 25px;
  }
  &:hover {
    background-color: ${props => hexToRGBA(props.theme.palette.brand.primary[50], 0.04)};
  }
  &.Mui-checked {
    color: ${props => props.theme.palette.brand.primary[50]};
  }
  &.MuiCheckbox-colorPrimary.Mui-disabled {
    color: ${props => props.theme.palette.brand.gray[75]};
  }
`

export const Item = styled(FormControlLabel)`
  align-items: flex-start;
  cursor: pointer;
  margin: 0 0 22px 0;

  & .MuiFormControlLabel-label {
    color: ${props => props.theme.palette.brand.black};
    font-family: ${props => props.theme.typography.fontFamily};
    font-size: 15px;
    letter-spacing: 0;
    margin: 0 0 0 11px;
    user-select: none;
  }

  &.Mui-disabled {
    cursor: not-allowed;
  }
`
