import React from 'react'
import PropTypes from 'prop-types'
import DateFnsUtils from '@date-io/date-fns'
import EsLocale from 'date-fns/locale/es'
import {
  BaseDatePicker,
  BasePickerProvider,
  BottomMessage,
  Error,
  GlobalStyles,
  Info,
  Label,
  Required,
  Root
} from './DatePicker.styles'

/**
 * The Date Picker' component.
 */
const DatePicker = props => {
  const {
    disabled,
    disableFuture,
    disablePast,
    disableToolbar,
    error,
    format,
    info,
    label,
    margin,
    maxDate,
    minDate,
    padding,
    required,
    value,
    variant,
    width,
    views,
    onChange,
    renderDay
  } = props

  return (
    <>
      <GlobalStyles />
      <Root margin={margin} padding={padding} width={width}>
        {label && (
          <Label>
            {`${label} `}
            {required && <Required />}
          </Label>
        )}

        <BasePickerProvider utils={DateFnsUtils} locale={EsLocale}>
          <BaseDatePicker
            allowKeyboardControl={false}
            autoOk
            disabled={disabled}
            disableFuture={disableFuture}
            disablePast={disablePast}
            disableToolbar={disableToolbar}
            format={format}
            invalidDateMessage="Formato de fecha no válido"
            KeyboardButtonProps={{
              'aria-label': 'cambiar fecha'
            }}
            maxDate={maxDate}
            maxDateMessage="La fecha no debe ser posterior a la fecha máxima"
            minDate={minDate}
            minDateMessage="La fecha no debe ser anterior a la fecha mínima"
            onChange={onChange}
            variant={variant}
            value={value}
            views={views}
            renderDay={renderDay}
          />
        </BasePickerProvider>

        <BottomMessage>
          {!error && <Info>{info}</Info>}
          {error && <Error>{error}</Error>}
        </BottomMessage>
      </Root>
    </>
  )
}

DatePicker.defaultProps = {
  disabled: false,
  disableFuture: false,
  disablePast: false,
  disableToolbar: false,
  error: '',
  format: 'dd/MM/yyyy',
  info: '',
  label: '',
  margin: 0,
  padding: 0,
  required: false,
  variant: 'dialog',
  width: 'initial',
  onChange: () => undefined
}
DatePicker.propTypes = {
  disabled: PropTypes.bool,
  disableFuture: PropTypes.bool,
  disablePast: PropTypes.bool,
  disableToolbar: PropTypes.bool,
  error: PropTypes.string,
  format: PropTypes.string,
  info: PropTypes.string,
  label: PropTypes.string,
  margin: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  maxDate: PropTypes.instanceOf(Date),
  minDate: PropTypes.instanceOf(Date),
  padding: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  required: PropTypes.bool,
  value: PropTypes.instanceOf(Date).isRequired,
  variant: PropTypes.oneOf(['dialog', 'inline', 'static']),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  views: PropTypes.array,
  onChange: PropTypes.func,
  renderDay: PropTypes.func
}

export default DatePicker
