import styled from 'styled-components'
import { Badge } from '@material-ui/core'

export const Root = styled(Badge)`
  &.MuiBadge-root {
    margin: ${props => props.margin};
    & .MuiBadge-colorPrimary {
      background-color: ${props => props.theme.palette.brand.success[50]};
      color: ${props => props.theme.palette.brand.white};
    }
    & .MuiBadge-colorSecondary {
      background-color: ${props => props.theme.palette.brand.warning[50]};
      color: ${props => props.theme.palette.brand.white};
    }
    & .MuiBadge-colorError {
      background-color: ${props => props.theme.palette.brand.error[50]};
      color: ${props => props.theme.palette.brand.white};
    }
  }
`
