import React from 'react'
import PropTypes from 'prop-types'
import { Root } from './Accordion.styles'

/**
 * The Accordion' component.
 */
const Accordion = props => {
  const { children, disabled, expanded, margin, padding, onChange } = props

  return (
    <Root disabled={disabled} expanded={expanded} margin={margin} padding={padding} square onChange={onChange}>
      {children}
    </Root>
  )
}

Accordion.defaultProps = {
  disabled: false,
  onChange: () => undefined
}
Accordion.propTypes = {
  children: PropTypes.node.isRequired,
  disabled: PropTypes.bool,
  expanded: PropTypes.bool,
  margin: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  padding: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onChange: PropTypes.func
}

export default Accordion
