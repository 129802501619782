import { GET_REPORT_DATA, GET_REPORT_DATA_ERROR, GET_REPORT_DATA_SUCCESS } from './Reports.actions'

const initialState = {
  loading: true,
  error: false,
  data: null
}

const reportReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_REPORT_DATA:
      return { ...state, loading: true, error: false }
    case GET_REPORT_DATA_ERROR:
      return { ...state, loading: false, error: true }
    case GET_REPORT_DATA_SUCCESS:
      return { ...state, loading: false, error: false, data: payload.data }
    default:
      return state
  }
}

export default reportReducer
