import React, { useEffect } from 'react'
import { batch, useDispatch } from 'react-redux'
import Radio from '../../components/Inputs/Radio'
import { Grid } from '@material-ui/core'
import { useRadio } from '../../components/Inputs/Inputs.hooks'
import Button from '../../components/Buttons/Button'
import { onChangeFirstFilter, onChangeSecondFilter } from './NewAnomalyFilter.actions'
import { Wrapper, ButtonWrapper } from './NewAnomalyFilter.styles'

const NewAnomalyFilter = () => {
  const dispatch = useDispatch()

  const { value: filter1, onChange: handleChange1 } = useRadio({
    initialValue: '1'
  })

  const { value: filter2, onChange: handleChange2 } = useRadio({
    initialValue: '1'
  })

  useEffect(() => {
    batch(() => {
      dispatch(onChangeFirstFilter({ filter1 }))
      dispatch(onChangeSecondFilter({ filter2 }))
    })
  }, [])

  const filterGraphs = () => {
    batch(() => {
      dispatch(onChangeFirstFilter({ filter1 }))
      dispatch(onChangeSecondFilter({ filter2 }))
    })
  }

  return (
    <>
      <Grid container direction="row" alignitems="center" justifycontent="space-evenly" spacing={2}>
        <Grid item xs={3}>
          <Wrapper>
            <Radio
              direction="horizontal"
              padding="15px 0 0 0"
              options={[
                {
                  label: 'Cantidad',
                  value: '1'
                },
                {
                  label: 'Litros',
                  value: '2'
                }
              ]}
              value={filter1}
              onChange={handleChange1}
            />
          </Wrapper>
        </Grid>
        <Grid item xs={4}>
          <Wrapper>
            <Radio
              direction="horizontal"
              padding="15px 0 0 0"
              options={[
                {
                  label: 'Total',
                  value: '1'
                },
                {
                  label: 'Tipo de anomalía',
                  value: '2'
                }
              ]}
              value={filter2}
              onChange={handleChange2}
            />
          </Wrapper>
        </Grid>
        <Grid item xs={2} />
        <Grid item xs={3}>
          <ButtonWrapper>
            <Button color="primary" variant="contained" onClick={filterGraphs} size="small">
              FILTRAR
            </Button>
          </ButtonWrapper>
        </Grid>
      </Grid>
    </>
  )
}

export default NewAnomalyFilter
