import {
  CHANGE_HARD_BRAKING_GROUP_PAGE,
  CHANGE_HARD_BRAKING_GROUP_SELECTED,
  CHANGE_HARD_BRAKING_GROUP_SELECTED_ERROR,
  CHANGE_HARD_BRAKING_GROUP_SELECTED_SUCCESS,
  GET_HARD_BRAKING,
  GET_HARD_BRAKING_ERROR,
  GET_HARD_BRAKING_GROUP,
  GET_HARD_BRAKING_GROUP_ERROR,
  GET_HARD_BRAKING_GROUP_SUCCESS,
  GET_HARD_BRAKING_SUCCESS,
  UPDATE_OPERATORS_HARD_BRAKING,
  GET_HARD_BRAKE_REPORT,
  GET_HARD_BRAKE_REPORT_SUCCESS,
  GET_HARD_BRAKE_REPORT_ERROR
} from './HardBraking.actions'

const hardBrakingState = {
  consultationDate: '',
  data: [],
  error: false,
  groups: [],
  loading: true,
  selectedGroupId: false,
  reportLoading: false,
  reportSuccess: false,
  reportData: [],
  reportError: false
}

/**
 * The hard braking's reducer.
 */
const hardBrakingReducer = (state = hardBrakingState, { payload, type }) => {
  switch (type) {
    case GET_HARD_BRAKING: {
      return { ...state, loading: true, error: false }
    }
    case GET_HARD_BRAKING_ERROR: {
      return { ...state, loading: false, error: payload.error }
    }
    case GET_HARD_BRAKING_SUCCESS: {
      return { ...state, loading: false, ...payload }
    }
    case CHANGE_HARD_BRAKING_GROUP_PAGE: {
      return {
        ...state,
        [payload.groupId]: {
          ...state[payload.groupId],
          page: payload.page
        }
      }
    }
    case GET_HARD_BRAKING_GROUP:
    case CHANGE_HARD_BRAKING_GROUP_SELECTED:
    case CHANGE_HARD_BRAKING_GROUP_SELECTED_SUCCESS: {
      return { ...state, selectedGroupId: payload.selectedGroupId }
    }
    case GET_HARD_BRAKING_GROUP_ERROR:
    case CHANGE_HARD_BRAKING_GROUP_SELECTED_ERROR: {
      return {
        ...state,
        [payload.selectedGroupId]: {
          ...state[payload.selectedGroupId],
          error: payload.error,
          loading: false
        }
      }
    }
    case GET_HARD_BRAKING_GROUP_SUCCESS: {
      return {
        ...state,
        [payload.selectedGroupId]: {
          loading: false,
          error: false,
          page: 1,
          data: payload.data
        }
      }
    }
    case UPDATE_OPERATORS_HARD_BRAKING: {
      return {
        ...state,
        [payload.selectedGroupId]: {
          ...state[payload.selectedGroupId],
          data: payload.data
        }
      }
    }
    case GET_HARD_BRAKE_REPORT: {
      return {
        ...state,
        reportLoading: !state.reportLoading,
        reportError: false,
        reportSuccess: false
      }
    }
    case GET_HARD_BRAKE_REPORT_SUCCESS: {
      return {
        ...state,
        reportLoading: !state.reportLoading,
        reportData: payload.reportData,
        reportError: false,
        reportSuccess: true
      }
    }
    case GET_HARD_BRAKE_REPORT_ERROR: {
      return {
        ...state,
        reportLoading: !state.reportLoading,
        reportSuccess: false,
        reportError: true
      }
    }
    default: {
      return state
    }
  }
}

export default hardBrakingReducer
