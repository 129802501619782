const COSMOS_COLORS = {
  primary_dark: '#DC5B04',
  primary: '#FA6A09',
  primary_light: '#FF9045',
  secondary_gray: '#828282',
  background_light: '#E6E3DF',
  background_dark: '#333333',

  // scores
  scores_good: '#346DFF',
  scores_bad: '#F43737',
  scores_mean: '#BDBDBD',

  // black & whites
  white: '#FFFFFF',
  silver: '#E5E5E5',
  disabled_gray: '#828282',
  dark: '#263238',
  black: '#000000',

  // fuel level indicators
  red_critical: '#AD000F',
  violet_lowest: '#A6008C',
  purple_low: '#4100A0',
  blue_good: '#00359A',
  aqua_very_good: '#009486',
  green_full: '#008E17',

  // transparencies
  hoverBlue: 'rgba(52, 109, 255, 0.3)',
  hoverPrimary: 'rgba(250, 106, 9, 0.6)',
  hoverBlack: 'rgba(51, 51, 51, 0.5)',
  hoverMean: 'rgba(189,189,189,0.3)',
  hoverGray: '#F2F2F2'
}

export default COSMOS_COLORS
