import { GET_ORPAK_DISPATCH, GET_ORPAK_DISPATCH_ERROR, GET_ORPAK_DISPATCH_SUCCESS } from './dispatchOrpak.actions'

const dispatchOrpakState = {
  loadingDispatchOrpak: true,
  errorDispatchOrpak: false,
  dispatchOrpak: {
    dispatchOrpakData: []
  },
  tableFooter: {}
}

/**
 * The dispatch orpak reducer.
 */
const dispatchOrpakReducer = (state = dispatchOrpakState, { payload, type }) => {
  switch (type) {
    case GET_ORPAK_DISPATCH: {
      return { ...state, loadingDispatchOrpak: true, errorDispatchOrpak: false }
    }
    case GET_ORPAK_DISPATCH_ERROR: {
      return { ...state, loadingDispatchOrpak: false, errorDispatchOrpak: true }
    }
    case GET_ORPAK_DISPATCH_SUCCESS: {
      return {
        ...state,
        loadingDispatchOrpak: false,
        errorDispatchOrpak: false,
        dispatchOrpak: payload.dispatchOrpak,
        tableFooter: payload.tableFooter
      }
    }
    default: {
      return state
    }
  }
}

export default dispatchOrpakReducer
