import React, { useState } from 'react'
import { CONSUMPTION_OPERATOR_TYPE } from '../config/settings/constant'
import routes from '../config/settings/routes'
import { usePathParam } from '../modules/hooks/route'
import ConsumptionOperatorTopbar from '../containers/ConsumptionOperatorTopbar/ConsumptionOperatorTopbar.container'
import HardBrakingContainer from '../containers/HardBraking/HardBraking.container'
import IdleRpmContainer from '../containers/IdleRpm/IdleRpm.container'
import ManagementSectionContainer from '../containers/ManagementMenu/ManagementMenu.container'
import NavbarContainer from '../containers/Navbar/Navbar.container'
import ReinforcementModalContainer from '../containers/Reinforcement/ReinforcementModal.container'
import ReinforcementFeedbackModalContainer from '../containers/ReinforcementFeedbackModal/ReinforcementFeedbackModal.container'
import RpmDownloadContainer from '../containers/RpmDownload/RpmDownload.container'
import BrandLayout from '../components/Layouts/BrandLayout'
import Heading from '../components/Layouts/Heading'
import ProtectByFeatures from '../components/Security/ProtectByFeatures'
import ProtectBySession from '../components/Security/ProtectBySession'
import NotFoundPage from './NotFound'

/**
 * The ConsumptionOperator' page.
 */
const ConsumptionOperator = () => {
  const title = 'Consumo por Operador'
  const [showModal, setShowModal] = useState(false)
  const type = usePathParam('type').toUpperCase()

  const handleToggleModal = () => setShowModal(!showModal)

  const consumptionPerOperatorOption = features =>
    features['com.cosmos/hard.braking'] || features['com.cosmos/idle.rpm'] || features['com.cosmos/rpm.download']

  return (
    <ProtectBySession rule={(hasSession, signedIn) => !!(hasSession && signedIn)}>
      <ProtectByFeatures rule={consumptionPerOperatorOption} fallback={() => <NotFoundPage />}>
        <BrandLayout
          navbar={<NavbarContainer />}
          header={<Heading title={title} topbar={ConsumptionOperatorTopbar} />}
          sidebar={<ManagementSectionContainer current={routes.consumptionPerOperator} />}
        >
          {CONSUMPTION_OPERATOR_TYPE[type] === 'IDLE_RPM' && <IdleRpmContainer onToggleModal={handleToggleModal} />}
          {CONSUMPTION_OPERATOR_TYPE[type] === 'RPM_DOWNLOAD' && (
            <RpmDownloadContainer onToggleModal={handleToggleModal} />
          )}
          {CONSUMPTION_OPERATOR_TYPE[type] === 'HARD_BRAKING' && (
            <HardBrakingContainer onToggleModal={handleToggleModal} />
          )}

          <ReinforcementModalContainer showModal={showModal} onToggleModal={handleToggleModal} />
          <ReinforcementFeedbackModalContainer onToggleModal={handleToggleModal} />
        </BrandLayout>
      </ProtectByFeatures>
    </ProtectBySession>
  )
}

export default ConsumptionOperator
