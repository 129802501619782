import React from 'react'
import { useCheckBox, useRadio } from '../../components/Inputs/Inputs.hooks'
import CheckBox from '../../components/Inputs/CheckBox'
import CheckBoxPlaceholder from '../../components/Placeholders/CheckBoxPlaceholder'
import Radio from '../../components/Inputs/Radio'
import Typography from '../../components/Basics/Typography'
import { Root } from './Examples.styles'

/**
 * The CheckRadioExample's container.
 */
const CheckRadioExample = () => {
  const { value: checked0, onChange: handleChange0 } = useCheckBox({ initialValue: false })
  const { value: checked1, onChange: handleChange1 } = useCheckBox({ initialValue: false })
  const { value: radio2, onChange: handleChange2 } = useRadio()
  const { value: radio3, onChange: handleChange3 } = useRadio()
  const { value: radio4, onChange: handleChange4 } = useRadio({ initialValue: '1' })

  return (
    <Root>
      <Typography variant="body1">Acá algunos ejemplos de checkboxes y algunos de ellos deshabilitados</Typography>
      <br />
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <CheckBox
          checked={checked0}
          label="Declaro que toda la información ingresada en este formulario es veraz, completa, verificable y se encuentra actualizada. En caso de encontrarse inconsistencias, maliciosamente falsos o datos no actualizados, los proveedores podrán ser suspendidos o eliminados del Registro de Proveedores, de acuerdo a los Artículos 95 y 96 del Reglamento de Compras Públicas."
          onChange={handleChange0}
        />
        <CheckBox checked={checked1} label="Enviar evaluación al instructor" onChange={handleChange1} />

        <CheckBox checked disabled label="Checked and disabled" />
        <CheckBox disabled label="No checked and disabled" />
      </div>
      <br />

      <Typography variant="body1">También pueden tener efecto de loading</Typography>
      <br />

      <div>
        <CheckBoxPlaceholder rows={2} />
        <CheckBoxPlaceholder margin="0 0 20px" rows={3} />
      </div>

      <br />
      <br />
      <Typography variant="body1">Ejemplos de Radio Button</Typography>
      <br />
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <Radio
          direction="vertical"
          label="Simple Radio - vertical"
          options={[
            {
              label: 'Nacional',
              value: '1'
            },
            {
              label: 'Extranjero',
              value: '2'
            }
          ]}
          value={radio2}
          onChange={handleChange2}
        />

        <Radio
          direction="horizontal"
          label="Simple Radio - horizontal"
          options={[
            {
              label: 'Opción 1',
              value: '1'
            },
            {
              label: 'Opción 2',
              value: '2'
            },
            {
              label: 'Opción 3',
              value: '3'
            },
            {
              label: 'Opción 4',
              value: '4'
            }
          ]}
          value={radio3}
          onChange={handleChange3}
        />

        <Radio
          direction="vertical"
          disabled
          label="Simple Radio - vertical"
          options={[
            {
              label: 'Nacional',
              value: '1'
            },
            {
              label: 'Extranjero',
              value: '2'
            }
          ]}
          value={radio4}
          onChange={handleChange4}
        />
      </div>
    </Root>
  )
}

export default CheckRadioExample
