// FIXME: Need to be refactored
import React, { useState, useEffect } from 'react'
// eslint-disable-next-line
import { default as moonIcon } from '../../constants/icons/Vectormoon.svg'
// eslint-disable-next-line
import { default as sunIcon } from '../../constants/icons/Vectorday.svg'
// eslint-disable-next-line
import { default as pauseIcon } from '../../constants/icons/Vectoroff.svg'
import { getEfficiencyStadistics, getHistoryDispach } from '../../services/fuelLevelService'
import useStyles from '../../styles/levelsStyle'
import LoadingContent from '../../components/Placeholders/LoadingContent'
import {
  Container,
  Grid,
  Icon,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@material-ui/core'
import { REACT_APP_CLIENT_NAME } from '../../config/settings/environment'

/**
 * The RefillEfficiency' container.
 */
const RefillEfficiency = () => {
  const rows = [
    {
      id: 1,
      bottom: 0,
      top: 15,
      label: 'Riesgo de panne (Falta presión)',
      backgroundColor: 'rgba(255,255,255,1)',
      fontWeight: 'normal'
    },
    {
      id: 2,
      bottom: 15,
      top: 25,
      label: 'Riesgo de panne',
      backgroundColor: 'rgba(255,255,255,1)',
      fontWeight: 'normal'
    },
    {
      id: 3,
      bottom: 25,
      top: 40,
      label: 'Abastecimiento óptimo',
      backgroundColor: 'rgba(52,109,255,0.1)',
      fontWeight: 'bold'
    },
    REACT_APP_CLIENT_NAME === 'zaldivar' && {
      id: 4,
      bottom: 26,
      top: 33,
      label: 'Abastecimiento óptimo'
    },
    {
      id: 5,
      bottom: 40,
      top: 50,
      label: 'Pérdida de eficiencia',
      backgroundColor: 'rgba(255,255,255,1)',
      fontWeight: 'normal'
    },
    {
      id: 6,
      bottom: 50,
      top: 100,
      label: 'Abastecimiento ineficiente',
      backgroundColor: 'rgba(255,255,255,1)',
      fontWeight: 'normal'
    }
  ].filter(Boolean)

  const [groups, setGroups] = useState([])
  const [loading, setLoading] = useState(true)
  const [historyDispatch, setHistoryDispatch] = useState([])
  const [dispatchPeriodSelected, setDispatchPeriodSelected] = useState('actual')
  const classes = useStyles()

  useEffect(() => {
    fetchData()
    return () => {
      setGroups([])
    }
  }, [])

  useEffect(() => {
    if (groups.length > 0) {
      setLoading(false)
    }
  }, [groups])

  const fetchData = async () => {
    setLoading(true)
    const response = await getEfficiencyStadistics()
    const historyTimes = await getHistoryDispach()
    const [g1, g2, g3, g4] = response
    setGroups([g1, g2, g3, g4])
    setHistoryDispatch(historyTimes)
  }

  const refreshData = async value => {
    if (value === 'actual') {
      fetchData()
    } else {
      setLoading(true)
      const selected = historyDispatch.find(period => period.value === value)
      const { month, year } = selected
      const historyTimes = await getEfficiencyStadistics(month, year)
      const [g1, g2, g3, g4] = historyTimes
      setGroups([g1, g2, g3, g4])
    }
  }

  const handleSelectChange = e => {
    setDispatchPeriodSelected(e.target.value)
    refreshData(e.target.value)
  }

  const isOfThisScale = (top, bottom, percentage) => {
    return bottom < percentage && percentage <= top
  }
  const isDisabled = activity => {
    return activity === 'off'
  }
  const selectIcon = (activity, width = '20px') => {
    const style = { width: width, verticalAlign: 'text-top' }

    switch (activity) {
      case 'off':
        return <img src={pauseIcon} alt="StandBy" style={style} />
      case 'day':
        return <img src={sunIcon} alt="Diurno" style={style} />
      case 'night':
        return <img src={moonIcon} alt="Nocturno" style={style} />
    }
  }

  const renderRow = scale => {
    return (
      <TableRow
        key={scale.top}
        className={isOfThisScale(33, 26, scale.top) ? classes.optimize : classes.normal}
        style={{ background: scale?.backgroundColor }}
      >
        <TableCell>
          <Grid container direction="column">
            <Typography style={{ fontWeight: scale?.fontWeight }}>{scale.bottom + '% a ' + scale.top + '%'}</Typography>
            <Typography className={isOfThisScale(33, 26, scale.top) ? classes.labelOptima : classes.label}>
              {scale.label}
            </Typography>
          </Grid>
        </TableCell>
        {groups.map(group => {
          const thisCell = group.stadistics.filter(score => {
            return isOfThisScale(scale.top, scale.bottom, score.percentage)
          })[0]
          return (
            <TableCell key={group.groupId + '-' + thisCell.percentage} align="center">
              <Typography className={isDisabled(group.activity) ? classes.disabled : classes.icon}>
                {parseInt(thisCell.count) + ' cargas'}
              </Typography>
              <Typography className={isDisabled(group.activity) ? classes.disabled : classes.icon}>
                <small>{thisCell.score.toFixed(2) + '%'}</small>
              </Typography>
            </TableCell>
          )
        })}
      </TableRow>
    )
  }

  const renderHeadRow = group => {
    return (
      <TableCell align="center" key={group.groupId}>
        <Grid container direction="row" justify="center">
          <Icon className={isDisabled(group.activity) ? classes.headerDisabled : classes.header} style={{ margin: 3 }}>
            {selectIcon(group.activity)}
          </Icon>
          <Typography className={isDisabled(group.activity) ? classes.headerDisabled : classes.header}>
            {group.groupName.toUpperCase()}
          </Typography>
        </Grid>
      </TableCell>
    )
  }

  return loading ? (
    <LoadingContent />
  ) : (
    <Container>
      <Grid
        container
        direction="row"
        justify="flex-end"
        alignItems="center"
        style={{ marginTop: '1rem', marginBottom: '1rem' }}
      >
        <Grid item xs={4} md={2}>
          <Typography>Seleccione un periodo: </Typography>
        </Grid>
        <Grid item xs={4} md={2}>
          <Select labelId="selectTime" id="selectTime" value={dispatchPeriodSelected} onChange={handleSelectChange}>
            {historyDispatch.map(period => {
              return (
                <MenuItem key={period.value} value={period.value}>
                  {period.value}
                </MenuItem>
              )
            })}
          </Select>
        </Grid>
      </Grid>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell style={{ width: '20%' }}>
                <Typography className={classes.header}>Combustible en estanque</Typography>
              </TableCell>
              {groups.map(group => {
                return renderHeadRow(group)
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map(linea => {
              return renderRow(linea)
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <Grid container direction="row" justify="flex-end" alignItems="center" style={{ margin: 10 }}>
        <Icon>{selectIcon('off', '15px')}</Icon>
        <Typography className={classes.legend}>Grupo en turno stand-by {'  '}</Typography>
        <Icon>{selectIcon('day', '15px')}</Icon>
        <Typography className={classes.legend}>Grupo en turno diurno {'  '}</Typography>
        <Icon>{selectIcon('night', '15px')}</Icon>
        <Typography className={classes.legend}>Grupo en turno nocturno{'  '}</Typography>
      </Grid>
    </Container>
  )
}

export default RefillEfficiency
