import React from 'react'
import routes from '../config/settings/routes'
import { VISUALIZADOR_TYPE } from '../config/settings/constant'
import ManagementSectionContainer from '../containers/ManagementMenu/ManagementMenu.container'
import VisualizadorEspacialContainerHistorico from '../containers/VisualizadorEspacialHistorico/VisualizadorEspacialHistorico.container'
import VisualizadorEspacialContainerFrenados from '../containers/VisualizadorEspacialFrenados/VisualizadorEspacialFrenados.container'
import VisualizadorEspacialTopbar from '../containers/VisualizadorEspacialTopbar/VisualizadorEspacialTopbar.container'
import VisualizadorEspacialContainer from '../containers/VisualizadorEspacial/VisualizadorEspacial.container'
import VisualizadorEspacialRalentiContainer from '../containers/VisualizadorEspacialRalenti/VisualizadorEspacialRalenti.container'
import NavbarContainer from '../containers/Navbar/Navbar.container'
import BrandLayout from '../components/Layouts/BrandLayout'
import Heading from '../components/Layouts/Heading'
import ProtectByFeatures from '../components/Security/ProtectByFeatures'
import ProtectBySession from '../components/Security/ProtectBySession'
import NotFoundPage from './NotFound'
import { usePathParam } from '../modules/hooks/route'

/**
 * The AnomayDetection' page.
 */
const VisualizadorEspacial = () => {
  const title = 'Visualizador Espacial'
  const type = usePathParam('type').toUpperCase()

  const visualizadorPerOption = features =>
    features['com.cosmos/visualizador.espacial.dia'] ||
    features['com.cosmos/visualizador.espacial.historico'] ||
    features['com.cosmos/visualizador.espacial.frenados'] ||
    features['com.cosmos/visualizador.espacial.ralenti']

  return (
    <ProtectBySession rule={(hasSession, signedIn) => !!(hasSession && signedIn)}>
      <ProtectByFeatures rule={visualizadorPerOption} fallback={() => <NotFoundPage />}>
        <BrandLayout
          navbar={<NavbarContainer />}
          header={<Heading title={title} topbar={VisualizadorEspacialTopbar} />}
          sidebar={<ManagementSectionContainer current={routes.visualizadorOperaciones} />}
          fullSection
        >
          {VISUALIZADOR_TYPE[type] === 'DIA' && <VisualizadorEspacialContainer />}
          {VISUALIZADOR_TYPE[type] === 'HISTORICO' && <VisualizadorEspacialContainerHistorico />}
          {VISUALIZADOR_TYPE[type] === 'FRENADOS' && <VisualizadorEspacialContainerFrenados />}
          {VISUALIZADOR_TYPE[type] === 'GPSRALENTI' && <VisualizadorEspacialRalentiContainer />}
        </BrandLayout>
      </ProtectByFeatures>
    </ProtectBySession>
  )
}

export default VisualizadorEspacial
