import React from 'react'
import Breadcrumbs from '../../components/Navigation/Breadcrumbs'
import Skeleton from '../../components/Placeholders/Skeleton'
import TablePlaceholder from '../../components/Placeholders/TablePlaceholder'
import Typography from '../../components/Basics/Typography'
import { Root } from './Examples.styles'

/**
 * The SkeletonExample's container.
 */
const SkeletonExample = () => {
  const breadcrumbs = [
    {
      text: 'Link a google',
      link: 'https://google.com',
      isExternal: true
    },
    {
      text: 'Link a otro lugar',
      link: '#',
      isExternal: true
    },
    {
      text: 'Consumo operador',
      link: '/consumo-operador/ralenti',
      isExternal: false
    },
    {
      text: 'Ejemplos'
    }
  ]

  return (
    <Root>
      <Typography variant="body1">
        El breadcrumb soporta redirigir a links externos y a rutas dentro de la misma aplicación.
      </Typography>
      <br />
      <Breadcrumbs breadcrumbs={breadcrumbs} />

      <br />
      <Typography variant="body1">Soy un skeleton rectangular de tamaño fijo: 50x200 (variant="rect")</Typography>
      <Skeleton variant="rect" height="50px" width="200px" />

      <br />
      <Typography variant="body1">Tambien tengo variantes: variant="circle"</Typography>
      <Skeleton variant="circle" height="40px" width="40px" />

      <br />
      <Typography variant="body1">
        Y si estoy dentro de un componente del typography, tengo un alto definido segun la variante de este componente
      </Typography>
      <Typography variant="body1">Esta es una variante de un h1</Typography>
      <Typography variant="h1">
        <Skeleton width="150px" />
      </Typography>
      <Typography variant="body1">Esta es una variante de un body1</Typography>
      <Typography variant="body1">
        <Skeleton width="150px" />
      </Typography>

      <br />
      <br />
      <Typography variant="body1">
        Tambien existe uno especifico para tablas que se llama TablePlaceholder. Por defecto usare todo el ancho, pero
        pueden setiarme esta propiedad.
      </Typography>
      <TablePlaceholder width="500px" />
    </Root>
  )
}

export default SkeletonExample
