export const ADDRESS_CHECK = /^(?![.,\s/#º-])(?!.*[.,\s/#º-]$)(?!.*\s[.,/-])(?!.*[.,/-]\s#)(?!.*Nº[^0-9])(?!.*[.,/-]\s#)(?!.*(?:[.,/#º-]{2,}|([.,\s/#-])\1))(?!.*([A-Za-zÀ-ÖØ-öø-ÿ])\2\2)[A-Za-zÀ-ÖØ-öø-ÿ0-9.,\s/#º-]+$/gi
export const ALLOW_ONLY_NUMBERS = /^[0-9]*$/
export const CLEAN_NUMBERS = /^0+|[^0-9]+/g
export const CLEAN_DNI = /^0+|[^0-9kK]+/g
export const CURRENCY_DOTS = /(\d)(?=(?:[0-9,]{3})+\b)/g
export const DNI_CHECK = /^(\d{1,3}(?:\.\d{1,3}){2}-[\dkK])$/
export const EMAIL_CHECK = /^[a-z0-9]((\.|\+|-|_)?[a-z0-9]){1,}@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/
export const ESCAPE_HTML = /[^\w. ]/gi
export const ESCAPE_JS = /javascript:/gi
export const NAME_CHECK = /^(?![.,\s-])(?!.*[.,\s-]$)(?!.*\s[.,-])(?!.*(?:[.,-]{2,}|([.,\s-])\1))(?!.*([A-Za-zÀ-ÖØ-öø-ÿ])\2\2)[A-Za-zÀ-ÖØ-öø-ÿ.,\s-]+$/gi
export const ONLY_NUMBERS_CHECK = /^[0-9\b]+$/
export const PASSWORD_CHECK = /^(?=.{8,}$)(?=.*?[a-z])(?=.*?[0-9]).*$/
export const PATH_PARAM = /:\w+(?=\/?)/
