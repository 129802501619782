import React from 'react'
import routes from '../config/settings/routes'
import { useTabs } from '../components/Navigation/Tab.hooks'
import ContactContentContainer from '../containers/Help/ContactContent.container'
import ManagementSectionContainer from '../containers/ManagementMenu/ManagementMenu.container'
import ManualContentContainer from '../containers/Help/ManualContent.container'
import NavbarContainer from '../containers/Navbar/Navbar.container'
import BrandLayout from '../components/Layouts/BrandLayout'
import Heading from '../components/Layouts/Heading'
import ProtectByFeatures from '../components/Security/ProtectByFeatures'
import ProtectBySession from '../components/Security/ProtectBySession'
import Tab from '../components/Navigation/Tab'
import Tabs from '../components/Navigation/Tabs'
import NotFoundPage from './NotFound'
import { Container } from './Help.styles'

/**
 * The Help' page.
 */
const Help = () => {
  const title = 'Ayuda'
  const { value: pageSelected, onChange: handleChangeTab } = useTabs({ initialValue: 'contact' })

  const renderTopbar = () => {
    return (
      <Tabs value={pageSelected} onChange={handleChangeTab} aria-label="User guide tabs" variant="scrollable">
        <Tab label="Contacto" value="contact" />
        <Tab label="Manual de Usuario" value="manual" />
      </Tabs>
    )
  }

  return (
    <ProtectBySession rule={(hasSession, signedIn) => !!(hasSession && signedIn)}>
      <ProtectByFeatures rule={features => features['com.cosmos/help']} fallback={() => <NotFoundPage />}>
        <BrandLayout
          navbar={<NavbarContainer />}
          header={<Heading title={title} topbar={renderTopbar} />}
          sidebar={<ManagementSectionContainer current={routes.help} />}
        >
          <Container>
            {pageSelected === 'manual' && <ManualContentContainer />}
            {pageSelected === 'contact' && <ContactContentContainer />}
          </Container>
        </BrandLayout>
      </ProtectByFeatures>
    </ProtectBySession>
  )
}

export default Help
