import { API } from 'aws-amplify'
import { REACT_APP_CLIENT_NAME } from '../config/settings/environment'
import { format } from 'date-fns'

/**
 * To get prediction data.
 */
export const getPredictData = async ({ apiKey, apiName, startDate, endDate, truck }) => {
  const paths = {
    amsa: '/v2/pred-graph',
    anglo: '/v2/pred-graph',
    antucoya: '/v2/pred-graph'
  }
  const path = paths[REACT_APP_CLIENT_NAME] || '/v2/pred-graph'

  const stringDateFrom = format(startDate, 'yyyy-MM-dd')
  const stringDateTo = format(endDate, 'yyyy-MM-dd')

  const newPath = `${path}?start_date=${stringDateFrom}&end_date=${stringDateTo}&equipo=${truck}`
  console.log(newPath)

  const response = await API.get(apiName, newPath, { headers: { 'x-api-key': apiKey }, response: true, body: {} })

  return {
    data: response.data,
    headers: response.headers,
    request: response.config,
    status: response.status
  }
}
