import { API, Auth } from 'aws-amplify'
import {
  REACT_APP_API_BASE_URL,
  REACT_APP_BASE_KEY,
  REACT_APP_BASE_NAME,
  REACT_APP_CLIENT_NAME,
  REACT_APP_BASE_SUB_PATH
} from '../config/settings/environment'
import axios from 'axios'
import { format } from 'date-fns'

/**
 * To get the new anomalies
 */
export const getNewAnomalies = async ({ apiKey, apiName }) => {
  const paths = {
    amsa: '/v2/new-anomalies',
    anglo: '/v2/new-anomalies',
    antucoya: '/v2/new-anomalies/'
  }
  const path = paths[REACT_APP_CLIENT_NAME] || '/v2/new-anomalies'

  const init = {
    headers: {
      'x-api-key': REACT_APP_BASE_KEY
    },
    response: true
  }
  const response = await API.get(REACT_APP_BASE_NAME, path, init)

  return {
    data: response.data,
    headers: response.headers,
    request: response.config,
    status: response.status
  }
}

/**
 * To get the new anomaly summary information.
 */
export const getNewAnomaliesSummary = async ({ apiKey, apiName }) => {
  const paths = {
    antucoya: '/v2/new-anomalies/summary'
  }
  const path = paths[REACT_APP_CLIENT_NAME] || '/v2/new-anomalies/summary'

  const response = await API.get(apiName, path, { headers: { 'x-api-key': apiKey }, response: true, body: {} })

  return {
    data: response.data,
    headers: response.headers,
    request: response.config,
    status: response.status
  }
}

/**
 * To get the new anomaly summary information.
 */
export const getNewAnomaliesReport = async ({ apiKey, apiName }) => {
  const paths = {
    antucoya: '/v2/new-anomalies/reports'
  }
  const api = paths[REACT_APP_CLIENT_NAME] || '/v2/new-anomalies/reports'
  const path = `${REACT_APP_API_BASE_URL}${REACT_APP_BASE_SUB_PATH}${api}`

  const config = {
    headers: {
      Authorization: `Bearer ${(await Auth.currentSession())?.getIdToken()?.getJwtToken()}`,
      'Access-Control-Allow-Headers':
        'x-api-key,Content-Type,X-Amz-Date,X-Amz-Security-Token,Authorization,X-Api-Key,X-Requested-With,Accept,Access-Control-Allow-Methods,Access-Control-Allow-Origin,Access-Control-Allow-Headers',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': 'DELETE,GET,HEAD,OPTIONS,PATCH,POST,PUT',
      'X-Requested-With': '*',
      'x-api-key': apiKey
    }
  }

  const response = await axios
    .get(path, config)
    .then(response => {
      return response
    })
    .catch(e => {
      console.log(e)
    })
  // const response = await API.get(apiName, path, { headers: { 'x-api-key': apiKey }, response: true })
  // console.log(apiKey, apiName)
  // console.log(response)

  return {
    data: response.data,
    body: response.body,
    headers: response.headers,
    request: response.config,
    status: response.status
  }
}

/**
 * To get the new anomaly summary information.
 */
export const getDispatchOrpak = async ({ apiKey, apiName }) => {
  const paths = {
    antucoya: '/v2/dispatch-orpak'
  }
  const path = paths[REACT_APP_CLIENT_NAME] || '/v2/dispatch-orpak'
  const response = await API.get(apiName, path, { headers: { 'x-api-key': apiKey }, response: true, body: {} })

  return {
    data: response.data,
    headers: response.headers,
    request: response.config,
    status: response.status
  }
}

/**
 * To get dispatch and orpak data
 */
export const getDispatchOrpakGraph = async ({ apiKey, apiName, startDate, endDate }) => {
  const paths = {
    antucoya: '/v2/dispatch-orpak/graph'
  }
  const path = paths[REACT_APP_CLIENT_NAME] || '/v2/dispatch-orpak/graph'
  let newPath = ''
  if (startDate & endDate) {
    const stringDateFrom = format(startDate, 'yyyy-MM-dd')
    const stringDateTo = format(endDate, 'yyyy-MM-dd')

    newPath = `${path}?start_date=${stringDateFrom}&end_date=${stringDateTo}`
  } else {
    newPath = path
  }

  const response = await API.get(apiName, newPath, { headers: { 'x-api-key': apiKey }, response: true, body: {} })

  return {
    data: response.data,
    headers: response.headers,
    request: response.config,
    status: response.status
  }
}
