// FIXME: Need to be refactored
import React from 'react'
import PropTypes from 'prop-types'
import {
  REACT_APP_FEATURE_FLAG_FIX_STATIONS,
  REACT_APP_FEATURE_FLAG_MOBILE_STATIONS
} from '../../config/settings/environment'
import LoadingContent from '../../components/Placeholders/LoadingContent'
// import { FixStationContent } from './fixStationContent'
// import { getDataStation } from '../../services/stationsManagementServices'
// import { Grid } from '@material-ui/core'
import ResumenFixStations from './ResumenFixStations.container'
import ResumenMobileStations from './ResumenMobileStations.container'

/**
 * The ResumenStationScreen' container.
 */
const ResumenStationScreen = ({ dataMobile, dataFix, loading }) => {
  // const [loading, setLoading] = useState(true)
  // const [fixData, setFixData] = useState({})

  // useEffect(() => {
  //   getDataMobile()
  // }, [])

  // const getDataMobile = async () => {
  //   const response = await getDataStation('fija')
  //   setFixData(response)
  //   setLoading(false)
  // }

  return (
    <>
      {loading && <LoadingContent />}
      {!loading && (
        <>
          {/* <Grid container direction='row' justify='flex-end' alignItems='flex-end'>
            <p><strong>Periodo observado: </strong> {`${dataMobile.start_date} al ${dataMobile.end_date}`} </p>
          </Grid> */}
          {REACT_APP_FEATURE_FLAG_FIX_STATIONS === 'true' && (
            <ResumenFixStations total={dataFix.totals} chartsData={dataFix.pie_chart} />
          )}
          {REACT_APP_FEATURE_FLAG_MOBILE_STATIONS === 'true' && (
            <ResumenMobileStations total={dataMobile.totals} chartsData={dataMobile.pie_chart} />
          )}
        </>
      )}
    </>
  )
}

ResumenStationScreen.propTypes = {
  dataFix: PropTypes.object,
  dataMobile: PropTypes.object,
  loading: PropTypes.bool
}

export default ResumenStationScreen
