import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { addDays, compareAsc, isFuture } from 'date-fns'
import { useCheckBox } from '../../components/Inputs/Inputs.hooks'
import { usePathParam } from '../../modules/hooks/route'
import { useViewportSize } from '../../modules/hooks/viewport'
import { onSendReinforcementThunk, onUpdateFormValue } from './Reinforcement.actions'
import Button from '../../components/Buttons/Button'
import CheckBox from '../../components/Inputs/CheckBox'
import LinkButton from '../../components/Buttons/LinkButton'
import { Grid } from './Reinforcement.styles'

/**
 * The Reinforcement Footer' container.
 */
const ReinforcementFooter = props => {
  const { backButtonText, onToggleModal } = props
  const dispatch = useDispatch()
  const {
    groupReinforcement,
    instructorEmail,
    reinforcementDate,
    reinforcementFinished,
    reinforcementId,
    selectedOperators,
    sending
  } = useSelector(state => state.reinforcement)
  const type = usePathParam('type')
  const { isExtraTiny, isTiny } = useViewportSize()
  const minReinforcementDate = addDays(new Date().setHours(0, 0, 0, 0), -21)

  const { onChange: handleChange } = useCheckBox({
    initialValue: reinforcementFinished,
    changeCallback: value => dispatch(onUpdateFormValue({ name: 'reinforcementFinished', value }))
  })

  const handleSubmit = () => {
    dispatch(onSendReinforcementThunk({ callback: onToggleModal }))
  }

  const canNotSubmit = useCallback(() => {
    if (reinforcementDate && isFuture(reinforcementDate)) {
      return true
    }
    if (reinforcementDate && compareAsc(reinforcementDate, minReinforcementDate) < 0) {
      return true
    }
    if (!groupReinforcement && selectedOperators.length !== 1) {
      return true
    }
    if (groupReinforcement && selectedOperators.length <= 1) {
      return true
    }

    return instructorEmail === '' || reinforcementDate === '' || !reinforcementFinished
  }, [groupReinforcement, instructorEmail, reinforcementDate, reinforcementFinished, selectedOperators])

  const disabledSubmit = canNotSubmit()

  return (
    <Grid
      container
      alignItems="center"
      flexDirection={isExtraTiny && 'column'}
      justify={reinforcementId ? 'flex-end' : 'space-between'}
    >
      {!reinforcementId && (
        <Grid item margin={isTiny ? '0 auto 0 0' : '0 0 -22px'}>
          <CheckBox
            checked={reinforcementFinished}
            disabled={sending}
            label="Registro de reforzamiento terminado"
            onChange={handleChange}
          />
        </Grid>
      )}

      <Grid container margin={isTiny && '-10px auto 0'} spacing={1} width="auto">
        <Grid item>
          {backButtonText === undefined && (
            <Button disabled={sending} size={isTiny && 'small'} onClick={onToggleModal}>
              {reinforcementId ? 'Cerrar' : 'Cancelar'}
            </Button>
          )}
          {backButtonText !== undefined && (
            <LinkButton disabled={sending} size={isTiny && 'small'} to={`/consumo-operador/${type}`}>
              {backButtonText}
            </LinkButton>
          )}
        </Grid>
        {!reinforcementId && (
          <Grid item>
            <Button
              color="primary"
              disabled={disabledSubmit}
              loading={sending}
              size={isTiny && 'small'}
              onClick={handleSubmit}
            >
              Enviar
            </Button>
          </Grid>
        )}
      </Grid>
    </Grid>
  )
}

ReinforcementFooter.defaultProps = {
  onToggleModal: () => undefined
}
ReinforcementFooter.propTypes = {
  backButtonText: PropTypes.string,
  onToggleModal: PropTypes.func
}

export default ReinforcementFooter
