import React from 'react'
import { useSelector } from 'react-redux'
import { addMonths } from 'date-fns'
import { CONSUMPTION_OPERATOR_TYPE } from '../../config/settings/constant'
import { usePathParam } from '../../modules/hooks/route'
import { formatDate } from '../../modules/utils/formatters'
import Alert from '../../components/Basics/Alert'
import Typography from '../../components/Basics/Typography'
import { WarningIcon } from './Reinforcement.styles'

/**
 * The Reinforcement Header' container.
 */
const ReinforcementHeader = () => {
  const type = usePathParam('type').toUpperCase()
  const { loading, reinforcementDate, reinforcementId, reportPeriod } = useSelector(state => state.reinforcement)
  const currentDate = reinforcementDate !== '' ? addMonths(!reinforcementId ? new Date() : reinforcementDate, -1) : ''

  const IDLE_RPM_RECOMMENDATION = () => {
    return (
      <>
        <Typography color="dark" fontSize="12px" margin="0 0 8px" variant="body2">
          “Evite mantener el motor encendido de forma innecesaria. Esto ayuda a reducir focos de sobreconsumo de
          combustible.
        </Typography>
        <Typography color="dark" fontSize="12px" margin="0 0 8px" variant="body2">
          El tiempo durante el cual el camión permanece encendido en ralentí, genera un importante sobreconsumo de
          combustible. Esto tiene un impacto especialmente negativo cuando se trata de una situación innecesaria y que
          podría evitarse.
        </Typography>
        <Typography color="dark" fontSize="12px" margin="0 0 21px" variant="body2">
          Estas situaciones pueden ser: Detenciones por idas al baño, después de descargar, en comedor, etc. En resumen,
          serán consideradas como innecesarias todas aquellas instancias que no impliquen cola para carga, transporte,
          descarga o abastecimiento de combustible”.
        </Typography>
      </>
    )
  }

  return (
    <>
      {!loading && currentDate !== '' && reportPeriod !== formatDate(currentDate, 'yyyy-MM') && (
        <Alert
          icon={<WarningIcon src="/assets/icons/warningIcon.svg" />}
          margin="0 0 16px"
          mode="dark"
          padding="8px 12px"
          severity="warning"
          title="Atención:"
        >
          {!reinforcementId && 'Está realizando un reporte de recomendación en base a información histórica.'}
          {reinforcementId && 'Reforzamiento realizado en base a data historica.'}
        </Alert>
      )}

      {CONSUMPTION_OPERATOR_TYPE[type] === 'IDLE_RPM' && (
        <>
          <Typography fontWeight="bold" margin="0 0 10px" variant="body1">
            Recomendación
          </Typography>

          {IDLE_RPM_RECOMMENDATION()}
        </>
      )}
    </>
  )
}

export default ReinforcementHeader
