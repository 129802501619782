import { TOGGLE_NEW_ANOMALY_TOPBAR_DASHBOARD, EMPTY_ANOMALIES_DATA } from './NewAnomalyTopbar.actions'

const newAnomalyDashboard = {
  showDashboard: true,
  emptyAnomalies: false
}

/**
 * The new anomaly topbar reducer.
 */
const newAnomalyTopbarReducer = (state = newAnomalyDashboard, { payload, type }) => {
  switch (type) {
    case TOGGLE_NEW_ANOMALY_TOPBAR_DASHBOARD: {
      return { ...state, showDashboard: !state.showDashboard }
    }
    case EMPTY_ANOMALIES_DATA: {
      return { ...state, emptyAnomalies: !state.emptyAnomalies }
    }
    default: {
      return state
    }
  }
}

export default newAnomalyTopbarReducer
