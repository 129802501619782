import styled from 'styled-components'
import { Accordion } from '@material-ui/core'
import { hexToRGBA } from '../../modules/utils/color'

export const Root = styled(Accordion)`
  margin: ${props => props.margin};
  padding: ${props => props.padding};

  &.MuiPaper-root {
    border-radius: 4px;
    border: 1px solid ${props => props.theme.palette.brand.gray[85]};
    background: ${props => props.theme.palette.brand.white};
    margin-bottom: 10px;
  }

  &.MuiPaper-elevation1 {
    box-shadow: 0px 0px 5px ${props => hexToRGBA(props.theme.palette.brand.black, 0.1)};
  }

  &:before {
    background: none;
  }
`
