import React, { useCallback } from 'react'
import Modal from '../../components/Basics/Modal'
import { useSelector, useDispatch } from 'react-redux'
import routes from '../../config/settings/routes'
import { onCloseChangePasswordModal, onChangePasswordThunk } from './Authentication.actions'
import { Button, Container, Loading, LoadingIcon, LogInForm, Logo, Root, Title, Wrapper } from './Login.styles'
import Input from '../../components/Inputs/Input'
import Typography from '../../components/Basics/Typography'
import { useInput } from '../../components/Inputs/Inputs.hooks'
import { screenResolution } from '../../store/actions/screenActions'
import { checkErrorRequired } from '../../modules/errors/validations'
import { REACT_APP_BASE_URL } from '../../config/settings/environment'
import { getFromCookie } from '../../modules/storages/cookies'

/**
 * The change password container
 */

const ChangePasswordModal = () => {
  const dispatch = useDispatch()
  const { loading, error, showChangePasswordModal } = useSelector(state => state.auth)
  const handleClose = () => dispatch(onCloseChangePasswordModal())
  const redirectPath = getFromCookie('redirect_path') || routes.root

  const { error: usernameError, value: usernameValue, onChange: handleUsernameChange } = useInput({
    initialValue: '',
    errorCallbacks: [checkErrorRequired()]
  })
  const { error: passwordError, value: passwordValue, onChange: handlePasswordChange } = useInput({
    initialValue: '',
    errorCallbacks: [checkErrorRequired()]
  })
  const { error: newPasswordError, value: newPasswordValue, onChange: handleNewPasswordChange } = useInput({
    initialValue: '',
    errorCallbacks: [checkErrorRequired()]
  })

  const handleChangePassword = event => {
    event.preventDefault()
    screenResolution(window.screen.width)
    dispatch(
      onChangePasswordThunk({
        username: usernameValue,
        password: passwordValue,
        newPassword: newPasswordValue,
        onSuccessCallback: () => window.open(`${REACT_APP_BASE_URL}${redirectPath}`, '_top')
      })
    )
  }

  const canNotSubmit = useCallback(() => {
    return passwordError !== '' || newPasswordError !== '' || usernameError !== ''
  }, [passwordError, newPasswordError, usernameError])
  const disabledSubmit = canNotSubmit()

  return (
    <Modal open={showChangePasswordModal} onClose={handleClose} maxWidth="25%" padding="0px">
      <Root>
        <Container maxWidth="md">
          <Wrapper container direction="column" justify="center" alignItems="center" style={{ minHeight: '50vh' }}>
            <Logo src="/assets/images/cosmos-h.svg" alt="Universal Intelligence" />
            <LogInForm onSubmit={handleChangePassword} autoComplete="off">
              <Title>Por favor crear nueva contraseña</Title>
              <Input
                error={usernameError}
                margin="0 0 4px"
                placeholder="Usuario"
                reverse
                value={usernameValue}
                onChange={handleUsernameChange}
              />
              <Input
                error={passwordError}
                margin="0 0 16px"
                placeholder="Contraseña temporal"
                reverse
                type="password"
                value={passwordValue}
                onChange={handlePasswordChange}
              />

              <Typography align="center" color="white" padding="0 0 18px" variant="body2">
                Nueva contraseña debe contener 8 caracteres mínimo
              </Typography>

              <Input
                error={newPasswordError}
                margin="0 0 16px"
                placeholder="Nueva contraseña"
                reverse
                type="password"
                value={newPasswordValue}
                onChange={handleNewPasswordChange}
              />

              {!loading && error && error.reason === 'INCORRECT_USERNAME_OR_PASSWORD_ERROR' && (
                <Typography align="center" color="white" padding="0 0 18px" variant="body2">
                  Nombre de usuario o contraseña incorrectos
                </Typography>
              )}

              {!loading && error && error.reason === 'PASSWORD_DOES_NOT_CONFORM_TO_POLICY' && (
                <Typography align="center" color="white" padding="0 0 18px" variant="body2">
                  Contraseña nueva debe contener minimo 8 caracteres
                </Typography>
              )}

              {!loading && error && error.reason === 'SOMETHING_WENT_WRONG_ERROR' && (
                <Typography align="center" color="white" padding="0 0 18px" variant="body2">
                  Lo sentimos, estamos presentando problemas con nuestro servidor
                </Typography>
              )}

              <Button color="primary" disabled={disabledSubmit} type="submit" variant="contained">
                {loading && (
                  <Loading>
                    <LoadingIcon size={22} />
                  </Loading>
                )}
                {!loading && 'INGRESAR'}
              </Button>
            </LogInForm>
          </Wrapper>
        </Container>
      </Root>
    </Modal>
  )
}

export default ChangePasswordModal
