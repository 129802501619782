import styled from 'styled-components'

export const Wrapper = styled.div`
  align-items: center;
  justify-content: space-around;
  display: flex;
  margin: ${props => props.margin};
  height: 55vh;
  width: 100%;
`

export const Graphdiv = styled.div`
  height: 100%;
  width: 50vw;
`
export const TableWrapper = styled.div`
  display: flex;
  padding: 10px;
  width: 100%;
`

export const Tablediv = styled.div`
  height: 100%;
  width: 100%;
  display: inline-flex;
  overflow: auto;
`

export const GraphWrapper = styled.div`
  align-items: flex-start;
  display: flex;
  margin-top: 20px;
  justify-content: center;
  margin: ${props => props.margin};
  height: 40vw;
  > * {
    margin-left: 10px;
  }
`

export const ControlGraphdiv = styled.div`
  height: 100%;
  width: 80%;
`
