import styled from 'styled-components'

export const Root = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  float: left;
  width: 100%;

  & .MuiTabs-root {
    margin-bottom: 10px;
  }
`

export const GraphRoot = styled.div`
  height: 60vh;
  width: 100%;
`

export const Wrapper = styled.div`
  align-items: baseline;
  display: flex;
`

export const Dates = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
`
export const WrapperGraph = styled.div`
  align-items: center;
  justify-content: space-around;
  display: flex;
  margin: ${props => props.margin};
  height: 55vh;
  width: 100%;
`
