import React from 'react'
import PropTypes from 'prop-types'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'
import { Root } from './AccordionSummary.styles'

/**
 * The AccordionSummary' component.
 */
const AccordionSummary = props => {
  const { accessibility, alignItems, children, flexWrap, id, margin, padding } = props

  return (
    <Root
      alignItems={alignItems}
      aria-controls={accessibility.controls}
      expandIcon={<ArrowForwardIosIcon />}
      flexWrap={flexWrap}
      id={id}
      margin={margin}
      padding={padding}
    >
      {children}
    </Root>
  )
}

AccordionSummary.defaultProps = {
  accessibility: {
    controls: 'panel-content'
  },
  alignItems: 'center',
  flexWrap: 'initial'
}
AccordionSummary.propTypes = {
  accessibility: PropTypes.shape({
    controls: PropTypes.string
  }),
  alignItems: PropTypes.oneOf(['flex-start', 'center', 'flex-end', 'stretch', 'baseline']),
  children: PropTypes.node.isRequired,
  flexWrap: PropTypes.oneOf(['inherit', 'initial', 'nowrap', 'wrap']),
  id: PropTypes.string.isRequired,
  margin: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  padding: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
}

export default AccordionSummary
