import { makeActionCreator } from '../../config/store/utils'
import { batch } from 'react-redux'
import { getQuicksightEmbedUrl } from '../../services/quicksightServices'

export const GET_EMBED_URL = 'GET_EMBED_URL'
export const GET_EMBED_URL_ERROR = 'GET_EMBED_URL_ERROR'
export const GET_EMBED_URL_SUCCESS = 'GET_EMBED_URL_SUCCESS'
export const onGetEmbedUrl = makeActionCreator(GET_EMBED_URL)
export const onGetEmbedUrlError = makeActionCreator(GET_EMBED_URL_ERROR, 'payload')
export const onGetEmbedUrlSuccess = makeActionCreator(GET_EMBED_URL_SUCCESS, 'payload')
export const onGetEmbedUrlSuccessThunk = () => async (dispatch, getState) => {
  dispatch(onGetEmbedUrl())

  try {
    const {
      apis: {
        base: { apiKey, apiName }
      }
    } = getState().auth

    const { data } = await getQuicksightEmbedUrl({
      apiKey,
      apiName
    })

    const url = data.EmbedUrl

    return batch(() => {
      dispatch(
        onGetEmbedUrlSuccess({
          url
        })
      )
    })
  } catch (error) {
    return dispatch(onGetEmbedUrlError({ error }))
  }
}
