import { makeStyles } from '@material-ui/styles'
import colors from './colors'

const useStyles = makeStyles(theme => ({
  tooltipFontLight: {
    fontWeight: 'light',
    fontSize: '0.8rem',
    marginTop: '10px'
  },
  tooltipFontMedium: {
    fontWeight: 'bold',
    fontSize: '0.8rem',
    marginTop: '5px'
  },
  disabled: {
    color: colors.hoverBlack
  },
  header: {
    fontWeight: 'bold',
    fontSize: '1.2rem'
  },
  headerDisabled: {
    fontWeight: 'bold',
    fontSize: '1.2rem',
    color: colors.hoverBlack
  },
  label: {
    fontSize: '0.8rem',
    color: colors.hoverBlack
  },
  labelOptima: {
    fontSize: '0.8rem',
    color: colors.hoverBlack,
    fontWeight: 'bold'
  },
  optimize: {
    backgroundColor: colors.hoverBlue
  },
  normal: {
    backgroundColor: 'transparent'
  },
  legend: {
    fontSize: '0.7rem',
    color: colors.hoverBlack,
    marginRight: 8
  },
  statusDisconnected: {
    color: colors.red_critical
  },
  statusConnected: {
    color: colors.green_full
  },
  serviceBadge: {
    color: colors.white,
    fontSize: '0.7rem',
    fontWeight: 'bold',
    marginLeft: 3,
    marginRight: 5
  }
}))
export default useStyles
