import { API } from 'aws-amplify'
import { REACT_APP_BASE_KEY, REACT_APP_BASE_NAME, REACT_APP_CLIENT_NAME } from '../config/settings/environment'

/**
 * To get fuel level graphs embed url.
 */
export const getFuelLevelGraphs = async ({ apiKey, apiName }) => {
  const paths = {
    amsa: '/v2/fuel-level-graph',
    anglo: '/v2/fuel-level-graph',
    antucoya: '/v2/fuel-level-graph'
  }
  const path = paths[REACT_APP_CLIENT_NAME] || '/v2/fuel-level-graph'

  const init = {
    headers: {
      'x-api-key': REACT_APP_BASE_KEY
    },
    response: true
  }

  const response = await API.get(REACT_APP_BASE_NAME, path, init)

  return {
    data: response.data,
    headers: response.headers,
    request: response.config,
    status: response.status
  }
}

/**
 * To get fuel level graphs embed url.
 */
export const getGerenciaGraphs = async ({ apiKey, apiName, date }) => {
  const paths = {
    amsa: '/v2/gerencia',
    anglo: '/v2/gerencia',
    antucoya: '/v2/gerencia'
  }
  let path = paths[REACT_APP_CLIENT_NAME] || '/v2/gerencia'

  if (date) {
    const year = date.getFullYear()
    const month = date.getMonth() + 1
    const day = date.getDate()
    path = `${path}?year=${year}&month=${month}&day=${day}`
  }

  const init = {
    headers: {
      'x-api-key': REACT_APP_BASE_KEY
    },
    response: true
  }

  const response = await API.get(REACT_APP_BASE_NAME, path, init)

  return {
    data: response.data,
    headers: response.headers,
    request: response.config,
    status: response.status
  }
}
