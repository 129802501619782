import { API } from 'aws-amplify'
import { REACT_APP_BASE_KEY, REACT_APP_BASE_NAME, REACT_APP_CLIENT_NAME } from '../config/settings/environment'
import axios from 'axios'

/**
 * To get kepler data.
 */
export const getKeplerData = async ({ apiKey, apiName, datePicker }) => {
  const paths = {
    amsa: '/v2/kepler-gps',
    anglo: '/v2/kepler-gps',
    antucoya: '/v2/kepler-gps'
  }
  const path = paths[REACT_APP_CLIENT_NAME] || '/v2/kepler-gps'

  var stringDate = ''
  if (datePicker) {
    const dateFromPicker = datePicker.dateValue
    stringDate = dateFromPicker.getFullYear() + '-' + (dateFromPicker.getMonth() + 1) + '-' + dateFromPicker.getDate()
    console.log(stringDate)
  } else {
    const date = new Date()
    date.setDate(date.getDate() - 1)
    stringDate = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate()
  }

  const newPath = `${path}?date=${stringDate}`

  const init = {
    headers: {
      'x-api-key': REACT_APP_BASE_KEY
    },
    response: true
  }
  const response = await API.get(REACT_APP_BASE_NAME, newPath, init)

  return {
    url: response.data,
    headers: response.headers,
    request: response.config,
    status: response.status
  }
}

export const getKeplerHistoricalData = async ({ apiKey, apiName, datePicker }) => {
  const paths = {
    amsa: '/v2/kepler-gps-historic',
    anglo: '/v2/kepler-gps-historic',
    antucoya: '/v2/kepler-gps-historic'
  }
  const path = paths[REACT_APP_CLIENT_NAME] || '/v2/kepler-gps-historic'

  const init = {
    headers: {
      'x-api-key': REACT_APP_BASE_KEY
    },
    response: true
  }
  const response = await API.get(REACT_APP_BASE_NAME, path, init)

  return {
    url: response.data,
    headers: response.headers,
    request: response.config,
    status: response.status
  }
}

export const getKeplerHardBraking = async ({ apiKey, apiName }) => {
  const paths = {
    amsa: '/v2/kepler-hard-brake',
    anglo: '/v2/kepler-hard-brake',
    antucoya: '/v2/kepler-hard-brake'
  }
  const path = paths[REACT_APP_CLIENT_NAME] || '/v2/kepler-hard-brake'

  const init = {
    headers: {
      'x-api-key': REACT_APP_BASE_KEY
    },
    response: true
  }

  const response = await API.get(REACT_APP_BASE_NAME, path, init)

  return {
    url: response.data,
    headers: response.headers,
    request: response.config,
    status: response.status
  }
}

/**
 * To get kepler data.
 */
export const getKeplerUrl = async ({ apiKey, apiName, url }) => {
  const config = {
    headers: {
      'Access-Control-Allow-Headers':
        'x-api-key,Content-Type,X-Amz-Date,X-Amz-Security-Token,Authorization,X-Api-Key,X-Requested-With,Accept,Access-Control-Allow-Methods,Access-Control-Allow-Origin,Access-Control-Allow-Headers',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': 'DELETE,GET,HEAD,OPTIONS,PATCH,POST,PUT',
      'X-Requested-With': '*'
    }
  }

  const response = await axios
    .get(url, config)
    .then(response => {
      return response
    })
    .catch(e => {
      console.log(e)
    })

  return {
    data: response.data,
    headers: response.headers,
    request: response.config,
    status: response.status
  }
}
