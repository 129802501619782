import React, { useCallback } from 'react'
import Modal from '../../components/Basics/Modal'
import { useSelector, useDispatch } from 'react-redux'
import { onForgotPasswordModal, onForgotPasswordThunk } from './Authentication.actions'
import Input from '../../components/Inputs/Input'
import Typography from '../../components/Basics/Typography'
import { useInput } from '../../components/Inputs/Inputs.hooks'
import { checkErrorRequired } from '../../modules/errors/validations'
import { Container, Loading, LoadingIcon, Button, Root, Title, Wrapper } from './Login.styles'

/**
 * The change password container
 */

const ForgotPasswordModal = () => {
  const dispatch = useDispatch()
  const { loading, error, showForgotPasswordModal, resetMessage } = useSelector(state => state.auth)
  const handleClose = () => dispatch(onForgotPasswordModal())

  const { error: usernameError, value: usernameValue, onChange: handleUsernameChange } = useInput({
    initialValue: '',
    errorCallbacks: [checkErrorRequired()]
  })

  const canNotSubmit = useCallback(() => {
    return usernameError !== ''
  }, [usernameError])
  const disabledSubmit = canNotSubmit()

  const submitEmail = () => {
    return dispatch(onForgotPasswordThunk(usernameValue))
  }
  return (
    <Modal open={showForgotPasswordModal} onClose={handleClose} maxWidth="25%" padding="0px">
      <Root>
        <Container maxWidth="md">
          <Wrapper container direction="column" justify="center" alignItems="center" style={{ minHeight: '50vh' }}>
            <Title>Por favor escriba su correo</Title>
            <Input
              error={usernameError}
              margin="0 0 4px"
              placeholder="Usuario"
              reverse
              value={usernameValue}
              onChange={handleUsernameChange}
            />

            {!loading && error && error.reason === 'INCORRECT_USERNAME_OR_PASSWORD_ERROR' && (
              <Typography align="center" color="white" padding="0 0 18px" variant="body2">
                Nombre de usuario no encontrado
              </Typography>
            )}

            {!loading && error && error.reason === 'USERNAME_CANNOT_BE_EMPTY' && (
              <Typography align="center" color="white" padding="0 0 18px" variant="body2">
                Por favor ingrese su nombre de usuario
              </Typography>
            )}

            {!loading && error && error.reason === 'LIMIT_EXCEEDED_EXCEPTION' && (
              <Typography align="center" color="white" padding="0 0 18px" variant="body2">
                Limite excedido, por favor inténtelo de nuevo más tarde
              </Typography>
            )}

            {!loading && error && error.reason === 'SOMETHING_WENT_WRONG_ERROR' && (
              <Typography align="center" color="white" padding="0 0 18px" variant="body2">
                Lo sentimos, estamos presentando problemas con nuestro servidor
              </Typography>
            )}

            {!loading && !error && (
              <Typography align="center" color="white" padding="0 0 18px" variant="body2">
                {resetMessage}
              </Typography>
            )}

            <Button color="primary" disabled={disabledSubmit} onClick={submitEmail} type="submit" variant="contained">
              {loading && (
                <Loading>
                  <LoadingIcon size={22} />
                </Loading>
              )}
              {!loading && 'INGRESAR'}
            </Button>
          </Wrapper>
        </Container>
      </Root>
    </Modal>
  )
}

export default ForgotPasswordModal
