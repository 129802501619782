import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { REACT_APP_BASE_URL } from '../../config/settings/environment'
import routes from '../../config/settings/routes'
import { getFromCookie } from '../../modules/storages/cookies'
import { checkErrorRequired } from '../../modules/errors/validations'
import { useInput } from '../../components/Inputs/Inputs.hooks'
import { screenResolution } from '../../store/actions/screenActions'
import { onLogInThunk, onForgotPasswordModal } from './Authentication.actions'
import Input from '../../components/Inputs/Input'
import Typography from '../../components/Basics/Typography'
import { Container, Loading, LoadingIcon, LogInForm, Logo, Root, Title, Wrapper } from './Login.styles'
import Button from '../../components/Buttons/Button'
import ChangePasswordModal from './ChangePasswordModal.container'
import ForgotPasswordModal from './ResetPassword.container'

/**
 * The Login' container.
 */
const Login = () => {
  const dispatch = useDispatch()
  const { error, loading, showChangePasswordModal, showForgotPasswordModal } = useSelector(state => state.auth)
  const redirectPath = getFromCookie('redirect_path') || routes.root

  const { error: usernameError, value: usernameValue, onChange: handleUsernameChange } = useInput({
    initialValue: '',
    errorCallbacks: [checkErrorRequired()]
  })
  const { error: passwordError, value: passwordValue, onChange: handlePasswordChange } = useInput({
    initialValue: '',
    errorCallbacks: [checkErrorRequired()]
  })

  const handleLogIn = event => {
    event.preventDefault()
    screenResolution(window.screen.width)
    dispatch(
      onLogInThunk({
        username: usernameValue,
        password: passwordValue,
        onSuccessCallback: () => window.open(`${REACT_APP_BASE_URL}${redirectPath}`, '_top')
      })
    )
  }

  const canNotSubmit = useCallback(() => {
    return passwordError !== '' || usernameError !== ''
  }, [passwordError, usernameError])
  const disabledSubmit = canNotSubmit()

  return (
    <Root>
      <Container maxWidth="md">
        <Wrapper container direction="column" justify="center" alignItems="center">
          <Logo src="/assets/images/cosmos-h.svg" alt="Universal Intelligence" />
          <LogInForm onSubmit={handleLogIn} autoComplete="off">
            <Title>
              Bienvenido a Cosmos, <br />
              ingrese sus datos de acceso
            </Title>
            <Input
              error={usernameError}
              margin="0 0 4px"
              placeholder="Usuario"
              reverse
              value={usernameValue}
              onChange={handleUsernameChange}
            />
            <Input
              error={passwordError}
              margin="0 0 16px"
              placeholder="Contraseña"
              reverse
              type="password"
              value={passwordValue}
              onChange={handlePasswordChange}
            />

            {!loading && error && error.reason === 'INCORRECT_USERNAME_OR_PASSWORD_ERROR' && (
              <Typography align="center" color="white" padding="0 0 18px" variant="body2">
                Nombre de usuario o contraseña incorrectos
              </Typography>
            )}

            {!loading && error && error.reason === 'SOMETHING_WENT_WRONG_ERROR' && (
              <Typography align="center" color="white" padding="0 0 18px" variant="body2">
                Lo sentimos, estamos presentando problemas con nuestro servidor
              </Typography>
            )}

            {!loading && error && error.reason === 'POR FAVOR CREE UNA NUEVA CONTRASEÑA' && (
              <Typography align="center" color="white" padding="0 0 18px" variant="body2">
                Por favor crear una nueva contraseña
              </Typography>
            )}

            <Button color="primary" disabled={disabledSubmit} type="submit" variant="contained">
              {loading && (
                <Loading>
                  <LoadingIcon size={22} />
                </Loading>
              )}
              {!loading && 'INGRESAR'}
            </Button>
          </LogInForm>
          <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
            <Button color="primary" variant="text" onClick={() => dispatch(onForgotPasswordModal())}>
              Reestablecer contraseña
            </Button>
          </div>
        </Wrapper>
      </Container>
      {showChangePasswordModal && <ChangePasswordModal />}
      {showForgotPasswordModal && <ForgotPasswordModal />}
    </Root>
  )
}

export default Login
