import React from 'react'
import { checkErrorRequired } from '../../modules/errors/validations'
import { useAutocomplete, useSelect } from '../../components/Inputs/Inputs.hooks'
import Autocomplete from '../../components/Inputs/Autocomplete'
import Select from '../../components/Inputs/Select'
import Typography from '../../components/Basics/Typography'
import { Root } from './Examples.styles'

/**
 * The SelectExample's container.
 */
const SelectExample = () => {
  const { value: value0, onChange: handleValue0Change } = useSelect()
  const { error: error1, value: value1, onChange: handleValue1Change } = useSelect({
    errorCallbacks: [checkErrorRequired()]
  })

  const { error: error2, option, value: value2, onChange, onUpdate } = useAutocomplete({
    errorCallbacks: [checkErrorRequired()]
  })

  return (
    <Root>
      <Typography variant="body1">Algunos ejemplos de selects... Falta ejemplo de selector multiple</Typography>
      <br />
      <div style={{ width: '200px' }}>
        <Select
          label="Simple select"
          options={[
            { name: 'Name 1', value: 'value-1' },
            { name: 'Name 2', value: 'value-2' },
            { name: 'Name 3', value: 'value-3' },
            { name: 'Name 4', value: 'value-4' },
            { name: 'Name 5', value: 'value-5' },
            { name: 'Name 6', value: 'value-6' }
          ]}
          placeholder="select..."
          value={value0}
          onChange={handleValue0Change}
        />
      </div>

      <div style={{ width: '350px' }}>
        <Select
          error={error1}
          label="Select with validation"
          options={[
            { name: 'Name 1', value: 'value-1' },
            { name: 'Name 2', value: 'value-2' },
            { name: 'Name 3', value: 'value-3' },
            { name: 'Name 4', value: 'value-4' },
            { name: 'Name 5', value: 'value-5' },
            { name: 'Name 6', value: 'value-6' }
          ]}
          placeholder="select"
          required
          value={value1}
          onChange={handleValue1Change}
        />
      </div>

      <div style={{ width: '350px' }}>
        <Select label="loading" loading value={value1} onChange={handleValue1Change} />
      </div>

      <Typography variant="body1">Autocomplete</Typography>
      <br />
      <div style={{ width: '350px' }}>
        <Autocomplete
          error={error2}
          label="Autocomplete with validation"
          option={option}
          options={[
            { name: 'Chile', value: 'chile' },
            { name: 'Canada', value: 'canada' },
            { name: 'Suiza', value: 'suiza' },
            { name: 'Alemania', value: 'alemania' },
            { name: 'Francia', value: 'francia' },
            { name: 'Italia', value: 'italia' },
            { name: 'España', value: 'españa' }
          ]}
          placeholder="type..."
          required
          value={value2}
          onChange={onChange}
          onUpdate={onUpdate}
        />
      </div>
    </Root>
  )
}

export default SelectExample
