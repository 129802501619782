import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import LoadingContent from '../../components/Placeholders/LoadingContent'
import DisconnectedData from '../FuelLevel/DisconnectedData.container'
import { AgChartsReact } from 'ag-charts-react'
import { onGetOrpakDispatchGraphThunk } from './FuelManagement.actions'
import theme from '../../config/styles/theme'
import { GraphRoot } from './FuelManagementTopbar.styles'

const FuelManagementContainer = () => {
  const { orpak, thisDispatch, loading, error } = useSelector(state => state.fuelManagement)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(onGetOrpakDispatchGraphThunk())
  }, [])
  const tooltipRenderer = params => {
    return `
        <div class="ag-chart-tooltip-title" style="background-color: ${params.color}">
            ${params.datum.truck}
        </div>
        <div class="ag-chart-tooltip-content">
          Fecha: ${params.xValue.toLocaleDateString()} ${params.xValue.toLocaleTimeString()}
        </div>
        <div class="ag-chart-tooltip-content">
          Cantidad: ${params.yValue}
        </div>`
  }

  const options = {
    options: {
      autoSize: true,
      title: {
        text: 'Abastecimientos'
      },
      series: [
        {
          type: 'scatter',
          data: orpak,
          xKey: 'fecha',
          tooltip: { renderer: tooltipRenderer },
          yKey: 'quantity',
          yName: 'Registro Abastecimiento (Orpak) (L)',
          marker: {
            fill: '#03f40b',
            stroke: '#02ab27'
          }
        },
        {
          type: 'scatter',
          data: thisDispatch,
          xKey: 'fecha',
          tooltip: { renderer: tooltipRenderer },
          yKey: 'quantity',
          yName: 'Registro Abastecimiento (Jigsaw) (L)',
          marker: {
            fill: '#db0b1c',
            stroke: '#02ab27'
          }
        }
      ],
      navigator: {
        mask: {
          fill: theme.palette.brand.primary[95],
          stroke: theme.palette.brand.primary[35],
          fillOpacity: 0.7
        },
        minHandle: {
          fill: theme.palette.brand.primary[50]
        },
        maxHandle: {
          fill: theme.palette.brand.primary[50]
        },
        enabled: true
      },
      axes: [
        {
          type: 'time',
          position: 'bottom'
        },
        {
          type: 'number',
          position: 'left',
          label: {
            format: '#{.1f}'
          },
          crossLines: [
            {
              type: 'line',
              value: 3634,
              fillOpacity: 0.5,
              lineDash: [6, 12],
              stroke: theme.palette.brand.error[50]
            }
          ]
        }
      ],
      legend: {
        position: 'bottom'
      }
    }
  }

  return (
    <>
      {loading && <LoadingContent />}
      {!loading && error && <DisconnectedData />}
      {!loading && !error && (
        <>
          <GraphRoot>
            <AgChartsReact options={options.options} />
          </GraphRoot>
        </>
      )}
    </>
  )
}

export default FuelManagementContainer
