import styled from 'styled-components'
import { hexToRGBA } from '../../modules/utils/color'
import { ButtonBase, CircularProgress, Container as BaseContainer, Grid as BaseGrid } from '@material-ui/core'

export const Button = styled(ButtonBase)`
  &.MuiButtonBase-root {
    align-items: center;
    background-color: ${({ color, theme }) => theme.palette.brand[color][50]};
    border: 1px solid transparent;
    border-color: ${({ color, theme }) => theme.palette.brand[color][50]};
    border-radius: 4px;
    box-shadow: 0px 0px 2px ${props => hexToRGBA(props.theme.palette.brand.black, 0.12)},
      0px 2px 2px ${props => hexToRGBA(props.theme.palette.brand.black, 0.24)};
    color: ${props => props.theme.palette.brand.white};
    cursor: pointer;
    display: inline-flex;
    font-family: ${props => props.theme.typography.fontFamily};
    font-size: 14px;
    font-weight: ${props => props.theme.fontWeights[500]};
    height: 40px;
    justify-content: center;
    letter-spacing: 1.25px;
    line-height: 1.75;
    padding: 0.56rem 2.81rem;
    text-transform: uppercase;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
      color 0.15s ease-in-out;
    user-select: none;

    &.Mui-disabled {
      background-color: ${({ color, theme }) => theme.palette.brand[color][75]};
      border-color: ${({ color, theme }) => theme.palette.brand[color][75]};
      box-shadow: 0px 0px 2px ${props => hexToRGBA(props.theme.palette.brand.black, 0.06)},
        0px 2px 2px ${props => hexToRGBA(props.theme.palette.brand.black, 0.12)};
      cursor: not-allowed;
      pointer-events: none;
      user-select: none;
    }

    &:focus {
      box-shadow: 0 0 0 0.2rem ${({ color, theme }) => hexToRGBA(theme.palette.brand[color][50], 0.4)};
      outline: 0;
    }

    &:active {
      transform: scale(0.98);
    }

    &.MuiTouchRipple-root {
      display: none;
    }

    &:hover {
      background-color: ${props => props.theme.palette.brand.primary[35]};
      border-color: ${props => props.theme.palette.brand.primary[35]};
    }
  }
`

export const Container = styled(BaseContainer)``

export const Loading = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  width: 100%;
`

export const LoadingIcon = styled(CircularProgress)`
  &.MuiCircularProgress-root {
    color: inherit;
  }
`

export const LogInForm = styled.form`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  min-width: 310px;
  max-width: 310px;

  ${props => props.theme.breakpoints.only('xs')} {
    min-width: 85%;
    max-width: 85%;
  }
`

export const Logo = styled.img`
  ${props => props.theme.breakpoints.only('xs')} {
    max-width: 85%;
  }
`

export const Root = styled.div`
  background-color: ${props => props.theme.palette.brand.black};
`

export const Title = styled.h1`
  color: ${props => props.theme.palette.brand.white};
  font-family: ${props => props.theme.typography.fonts[2]};
  font-size: 1.6rem;
  font-weight: 200;
  line-height: 1.167;
  margin-bottom: 30px;
  margin-top: 100px;
  text-align: center;

  ${props => props.theme.breakpoints.only('xs')} {
    margin-top: 40px;
  }
`

export const Wrapper = styled(BaseGrid)`
  min-height: 100vh;
`
