import styled from 'styled-components'
import { Box as BaseBox, Grid as BaseGrid } from '@material-ui/core'

export const Box = styled(BaseBox)``

export const Grid = styled(BaseGrid)``

export const Separator = styled.hr`
  border: 1px solid;
  color: ${props => props.theme.palette.brand.gray[85]};
`

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin: 0 auto;
`

export const Wrapper = styled.div`
  border-radius: 15px;
  height: auto;
  width: 100%;

  border: 1px solid ${props => props.theme.palette.brand.gray[85]};
`
