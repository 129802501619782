import React from 'react'
import PropTypes from 'prop-types'
import { Root } from './Chip.styles'

/**
 * The Chip's component.
 */
const Chip = props => {
  const { color, disabled, label, margin, size, variant, onDelete } = props

  return (
    <Root
      color={color}
      disabled={disabled}
      clickable={false}
      label={label}
      margin={margin}
      size={size}
      variant={variant === 'contained' ? 'default' : variant}
      onDelete={onDelete}
    />
  )
}

Chip.defaultProps = {
  color: 'primary',
  disabled: false,
  size: 'medium',
  variant: 'contained'
}
Chip.propTypes = {
  color: PropTypes.oneOf(['default', 'primary']),
  disabled: PropTypes.bool,
  label: PropTypes.string.isRequired,
  margin: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  size: PropTypes.oneOf(['small', 'medium']),
  variant: PropTypes.oneOf(['contained', 'outlined']),
  onDelete: PropTypes.func
}

export default Chip
