import React, { useState } from 'react'
import { useViewportSize } from '../../modules/hooks/viewport'
import Alert from '../../components/Basics/Alert'
import Button from '../../components/Buttons/Button'
import Modal from '../../components/Basics/Modal'
import Typography from '../../components/Basics/Typography'
import { AlertAction, WarningIcon, Wrapper } from './ConsumptionOperatorAlert.styles'

/**
 * The Consumption Operator Alert' container.
 */
const ConsumptionOperatorAlert = () => {
  const { isTiny } = useViewportSize()
  const [showHelpModal, setShowHelpModal] = useState(false)

  const handleCloseHelpModal = () => setShowHelpModal(false)
  const handleShowHelpModal = () => setShowHelpModal(true)

  const asd = <AlertAction onClick={handleShowHelpModal}>MÁS</AlertAction>

  return (
    <>
      <Alert
        action={isTiny && asd}
        icon={<WarningIcon height={isTiny && '26px'} width={isTiny && '26px'} src="/assets/icons/warningIcon.svg" />}
        margin="0 0 23px"
        severity="warning"
        title="Atención:"
      >
        {!isTiny &&
          'La información del reporte a continuación está basada en data histórica, en cuanto las bases de datos actualizadas sean enviadas, podremos disponibilizar los reportes con datos correspondientes al período seleccionado.'}
        {isTiny && 'Basado en data historica.'}
      </Alert>

      {isTiny && (
        <Modal maxWidth="90%" open={showHelpModal} onClose={handleCloseHelpModal}>
          <Wrapper container alignItems="center" direction="column" margin="50px 0">
            <img src="/assets/icons/warningIcon.svg" alt="Atención" />
            <Typography fontWeight="bold" margin="15px 0" variant="body2">
              Atención:
            </Typography>
            <Typography align="center" variant="body2">
              La información del reporte a continuación está basada en data histórica, en cuanto las bases de datos
              actualizadas sean enviadas, podremos disponibilizar los reportes con datos correspondientes al período
              seleccionado.
            </Typography>
          </Wrapper>

          <Wrapper container justify="center">
            <Button color="primary" margin="27px 0 0" onClick={handleCloseHelpModal}>
              Continuar
            </Button>
          </Wrapper>
        </Modal>
      )}
    </>
  )
}

export default ConsumptionOperatorAlert
