import styled from 'styled-components'
import { Grid as BaseGrid } from '@material-ui/core'

export const AlertAction = styled.a`
  border-radius: 4px;
  color: ${props => props.theme.palette.brand.gray[15]};
  cursor: pointer;
  font-weight: ${props => props.theme.fontWeights[600]};
  padding: 6px 8px;

  :hover {
    background-color: ${props => props.theme.palette.brand.warning[50]};
    color: ${props => props.theme.palette.brand.white};
  }
`

export const WarningIcon = styled.img`
  height: ${props => props.height};
  width: ${props => props.width};
`

export const Wrapper = styled(BaseGrid)``
