import styled from 'styled-components'
import { Grid as BaseGrid } from '@material-ui/core'

export const Separator = styled.hr`
  border: 0;
  border-top: 1px solid ${props => props.theme.palette.brand.gray[85]};
  box-sizing: content-box;
  height: 0;
  margin: 24px 0;
  overflow: visible;
`

export const Wrapper = styled(BaseGrid)`
  margin: ${props => props.margin};
`
