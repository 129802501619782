import React from 'react'
import Typography from '../../components/Basics/Typography'
import { Root } from './Examples.styles'

/**
 * The TypographyExample's container.
 */
const TypographyExample = () => {
  return (
    <Root>
      <Typography variant="h1">Cosmos Fuel h1</Typography>
      <Typography variant="h2">Cosmos Fuel h2</Typography>
      <Typography variant="h3">Cosmos Fuel h3</Typography>
      <Typography variant="h4">Cosmos Fuel h4</Typography>
      <Typography variant="body1">Cosmos Fuel body1</Typography>
      <Typography variant="body2">Cosmos Fuel body2</Typography>
      <Typography variant="subtitle">Cosmos Fuel subtitle</Typography>
      <Typography variant="caption">Cosmos Fuel caption</Typography>

      <br />
      <Typography variant="body2">
        A continuación se lista el <strong>font-size / line-heigt</strong> de cada variante. Color por defecto #000000:
        <br />
        h1: 25px / 31px
        <br />
        h2: 23px / 28px
        <br />
        h3: 22px / 26px
        <br />
        h4: 18px / 22px
        <br />
        body1: 16px / 20px
        <br />
        body2: 14px / 18px
        <br />
        subtitle: 12px / 16px. Color por defecto: #828282
        <br />
        caption: 12px / 16px (este es el unico uppercase). Color por defecto: #595959
      </Typography>

      <br />
      <Typography variant="body2">
        <strong>Colores disponibles: </strong>
        <br />
        'black': #000000
        <br />
        'black2': #262626
        <br />
        'dark': #333333
        <br />
        'dark2': #4F4F4F
        <br />
        'light-dark': #595959
        <br />
        'disabled-gray': #828282
        <br />
        'gray': #BFBFBF
        <br />
        'silver': #E5E5E5
        <br />
        'primary': #FA6A09
        <br />
        'success': #2FA12B
        <br />
        'error': #AD000F
        <br />
        'warning': #F7C137
      </Typography>

      <br />
      <Typography variant="body2">
        <strong>El texto se puede alinear:</strong> 'inherit', 'left', 'center', 'right', 'justify'
      </Typography>

      <br />
      <Typography variant="body2">
        <strong>font weight: </strong>
      </Typography>
      <Typography fontWeight="black" variant="h1">
        'black': 900
      </Typography>
      <Typography fontWeight="bold" variant="h1">
        'bold': 700
      </Typography>
      <Typography fontWeight="regular" variant="h1">
        'regular': 400
      </Typography>
      <Typography fontWeight="light" variant="h1">
        'light': 300
      </Typography>

      <br />
      <Typography variant="body2">
        Otras propiedades interesantes: <strong>italic y noWrap</strong>
        <br />
        <strong>Siempre se puede modificar el font-size y line-height por defecto de la variante</strong>
      </Typography>
      <br />
      <br />
      <Typography variant="body2">Con noWrap</Typography>
      <Typography variant="body2" noWrap>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo
        lobortis eget. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet
        blandit leo lobortis eget. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus
        ex, sit amet blandit leo lobortis eget.
      </Typography>
      <br />
      <Typography variant="body2">Sin noWrap</Typography>
      <Typography variant="body2">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo
        lobortis eget. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet
        blandit leo lobortis eget. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus
        ex, sit amet blandit leo lobortis eget.
      </Typography>
    </Root>
  )
}

export default TypographyExample
