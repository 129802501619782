import React from 'react'
import { useDispatch } from 'react-redux'
import { useHistory, useRouteMatch } from 'react-router'
import { useTabs } from '../../components/Navigation/Tab.hooks'
import { useViewportSize } from '../../modules/hooks/viewport'
import { CONSUMPTION_OPERATOR_TYPE } from '../../config/settings/constant'
import {
  REACT_APP_FEATURE_FLAG_HARD_BRAKING,
  REACT_APP_FEATURE_FLAG_IDLE_RPM,
  REACT_APP_FEATURE_FLAG_RPM_DOWNLOAD
} from '../../config/settings/environment'
import routes from '../../config/settings/routes'
import Tab from '../../components/Navigation/Tab'
import Tabs from '../../components/Navigation/Tabs'
import Typography from '../../components/Basics/Typography'
import { useDatePicker } from '../../components/Inputs/Inputs.hooks'
import DatePicker from '../../components/Inputs/DatePicker'
import Button from '../../components/Buttons/Button'
import { usePathParam } from '../../modules/hooks/route'
import { onGetIdleRpmThunk } from '../IdleRpm/IdleRpm.actions'
import { onGetHardBrakingThunk } from '../HardBraking/HardBraking.actions'
import { Root, Wrapper } from './ConsumptionOperatorTopbar.styles'
import moment from 'moment'

/**
 * The consumption operator topbar' container.
 */
const ConsumptionOperatorTopbar = () => {
  const prefix = 'operator'
  const dispatch = useDispatch()
  const history = useHistory()
  const { isExtraTiny } = useViewportSize()
  const type = usePathParam('type').toUpperCase()
  const minDate = moment('2021-06-01', 'YYYY-MM-DD')
  var maxDate = new Date()
  maxDate.setMonth(maxDate.getMonth() - 1)

  const options = [
    (REACT_APP_FEATURE_FLAG_IDLE_RPM === true || REACT_APP_FEATURE_FLAG_IDLE_RPM === 'true') && {
      label: 'Ralentí Excesivo',
      current: useRouteMatch(routes.idleRpm)?.isExact || false,
      route: routes.idleRpm
    },
    (REACT_APP_FEATURE_FLAG_RPM_DOWNLOAD === true || REACT_APP_FEATURE_FLAG_RPM_DOWNLOAD === 'true') && {
      label: 'RPM Descarga',
      current: useRouteMatch(routes.rpmDownload)?.isExact || false,
      route: routes.rpmDownload
    },
    (REACT_APP_FEATURE_FLAG_HARD_BRAKING === true || REACT_APP_FEATURE_FLAG_HARD_BRAKING === 'true') && {
      label: 'Frenados Bruscos',
      current: useRouteMatch(routes.hardBraking)?.isExact || false,
      route: routes.hardBraking
    }
  ].filter(Boolean)

  const { value, onChange: handleChangeTab } = useTabs({
    initialValue: options.findIndex(tab => tab.current),
    changeCallback: newValue => history.push(options[newValue].route)
  })

  const { value: dateValue, setValue, onChange: handleDateChange } = useDatePicker({
    initialValue: maxDate
  })

  const onChange = () => {
    setValue(dateValue)
    if (dateValue) {
      if (CONSUMPTION_OPERATOR_TYPE[type] === 'IDLE_RPM') {
        dispatch(onGetIdleRpmThunk(dateValue))
      } else if (CONSUMPTION_OPERATOR_TYPE[type] === 'HARD_BRAKING') {
        dispatch(onGetHardBrakingThunk(dateValue))
      }
    }
  }

  const tabProps = index => {
    return {
      id: `${prefix}-tab-${index}`,
      'aria-controls': `${prefix}-tabpanel-${index}`,
      key: `${prefix}-tab-${index}`,
      value: index
    }
  }

  return (
    <Root>
      <Tabs
        aria-label="Operator tabs"
        scrollButtons={isExtraTiny && 'on'}
        value={value}
        variant="scrollable"
        onChange={handleChangeTab}
      >
        {options.map((option, index) => {
          return <Tab label={option.label} key={`${prefix}-tab-${index}`} {...tabProps(index)} />
        })}
      </Tabs>
      <Wrapper>
        <Typography fontWeight="bold" padding="0 20px 0 0" variant="body2">
          Seleccione un periodo:
        </Typography>
        <DatePicker
          views={['year', 'month']}
          format="MMMM yyyy"
          disableToolbar
          value={dateValue}
          minDate={minDate}
          maxDate={maxDate}
          variant="inline"
          width="40%"
          onChange={handleDateChange}
          margin="0px 20px"
        />
        <Button color="primary" onClick={onChange} size="small">
          Go
        </Button>
      </Wrapper>
    </Root>
  )
}

export default ConsumptionOperatorTopbar
