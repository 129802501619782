import React from 'react'
import { useViewportSize } from '../../modules/hooks/viewport'
import Typography from '../../components/Basics/Typography'
import { Grid, Root } from './ContactContent.styles'

/**
 * The Contact Content' container.
 */
const ContactContent = () => {
  const { isTiny } = useViewportSize()

  return (
    <>
      <Root>
        <Grid
          container
          alignItems="center"
          direction="row"
          height={isTiny ? 'auto' : '80vh'}
          justify="space-evenly"
          padding={isTiny ? '40px 0' : '0'}
        >
          {/* DATA CONTACT */}
          <Grid item xs={10} md={3}>
            <Grid container alignItems="center" direction="row" justify="center">
              <Grid item xs={10}>
                <Typography color="white" margin="1em 5px 50px" variant="body2">
                  Si tienes dudas, necesitas asistencia técnica o quieres darnos tu opinión, contáctanos.
                </Typography>
              </Grid>
            </Grid>
            <Grid container alignItems="center" direction="row" justify="flex-start">
              <Grid item xs={3} textAlign="center">
                <img src="/assets/icons/mail-icon.svg" alt="Email" />
              </Grid>
              <Grid item xs={9}>
                <Typography color="white" margin="0 5px 5px" variant="body2">
                  EMAIL
                </Typography>
                <Typography color="white" fontWeight="bold" margin="0 2px 2px" variant="body2">
                  antucoya@cosmosenergy.ai
                </Typography>
              </Grid>
            </Grid>
            <Grid container alignItems="center" direction="row" justify="flex-start" margin="30px 0 0">
              <Grid item xs={3} textAlign="center">
                <img src="/assets/icons/phone-icon.svg" alt="Teléfono" />
              </Grid>
              <Grid item xs={9}>
                <Typography color="white" margin="0 5px 5px" variant="body2">
                  TELÉFONO
                </Typography>
                <Typography color="white" fontWeight="bold" margin="0 2px 2px" variant="body2">
                  +56 9 9830 8365
                </Typography>
                <Typography color="white" fontSize="12px" margin="0 2px 2px" variant="body2">
                  Lunes a Viernes: 08:00 a 18:00 hrs
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Root>
    </>
  )
}

export default ContactContent
