import { GET_PREDICTIONS_DATA, GET_PREDICTIONS_DATA_ERROR, GET_PREDICTIONS_DATA_SUCCESS } from './Predictions.actions'

const predictionsState = {
  loading: false,
  error: false,
  predictions: [],
  orpak: [],
  dispatch: [],
  distance: [],
  truck: ''
}

const predictionsReducer = (state = predictionsState, { payload, type }) => {
  switch (type) {
    case GET_PREDICTIONS_DATA: {
      return { ...state, loading: true, error: false }
    }
    case GET_PREDICTIONS_DATA_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        predictions: payload.predictionSerie,
        orpak: payload.orpakSerie,
        dispatch: payload.dispatchSerie,
        distance: payload.distanceSerie,
        truck: payload.truck
      }
    }
    case GET_PREDICTIONS_DATA_ERROR: {
      return { ...state, loading: false, error: true }
    }
    default: {
      return state
    }
  }
}

export default predictionsReducer
