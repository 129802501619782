// FIXME: Need to be refactored
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { REACT_APP_CLIENT_NAME } from '../../config/settings/environment'
import colors from '../../styles/colors'
// eslint-disable-next-line
import { default as truckSymbololgy } from '../../constants/icons/truckSymbololgy.svg'
// eslint-disable-next-line
import { default as gasSymbology } from '../../constants/icons/gasSymbology.svg'
// eslint-disable-next-line
import { default as exclamacionSymbology } from '../../constants/icons/exclamacionSymbology.svg'
import { onFetchDataTankersThunk } from './FuelLevel.actions'
import DisconnectedData from './DisconnectedData.container'
import LoadingContent from '../../components/Placeholders/LoadingContent'
import ServerStatus from '../ServerStatus/ServerStatus.container'
import TankerVehicle from '../../components/TankerVehicle'
import TruckVehicle from '../../components/TruckVehicle'
import TruckVehicleZaldivar from '../../components/TruckVehicleZaldivar'
import {
  Typography,
  TextField,
  Grid,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  Paper,
  Box
} from '@material-ui/core'
import Alert from '../../components/Basics/Alert'

/**
 * The FuelLevelContent's container.
 */
const FuelLevelContent = props => {
  const { title, fullScreen } = props
  const dispatch = useDispatch()
  const { error, loading, trucks } = useSelector(state => state.fuelLevel)
  const { levelsMean, weeklyTank } = useSelector(state => state.countdown)
  const [searchTruck, setSearchTruck] = useState('')
  const [selectedTrucks, setSelectedTrucks] = useState([])
  const rows = [
    {
      id: 1,
      bottom: 0,
      top: 20,
      color: colors.red_critical,
      summary: 'Riesgo de panne',
      minValue: 0,
      maxValue: 909,
      backgroundColor: 'rgba(173,0,15,0.12)',
      fontWeight: 'normal',
      fontSize: '14px'
    },
    {
      id: 2,
      bottom: 20,
      top: 30,
      color: colors.blue_good,
      summary: 'Abastecimiento óptimo',
      minValue: 909,
      maxValue: 1363,
      backgroundColor: 'rgba(52,109,255,0.05)',
      fontWeight: 'bold',
      fontSize: '14px'
    },
    {
      id: 3,
      bottom: 30,
      top: 50,
      color: colors.background_dark,
      summary: 'Pérdida de eficiencia',
      minValue: 1363,
      maxValue: 2271,
      backgroundColor: 'rgba(255,255,255,1)',
      fontWeight: 'normal',
      fontSize: '14px'
    },
    // {
    //   id: 4,
    //   bottom: 40,
    //   top: 50,
    //   color: colors.blue_good,
    //   summary: 'Abastecimiento óptimo',
    //   minValue: 1182,
    //   maxValue: 1499,
    //   backgroundColor: 'rgba(52,109,255,0.2)',
    //   fontWeight: 'bold',
    //   fontSize: '12px'
    // },
    {
      id: 4,
      bottom: 50,
      top: 100,
      color: colors.background_dark,
      summary: 'Abastecimiento ineficiente',
      minValue: 2271,
      maxValue: 4542,
      backgroundColor: 'rgba(255,255,255,1)',
      fontWeight: 'normal',
      fontSize: '14px'
    }
    // {
    // id: 6,
    // bottom: 50,
    // top: 100,
    // color: colors.background_dark,
    // summary: 'Abastecimiento ineficiente',
    // minValue: 2272,
    // maxValue: 4542,
    // backgroundColor: 'rgba(255,255,255,1)',
    // fontWeight: 'normal',
    // fontSize: '14px'
    // }
  ]

  // When page load or must be reload
  useEffect(() => {
    switch (title) {
      case 'CAEX':
        // dispatch(onFetchDataTrucksThunk())
        break
      case 'aljibes':
        dispatch(onFetchDataTankersThunk())
        break
      default:
        break
    }
  }, [title])

  // After fetch data or when must execute a reload data, we must updated the shown trucks
  useEffect(() => {
    if (setSearchTruck === '') {
      setSelectedTrucks(trucks)
    } else {
      const filteredTrucks = trucks.filter(truck => truck.patent.includes(searchTruck))
      setSelectedTrucks(filteredTrucks)
    }
  }, [trucks])

  // To filter the trucks shown
  const filterBySearch = event => {
    const value = event.target.value.toUpperCase()
    setSearchTruck(value)
    if (event.target.value.length === 0) {
      setSelectedTrucks(trucks)
    } else {
      const filteredTrucks = trucks.filter(truck => truck.patent.includes(value))
      setSelectedTrucks(filteredTrucks)
    }
  }

  const renderRow = scale => {
    const scaleTrucks = selectedTrucks.filter(truck => {
      return scale.bottom < truck.percent && truck.percent <= scale.top
    })

    return (
      <TableRow
        key={scale.id}
        style={{
          marginLeft: '50px',
          marginRight: '50px',
          background: title === 'CAEX' ? `${scale.backgroundColor}` : 'white'
        }}
      >
        <TableCell style={{ width: '15%', paddingLeft: '20px' }}>
          <Typography
            style={{
              color: title === 'CAEX' ? `${scale.color}` : 'black',
              fontWeight: title === 'CAEX' ? `${scale.fontWeight}` : 'normal'
            }}
          >
            {`${scale.bottom}% a ${scale.top}%`}
          </Typography>
          {scaleTrucks.length !== 0 && title === 'CAEX' && (
            <>
              <Typography style={{ fontSize: '12px', color: '#828282', fontWeight: scale.fontWeight }}>
                {scale.summary}
              </Typography>
            </>
          )}
        </TableCell>
        {title === 'CAEX' && (
          <TableCell
            align="center"
            style={{
              width: '85%',
              paddingRight: '50px',
              paddingTop: scaleTrucks.length !== 0 ? '3%' : '6px',
              paddingBottom: scaleTrucks.length !== 0 ? '3%' : '6px'
            }}
          >
            <Grid container justify="center">
              {scaleTrucks.map(truck => {
                if (REACT_APP_CLIENT_NAME === 'zaldivar') {
                  return <TruckVehicleZaldivar key={truck.patent} truckData={truck} color={scale.color} />
                }
                return <TruckVehicle key={truck.patent} truckData={truck} color={scale.color} />
              })}
            </Grid>
          </TableCell>
        )}
        {title === 'aljibes' && (
          <TableCell align="center" style={{ width: '85%', paddingRight: '50px' }}>
            <Grid container justify="center">
              {scaleTrucks.map(tanker => {
                return <TankerVehicle key={tanker.patent} tankerData={tanker} color="#828282" />
              })}
            </Grid>
          </TableCell>
        )}
      </TableRow>
    )
  }
  const alertColor = levelsMean > 30 ? 'error' : levelsMean < 20 ? 'error' : 'success' || 'success'
  const alertColorWeek = weeklyTank > 30 ? 'error' : weeklyTank < 20 ? 'error' : 'success' || 'success'

  const symbology = () => {
    return (
      <>
        <hr />
        <Grid
          container
          direction="column"
          justify="flex-end"
          alignItems="flex-end"
          style={{ marginTop: '20px', paddingRight: '20px' }}
        >
          <Grid item>
            <p
              style={{
                textAlign: 'right',
                fontSize: '11px',
                display: 'flex',
                alignItems: 'center'
              }}
            >
              %: nivel de combustible remanente
            </p>
          </Grid>
          <Grid item>
            <p
              style={{
                textAlign: 'right',
                fontSize: '11px',
                display: 'flex',
                alignItems: 'center'
              }}
            >
              km: kilómetros desde último abastecimiento. Naranjo > 240 km - Rojo > 250 km
            </p>
          </Grid>
          <Grid item>
            <p
              style={{
                marginBottom: '5px',
                textAlign: 'right',
                fontSize: '11px',
                display: 'flex',
                alignItems: 'center'
              }}
            >
              h: tiempo encendido desde último abastecimiento. Naranjo > 19 h - Rojo > 20h
            </p>
          </Grid>
        </Grid>
      </>
    )
  }

  return (
    <>
      {loading && <LoadingContent />}
      {!loading && error && <DisconnectedData />}
      {!loading && !error && (
        <>
          <Box style={{ minWidth: '100%' }}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Alert margin="5px 0 2% 0" severity={alertColor} align="center">
                  <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                    Nivel promedio de combustible remanente al abastecer (turno en curso): {levelsMean} %
                  </Typography>
                </Alert>
              </Grid>
              <Grid item xs={6}>
                <Alert margin="5px 0 2% 0" severity={alertColorWeek} align="center">
                  <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                    Nivel promedio de combustible remanente al abastecer (semana en curso): {weeklyTank} %
                  </Typography>
                </Alert>
              </Grid>
            </Grid>
            <Grid container direction="row" justify="space-evenly" alignItems="center">
              {REACT_APP_CLIENT_NAME === 'amsa' && (
                <Grid xs={12}>
                  <Paper
                    elevation={3}
                    style={{
                      background: '#FA6A09',
                      color: 'white',
                      textAlign: 'center',
                      paddingTop: '10px',
                      paddingBottom: '10px',
                      marginBottom: '10px'
                    }}
                  >
                    <p style={{ fontSize: '18px', fontWeight: 'bold', marginBottom: '0px' }}>
                      Esta interfaz es solo para fines de validaciones de Experiencia de Usuario.
                    </p>
                    <p style={{ fontSize: '14px', marginTop: '3px' }}>
                      Estará online nuevamente en cuanto dispongamos de las nuevas fuentes de datos y sus respectivas
                      conexiones (APIs) implementadas por AMSA.
                    </p>
                  </Paper>
                </Grid>
              )}
              {REACT_APP_CLIENT_NAME === 'anglo' && (
                <Grid xs={12}>
                  <Paper
                    elevation={3}
                    style={{
                      background: '#FA6A09',
                      color: 'white',
                      textAlign: 'center',
                      paddingTop: '10px',
                      paddingBottom: '10px',
                      marginBottom: '10px'
                    }}
                  >
                    <p style={{ fontSize: '18px', fontWeight: 'bold', marginBottom: '0px' }}>Atención:</p>
                    <p style={{ fontSize: '14px', marginTop: '3px', marginRight: '3px', marginLeft: '3px' }}>
                      Una nueva versión de COSMOS está disponible desde el lunes 9 de agosto con mejoras en el
                      procesamiento de los datos de entrada de GPS que permitirán una mejor ubicación de los camiones y
                      de sus consumos respectivos. Estas mejoras van a implementarse progresivamente en los siguientes
                      días al mismo tiempo que COSMOS procesa estos nuevos datos. Le recordamos vigilar la calidad de la
                      conexión de datos (semáforo ubicado arriba a la derecha). Por cualquier duda, quedamos a su
                      disposición: contacto@cosmosfuel.ai
                    </p>
                  </Paper>
                </Grid>
              )}
              <Grid item xs={12} md={5}>
                <Typography style={{ fontWeight: '600', fontSize: '20', paddingLeft: '20px' }}>
                  {`Combustible en estanque - ${title}`}
                </Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                {fullScreen && <ServerStatus />}
              </Grid>
              <Grid item xs={12} md={2} style={{ textAlign: 'right' }}>
                <TextField
                  id="outlined-secondary"
                  label={`Buscar ${title}`}
                  variant="outlined"
                  size="small"
                  onChange={filterBySearch}
                  value={searchTruck}
                />
              </Grid>
              {title === 'CAEX'}
            </Grid>
            <hr />
            <TableContainer style={{ overflowY: 'visible' }}>
              <Table size="small">
                <TableBody>
                  {rows.map(linea => {
                    return renderRow(linea)
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            {title === 'CAEX' && symbology()}
          </Box>
        </>
      )}
    </>
  )
}

FuelLevelContent.propTypes = {
  fullScreen: PropTypes.bool,
  title: PropTypes.string
}

export default FuelLevelContent
