import React from 'react'
import styled, { css } from 'styled-components'
import { hexToRGBA } from '../../modules/utils/color'
import { IconButton as BaseIconButton } from '@material-ui/core'

export const Wrapper = styled.div`
  align-items: center;
  justify-content: space-around;
  display: flex;
  margin: ${props => props.margin};
  height: 55vh;
  width: 100%;
`

export const Graphdiv = styled.div`
  height: 100%;
  width: ${props => props.width || '50vw'};
`

export const IconButton = styled(({ dayInCurrentMonth, currmonthbetween, dayIsBetween, futureDisable, ...props }) => (
  <BaseIconButton {...props} />
))`
  width: 36px;
  height: 36px;
  font-size: 15px;
  font-family: ${props => props.theme.typography.fontFamily};
  margin: 0 2px;
  color: ${props => (props.dayIsBetween ? props.theme.palette.brand.white : props.theme.palette.brand.gray[20])};

  ${props =>
    (props.dayInCurrentMonth || props.futureDisable) &&
    css`
      color: ${props => hexToRGBA(props.theme.palette.brand.gray[50], 0.5)};
    `}

  ${props =>
    props.currmonthbetween &&
    css`
      color: #676767;
    `}
`

export const Highlight = styled.div`
  background: ${props => (props.dayIsBetween ? props.theme.palette.brand.primary[50] : '')};
  color: ${props => (props.dayIsBetween ? props.theme.palette.brand.white : '')};

  border-top-left-radius: ${props => (props.isFirstDay ? '50%' : '0%')};
  border-bottom-left-radius: ${props => (props.isFirstDay ? '50%' : '0%')};

  border-top-right-radius: ${props => (props.isLastDay ? '50%' : '0%')};
  border-bottom-right-radius: ${props => (props.isLastDay ? '50%' : '0%')};
`
