import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import Skeleton from '../Placeholders/Skeleton'
import { DefaultIcon, ErrorIcon, InfoIcon, Root, Span, SuccessIcon, WarningIcon } from './Tag.styles'

/**
 * The Tag's component.
 */
const Tag = props => {
  const { color, icon, loading, margin, text, textWeight, withIcon } = props

  const BaseIcon = useMemo(() => {
    switch (color) {
      case 'error':
        return ErrorIcon
      case 'primary':
        return InfoIcon
      case 'success':
        return SuccessIcon
      case 'warning':
        return WarningIcon
      default:
        return DefaultIcon
    }
  }, [color])

  return (
    <>
      {loading && <Skeleton height={32} margin={margin} variant="rect" width={120} />}
      {!loading && (
        <Root color={color === 'default' ? 'gray' : color} elevation={0} margin={margin}>
          {icon !== undefined && icon}
          {icon === undefined && withIcon && <BaseIcon />}
          <Span color={color === 'default' ? 'gray' : color} textWeight={textWeight}>
            {text}
          </Span>
        </Root>
      )}
    </>
  )
}

Tag.defaultProps = {
  color: 'success',
  loading: false,
  text: '',
  textWeight: 'bold',
  withIcon: false
}
Tag.propTypes = {
  color: PropTypes.oneOf(['default', 'error', 'primary', 'success', 'warning']),
  icon: PropTypes.node,
  loading: PropTypes.bool,
  margin: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  text: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  textWeight: PropTypes.oneOf(['black', 'bold', 'regular', 'light']),
  withIcon: PropTypes.bool
}

export default Tag
