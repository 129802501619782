import React from 'react'
import { useDispatch, batch } from 'react-redux'
import { Root, Wrapper, Dates, Highlight, IconButton } from './PredictionsTopbar.styles'
import DatePicker from '../../components/Inputs/DatePicker'
import Button from '../../components/Buttons/Button'
import { onGetPredictionsSuccessThunk } from './Predictions.actions'
import { useDatePicker, useSelect } from '../../components/Inputs/Inputs.hooks'
import { startOfWeek, endOfWeek, isSameDay, isWithinInterval, format } from 'date-fns'
import { checkErrorRequired } from '../../modules/errors/validations'
import Select from '../../components/Inputs/Select'

/**
 * The Prediction topbar' container.
 */
const PredictionsTopbar = () => {
  // const now = moment()
  const dispatch = useDispatch()

  const { error: error1, value: value1, onChange: handleValue1Change } = useSelect({
    errorCallbacks: [checkErrorRequired()]
  })

  const { error: pastDateError, value: pastDateValue, onChange: handlePastDateChange } = useDatePicker({
    initialValue: new Date()
  })

  const renderWrapWeekDay = (date, selectedDate, dayInCurrentMonth, disableFuture) => {
    const dateClone = new Date(date)
    const selectedDateClone = new Date(selectedDate)

    const start = startOfWeek(selectedDateClone, { weekStartsOn: 1 })
    const end = endOfWeek(selectedDateClone, { weekStartsOn: 1 })

    const dayIsBetween = isWithinInterval(dateClone, { start, end })
    const isFirstDay = isSameDay(dateClone, start)
    const isLastDay = isSameDay(dateClone, end)

    const currmonthbetween = !dayInCurrentMonth && dayIsBetween

    return (
      <>
        <Highlight dayIsBetween={dayIsBetween} isFirstDay={isFirstDay} isLastDay={isLastDay}>
          <IconButton
            dayInCurrentMonth={!dayInCurrentMonth}
            currmonthbetween={currmonthbetween}
            dayIsBetween={dayIsBetween}
            futureDisable={disableFuture.props.disabled}
          >
            <span> {format(dateClone, 'd')} </span>
          </IconButton>
        </Highlight>
      </>
    )
  }

  const getData = () => {
    if (value1 !== '') {
      const start = startOfWeek(pastDateValue, { weekStartsOn: 1 })
      const end = endOfWeek(pastDateValue, { weekStartsOn: 1 })
      batch(() => {
        dispatch(onGetPredictionsSuccessThunk(start, end, value1))
      })
    }
  }

  return (
    <Root>
      <>
        <Wrapper>
          <Dates>
            <DatePicker
              error={pastDateError}
              value={pastDateValue}
              label="Seleccione semana"
              width="100%"
              onChange={handlePastDateChange}
              renderDay={renderWrapWeekDay}
              disableFuture
            />
          </Dates>
          <Select
            error={error1}
            label="Seleccione equipo"
            options={[
              { name: 'C03', value: 'C03' },
              { name: 'C04', value: 'C04' },
              { name: 'C05', value: 'C05' },
              { name: 'C13', value: 'C13' },
              { name: 'C16', value: 'C16' },
              { name: 'C18', value: 'C18' },
              { name: 'C20', value: 'C20' },
              { name: 'C21', value: 'C21' },
              { name: 'C23', value: 'C23' },
              { name: 'C25', value: 'C25' },
              { name: 'C26', value: 'C26' },
              { name: 'C28', value: 'C28' },
              { name: 'C29', value: 'C29' },
              { name: 'C30', value: 'C30' },
              { name: 'C31', value: 'C31' },
              { name: 'C32', value: 'C32' },
              { name: 'C33', value: 'C33' },
              { name: 'C34', value: 'C34' },
              { name: 'C35', value: 'C35' },
              { name: 'C100', value: 'C100' },
              { name: 'C101', value: 'C101' },
              { name: 'C102', value: 'C102' },
              { name: 'C104', value: 'C104' },
              { name: 'C105', value: 'C105' }
            ]}
            placeholder="select"
            value={value1}
            onChange={handleValue1Change}
          />
          <Button color="primary" onClick={getData} size="small">
            Go
          </Button>
        </Wrapper>
      </>
    </Root>
  )
}

export default PredictionsTopbar
