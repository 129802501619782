export const {
  REACT_APP_API_BASE_URL,
  REACT_APP_BASE_KEY,
  REACT_APP_BASE_NAME,
  REACT_APP_BASE_SUB_PATH,
  REACT_APP_BASE_URL,
  REACT_APP_CLIENT_NAME,
  REACT_APP_ENVIRONMENT,
  REACT_APP_FEATURE_FLAG_ANOMALY_DETECTION,
  REACT_APP_FEATURE_FLAG_EXAMPLES,
  REACT_APP_FEATURE_FLAG_FUEL_LEVEL,
  REACT_APP_FEATURE_FLAG_GLOBAL_COMPARATIVE,
  REACT_APP_FEATURE_FLAG_HARD_BRAKING,
  REACT_APP_FEATURE_FLAG_HELP,
  REACT_APP_FEATURE_FLAG_IDLE_RPM,
  REACT_APP_FEATURE_FLAG_OPERATORS_REPORT,
  REACT_APP_FEATURE_FLAG_RPM_DOWNLOAD,
  REACT_APP_FEATURE_FLAG_STATION_MANAGMENT,
  REACT_APP_FEATURE_FLAG_FIX_STATIONS,
  REACT_APP_FEATURE_FLAG_MOBILE_STATIONS,
  REACT_APP_HOTJAR_ID,
  REACT_APP_HOTJAR_SNIPPET_VERSION,
  REACT_APP_PATH_WEB_SOCKET,
  REACT_APP_REINFORCEMENT_KEY,
  REACT_APP_REINFORCEMENT_NAME,
  REACT_APP_REINFORCEMENT_SUB_PATH,
  REACT_APP_REGION,
  REACT_APP_USER_POOL_CLIENT_ID,
  REACT_APP_USER_POOL_ID,
  REACT_APP_USER_IDENTITY_POOL_ID,
  REACT_APP_USERNAME,
  REACT_APP_FEATURE_FLAG_NEW_ANOMALY,
  REACT_APP_FEATURE_FLAG_QUICKSIGHT,
  REACT_APP_FEATURE_FLAG_VISUALIZADOR_ESPACIAL,
  REACT_APP_MAPBOX_ACCESS_TOKEN,
  REACT_APP_FEATURE_FLAG_VISUALIZADOR_DIA,
  REACT_APP_FEATURE_FLAG_VISUALIZADOR_HISTORICO,
  REACT_APP_FEATURE_FLAG_VISUALIZADOR_HARD_BRAKING,
  REACT_APP_FEATURE_FLAG_VISUALIZADOR_RALENTI,
  REACT_APP_FEATURE_FLAG_PREDICTIONS_GRAPH,
  REACT_APP_FEATURE_FLAG_TIRES,
  REACT_APP_FEATURE_FLAG_TIRES_ANOMALY_TABLE,
  REACT_APP_FEATURE_FLAG_TIRES_VELOCITY,
  REACT_APP_FEATURE_FLAG_FUEL_MANAGEMENT,
  REACT_APP_PINPOINT_ID,
  REACT_APP_API_BASE_URL_V2,
  REACT_APP_FEATURE_FLAG_DINING
} = process.env

export const isDevEnv = process.env.REACT_APP_ENVIRONMENT !== 'prod'
