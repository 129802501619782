import React, { useEffect } from 'react'
import { batch, useDispatch, useSelector } from 'react-redux'
import LoadingContent from '../../components/Placeholders/LoadingContent'
import { usePagination } from '../../components/Tables/Pagination.hooks'
import Pagination from '../../components/Tables/Pagination'
import DisconnectedData from '../FuelLevel/DisconnectedData.container'
import { Box, Paper } from '@material-ui/core'
import Table from '../../components/Tables/Table'
import TableCell from '../../components/Tables/TableCell'
import TableRow from '../../components/Tables/TableRow'
import Typography from '../../components/Basics/Typography'
import { getOrpakDispatchThunk } from './dispatchOrpak.actions'

const NewAnomalieDispatchOrpak = () => {
  const dispatch = useDispatch()
  const { loadingDispatchOrpak, errorDispatchOrpak, dispatchOrpak, tableFooter } = useSelector(
    state => state.dispatchOrpak
  )

  useEffect(() => {
    batch(() => {
      dispatch(getOrpakDispatchThunk())
    })
  }, [])

  const { page, totalPages, onChangePage, onGetCurrentPage } = usePagination({
    data: dispatchOrpak.dispatchOrpakData,
    initialPage: 1,
    rowsPerPage: 12
  })

  return (
    <>
      {loadingDispatchOrpak && <LoadingContent />}
      {!loadingDispatchOrpak && errorDispatchOrpak && <DisconnectedData />}
      {!loadingDispatchOrpak && !errorDispatchOrpak && (
        <>
          <Box>
            <Paper style={{ overflow: 'auto' }}>
              <Table accesibility={{ label: 'Dispatch vs Orpak' }}>
                <TableRow>
                  <TableCell />
                  <TableCell align="center" colSpan={3} minWidth="150px">
                    <Typography variant="h4" fontWeight="bold">
                      Jigsaw
                    </Typography>
                  </TableCell>
                  <TableCell align="center" colSpan={3} minWidth="150px">
                    <Typography variant="h4" fontWeight="bold">
                      Orpak
                    </Typography>
                  </TableCell>
                  <TableCell />
                </TableRow>
                <TableRow head>
                  <TableCell minWidth="150px">Fecha</TableCell>
                  <TableCell minWidth="150px">Abastecimientos</TableCell>
                  <TableCell minWidth="150px">Litros</TableCell>
                  <TableCell minWidth="150px">Camiones</TableCell>
                  <TableCell minWidth="150px">Abastecimientos</TableCell>
                  <TableCell minWidth="150px">Litros</TableCell>
                  <TableCell minWidth="150px">Camiones</TableCell>
                  <TableCell minWidth="150px">Diferencia Jigsaw vs Orpak</TableCell>
                </TableRow>

                {onGetCurrentPage().map((row, index) => {
                  return (
                    <TableRow key={`dispatch-orpak-${index}`}>
                      <TableCell align="center" minWidth="150px">
                        {row.mes} de {row.año}
                      </TableCell>
                      <TableCell align="center" minWidth="150px">
                        {row.countDispatch}
                      </TableCell>
                      <TableCell align="center" minWidth="150px">
                        {row.ltrsDispatch}
                      </TableCell>
                      <TableCell align="center" minWidth="150px">
                        {row.camionesDispatch}
                      </TableCell>
                      <TableCell align="center" minWidth="150px">
                        {row.countOrpak}
                      </TableCell>
                      <TableCell align="center" minWidth="150px">
                        {row.ltrsOrpak}
                      </TableCell>
                      <TableCell align="center" minWidth="150px">
                        {row.camionesOrpak}
                      </TableCell>
                      <TableCell align="center" minWidth="150px">
                        {row.diffDispatchOrpak}
                      </TableCell>
                    </TableRow>
                  )
                })}
                <TableRow>
                  <TableCell align="center" minWidth="150px">
                    Total
                  </TableCell>
                  <TableCell align="center" minWidth="150px">
                    {tableFooter.total.totalCountDis}
                  </TableCell>
                  <TableCell align="center" minWidth="150px">
                    {tableFooter.total.totalLitersDis}
                  </TableCell>
                  <TableCell align="center" minWidth="150px" />
                  <TableCell align="center" minWidth="150px">
                    {tableFooter.total.totalCountOrpak}
                  </TableCell>
                  <TableCell align="center" minWidth="150px">
                    {tableFooter.total.totalLitersOrpak}
                  </TableCell>
                  <TableCell align="center" minWidth="150px" />
                  <TableCell align="center" minWidth="150px">
                    {tableFooter.total.totalDiff}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="center" minWidth="150px">
                    Promedio
                  </TableCell>
                  <TableCell align="center" minWidth="150px">
                    {tableFooter.mean.meanCountDis}
                  </TableCell>
                  <TableCell align="center" minWidth="150px">
                    {tableFooter.mean.meanLiterDis}
                  </TableCell>
                  <TableCell align="center" minWidth="150px">
                    {tableFooter.mean.meanTruckDis}
                  </TableCell>
                  <TableCell align="center" minWidth="150px">
                    {tableFooter.mean.meanCountOrpak}
                  </TableCell>
                  <TableCell align="center" minWidth="150px">
                    {tableFooter.mean.meanLitersOrpak}
                  </TableCell>
                  <TableCell align="center" minWidth="150px">
                    {tableFooter.mean.meanTruckOrpak}
                  </TableCell>
                  <TableCell align="center" minWidth="150px">
                    {tableFooter.mean.meanDiff}
                  </TableCell>
                </TableRow>
              </Table>
              <Pagination alignment="right" count={totalPages} page={page} onChange={onChangePage} />
            </Paper>
          </Box>
        </>
      )}
    </>
  )
}

export default NewAnomalieDispatchOrpak
