import React from 'react'
import Button from '../../components/Buttons/Button'
import Typography from '../../components/Basics/Typography'
import { Root } from './Examples.styles'

/**
 * The ButtonExample's container.
 */
const ButtonExample = () => {
  const margin = '5px'
  return (
    <Root>
      <Typography variant="body2">Todas la variantes y tamaños existen en todos los colores</Typography>
      <br />

      <Typography variant="h1">Button Colors</Typography>
      <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-evenly' }}>
        <Button color="primary" margin={margin}>
          Primary
        </Button>
        <Button color="default" margin={margin}>
          Default
        </Button>
        <Button color="success" margin={margin}>
          Success
        </Button>
        <Button color="error" margin={margin}>
          Error
        </Button>
        <Button color="warning" margin={margin}>
          Warning
        </Button>
        <Button color="primary" disabled margin={margin}>
          Disabled
        </Button>
        <Button color="primary" loading margin={margin}>
          Loading
        </Button>
      </div>
      <br />

      <Typography variant="h1">Button Variants</Typography>
      <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
        <Button color="primary" margin={margin} variant="contained">
          Contained
        </Button>
        <Button color="primary" margin={margin} variant="outlined">
          Outlined
        </Button>
        <Button color="primary" margin={margin} variant="text">
          Text button
        </Button>
      </div>
      <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
        <Button color="default" margin={margin} variant="contained">
          Contained
        </Button>
        <Button color="default" margin={margin} variant="outlined">
          Outlined
        </Button>
        <Button color="default" margin={margin} variant="text">
          Text button
        </Button>
      </div>
      <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
        <Button color="success" margin={margin} variant="contained">
          Contained
        </Button>
        <Button color="success" margin={margin} variant="outlined">
          Outlined
        </Button>
        <Button color="success" margin={margin} variant="text">
          Text button
        </Button>
      </div>
      <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
        <Button color="error" margin={margin} variant="contained">
          Contained
        </Button>
        <Button color="error" margin={margin} variant="outlined">
          Outlined
        </Button>
        <Button color="error" margin={margin} variant="text">
          Text button
        </Button>
      </div>
      <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
        <Button color="warning" margin={margin} variant="contained">
          Contained
        </Button>
        <Button color="warning" margin={margin} variant="outlined">
          Outlined
        </Button>
        <Button color="warning" margin={margin} variant="text">
          Text button
        </Button>
      </div>
      <br />

      <Typography variant="h1">Button Sizes</Typography>
      <div style={{ alignItems: 'center', display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
        <Button color="primary" margin={margin} size="small" variant="text">
          Small
        </Button>
        <Button color="primary" margin={margin} size="medium" variant="text">
          Medium
        </Button>
        <Button color="primary" margin={margin} size="large" variant="text">
          Large
        </Button>
      </div>
      <div style={{ alignItems: 'center', display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
        <Button color="primary" margin={margin} size="small" variant="outlined">
          Small
        </Button>
        <Button color="primary" margin={margin} size="medium" variant="outlined">
          Medium
        </Button>
        <Button color="primary" margin={margin} size="large" variant="outlined">
          Large
        </Button>
      </div>
      <div style={{ alignItems: 'center', display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
        <Button color="primary" margin={margin} size="small" variant="contained">
          Small
        </Button>
        <Button color="primary" margin={margin} size="medium" variant="contained">
          Medium
        </Button>
        <Button color="primary" margin={margin} size="large" variant="contained">
          Large
        </Button>
      </div>
      <br />

      <Typography variant="h1">Loading</Typography>
      <div style={{ alignItems: 'center', display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
        <Button color="primary" loading margin={margin} variant="contained">
          Loading
        </Button>
        <Button color="primary" loading margin={margin} variant="outlined">
          Loading
        </Button>
        <Button color="primary" loading margin={margin} variant="text">
          Loading
        </Button>
      </div>
      <div style={{ alignItems: 'center', display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
        <Button color="default" loading margin={margin} variant="contained">
          Loading
        </Button>
        <Button color="default" loading margin={margin} variant="outlined">
          Loading
        </Button>
        <Button color="default" loading margin={margin} variant="text">
          Loading
        </Button>
      </div>
      <div style={{ alignItems: 'center', display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
        <Button color="success" loading margin={margin} variant="contained">
          Loading
        </Button>
        <Button color="success" loading margin={margin} variant="outlined">
          Loading
        </Button>
        <Button color="success" loading margin={margin} variant="text">
          Loading
        </Button>
      </div>
      <div style={{ alignItems: 'center', display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
        <Button color="error" loading margin={margin} variant="contained">
          Loading
        </Button>
        <Button color="error" loading margin={margin} variant="outlined">
          Loading
        </Button>
        <Button color="error" loading margin={margin} variant="text">
          Loading
        </Button>
      </div>
      <div style={{ alignItems: 'center', display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
        <Button color="warning" loading margin={margin} variant="contained">
          Loading
        </Button>
        <Button color="warning" loading margin={margin} variant="outlined">
          Loading
        </Button>
        <Button color="warning" loading margin={margin} variant="text">
          Loading
        </Button>
      </div>
    </Root>
  )
}

export default ButtonExample
