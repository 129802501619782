import React from 'react'
import Alert from '../../components/Basics/Alert'
import Typography from '../../components/Basics/Typography'
import { Root } from './Examples.styles'

/**
 * The AlertsExample's container.
 */
const AlertsExample = () => {
  const margin = '5px'

  return (
    <Root>
      <Typography variant="body1">
        Las alertas tienen diferentes niveles de gravedad para utilizar. Las opciones posibles son: info, error,
        success, warning y default.
      </Typography>
      <br />
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <Alert margin={margin} severity="warning" title="Atención:">
          La información del reporte a continuación está basada en data histórica, en cuanto las bases de datos
          actualizadas sean enviadas, podremos disponibilizar los reportes con datos correspondientes al período
          seleccionado.
        </Alert>
        <Alert margin={margin} severity="info" title="Información:">
          Este es un texto con información no tan largo.
        </Alert>
        <Alert margin={margin} severity="error" title="Atención:">
          Este es un texto con información no tan largo.
        </Alert>
        <Alert margin={margin} severity="success" title="Atención:">
          Este es un texto con información no tan largo.
        </Alert>
        <Alert margin={margin} severity="default" title="Información:">
          Este es un texto con información no tan largo.
        </Alert>
      </div>
      <br />

      <Typography variant="body1">Alerta con icono. Se debe entregar el icono a utilizar.</Typography>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <Alert
          action={<a href="#">MÁS</a>}
          icon={<img src="/assets/icons/warningIcon.svg" />}
          margin={margin}
          severity="warning"
          title="Atención:"
        >
          La información del reporte a continuación está basada en data histórica, en cuanto las bases de datos
          actualizadas sean enviadas, podremos disponibilizar los reportes con datos correspondientes al período
          seleccionado.
        </Alert>
      </div>
      <br />

      <Typography variant="body1">
        Tambien tiene dos modos, 'light' y 'dark'. Por defecto estara seleccionado la opción light.
      </Typography>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <Alert
          icon={<img src="/assets/icons/warningIcon.svg" />}
          margin={margin}
          mode="dark"
          severity="warning"
          title="Atención:"
        >
          La información del reporte a continuación está basada en data histórica, en cuanto las bases de datos
          actualizadas sean enviadas, podremos disponibilizar los reportes con datos correspondientes al período
          seleccionado.
        </Alert>
      </div>
    </Root>
  )
}

export default AlertsExample
