import React, { useState } from 'react'
import Slider from '../../components/Inputs/Slider'
import { Root } from './Examples.styles'

/**
 * The SliderExample's container.
 */
const SliderExample = () => {
  const [value, setValue] = useState([20, 37])

  const handleChange = (_, newValue) => {
    setValue(newValue)
  }

  const marks = [
    { label: 'XX lts', value: 0 },
    { label: 'XXX lts', value: 100 }
  ]

  return (
    <Root>
      <div style={{ width: '200px' }}>
        <Slider margin="0 0 0 20px" markPosition="top" marks={marks} value={value} onChange={handleChange} />
      </div>
      <br />
    </Root>
  )
}

export default SliderExample
