import React from 'react'
import styled from 'styled-components'
import { Tab } from '@material-ui/core'

export const Root = styled(({ align, display, isSelected, padding, width, ...props }) => <Tab {...props} />)`
  background-color: ${({ isSelected, theme }) =>
    isSelected ? theme.palette.brand.primary[50] : theme.palette.brand.white};
  border: 1px solid
    ${({ isSelected, theme }) => (isSelected ? theme.palette.brand.primary[50] : theme.palette.brand.gray[85])};
  border-radius: 4px;
  color: ${({ isSelected, theme }) => (isSelected ? theme.palette.brand.white : theme.palette.brand.gray[30])};
  display: ${props => props.display};
  font-size: 16px;
  font-size: 16px;
  line-height: 24px;
  margin-right: 5px;
  min-height: auto;
  min-width: ${props => props.width || '110px'};
  opacity: 1;
  padding: ${props => props.padding || '7px 16px'};
  text-transform: inherit;
  width: ${props => props.width};

  .MuiTab-wrapper {
    align-items: ${({ align }) => {
      switch (align) {
        case 'left':
          return 'flex-start'
        case 'right':
          return 'flex-end'
        case 'center':
        default:
          return 'center'
      }
    }};
  }

  &:hover {
    background-color: ${({ isSelected, theme }) =>
      isSelected ? theme.palette.brand.primary[35] : theme.palette.brand.gray[95]};
    border-color: ${({ isSelected, theme }) =>
      isSelected ? theme.palette.brand.primary[35] : theme.palette.brand.gray[85]};
    text-decoration: none;
  }

  &.Mui-selected {
    background-color: ${props => props.theme.palette.brand.primary[50]};
    border-color: ${({ isSelected, theme }) => theme.palette.brand.primary[50]};
    color: ${props => props.theme.palette.brand.white};

    &:hover {
      background-color: ${props => props.theme.palette.brand.primary[35]};
      border-color: ${props => props.theme.palette.brand.primary[35]};
      text-decoration: none;
    }
  }
`

export const LoadingWrapper = styled.div`
  align-items: center;
  display: flex;
  height: 40px;
  width: 120px;
`
