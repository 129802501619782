import React from 'react'
import styled from 'styled-components'
import { AccordionDetails as BaseAccordionDetails, Grid as BaseGrid } from '@material-ui/core'
import { Search as BaseSearchIcon } from '@material-ui/icons'
import Spinner from '../../components/Basics/Spinner'
import { InputPrefix } from '../../components/Inputs/Input'

export const AccordionDetails = styled(BaseAccordionDetails)``

export const Form = styled.form`
  width: 100%;
`

export const PhoneNumberPrefix = styled(InputPrefix)``

export const Root = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  width: 100%;
`

export const Separator = styled.hr`
  border: 0;
  border-top: 1px solid ${props => props.theme.palette.brand.gray[85]};
  box-sizing: content-box;
  height: 0;
  margin: 24px 0;
  overflow: visible;
`

export const Search = styled(({ loading, ...props }) => <button {...props} />)`
  align-items: center;
  background-color: ${props => props.theme.palette.brand.primary.main};
  border: 0;
  border-radius: 0;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  display: flex;
  height: 100%;
  justify-content: center;
  opacity: ${props => (props.disabled ? 0.5 : 1)};
  outline: 0;
  pointer-events: ${props => (props.disabled ? 'none' : 'initial')};
  user-select: ${props => (props.disabled ? 'none' : 'initial')};
  width: 40px;

  &:focus {
    outline: 0;
  }

  &:hover {
    background-color: ${props => props.theme.palette.primary.dark};
  }
`

export const SearchIcon = styled(BaseSearchIcon)`
  color: ${props => props.theme.palette.blackGrayBrand.white};
  height: 24px;
  width: 24px;
`

export const SearchLoadingIcon = styled(props => <Spinner {...props} color="white" size="18px" />)``

export const Wrapper = styled(BaseGrid)`
  margin: ${props => props.margin};
`
