import React from 'react'
import PropTypes from 'prop-types'
import { Root } from './Badge.styles'

/**
 * The Badge's component.
 */
const Mybadge = props => {
  const { color, children, badgeContent, anchorOrigin, max, margin } = props

  return (
    <Root color={color} max={max} anchorOrigin={anchorOrigin} badgeContent={badgeContent} margin={margin}>
      {children}
    </Root>
  )
}

Mybadge.defaultProps = {
  color: 'primary'
}
Mybadge.propTypes = {
  color: PropTypes.oneOf(['default', 'primary']),
  children: PropTypes.node.isRequired,
  badgeContent: PropTypes.string,
  anchorOrigin: PropTypes.object,
  max: PropTypes.number,
  margin: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}

export default Mybadge
