import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Chart from 'react-apexcharts'
import { Wrapper, Graphdiv } from './BoxPlotGraph.styles'
import LoadingContent from '../../components/Placeholders/LoadingContent'
import DisconnectedData from '../FuelLevel/DisconnectedData.container'
import { onGetGraphSuccessThunk } from './BoxPlotGraph.actions'
import theme from '../../config/styles/theme'

/**
 * The BoxPlots's container.
 */
const BoxplotContent = () => {
  const dispatch = useDispatch()
  const { error, loading, fuelRate, orpak } = useSelector(state => state.fuelLevelGraphs)

  useEffect(() => {
    if (fuelRate.length === 0) {
      dispatch(onGetGraphSuccessThunk())
    }
  }, [])

  const state = {
    optionsFuelRate: {
      plotOptions: {
        boxPlot: {
          colors: {
            upper: theme.palette.brand.primary[90],
            lower: theme.palette.brand.primary[50]
          }
        }
      },
      chart: {
        type: 'boxPlot'
      },
      title: {
        text: 'Tasa de consumo de combustible (L/h) en los últimos 15 días',
        align: 'middle',
        style: {
          fontSize: '16px',
          fontWeight: 'bold',
          fontFamily: theme.typography.fontFamily
        }
      },
      legend: {
        show: true,
        showForSingleSeries: true,
        position: 'left'
      },
      annotations: {
        yaxis: [
          {
            y: 200,
            borderColor: theme.palette.brand.error[50],
            strokeDashArray: 0
          }
        ]
      }
    },
    seriesFuelRate: [
      {
        name: 'L/H',
        type: 'boxPlot',
        data: fuelRate
      }
    ],
    optionsOrpak: {
      plotOptions: {
        boxPlot: {
          colors: {
            upper: theme.palette.brand.primary[90],
            lower: theme.palette.brand.primary[50]
          }
        }
      },
      chart: {
        id: 'basic-boxplot',
        type: 'boxPlot'
      },
      title: {
        text: 'Volumen de combustible abastecido (L) en los últimos 15 días',
        align: 'middle',
        style: {
          fontSize: '16px',
          fontWeight: 'bold',
          fontFamily: theme.typography.fontFamily
        }
      },
      legend: {
        show: true,
        showForSingleSeries: true,
        position: 'left'
      }
    },
    tooltip: {
      style: {
        fontFamily: theme.typography.fontFamily
      }
    },
    seriesOrpak: [
      {
        name: 'Litros',
        type: 'boxPlot',
        data: orpak
      }
    ]
  }

  return (
    <>
      {loading && !error && <LoadingContent />}
      {error && !loading && <DisconnectedData />}
      {!loading && !error && (
        <>
          <Wrapper>
            <Graphdiv>
              <Chart
                options={state.optionsFuelRate}
                series={state.seriesFuelRate}
                type="boxPlot"
                height="100%"
                width="100%"
              />
            </Graphdiv>
            <Graphdiv>
              <Chart
                options={state.optionsOrpak}
                series={state.seriesOrpak}
                type="boxPlot"
                height="100%"
                width="100%"
              />
            </Graphdiv>
          </Wrapper>
        </>
      )}
    </>
  )
}

export default BoxplotContent
