import React from 'react'
import Typography from '../../components/Basics/Typography'
import { Root } from './Examples.styles'

/**
 * The ColorExample's container.
 */
const ColorExample = () => {
  return (
    <Root>
      <Typography variant="body1">
        Para cada color... el 50 es el color principal que se utiliza en los botones contained, el 35 se utiliza para el
        hover del boton y el 95 para el boton en su version outlined o text. El 75 de cada color, es el fondo del boton
        cuando esta deshabilitado
        <br />
        La paleta de colores de grises, tambien se utiliza como el color default en los botones
      </Typography>
      <br />
      <img src="/assets/colors/primary.png" alt="primary" style={{ width: '500px', height: 'auto' }} />
      <br />
      <br />

      <Typography variant="body1">
        Esta es la paleta de grises que se usa ahora. En otro lugar esta definido el negro, el cual se utiliza por
        defecto en los textos. El 85 para lineas, bordes, etc. Hasta el momento no he usado el 60, pero creo que lo
        usare en los inputs.
      </Typography>
      <br />
      <img src="/assets/colors/grises.png" alt="grises" style={{ width: '500px', height: 'auto' }} />
      <br />
      <br />

      <Typography variant="body1">
        Aca el resto de los colores de la paleta. El rojo 50 lo pille en algun lugar del código. Quizas estos son los
        colores que mas podrian varian :)
        <br />
        Los colores success y warning los copie de mi pega :P
      </Typography>
      <br />
      <img src="/assets/colors/otros.png" alt="otros" style={{ width: '500px', height: 'auto' }} />

      <br />
      <br />
      <a href="https://www.w3schools.com/colors/colors_picker.asp" target="_blank" rel="noopener noreferrer">
        Link util de donde saque la paleta del error
      </a>
    </Root>
  )
}

export default ColorExample
