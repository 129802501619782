import {
  FETCH_DATA_TANKERS,
  FETCH_DATA_TANKERS_ERROR,
  FETCH_DATA_TANKERS_SUCCESS,
  FETCH_DATA_TRUCKS,
  FETCH_DATA_TRUCKS_ERROR,
  FETCH_DATA_TRUCKS_SUCCESS,
  REQUEST_FULL_SCREEN_SUCCESS,
  GET_HISTORICAL_ORPAK,
  GET_HISTORICAL_ORPAK_ERROR,
  GET_HISTORICAL_ORPAK_SUCCESS,
  GET_MONTHLY_ORPAK,
  GET_MONTHLY_ORPAK_ERROR,
  GET_MONTHLY_ORPAK_SUCCESS,
  GET_DATE_ORPAK,
  GET_DATE_ORPAK_ERROR,
  GET_DATE_ORPAK_SUCCESS
} from './FuelLevel.actions'

const fuelLevelState = {
  error: false,
  fullScreenRequested: false,
  loading: true,
  trucks: [],
  loadingHistoricOrpak: false,
  errorHistoricOrpak: false,
  historicOrpakData: [],
  monthlyOrpakData: [],
  dateOrpakData: []
}

/**
 * The fuel level' reducer.
 */
const fuelLevelReducer = (state = fuelLevelState, { payload, type }) => {
  switch (type) {
    case FETCH_DATA_TANKERS:
    case FETCH_DATA_TRUCKS: {
      return { ...state, loading: true, error: false }
    }
    case FETCH_DATA_TANKERS_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        trucks: payload.trucks
      }
    }
    case FETCH_DATA_TRUCKS_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: payload.posibleError,
        trucks: payload.trucks
      }
    }
    case FETCH_DATA_TANKERS_ERROR:
    case FETCH_DATA_TRUCKS_ERROR: {
      return {
        ...state,
        loading: false,
        error: true
      }
    }
    case REQUEST_FULL_SCREEN_SUCCESS: {
      return {
        ...state,
        fullScreenRequested: !state.fullScreenRequested
      }
    }
    case GET_HISTORICAL_ORPAK:
    case GET_MONTHLY_ORPAK:
    case GET_DATE_ORPAK: {
      return {
        ...state,
        loadingHistoricOrpak: true,
        errorHistoricOrpak: false
      }
    }
    case GET_HISTORICAL_ORPAK_ERROR:
    case GET_MONTHLY_ORPAK_ERROR:
    case GET_DATE_ORPAK_ERROR: {
      return {
        ...state,
        loadingHistoricOrpak: false,
        errorHistoricOrpak: true
      }
    }
    case GET_HISTORICAL_ORPAK_SUCCESS: {
      return {
        ...state,
        loadingHistoricOrpak: false,
        errorHistoricOrpak: false,
        historicOrpakData: payload.historicOrpakData
      }
    }
    case GET_MONTHLY_ORPAK_SUCCESS: {
      return {
        ...state,
        loadingHistoricOrpak: false,
        errorHistoricOrpak: false,
        monthlyOrpakData: payload.monthlyOrpakData
      }
    }
    case GET_DATE_ORPAK_SUCCESS: {
      return {
        ...state,
        loadingHistoricOrpak: false,
        errorHistoricOrpak: false,
        dateOrpakData: payload.dateOrpakData
      }
    }
    default: {
      return state
    }
  }
}

export default fuelLevelReducer
