import {
  LOG_IN,
  LOG_IN_ERROR,
  LOG_IN_SUCCESS,
  LOG_OUT,
  LOG_OUT_ERROR,
  LOG_OUT_SUCCESS,
  SILENT_LOG_IN,
  SILENT_LOG_IN_ERROR,
  SILENT_LOG_IN_SUCCESS,
  TOGGLE_LOGOUT_MODAL_SUCCESS,
  CLOSE_CHANGEPASSWORD_MODAL,
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_ERROR,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_MODAL
} from './Authentication.actions'

const authenticationState = {
  apis: {},
  baseUrl: '',
  clientName: '',
  error: false,
  loading: true,
  showLogoutModal: false,
  signedIn: false,
  startDatetime: 0,
  userId: null,
  username: null,
  websocketPath: '',
  showChangePasswordModal: false,
  showForgotPasswordModal: false,
  resetMessage: ''
}

/**
 * The authentication' reducer.
 */
const authenticationReducer = (state = authenticationState, { payload, type }) => {
  switch (type) {
    case TOGGLE_LOGOUT_MODAL_SUCCESS: {
      return { ...state, showLogoutModal: !state.showLogoutModal }
    }
    case LOG_IN:
    case LOG_OUT:
    case SILENT_LOG_IN:
    case FORGOT_PASSWORD: {
      return { ...state, loading: true, error: false }
    }
    case LOG_IN_ERROR:
    case SILENT_LOG_IN_ERROR:
    case FORGOT_PASSWORD_ERROR: {
      return { ...state, loading: false, error: payload.error }
    }
    case LOG_IN_SUCCESS:
    case SILENT_LOG_IN_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        apis: payload.apis,
        baseUrl: payload.baseUrl,
        clientName: payload.clientName,
        signedIn: payload.signedIn,
        startDatetime: payload.startDatetime,
        userId: payload.userId,
        username: payload.username,
        websocketPath: payload.websocketPath
      }
    }
    case LOG_OUT_ERROR: {
      return {
        ...state,
        loading: false,
        error: true,
        showLogoutModal: false,
        apis: {},
        baseUrl: '',
        clientName: payload.clientName,
        signedIn: false,
        startDatetime: 0,
        userId: null,
        username: null,
        websocketPath: ''
      }
    }
    case LOG_OUT_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: undefined,
        showLogoutModal: false,
        apis: {},
        baseUrl: '',
        clientName: '',
        signedIn: false,
        startDatetime: 0,
        userId: null,
        username: null,
        websocketPath: ''
      }
    }
    case FORGOT_PASSWORD_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: undefined,
        resetMessage: payload.message
      }
    }
    case CLOSE_CHANGEPASSWORD_MODAL: {
      return {
        ...state,
        showChangePasswordModal: !state.showChangePasswordModal
      }
    }
    case FORGOT_PASSWORD_MODAL: {
      return {
        ...state,
        showForgotPasswordModal: !state.showForgotPasswordModal
      }
    }
    default: {
      return state
    }
  }
}

export default authenticationReducer
