import React from 'react'
import routes from '../config/settings/routes'
import { useTabs } from '../components/Navigation/Tab.hooks'
import ManagementSectionContainer from '../containers/ManagementMenu/ManagementMenu.container'
import NavbarContainer from '../containers/Navbar/Navbar.container'
import BrandLayout from '../components/Layouts/BrandLayout'
import Heading from '../components/Layouts/Heading'
import ProtectByFeatures from '../components/Security/ProtectByFeatures'
import ProtectBySession from '../components/Security/ProtectBySession'
import Tab from '../components/Navigation/Tab'
import Tabs from '../components/Navigation/Tabs'
import NotFoundPage from './NotFound'
import AccordeonExampleContainer from '../containers/Examples/Accordeon.container'
import AlertsExampleContainer from '../containers/Examples/Alerts.container'
import BasicsExampleContainer from '../containers/Examples/Basics.container'
import ButtonExampleContainer from '../containers/Examples/Buttons.container'
import CheckRadioExampleContainer from '../containers/Examples/CheckRadio.container'
import ColorExampleContainer from '../containers/Examples/Color.container'
import DatePickerExampleContainer from '../containers/Examples/DatePicker.container'
import InputsExampleContainer from '../containers/Examples/Inputs.container'
import SelectExampleContainer from '../containers/Examples/Select.container'
import SkeletonExampleContainer from '../containers/Examples/Skeleton.container'
import SliderExampleContainer from '../containers/Examples/Slider.container'
import TableExampleContainer from '../containers/Examples/Table.container'
import TypographyExampleContainer from '../containers/Examples/Typography.container'
import SankeyExample from '../containers/Examples/Sankey.container'

/**
 * The Example' page.
 */
const Example = () => {
  const title = 'Ejemplos'
  const { value: optionSelected, onChange: handleChangeTab } = useTabs({ initialValue: 'slider' })

  const renderTopbar = () => {
    return (
      <Tabs
        aria-label="Example tabs"
        scrollButtons="on"
        value={optionSelected}
        variant="scrollable"
        onChange={handleChangeTab}
      >
        <Tab label="Tablas" value="tables" />
        <Tab label="Skeleton y Breadcrumb" value="skeleton" />
        <Tab label="Accordeon" value="accordeon" />
        <Tab label="Typography" value="typography" />
        <Tab label="Button" value="button" />
        <Tab label="Colores" value="color" />
        <Tab label="Inputs" value="inputs" />
        <Tab label="CheckBox y Radio" value="checkRadio" />
        <Tab label="DatePicker" value="datepicker" />
        <Tab label="Modal y Tag" value="basics" />
        <Tab label="Select" value="select" />
        <Tab label="Alertas" value="alerts" />
        <Tab label="Slider" value="slider" />
        <Tab label="Graphs" value="graphs" />
      </Tabs>
    )
  }

  return (
    <ProtectBySession rule={(hasSession, signedIn) => !!(hasSession && signedIn)}>
      <ProtectByFeatures rule={features => features['com.cosmos/examples']} fallback={() => <NotFoundPage />}>
        <BrandLayout
          navbar={<NavbarContainer />}
          header={<Heading title={title} topbar={renderTopbar} />}
          sidebar={<ManagementSectionContainer current={routes.example} />}
        >
          {optionSelected === 'tables' && <TableExampleContainer />}
          {optionSelected === 'skeleton' && <SkeletonExampleContainer />}
          {optionSelected === 'accordeon' && <AccordeonExampleContainer />}
          {optionSelected === 'typography' && <TypographyExampleContainer />}
          {optionSelected === 'button' && <ButtonExampleContainer />}
          {optionSelected === 'color' && <ColorExampleContainer />}
          {optionSelected === 'inputs' && <InputsExampleContainer />}
          {optionSelected === 'checkRadio' && <CheckRadioExampleContainer />}
          {optionSelected === 'datepicker' && <DatePickerExampleContainer />}
          {optionSelected === 'basics' && <BasicsExampleContainer />}
          {optionSelected === 'select' && <SelectExampleContainer />}
          {optionSelected === 'alerts' && <AlertsExampleContainer />}
          {optionSelected === 'slider' && <SliderExampleContainer />}
          {optionSelected === 'graphs' && <SankeyExample />}
        </BrandLayout>
      </ProtectByFeatures>
    </ProtectBySession>
  )
}

export default Example
