import React from 'react'
import routes from '../config/settings/routes'
import LoginContainer from '../containers/Authentication/Login.container'
import ProtectBySession from '../components/Security/ProtectBySession'

/**
 * The login page.
 */
const Login = () => {
  return (
    <ProtectBySession redirectTo={routes.root} rule={hasSession => !hasSession}>
      <LoginContainer />
    </ProtectBySession>
  )
}

export default Login
