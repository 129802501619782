import React, { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { usePathParam } from '../../modules/hooks/route'
import { useCheckBox, useDatePicker, useSelect } from '../../components/Inputs/Inputs.hooks'
import { formatDate } from '../../modules/utils/formatters'
import { checkErrorRequired } from '../../modules/errors/validations'
import {
  onAddOperatorThunk,
  onGetInstructorsThunk,
  onGetReinforcementThunk,
  onRemoveOperatorThunk,
  onUpdateFormValue
} from './Reinforcement.actions'
import CheckBox from '../../components/Inputs/CheckBox'
import Chip from '../../components/Basics/Chip'
import DatePicker from '../../components/Inputs/DatePicker'
import Input from '../../components/Inputs/Input'
import Select from '../../components/Inputs/Select'
import Typography from '../../components/Basics/Typography'
import { Calendar, Container, Grid } from './Reinforcement.styles'

/**
 * The Reinforcement Content' container.
 */
const ReinforcementContent = () => {
  const dispatch = useDispatch()
  const {
    groupReinforcement,
    instructorEmail,
    instructorName,
    instructors,
    loading,
    operators,
    reinforcementDate,
    reinforcementId,
    selectedOperators,
    sending
  } = useSelector(state => state.reinforcement)
  const id = usePathParam('id')
  const minReinforcementDate = new Date(`${formatDate(new Date(), 'yyyy-MM')}-01T00:00:00`)

  const { error: instructorError, onChange: handleInstructorChange } = useSelect({
    initialValue: instructorEmail,
    errorCallbacks: [checkErrorRequired()],
    changeCallback: value => dispatch(onUpdateFormValue({ name: 'instructorEmail', value }))
  })

  const { error: reinforcementDateError, onChange: handleReinforcementDateChange } = useDatePicker({
    initialValue: reinforcementDate,
    errorCallbacks: [checkErrorRequired()],
    changeCallback: value => dispatch(onUpdateFormValue({ name: 'reinforcementDate', value }))
  })

  const { onChange: handleGroupReinforcementChange } = useCheckBox({
    initialValue: groupReinforcement,
    changeCallback: value => dispatch(onUpdateFormValue({ name: 'groupReinforcement', value }))
  })

  const { error: operatorsError, onChange: handleOperatorsChange } = useSelect({
    initialValue: '',
    changeCallback: value => dispatch(onAddOperatorThunk({ operator: operators.find(item => item.value === value) }))
  })

  useEffect(() => {
    if (instructors.length === 0 && !reinforcementId) {
      dispatch(onGetInstructorsThunk())
    }
  }, [reinforcementId])

  useEffect(() => {
    if (id !== undefined) {
      dispatch(onGetReinforcementThunk({ reinforcementId: id }))
    }
  }, [id])

  const handleRemoveOpertor = operatorId => () => dispatch(onRemoveOperatorThunk({ operatorId }))

  const instructorsData = useCallback(() => {
    return instructors.map(item => {
      return {
        name: `${item.instructorName} - ${item.instructorEmail}`,
        value: item.instructorEmail
      }
    })
  }, [instructors])

  const filterOperatorsData = useCallback(() => {
    return operators.filter(item => !selectedOperators.map(x => x.operatorId).includes(item.value))
  }, [operators, selectedOperators])
  const operatorsData = filterOperatorsData()

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} sm={6} md={6}>
        {!reinforcementId && (
          <Select
            disabled={sending}
            error={instructorError}
            label="Instructor"
            loading={loading}
            options={instructorsData()}
            placeholder="Seleccione"
            value={instructorEmail}
            onChange={handleInstructorChange}
          />
        )}
        {reinforcementId && (
          <Input disabled label="Instructor" loading={loading} value={`${instructorName} - ${instructorEmail}`} />
        )}
      </Grid>
      <Grid item xs={12} sm={6} md={6}>
        {!reinforcementId && (
          <DatePicker
            disabled={sending}
            disableFuture
            disableToolbar
            error={reinforcementDateError}
            label="Fecha"
            minDate={minReinforcementDate}
            value={reinforcementDate}
            variant="inline"
            width="100%"
            onChange={handleReinforcementDateChange}
          />
        )}
        {reinforcementId && (
          <Input
            label="Fecha"
            disabled
            loading={loading}
            suffix={<Calendar />}
            value={!loading && reinforcementDate !== '' && formatDate(reinforcementDate, 'dd/MM/yyyy')}
            variant="inline"
            width="100%"
          />
        )}
      </Grid>
      <Grid item xs={12} sm={6} md={6}>
        <Typography fontWeight="bold" margin="0 0 0.5rem" variant="body1">
          Reforzamiento
        </Typography>
        <CheckBox
          checked={groupReinforcement}
          disabled={reinforcementId || sending}
          label="Reforzamiento grupal"
          onChange={handleGroupReinforcementChange}
        />
        {groupReinforcement && !reinforcementId && (
          <Select
            disabled={operatorsData.length === 0 || sending}
            error={operatorsError}
            loading={loading}
            options={operatorsData}
            placeholder="Seleccione"
            value=""
            onChange={handleOperatorsChange}
          />
        )}
      </Grid>
      <Grid item xs={12} sm={6} md={6}>
        <Container>
          <Typography margin="0 8px 0 0" variant="body2">
            Operador:
          </Typography>
          <Container flexDirection="row" flexWrap="wrap">
            {selectedOperators.map(operator => {
              if (selectedOperators.length === 1 || reinforcementId) {
                return (
                  <Chip
                    disabled={sending}
                    key={`operator-${operator.operatorId}`}
                    label={operator.operatorName}
                    margin="0 5px 5px"
                  />
                )
              } else {
                return (
                  <Chip
                    disabled={sending}
                    key={`operator-${operator.operatorId}`}
                    label={operator.operatorName}
                    margin="0 5px 5px"
                    onDelete={handleRemoveOpertor(operator.operatorId)}
                  />
                )
              }
            })}
          </Container>
        </Container>
      </Grid>
    </Grid>
  )
}

export default ReinforcementContent
