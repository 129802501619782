import React from 'react'
import { batch, useSelector, useDispatch } from 'react-redux'
import { usePagination } from '../../components/Tables/Pagination.hooks'
import { ANOMALIE_DEFINITIONS } from '../../config/settings/constant'
import Button from '../../components/Buttons/Button'
import TIreAnomalyModal from './TiresModalContent.container'
import Pagination from '../../components/Tables/Pagination'
import { Root } from './Tires.styles'
import Table from '../../components/Tables/Table'
import { onToggleTireAnomalyModalSuccess, onAddTireAnomalyModalThunk } from './Tires.actions'
import TableCell from '../../components/Tables/TableCell'
import TableRow from '../../components/Tables/TableRow'
/**
 * The Tire Anomalie's container
 */

const TireAnomalyContainer = () => {
  const dispatch = useDispatch()
  const { anomalyData, showTireAnomalyModal } = useSelector(state => state.anomalyTires)
  const { page, totalPages, onChangePage, onGetCurrentPage } = usePagination({
    data: anomalyData,
    initialPage: 1,
    rowsPerPage: 10
  })

  const onToggleModal = row => {
    batch(() => {
      dispatch(onAddTireAnomalyModalThunk(row))
      dispatch(onToggleTireAnomalyModalSuccess())
    })
  }

  return (
    <>
      <Root>
        <Table accessibility={{ label: 'Anomalies' }}>
          <TableRow head>
            <TableCell>Equipo</TableCell>
            <TableCell>Anomalia</TableCell>
            <TableCell>Posición Neumático</TableCell>
            <TableCell>Fecha Inicio</TableCell>
            <TableCell>Fecha Fin</TableCell>
            <TableCell>Duración (s)</TableCell>
            <TableCell>Carga</TableCell>
            <TableCell>Velocidad últimos 5 minutos</TableCell>
            <TableCell>Mapa</TableCell>
          </TableRow>

          {onGetCurrentPage().map((row, index) => {
            return (
              <TableRow key={`operador-${index}`}>
                <TableCell align="center">{row.camion}</TableCell>
                <TableCell align="center">{ANOMALIE_DEFINITIONS[row.anomaly]}</TableCell>
                <TableCell align="center">{row.posNeu}</TableCell>
                <TableCell align="center">{row.dateMin}</TableCell>
                <TableCell align="center">{row.dateMax}</TableCell>
                <TableCell align="center">{row.duration}</TableCell>
                <TableCell align="center">{row.tons}</TableCell>
                <TableCell align="center">{row.maxVelocityPast}</TableCell>
                <TableCell align="center" minWidth="150px">
                  <Button color="primary" variant="contained" size="small" onClick={() => onToggleModal(row)}>
                    Ver más
                  </Button>
                </TableCell>
              </TableRow>
            )
          })}
        </Table>
        {showTireAnomalyModal && <TIreAnomalyModal />}
        <Pagination alignment="right" count={totalPages} page={page} onChange={onChangePage} />
      </Root>
    </>
  )
}

export default TireAnomalyContainer
