import React, { useEffect, useRef } from 'react'
import { CSVLink } from 'react-csv'
import NewAnomalieModal from './NewAnomalyModalContent.container'
import { Separator, Wrapper, Tablediv, Frame } from './NewAnomaly.styles'
import { Box, Paper } from '@material-ui/core'
import { batch, useSelector, useDispatch } from 'react-redux'
import LoadingContent from '../../components/Placeholders/LoadingContent'
import DisconnectedData from '../FuelLevel/DisconnectedData.container'
import { usePagination } from '../../components/Tables/Pagination.hooks'
import Pagination from '../../components/Tables/Pagination'
import Table from '../../components/Tables/Table'
import TableCell from '../../components/Tables/TableCell'
import { useSelect } from '../../components/Inputs/Inputs.hooks'
import Select from '../../components/Inputs/Select'
import TableRow from '../../components/Tables/TableRow'
import Button from '../../components/Buttons/Button'
import Tag from '../../components/Basics/Tag'
import Typography from '../../components/Basics/Typography'

import {
  onToggleNewAnomalyModalSuccess,
  onAddNewAnomalyModalThunk,
  onGetNewAnomaliesTableType
} from './NewAnomaly.actions'

const NewAnomalyTable = () => {
  const dispatch = useDispatch()
  const csvLink = useRef()
  const {
    loadingNew,
    error,
    newAnomalies,
    anomaliesTable,
    showNewAnomalyModal,
    totalAnomalies,
    totalFuelLiters,
    firstDate,
    lastDate,
    reportSuccess,
    csvArray
  } = useSelector(state => state.newAnomaly)
  const { emptyAnomalies } = useSelector(state => state.newAnomalyTopbar)

  useEffect(() => {
    onChangePage('', 1)
  }, [anomaliesTable])

  const { page, totalPages, onChangePage, onGetCurrentPage } = usePagination({
    data: anomaliesTable,
    initialPage: 1,
    rowsPerPage: 10
  })

  const { error: anomaliesError, value: value1, onChange: handleAnomaliesChange } = useSelect({
    initialValue: '',
    changeCallback: value => dispatch(onGetNewAnomaliesTableType([value, newAnomalies.anomalies]))
  })

  const onToggleModal = row => {
    batch(() => {
      dispatch(onAddNewAnomalyModalThunk(row))
      dispatch(onToggleNewAnomalyModalSuccess())
    })
  }

  return (
    <>
      {loadingNew && <LoadingContent />}
      {!loadingNew && error && <DisconnectedData />}
      {!loadingNew && !error && (
        <>
          {emptyAnomalies ? (
            <>
              <Table accesibility={{ label: 'Anomalies' }}>
                <TableRow head>
                  <TableCell minWidth="150px">CAEX</TableCell>
                  <TableCell minWidth="150px">Fecha</TableCell>
                  <TableCell minWidth="150px">Hora Inicio</TableCell>
                  <TableCell minWidth="150px">Litros anómalos</TableCell>
                  <TableCell minWidth="150px">Tipo de Anomalía</TableCell>
                  <TableCell minWidth="150px">Tipo carga</TableCell>
                  <TableCell minWidth="150px">Estación</TableCell>
                  <TableCell minWidth="150px">Detalles</TableCell>
                </TableRow>
              </Table>
              <Frame>
                <Typography variant="body2">No hay anomalías en el período seleccionado</Typography>
              </Frame>
            </>
          ) : (
            <>
              <Box>
                <Typography variant="h3">Registro de abastecimiento anómalo</Typography>
                <Typography variant="subtitle" align="left" lineHeight="40px">
                  {firstDate.format('MMMM [de] YYYY')} - {lastDate.format('MMMM [de] YYYY')}
                </Typography>
                <Separator />
                <Tablediv>
                  <Tag color="primary" margin="5px" text={totalAnomalies} />
                  <Typography variant="body1" align="center" lineHeight="40px">
                    Anomalías detectadas
                  </Typography>
                  <Tag color="primary" margin="5px" text={totalFuelLiters} />
                  <Typography variant="body1" align="center" lineHeight="40px">
                    Total combustible irregular
                  </Typography>
                </Tablediv>
                <Paper style={{ overflow: 'auto' }}>
                  <Pagination alignment="right" count={totalPages} page={page} onChange={onChangePage} />
                  <Table accesibility={{ label: 'Anomalies' }}>
                    <TableRow head>
                      <TableCell>CAEX</TableCell>
                      <TableCell>Fecha</TableCell>
                      <TableCell>Hora Inicio</TableCell>
                      <TableCell>Litros anómalos</TableCell>
                      <TableCell>Tipo de Anomalía</TableCell>
                      <TableCell>Tipo carga</TableCell>
                      <TableCell>Estación</TableCell>
                      <TableCell>Detalles</TableCell>
                    </TableRow>

                    {onGetCurrentPage().map((row, index) => {
                      return (
                        <TableRow key={`anomalie-${index}`}>
                          <TableCell align="center" minWidth="150px">
                            {row.truck}
                          </TableCell>
                          <TableCell align="center" minWidth="150px">
                            {row.truckDate}
                          </TableCell>
                          <TableCell align="center" minWidth="150px">
                            {row.hourAnomaly[0]}
                          </TableCell>
                          <TableCell align="center" minWidth="150px">
                            {row.fuelLitres}
                          </TableCell>
                          <TableCell align="center" minWidth="150px">
                            {row.fuelLoadType}
                          </TableCell>
                          <TableCell align="center" minWidth="150px">
                            {row.isConcatenated}
                          </TableCell>
                          <TableCell align="center" minWidth="150px">
                            {row.stations[0]}
                          </TableCell>
                          <TableCell align="center" minWidth="150px">
                            <Button color="primary" variant="contained" size="small" onClick={() => onToggleModal(row)}>
                              Ver más
                            </Button>
                          </TableCell>
                        </TableRow>
                      )
                    })}
                  </Table>
                </Paper>
                <Wrapper>
                  {reportSuccess ? (
                    <div
                      style={{
                        width: '200px',
                        flexDirection: 'row',
                        display: 'flex',
                        alignItems: 'baseline',
                        margin: '5px 650px 0 0'
                      }}
                    >
                      <CSVLink
                        style={{ textDecoration: 'none' }}
                        data={csvArray[0]}
                        filename="Anomalías.csv"
                        ref={csvLink}
                        target="_blank"
                      >
                        <Button color="primary" size="small" variant="outlined">
                          Descargar
                        </Button>
                      </CSVLink>
                    </div>
                  ) : (
                    <Button loading color="primary" size="small" variant="outlined" margin="5px 650px 0 0">
                      Cargando
                    </Button>
                  )}
                  <div
                    style={{
                      width: '200px',
                      flexDirection: 'row',
                      display: 'flex',
                      alignItems: 'center'
                    }}
                  >
                    <Typography variant="body2" fontWeight="bold">
                      Ver: &nbsp;
                    </Typography>
                    <Select
                      error={anomaliesError}
                      options={[
                        { name: 'Todas', value: 'value-1' },
                        { name: 'Supera capacidad', value: 'value-2' },
                        { name: 'Carga pequeña', value: 'value-3' }
                      ]}
                      placeholder="Todas"
                      value={value1}
                      onChange={handleAnomaliesChange}
                    />
                  </div>
                  <Pagination alignment="right" count={totalPages} page={page} onChange={onChangePage} />
                </Wrapper>
              </Box>
              {showNewAnomalyModal && <NewAnomalieModal />}
            </>
          )}
        </>
      )}
    </>
  )
}

export default NewAnomalyTable
