import React from 'react'
import PropTypes from 'prop-types'
import { Badge, Typography, Grid, withStyles } from '@material-ui/core'
import { format } from 'date-fns'
// Import the new driver icon
import driverIcon from '../../constants/images/driver.svg'

const StyledBadge = withStyles(theme => ({
  badge: props => ({
    right: -20, // Increase this value to move the badge further to the right
    top: 15,
    padding: '0 4px',
    whiteSpace: 'nowrap', // Ensures the content stays on a single line
    backgroundColor: props.color, // Set the background color from props
    color: '#fff' // White text color for contrast
  })
}))(Badge)

const TimeBadge = withStyles(theme => ({
  badge: props => ({
    right: -20,
    top: 35,
    padding: '0 4px',
    whiteSpace: 'nowrap', // Ensures the content stays on a single line
    backgroundColor: props.color, // Dark gray background
    color: '#fff' // White text color for contrast
  })
}))(Badge)

const DriverVehicle = ({ driverData, color }) => {
  const roundedMinutes = driverData.duration_minutes.toFixed(1)
  const startTime = format(new Date(driverData.start_time), 'HH:mm')

  return (
    <Grid container direction="column" alignItems="center" style={{ margin: '10px' }}>
      <StyledBadge badgeContent={<span>{`${roundedMinutes} mins`}</span>} color={color}>
        <TimeBadge badgeContent={<span>{startTime}</span>} color={color}>
          <img src={driverIcon} alt="Driver Icon" style={{ width: '50px' }} />
        </TimeBadge>
      </StyledBadge>
      <div
        style={{
          backgroundColor: '#fff',
          padding: '2px 8px',
          borderRadius: '14px',
          marginTop: '5px',
          border: '1px solid #000', // Black outline
          lineHeight: '1.5' // Adjust height
        }}
      >
        <Typography variant="body2" style={{ color: '#000', textAlign: 'center' }}>
          {driverData.operator}
        </Typography>
      </div>
    </Grid>
  )
}

DriverVehicle.propTypes = {
  driverData: PropTypes.shape({
    operator: PropTypes.string.isRequired,
    start_time: PropTypes.number.isRequired,
    duration_minutes: PropTypes.number.isRequired
  }).isRequired,
  color: PropTypes.string.isRequired
}

export default DriverVehicle
