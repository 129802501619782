// FIXME: Need to be refactored
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import KpiHistoryModal from './KpiHistoryModal.container'
import { TableRow, TableCell } from '@material-ui/core'

/**
 * The KpiRowDesktop' container.
 */
const KpiRowDesktop = ({ data, historyData, kpis }) => {
  const [open, setOpen] = useState(false)

  const getValue = (group, key) => {
    const groupsSelected = kpis.find(kpi => kpi.group === group)
    return new Intl.NumberFormat('de-DE').format(groupsSelected.values[key])
  }

  return (
    <TableRow key={data.key}>
      <TableCell component="th" scope="row">
        <p>
          {data.name}
          <span style={{ color: '#FA6A09', marginLeft: '20px', cursor: 'pointer' }} onClick={() => setOpen(true)}>
            ver +
          </span>
        </p>
        {open && <KpiHistoryModal values={historyData} metric={data.name} open={open} setOpen={setOpen} />}
      </TableCell>
      <TableCell align="right">{getValue('G1', data.key)}</TableCell>
      <TableCell align="right">{getValue('G2', data.key)}</TableCell>
      <TableCell align="right">{getValue('G3', data.key)}</TableCell>
      <TableCell align="right">{getValue('G4', data.key)}</TableCell>
    </TableRow>
  )
}

KpiRowDesktop.propTypes = {
  data: PropTypes.object,
  historyData: PropTypes.any,
  kpis: PropTypes.any
}

export default KpiRowDesktop
