import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Modal from '../../components/Basics/Modal'
import { usePagination } from '../../components/Tables/Pagination.hooks'
import { onToggleOperatorRowModalSuccess } from './Tires.actions'
import { ANOMALIE_DEFINITIONS } from '../../config/settings/constant'
import Pagination from '../../components/Tables/Pagination'
import { Grid } from './Tires.styles'
import Table from '../../components/Tables/Table'
import TableCell from '../../components/Tables/TableCell'
import TableRow from '../../components/Tables/TableRow'
import Typography from '../../components/Basics/Typography'
import { capitalizeNames } from '../../modules/utils/helpers'

/**
 * The Tire Operator Anomalie's container
 */

const TireOperatorAnomalyModal = () => {
  const dispatch = useDispatch()
  const { rowOperatorSelected, rowOperator, showOperatorRowModal } = useSelector(state => state.anomalyTires)
  const { page, totalPages, onChangePage, onGetCurrentPage } = usePagination({
    data: rowOperatorSelected,
    initialPage: 1,
    rowsPerPage: 10
  })
  const handleClose = () => dispatch(onToggleOperatorRowModalSuccess())

  return (
    <>
      <Modal open={showOperatorRowModal} onClose={handleClose}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={12}>
            <Typography variant="h1">{capitalizeNames(rowOperator)}</Typography>
            <Table accessibility={{ label: 'Anomalies' }}>
              <TableRow head>
                <TableCell>Equipo</TableCell>
                <TableCell>Anomalia</TableCell>
                <TableCell>Posición Neumático</TableCell>
                <TableCell>Fecha Inicio</TableCell>
                <TableCell>Fecha Fin</TableCell>
                <TableCell>Duración (s)</TableCell>
                <TableCell>Carga</TableCell>
                <TableCell>Velocidad últimos 5 minutos</TableCell>
              </TableRow>

              {onGetCurrentPage().map((row, index) => {
                return (
                  <TableRow key={`operador-${index}`}>
                    <TableCell align="center">{row.camion}</TableCell>
                    <TableCell align="center">{ANOMALIE_DEFINITIONS[row.anomaly]}</TableCell>
                    <TableCell align="center">{row.posNeu}</TableCell>
                    <TableCell align="center">{row.dateMin}</TableCell>
                    <TableCell align="center">{row.dateMax}</TableCell>
                    <TableCell align="center">{row.duration}</TableCell>
                    <TableCell align="center">{row.tons}</TableCell>
                    <TableCell align="center">{row.maxVelocityPast}</TableCell>
                  </TableRow>
                )
              })}
            </Table>
            <Pagination alignment="right" count={totalPages} page={page} onChange={onChangePage} />
          </Grid>
        </Grid>
      </Modal>
    </>
  )
}

export default TireOperatorAnomalyModal
