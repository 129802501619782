import React from 'react'
import Chart from 'react-apexcharts'
import PropTypes from 'prop-types'

const MyGroupedColumnChart = props => {
  const { consumptionData } = props

  // Transform the input data
  const series = consumptionData[0].y.map((_, index) => ({
    name: `Semana ${index + 1}`,
    data: consumptionData.map(item => item.y[index] || 0)
  }))

  const options = {
    chart: {
      type: 'bar',
      height: 350
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '55%',
        endingShape: 'rounded',
        dataLabels: {
          position: 'top'
        }
      }
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent']
    },
    xaxis: {
      categories: consumptionData.map(item => item.x)
    },
    yaxis: {
      title: {
        text: 'Consumption'
      }
    },
    fill: {
      opacity: 1
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return val + ' units'
        }
      }
    },
    title: {
      text: 'Rendimiento efectivo combustible medio por CAEX de las últimas 4 semanas (L/h)',
      align: 'center'
    }
  }

  return <Chart options={options} series={series} type="bar" height="100%" width="100%" />
}

MyGroupedColumnChart.propTypes = {
  consumptionData: PropTypes.array
}

export default MyGroupedColumnChart
