import styled from 'styled-components'

export const Wrapper = styled.div`
  align-items: baseline;
  display: flex;
`
export const Root = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  float: left;
  width: 100%;

  & .MuiTabs-root {
    margin-bottom: 10px;
  }
`
