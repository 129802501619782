import { makeActionCreator } from '../../config/store/utils'
import { getReportHTML } from '../../services/ReportService'
import { batch } from 'react-redux'

export const GET_REPORT_DATA = 'GET_REPORT_DATA'
export const GET_REPORT_DATA_ERROR = 'GET_REPORT_DATA_ERROR'
export const GET_REPORT_DATA_SUCCESS = 'GET_REPORT_DATA_SUCCESS'

export const onGetReport = makeActionCreator(GET_REPORT_DATA)
export const onGetReportError = makeActionCreator(GET_REPORT_DATA_ERROR, 'payload')
export const onGetReportSuccess = makeActionCreator(GET_REPORT_DATA_SUCCESS, 'payload')

export const onGetReportThunk = () => async (dispatch, getState) => {
  dispatch(onGetReport())
  try {
    const {
      apis: {
        base: { apiKey, apiName }
      }
    } = getState().auth

    const { data } = await getReportHTML({
      apiKey: apiKey,
      apiName: `${apiName}_V2`
    })

    return batch(() => {
      dispatch(onGetReportSuccess({ data }))
    })
  } catch (error) {
    console.error('Error fetching report:', error)
    return dispatch(onGetReportError({ error }))
  }
}
