import React, { useEffect } from 'react'
import { batch, useSelector, useDispatch } from 'react-redux'
import Modal from '../../components/Basics/Modal'
import { onToggleTireAnomalyModalSuccess, onGetTireMapThunk } from './Tires.actions'
import LoadingContent from '../../components/Placeholders/LoadingContent'
import DisconnectedData from '../FuelLevel/DisconnectedData.container'
import { ModalWrapper } from './Tires.styles'

/**
 * The Tire Anomalie's container
 */

const TIreAnomalyModal = () => {
  const dispatch = useDispatch()
  const { showTireAnomalyModal, anomalyselected, mapLoading, mapError, imagesData } = useSelector(
    state => state.anomalyTires
  )
  const handleClose = () => dispatch(onToggleTireAnomalyModalSuccess())
  const equipo = anomalyselected[0].camion
  const tire = anomalyselected[0].posNeu
  const anomalyType = anomalyselected[0].anomaly
  const anomalyGroup = anomalyselected[0].group
  const fileKey = 'anomalias_marzo_' + equipo + '_' + tire + '_' + anomalyType + '_' + anomalyGroup + '.png'

  useEffect(() => {
    batch(() => {
      dispatch(onGetTireMapThunk(fileKey))
    })
  }, [])

  return (
    <>
      <Modal open={showTireAnomalyModal} onClose={handleClose} maxWidth="25%">
        {mapLoading && <LoadingContent />}
        {!mapLoading && mapError && <DisconnectedData />}
        {!mapLoading && !mapError && (
          <>
            <ModalWrapper>
              <img src={imagesData} alt="map" />
            </ModalWrapper>
          </>
        )}
      </Modal>
    </>
  )
}

export default TIreAnomalyModal
