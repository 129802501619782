import React from 'react'
import Typography from '../../components/Basics/Typography'
import { Root } from './DisconnectedData.styles'

/**
 * The Disconnected data's container.
 */
const DisconnectedData = () => {
  return (
    <Root container alignItems="center" direction="column" justify="center">
      <img src="/assets/images/cosmos-black-logo.svg" alt="Universal Intelligence" />
      <hr />
      <Typography align="center" variant="body1">
        <br />
        La página solicitada no está disponible,
      </Typography>
      <Typography align="center" variant="body1">
        estamos en conversaciones con el equipo de soporte de mina para resolverlo
        <br />
      </Typography>
    </Root>
  )
}

export default DisconnectedData
