import React from 'react'
import routes from '../config/settings/routes'
import PasswordRecoveryContainer from '../containers/Authentication/PasswordRecovery.container'
import ProtectBySession from '../components/Security/ProtectBySession'

/**
 * The Password Recovery' page.
 */
const PasswordRecovery = () => {
  return (
    <ProtectBySession redirectTo={routes.root} rule={hasSession => !hasSession}>
      <PasswordRecoveryContainer />
    </ProtectBySession>
  )
}

export default PasswordRecovery
