import React from 'react'
import styled from 'styled-components'
import { Grid as BaseGrid } from '@material-ui/core'
import { Event } from '@material-ui/icons'

export const Calendar = styled(Event)`
  margin: 12px;
`

export const Container = styled(({ children, flexDirection, flexWrap, ...props }) => <div {...props}>{children}</div>)`
  display: flex;
  flex-direction: ${props => props.flexDirection};
  flex-wrap: ${props => props.flexWrap};
`

export const Grid = styled(BaseGrid)`
  margin: ${props => props.margin};
  padding: ${props => props.padding};
  width: ${props => props.width};
`

export const WarningIcon = styled.img`
  height: 25px;
  margin-right: -10px;
  width: 25px;
`
