import React from 'react'
import styled from 'styled-components'
import { Slider } from '@material-ui/core'

export const Root = styled(({ markPosition, ...rest }) => <Slider {...rest} />)`
  &.MuiSlider-root {
    color: ${props => props.theme.palette.brand.primary[50]};
    margin: ${props => props.margin};
    padding: ${props => props.padding};

    &.MuiSlider-marked {
      margin-bottom: ${props => (props.markPosition === 'bottom' ? '20px' : '0')};
      margin-top: ${props => (props.markPosition === 'top' ? '20px' : '0')};
    }

    .MuiSlider-markLabel {
      color: ${props => props.theme.palette.brand.oxfordBlue};
      font-weight: ${props => props.theme.fontWeights[600]};
      transform: ${props => (props.markPosition === 'bottom' ? 'translateX(-50%)' : 'translate(-50%, -200%)')};
    }

    .MuiSlider-rail {
      background-color: ${props => props.theme.palette.brand.ghost};
      opacity: 1;
    }

    .MuiSlider-mark {
      background-color: transparent;
    }

    .MuiSlider-valueLabel > span > span {
      color: ${props => props.theme.palette.brand.white};
    }
  }
`
