export const ERROR = 'ERROR'
export const REQUEST = '_REQUEST'
export const SUCCESS = '_SUCCESS'
export const FAILURE = '_FAILURE'
export const RELOAD = 'RELOAD'
export const RESIZE = 'RESIZE'

// fuel levels
export const FETCH_TRUCKS = 'FETCH_TRUCKS'
export const FETCH_TANKERS = 'FETCH_TANKERS'
export const FETCH_SERVICES_STATUS = 'FETCH_SERVICES_STATUS'
export const FETCH_EFFICIENCY = 'FETCH_EFFICIENCY'

// operational practices
export const OPERATOR_RANKINGS = 'OPERATOR_RANKINGS'
export const OPERATOR_FEEDBACK = 'OPERATOR_FEEDBACK'
export const FEEDBACK_MODAL = 'OPEN_FEEDBACK_MODAL'
export const REPORT_TRAINING = 'REPORT_TRAINING'

// anomalies
export const ANOMALIES_SUMMARY = 'ANOMALIES_SUMMARY'
export const MONTHLY_ANOMALIES = 'MONTHLY_ANOMALIES'
export const REPORT_ANOMALY = 'REPORT_ANOMALY'
export const ERROR_ANOMALY = 'ERROR_ANOMALY'
