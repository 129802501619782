import {
  CHANGE_IDLE_RPM_GROUP_PAGE,
  CHANGE_IDLE_RPM_GROUP_SELECTED,
  CHANGE_IDLE_RPM_GROUP_SELECTED_ERROR,
  CHANGE_IDLE_RPM_GROUP_SELECTED_SUCCESS,
  GET_IDLE_RPM,
  GET_IDLE_RPM_ERROR,
  GET_IDLE_RPM_GROUP,
  GET_IDLE_RPM_GROUP_ERROR,
  GET_IDLE_RPM_GROUP_SUCCESS,
  GET_IDLE_RPM_SUCCESS,
  UPDATE_OPERATORS_IDLE_RPM,
  GET_IDLE_RPM_REPORT,
  GET_IDLE_RPM_REPORT_SUCCESS,
  GET_IDLE_RPM_REPORT_ERROR
} from './IdleRpm.actions'

const idleRpmState = {
  consultationDate: '',
  data: [],
  error: false,
  groups: [],
  loading: true,
  selectedGroupId: false,
  totalLitersOperators: 0,
  meanIdleRpmTotal: 0,
  p80Total: 0,
  reportLoading: false,
  reportSuccess: false,
  reportData: [],
  reportError: false
}

/**
 * The idle rpm's reducer.
 */
const idleRpmReducer = (state = idleRpmState, { payload, type }) => {
  switch (type) {
    case GET_IDLE_RPM: {
      return { ...state, loading: true, error: false }
    }
    case GET_IDLE_RPM_ERROR: {
      return { ...state, loading: false, error: payload.error }
    }
    case GET_IDLE_RPM_SUCCESS: {
      return { ...state, loading: false, ...payload }
    }
    case CHANGE_IDLE_RPM_GROUP_PAGE: {
      return {
        ...state,
        [payload.groupId]: {
          ...state[payload.groupId],
          page: payload.page
        }
      }
    }
    case GET_IDLE_RPM_GROUP:
    case CHANGE_IDLE_RPM_GROUP_SELECTED:
    case CHANGE_IDLE_RPM_GROUP_SELECTED_SUCCESS: {
      return { ...state, selectedGroupId: payload.selectedGroupId }
    }
    case GET_IDLE_RPM_GROUP_ERROR:
    case CHANGE_IDLE_RPM_GROUP_SELECTED_ERROR: {
      return {
        ...state,
        [payload.selectedGroupId]: {
          ...state[payload.selectedGroupId],
          error: payload.error,
          loading: false
        }
      }
    }
    case GET_IDLE_RPM_GROUP_SUCCESS: {
      return {
        ...state,
        [payload.selectedGroupId]: {
          loading: false,
          error: false,
          page: 1,
          data: payload.data
        }
      }
    }
    case UPDATE_OPERATORS_IDLE_RPM: {
      return {
        ...state,
        [payload.selectedGroupId]: {
          ...state[payload.selectedGroupId],
          data: payload.data
        }
      }
    }
    case GET_IDLE_RPM_REPORT: {
      return {
        ...state,
        reportLoading: !state.reportLoading,
        reportError: false,
        reportSuccess: false
      }
    }
    case GET_IDLE_RPM_REPORT_SUCCESS: {
      return {
        ...state,
        reportLoading: !state.reportLoading,
        reportData: payload.reportData,
        reportError: false,
        reportSuccess: true
      }
    }
    case GET_IDLE_RPM_REPORT_ERROR: {
      return {
        ...state,
        reportLoading: !state.reportLoading,
        reportSuccess: false,
        reportError: true
      }
    }
    default: {
      return state
    }
  }
}

export default idleRpmReducer
