import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import DisconnectedData from '../FuelLevel/DisconnectedData.container'
import LoadingContent from '../../components/Placeholders/LoadingContent'
import Chart from 'react-apexcharts'
import { onGetGraphSuccessThunk } from '../FuelLevelBoxplots/BoxPlotGraph.actions'
import { GraphWrapper, ControlGraphdiv } from './BoxPlotGraph.styles'
import { useSelect } from '../../components/Inputs/Inputs.hooks'
import { checkErrorRequired } from '../../modules/errors/validations'
import Select from '../../components/Inputs/Select'
import theme from '../../config/styles/theme'

const FuelLevelGraphs = () => {
  const dispatch = useDispatch()
  const [currentTruck, setCurrentTruck] = useState({
    truck: '',
    series: [],
    top: 0,
    bottom: 0,
    mean: 0
  })
  const { error, loading, orpakGraphData } = useSelector(state => state.fuelLevelGraphs)

  const onChangeTruck = value1 => {
    const currTruck = orpakGraphData[value1]
    setCurrentTruck({
      truck: value1,
      series: currTruck.data,
      top: currTruck.top,
      bottom: currTruck.bottom,
      mean: currTruck.mean
    })
  }

  const { error: error1, value: value1, onChange: handleValue1Change } = useSelect({
    errorCallbacks: [checkErrorRequired()],
    changeCallback: onChangeTruck
  })

  useEffect(() => {
    if (Object.keys(orpakGraphData).length === 0) {
      dispatch(onGetGraphSuccessThunk())
    }
  }, [])

  const state = {
    optionsOrpak: {
      chart: {
        name: 'Cantidad',
        id: 'basic-line'
      },
      stroke: {
        curve: 'straight'
      },
      yaxis: {
        max: max => {
          return Math.ceil((max + 500) / 100) * 100
        },
        min: min => {
          return Math.ceil((min - 500) / 100) * 100
        },
        title: {
          text: 'Litros'
        }
      },
      xaxis: {
        type: 'datetime'
      },
      markers: {
        size: 5
      },
      title: {
        text: 'Gráfico de control del volumen de combustible abastecido (L) en los últimos 15 días',
        align: 'middle',
        style: {
          fontSize: '16px',
          fontWeight: 'bold',
          fontFamily: theme.typography.fontFamily
        }
      },
      tooltip: {
        x: {
          format: 'dd MMM, yyyy HH:mm:ss'
        }
      },
      annotations: {
        yaxis: [
          {
            y: currentTruck.top,
            y2: currentTruck.bottom,
            opacity: 0.5
          },
          {
            y: currentTruck.top,
            borderColor: theme.palette.brand.error[50],
            label: {
              borderColor: theme.palette.brand.error[50],
              style: {
                color: theme.palette.brand.white,
                background: theme.palette.brand.error[50]
              },
              text: 'Limite superior 20%'
            }
          },
          {
            y: currentTruck.bottom,
            borderColor: theme.palette.brand.error[50],
            label: {
              borderColor: theme.palette.brand.error[50],
              style: {
                color: theme.palette.brand.white,
                background: theme.palette.brand.error[50]
              },
              text: 'Limite inferior 30%'
            }
          },
          {
            y: currentTruck.mean,
            borderColor: theme.palette.brand.success[50],
            label: {
              borderColor: theme.palette.brand.success[50],
              style: {
                color: theme.palette.brand.white,
                background: theme.palette.brand.success[50]
              },
              text: 'Promedio cantidad'
            }
          }
        ]
      }
    },
    seriesOrpak: [
      {
        name: 'Cantidad',
        data: currentTruck.series
      }
    ]
  }

  return (
    <>
      {loading && !error && <LoadingContent />}
      {error && !loading && <DisconnectedData />}
      {!loading && !error && (
        <GraphWrapper>
          <ControlGraphdiv>
            <Chart options={state.optionsOrpak} series={state.seriesOrpak} type="line" width="100%" height="100%" />
          </ControlGraphdiv>
          <div style={{ width: '15%' }}>
            <Select
              error={error1}
              label="Seleccione equipo"
              options={[
                { name: 'C03', value: 'C03' },
                { name: 'C04', value: 'C04' },
                { name: 'C05', value: 'C05' },
                { name: 'C13', value: 'C13' },
                { name: 'C16', value: 'C16' },
                { name: 'C18', value: 'C18' },
                { name: 'C20', value: 'C20' },
                { name: 'C21', value: 'C21' },
                { name: 'C23', value: 'C23' },
                { name: 'C25', value: 'C25' },
                { name: 'C26', value: 'C26' },
                { name: 'C28', value: 'C28' },
                { name: 'C29', value: 'C29' },
                { name: 'C30', value: 'C30' },
                { name: 'C31', value: 'C31' },
                { name: 'C100', value: 'C100' },
                { name: 'C101', value: 'C101' },
                { name: 'C102', value: 'C102' },
                { name: 'C103', value: 'C103' }
              ]}
              placeholder="select"
              value={value1}
              onChange={handleValue1Change}
            />
          </div>
        </GraphWrapper>
      )}
    </>
  )
}

export default FuelLevelGraphs
