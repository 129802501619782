import { RELOAD, REQUEST, RESIZE, SUCCESS } from '../actions/types'

const initialReload = {
  reload: false,
  loading: false,
  device: 'web', // or 'mobile'
  column2: 2,
  column3: 3,
  path: ''
}

export default function (state = initialReload, action) {
  switch (action.type) {
    case RELOAD + REQUEST:
      return {
        ...state,
        reload: true,
        path: action.payload
      }
    case RELOAD + SUCCESS:
      return {
        ...state,
        reload: false,
        path: ''
      }
    case RESIZE:
      return {
        ...state,
        device: action.payload.device,
        column2: action.payload.column2,
        column3: action.payload.column3
      }
    default:
      return state
  }
}
