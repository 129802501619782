// FIXME: Need to be refactored
import React from 'react'
import PropTypes from 'prop-types'
import LoadingContent from '../../components/Placeholders/LoadingContent'
import MobileStationContent from './MobileStationContent.container'
// import { ResumenMobileStations } from './ResumenMobileStations'
// import { getDataStation } from '../../services/stationsManagementServices'
import useStyles from '../../styles/stationStyle'
import { Typography } from '@material-ui/core'

/**
 * The MobileStationScreen' container.
 */
const MobileStationScreen = ({ data, loading }) => {
  // const [loading, setLoading] = useState(true)
  // const [mobileData, setMobileData] = useState({})

  // useEffect(() => {
  //   getDataMobile()
  // }, [])

  // const getDataMobile = async () => {
  //   const response = await getDataStation('movil')
  //   setMobileData(response)
  //   setLoading(false)
  // }

  const classes = useStyles()

  return (
    <>
      {loading && <LoadingContent />}
      {!loading && (
        <div style={{ margin: '20px' }}>
          {/* <Grid container direction='row' justify='flex-end' alignItems='flex-end'>
            <p><strong>Periodo observado: </strong> {`${data.start_date} al ${data.end_date}`} </p>
          </Grid> */}
          {/* <ResumenMobileStations total={data.totals} chartsData={data.pie_chart} /> */}
          <Typography className={classes.titleResumen}>
            <strong>Gestión de estaciones móviles por grupo: </strong>todos los abastecimientos y tiempo entre cada uno
          </Typography>
          <hr />
          <MobileStationContent groups={data.group_tables} />
        </div>
      )}
    </>
  )
}

MobileStationScreen.propTypes = {
  data: PropTypes.object,
  loading: PropTypes.bool
}

export default MobileStationScreen
