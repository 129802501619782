import { API } from 'aws-amplify'
import { REACT_APP_BASE_KEY, REACT_APP_BASE_NAME, REACT_APP_CLIENT_NAME } from '../config/settings/environment'

export const getKpis = (month = false, year = false) => {
  let path = ''
  if (month && year) {
    const paths = {
      amsa: `/fuel_levels/dispatch/kpi_monitor?month=${month}&year=${year}`,
      anglo: `/v2/fuel_levels/dispatch/kpi_monitor?month=${month}&year=${year}`,
      zaldivar: `/v2/fuel_levels/dispatch/kpi_monitor?month=${month}&year=${year}`
    }
    path = paths[REACT_APP_CLIENT_NAME] || `/v2/fuel_levels/dispatch/kpi_monitor?month=${month}&year=${year}`
  } else {
    const paths = {
      amsa: '/fuel_levels/dispatch/kpi_monitor',
      anglo: '/v2/fuel_levels/dispatch/kpi_monitor',
      zaldivar: '/v2/fuel_levels/dispatch/kpi_monitor'
    }
    path = paths[REACT_APP_CLIENT_NAME] || '/v2/fuel_levels/dispatch/kpi_monitor'
  }

  const init = {
    headers: {
      'x-api-key': REACT_APP_BASE_KEY
    },
    response: true
  }

  return API.get(REACT_APP_BASE_NAME, path, init)
    .then(response => {
      return response.data
    })
    .catch(error => {
      console.error('[API][GET][Compare] ' + JSON.stringify(error))
    })
}

export const getDateAvailables = () => {
  const paths = {
    amsa: '/fuel_levels/dispatch/kpi_monitor/available',
    anglo: '/v2/fuel_levels/dispatch/kpi_monitor/available',
    zaldivar: '/v2/fuel_levels/dispatch/kpi_monitor/available'
  }
  const path = paths[REACT_APP_CLIENT_NAME] || '/v2/fuel_levels/dispatch/kpi_monitor/available'

  const init = {
    headers: {
      'x-api-key': REACT_APP_BASE_KEY
    },
    response: true
  }

  return API.get(REACT_APP_BASE_NAME, path, init)
    .then(response => {
      const rawData = response.data
      const dataProcess = []
      for (const data of rawData) {
        const month = data.month < 10 ? `0${data.month}` : data.month
        data.value = `${month}/${data.year}`
        dataProcess.push(data)
      }

      dataProcess.push({ value: 'actual' })
      return dataProcess
    })
    .catch(error => {
      console.error('[API][GET][DISPATCH] ' + JSON.stringify(error))
    })
}

export const getHistoryData = () => {
  const paths = {
    amsa: '/fuel_levels/dispatch/historical_kpi_monitor',
    anglo: '/v2/fuel_levels/dispatch/historical_kpi_monitor',
    zaldivar: '/v2/fuel_levels/dispatch/historical_kpi_monitor'
  }
  const path = paths[REACT_APP_CLIENT_NAME] || '/v2/fuel_levels/dispatch/historical_kpi_monitor'

  const init = {
    headers: {
      'x-api-key': REACT_APP_BASE_KEY
    },
    response: true
  }

  return API.get(REACT_APP_BASE_NAME, path, init)
    .then(response => {
      return response.data
    })
    .catch(error => {
      console.error('[API][GET][DISPATCH] ' + JSON.stringify(error))
    })
}
