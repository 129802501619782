import styled from 'styled-components'
import { Grid } from '@material-ui/core'

export const Root = styled(Grid)`
  min-height: calc(100vh - 170px);
`

export const Strong = styled.span`
  font-weight: bold;
`
