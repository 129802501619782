import React from 'react'
import ReactDOM from 'react-dom'
import { Provider as ReduxProvider } from 'react-redux'
import { StylesProvider, ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles'
import { CssBaseline } from '@material-ui/core'
import { ThemeProvider as StyledThemeProvider } from 'styled-components'
import * as Package from '../package.json'
import * as serviceWorker from './serviceWorker'
import initializeAnalytics from './config/settings/analytics'
import storeSingleton from './config/store/store'
import theme from './config/styles/theme'
import Logger from './modules/debuggers/logger'
import Routes from './Routes'

initializeAnalytics()

const store = storeSingleton()
const logger = Logger.getLogger('App')

logger.info(`React v${React.version}`)
logger.info(`Cosmos Fuel v${Package.version}`)

// FIXME: In order to avoid findDOMNode is deprecated in StrictMode error, I comment the React.StrictMode (Yeah it's annoying)
// This should be solve in Material-ui v5, which is still in alpha.
ReactDOM.render(
  /* <React.StrictMode> */
  <ReduxProvider store={store}>
    <StylesProvider injectFirst>
      <MuiThemeProvider theme={theme}>
        <StyledThemeProvider theme={theme}>
          <CssBaseline />
          <Routes />
        </StyledThemeProvider>
      </MuiThemeProvider>
    </StylesProvider>
  </ReduxProvider>,
  /* </React.StrictMode> */
  document.getElementById('__root')
)

serviceWorker.unregister()
