import React from 'react'
import { CONSUMPTION_OPERATOR_MODAL_TITLE } from '../config/settings/constant'
import routes from '../config/settings/routes'
import { usePathParam } from '../modules/hooks/route'
import ManagementSectionContainer from '../containers/ManagementMenu/ManagementMenu.container'
import NavbarContainer from '../containers/Navbar/Navbar.container'
import ReinforcementContentContainer from '../containers/Reinforcement/ReinforcementContent.container'
import ReinforcementFeedbackModalContainer from '../containers/ReinforcementFeedbackModal/ReinforcementFeedbackModal.container'
import ReinforcementFooterContainer from '../containers/Reinforcement/ReinforcementFooter.container'
import ReinforcementHeaderContainer from '../containers/Reinforcement/ReinforcementHeader.container'
import BrandLayout from '../components/Layouts/BrandLayout'
import Heading from '../components/Layouts/Heading'
import ProtectByFeatures from '../components/Security/ProtectByFeatures'
import ProtectBySession from '../components/Security/ProtectBySession'
import Typography from '../components/Basics/Typography'
import NotFoundPage from './NotFound'
import { Separator } from './Reinforcement.styles'

/**
 * The Reinforcement' page.
 */
const Reinforcement = () => {
  const title = 'Consumo por Operador'
  const type = usePathParam('type').toUpperCase()

  const consumptionPerOperatorOption = features =>
    features['com.cosmos/hard.braking'] || features['com.cosmos/idle.rpm'] || features['com.cosmos/rpm.download']

  return (
    <ProtectBySession rule={(hasSession, signedIn) => !!(hasSession && signedIn)}>
      <ProtectByFeatures rule={consumptionPerOperatorOption} fallback={() => <NotFoundPage />}>
        <BrandLayout
          navbar={<NavbarContainer />}
          header={<Heading title={title} />}
          sidebar={<ManagementSectionContainer current={routes.consumptionPerOperator} />}
        >
          <Typography fontSize="20px" fontWeight="bold" variant="h3">
            {`Registrar reforzamiento - ${CONSUMPTION_OPERATOR_MODAL_TITLE[type]}`}
          </Typography>

          <Separator />

          <ReinforcementHeaderContainer />
          <ReinforcementContentContainer />

          <Separator />

          <ReinforcementFooterContainer backButtonText="Atrás" />

          <ReinforcementFeedbackModalContainer />
        </BrandLayout>
      </ProtectByFeatures>
    </ProtectBySession>
  )
}

export default Reinforcement
