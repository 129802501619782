import React from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import {
  REACT_APP_FEATURE_FLAG_ANOMALY_DETECTION,
  REACT_APP_FEATURE_FLAG_EXAMPLES,
  REACT_APP_FEATURE_FLAG_FUEL_LEVEL,
  REACT_APP_FEATURE_FLAG_GLOBAL_COMPARATIVE,
  REACT_APP_FEATURE_FLAG_HARD_BRAKING,
  REACT_APP_FEATURE_FLAG_HELP,
  REACT_APP_FEATURE_FLAG_IDLE_RPM,
  REACT_APP_FEATURE_FLAG_OPERATORS_REPORT,
  REACT_APP_FEATURE_FLAG_RPM_DOWNLOAD,
  REACT_APP_FEATURE_FLAG_STATION_MANAGMENT,
  REACT_APP_FEATURE_FLAG_NEW_ANOMALY,
  REACT_APP_FEATURE_FLAG_QUICKSIGHT,
  REACT_APP_FEATURE_FLAG_VISUALIZADOR_ESPACIAL,
  REACT_APP_FEATURE_FLAG_VISUALIZADOR_DIA,
  REACT_APP_FEATURE_FLAG_VISUALIZADOR_HISTORICO,
  REACT_APP_FEATURE_FLAG_VISUALIZADOR_HARD_BRAKING,
  REACT_APP_FEATURE_FLAG_PREDICTIONS_GRAPH,
  REACT_APP_FEATURE_FLAG_TIRES,
  REACT_APP_FEATURE_FLAG_TIRES_ANOMALY_TABLE,
  REACT_APP_FEATURE_FLAG_TIRES_VELOCITY,
  REACT_APP_FEATURE_FLAG_FUEL_MANAGEMENT,
  REACT_APP_FEATURE_FLAG_VISUALIZADOR_RALENTI,
  REACT_APP_FEATURE_FLAG_DINING
} from '../../config/settings/environment'

/**
 * The ProtectByFeatures' component.
 */
const ProtectByFeatures = props => {
  const { children, fallback, redirectTo, rule } = props
  const history = useHistory()

  const features = {
    'com.cosmos/anomaly.detection':
      REACT_APP_FEATURE_FLAG_ANOMALY_DETECTION === true || REACT_APP_FEATURE_FLAG_ANOMALY_DETECTION === 'true',
    'com.cosmos/new.anomaly':
      REACT_APP_FEATURE_FLAG_NEW_ANOMALY === true || REACT_APP_FEATURE_FLAG_NEW_ANOMALY === 'true',
    'com.cosmos/quicksight': REACT_APP_FEATURE_FLAG_QUICKSIGHT === true || REACT_APP_FEATURE_FLAG_QUICKSIGHT === 'true',
    'com.cosmos/examples': REACT_APP_FEATURE_FLAG_EXAMPLES === true || REACT_APP_FEATURE_FLAG_EXAMPLES === 'true',
    'com.cosmos/fuel.level': REACT_APP_FEATURE_FLAG_FUEL_LEVEL === true || REACT_APP_FEATURE_FLAG_FUEL_LEVEL === 'true',
    'com.cosmos/global.comparative':
      REACT_APP_FEATURE_FLAG_GLOBAL_COMPARATIVE === true || REACT_APP_FEATURE_FLAG_GLOBAL_COMPARATIVE === 'true',
    'com.cosmos/hard.braking':
      REACT_APP_FEATURE_FLAG_HARD_BRAKING === true || REACT_APP_FEATURE_FLAG_HARD_BRAKING === 'true',
    'com.cosmos/idle.rpm': REACT_APP_FEATURE_FLAG_IDLE_RPM === true || REACT_APP_FEATURE_FLAG_IDLE_RPM === 'true',
    'com.cosmos/operators.report':
      REACT_APP_FEATURE_FLAG_OPERATORS_REPORT === true || REACT_APP_FEATURE_FLAG_OPERATORS_REPORT === 'true',
    'com.cosmos/rpm.download':
      REACT_APP_FEATURE_FLAG_RPM_DOWNLOAD === true || REACT_APP_FEATURE_FLAG_RPM_DOWNLOAD === 'true',
    'com.cosmos/station.managment':
      REACT_APP_FEATURE_FLAG_STATION_MANAGMENT === true || REACT_APP_FEATURE_FLAG_STATION_MANAGMENT === 'true',
    'com.cosmos/help': REACT_APP_FEATURE_FLAG_HELP === true || REACT_APP_FEATURE_FLAG_HELP === 'true',
    'com.cosmos/visualizador.espacial':
      REACT_APP_FEATURE_FLAG_VISUALIZADOR_ESPACIAL === true || REACT_APP_FEATURE_FLAG_VISUALIZADOR_ESPACIAL === 'true',
    'com.cosmos/visualizador.espacial.dia':
      REACT_APP_FEATURE_FLAG_VISUALIZADOR_DIA === true || REACT_APP_FEATURE_FLAG_VISUALIZADOR_DIA === 'true',
    'com.cosmos/visualizador.espacial.historico':
      REACT_APP_FEATURE_FLAG_VISUALIZADOR_HISTORICO === true ||
      REACT_APP_FEATURE_FLAG_VISUALIZADOR_HISTORICO === 'true',
    'com.cosmos/visualizador.espacial.frenados':
      REACT_APP_FEATURE_FLAG_VISUALIZADOR_HARD_BRAKING === true ||
      REACT_APP_FEATURE_FLAG_VISUALIZADOR_HARD_BRAKING === 'true',
    'com.cosmos/visualizador.espacial.ralenti':
      REACT_APP_FEATURE_FLAG_VISUALIZADOR_RALENTI === true || REACT_APP_FEATURE_FLAG_VISUALIZADOR_RALENTI === 'true',
    'com.cosmos/predictions':
      REACT_APP_FEATURE_FLAG_PREDICTIONS_GRAPH === true || REACT_APP_FEATURE_FLAG_PREDICTIONS_GRAPH === 'true',
    'com.cosmos/tires': REACT_APP_FEATURE_FLAG_TIRES === true || REACT_APP_FEATURE_FLAG_TIRES === 'true',
    'com.cosmos/tires_table':
      REACT_APP_FEATURE_FLAG_TIRES_ANOMALY_TABLE === true || REACT_APP_FEATURE_FLAG_TIRES_ANOMALY_TABLE === 'true',
    'com.cosmos/tires_velocity':
      REACT_APP_FEATURE_FLAG_TIRES_VELOCITY === true || REACT_APP_FEATURE_FLAG_TIRES_VELOCITY === 'true',
    'com.cosmos/fuel_management':
      REACT_APP_FEATURE_FLAG_FUEL_MANAGEMENT === true || REACT_APP_FEATURE_FLAG_FUEL_MANAGEMENT === 'true',
    'com.cosmos/dining': REACT_APP_FEATURE_FLAG_DINING === true || REACT_APP_FEATURE_FLAG_DINING === 'true'
  }
  const result = rule(features)
  if (!result && redirectTo) {
    history.push(redirectTo)
  }

  if (!result) {
    return fallback(features)
  }

  return <>{children}</>
}

ProtectByFeatures.defaultProps = {
  fallback: () => null,
  redirectTo: '',
  rule: () => true
}
ProtectByFeatures.propTypes = {
  children: PropTypes.node.isRequired,
  fallback: PropTypes.func,
  redirectTo: PropTypes.string,
  rule: PropTypes.func
}

export default ProtectByFeatures
