class StatusModel {
  constructor() {
    this.services = []
  }

  getName = name => {
    if (name === 'loads') {
      return 'Carga'
    } else if (name === 'downloads') {
      return 'Descarga'
    } else {
      return name.toUpperCase()
    }
  }

  fromV1 = data => {
    const servicesKeys = ['orpak', 'gps', 'loads', 'downloads']
    servicesKeys.map(serv => {
      // eslint-disable-next-line
      if (data.reason.hasOwnProperty(serv)) {
        this.services.push({
          servicesId: serv,
          serviceName: this.getName(serv),
          online: data.reason[serv] === 1,
          essential: true
        })
      }
    })

    this.services.push({
      servicesId: 'global_service',
      serviceName: 'global_service',
      online: data.status === 1,
      essential: true
    })
    // console.log(JSON.stringify(this.services))
    // console.debug('[MODEL][SERVICES]' + JSON.stringify(this.services))
    return this
  }
}

export default StatusModel
