import React from 'react'
import styled from 'styled-components'
import { Alert } from '@material-ui/lab'

export const Title = styled.span`
  color: ${({ mode, theme }) => (mode === 'light' ? theme.palette.brand.gray[15] : theme.palette.brand.white)};
  font-weight: ${props => props.theme.fontWeights[700]};
  padding-right: 6px;
`
export const Subtitle = styled.span`
  color: ${({ mode, theme }) => (mode === 'light' ? theme.palette.brand.gray[20] : theme.palette.brand.white)};
`

export const Root = styled(({ children, disableBackground, mode, severity, ...props }) => (
  <Alert {...props}>{children}</Alert>
))`
  &.MuiAlert-root {
    align-items: center;
    color: ${({ disableBackground, theme }) => theme.palette.brand.gray[disableBackground ? 20 : 50]};
    background-color: ${({ mode, severity, theme, disableBackground }) =>
      disableBackground ? theme.palette.brand.white : theme.palette.brand[severity][mode === 'light' ? 95 : 50]};
    border: ${({ border, severity, theme }) => (border ? `1px solid ${theme.palette.brand[severity][50]}` : 'none')};
    border-radius: 4px;
    font-size: 14px;
    justify-content: ${props => {
      switch (props.align) {
        case 'center':
        case 'inherit':
          return props.align
        case 'right':
          return 'flex-end'
        case 'justify':
          return ''
        case 'left':
        default:
          return 'flex-start'
      }
    }};
    letter-spacing: 0;
    line-height: 18px;
    margin: ${props => props.margin};
    padding: ${props => props.padding || '11px 16px'};
    width: ${props => props.width || 'auto'};

    & .MuiAlert-icon,
    & .MuiAlert-message {
      padding: 0;
    }

    & .MuiAlert-icon {
      margin: 0 20px 0 0;
    }

    & .MuiAlert-message {
      width: 100%;
    }

    & .MuiAlert-action {
      padding-right: 16px;
    }
  }
`
