import { makeActionCreator } from '../../config/store/utils'
import {
  applyFilters,
  filterTableType,
  onGetNewAnomaliesSuccess,
  onGetNewAnomaliesError
} from '../NewAnomaly/NewAnomaly.actions'
import { batch } from 'react-redux'

export const TOGGLE_NEW_ANOMALY_TOPBAR_DASHBOARD = 'TOGGLE_NEW_ANOMALY_TOPBAR_DASHBOARD'
export const onToggleNewAnomalyDashboardSuccess = makeActionCreator(TOGGLE_NEW_ANOMALY_TOPBAR_DASHBOARD)

export const EMPTY_ANOMALIES_DATA = 'EMPTY_ANOMALIES_DATA'
export const onEmptyAnomaliesData = makeActionCreator(EMPTY_ANOMALIES_DATA)

export const FILTER_BY_DATE = 'FILTER_BY_DATE'
export const onFilterByDate = (dates, originalAnomalies, tableFilter) => async (dispatch, getState) => {
  // dispatch(onGetNewAnomalies())

  const { emptyAnomalies } = getState().newAnomalyTopbar

  const [start, end] = dates

  try {
    const dateFilteredAnomalies = originalAnomalies.filter(anomaly => anomaly.anomalyDate.isBetween(start, end))

    if (dateFilteredAnomalies.length === 0 && !emptyAnomalies) {
      return batch(() => {
        dispatch(onEmptyAnomaliesData())
      })
    }

    const {
      newAnomalies,
      anomaliesQuantityTotal,
      anomaliesQuantitySeparated,
      anomaliesFuelTotal,
      anomaliesFuelSeparated,
      totalExceden,
      totalCargaPequena,
      firstDate,
      lastDate
    } = applyFilters(dateFilteredAnomalies)

    const tableFiltered = newAnomalies.anomalies
    var anomaliesTable = tableFiltered.sort((a, b) => (a.anomalyDate > b.anomalyDate ? -1 : 1))
    if (emptyAnomalies) {
      dispatch(onEmptyAnomaliesData())
    }

    anomaliesTable = filterTableType([tableFilter, newAnomalies.anomalies])
    const totalAnomalies = anomaliesTable.length
    const totalFuelLiters = anomaliesTable.reduce((a, b) => {
      return a + b.fuelLitres
    }, 0)

    return batch(() => {
      dispatch(
        onGetNewAnomaliesSuccess({
          originalAnomalies,
          newAnomalies,
          anomaliesTable,
          anomaliesQuantityTotal,
          anomaliesQuantitySeparated,
          anomaliesFuelTotal,
          anomaliesFuelSeparated,
          totalAnomalies,
          totalFuelLiters,
          totalExceden,
          totalCargaPequena,
          firstDate,
          lastDate,
          tableFilter
        })
      )
    })
  } catch (error) {
    console.log(error)
    return dispatch(onGetNewAnomaliesError({ error }))
  }
}
