// import {FAILURE, RELOAD, REQUEST, RESIZE, SUCCESS} from "./types";
import { RELOAD, REQUEST, RESIZE, SUCCESS } from './types'

export const requestReload = path => dispatch => {
  // console.info('[DISPATCH] Reload page');
  dispatch(callRequest(RELOAD, path))
}

export const reloadDone = () => dispatch => {
  // console.info('[DISPATCH] Prevent');
  dispatch(callSuccess(RELOAD, null))
}

export const screenResolution = windowWidth => dispatch => {
  // console.info('[DISPATCH] Identify device sizes');
  if (windowWidth < 960) {
    dispatch({
      type: RESIZE,
      payload: {
        device: 'mobile',
        column2: 1,
        column3: 2
      }
    })
  } else {
    dispatch({
      type: RESIZE,
      payload: {
        device: 'web',
        column2: 2,
        column3: 3
      }
    })
  }
}

const callRequest = (TYPE, data) => {
  return { type: TYPE + REQUEST, payload: data }
}
const callSuccess = (TYPE, data) => {
  return { type: TYPE + SUCCESS, payload: data }
}
// const callFailure = (TYPE, error) => { return {type: TYPE+FAILURE, payload: error}};
