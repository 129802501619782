import React from 'react'
import routes from '../config/settings/routes'
import ManagementSectionContainer from '../containers/ManagementMenu/ManagementMenu.container'
import QuickSight from '../containers/QuickSight/QuickSightContent.container'
import NavbarContainer from '../containers/Navbar/Navbar.container'
import BrandLayout from '../components/Layouts/BrandLayout'
import Heading from '../components/Layouts/Heading'
import ProtectByFeatures from '../components/Security/ProtectByFeatures'
import ProtectBySession from '../components/Security/ProtectBySession'
import NotFoundPage from './NotFound'

/**
 * The AnomayDetection' page.
 */
const QuickSightPage = () => {
  const title = 'Dashboard'

  return (
    <ProtectBySession rule={(hasSession, signedIn) => !!(hasSession && signedIn)}>
      <ProtectByFeatures rule={features => features['com.cosmos/quicksight']} fallback={() => <NotFoundPage />}>
        <BrandLayout
          navbar={<NavbarContainer />}
          header={<Heading title={title} />}
          sidebar={<ManagementSectionContainer current={routes.quicksight} />}
        >
          <QuickSight />
        </BrandLayout>
      </ProtectByFeatures>
    </ProtectBySession>
  )
}

export default QuickSightPage
