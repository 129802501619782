import React from 'react'
import PropTypes from 'prop-types'
import Skeleton from '../Placeholders/Skeleton'
import { LoadingWrapper, Root } from './Tab.styles'

/**
 * The Tab' component.
 */
const Tab = props => {
  const { align, disabled, isSelected, label, loading, padding, value, width, wrapped, ...rest } = props

  return (
    <>
      {loading && (
        <LoadingWrapper>
          <Skeleton variant="rect" margin="0 5px 0 0" height="100%" width="100%" />
        </LoadingWrapper>
      )}
      {!loading && (
        <Root
          align={align}
          disabled={disabled}
          isSelected={isSelected ? 1 : 0}
          label={label}
          padding={padding}
          value={value}
          width={width}
          wrapped={wrapped}
          {...rest}
        />
      )}
    </>
  )
}

Tab.defaultProps = {
  align: 'center',
  disabled: false,
  display: 'flex',
  loading: false,
  wrapped: false
}
Tab.propTypes = {
  align: PropTypes.oneOf(['center', 'left', 'right']),
  disabled: PropTypes.bool,
  display: PropTypes.oneOf(['block', 'flex']),
  isSelected: PropTypes.bool,
  label: PropTypes.node.isRequired,
  loading: PropTypes.bool,
  padding: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  value: PropTypes.any.isRequired,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  wrapped: PropTypes.bool
}

export default Tab
