import { getMonth, getYear } from 'date-fns'
import { batch } from 'react-redux'
import { CONSUMPTION_OPERATOR_TYPE } from '../../config/settings/constant'
import { makeActionCreator } from '../../config/store/utils'
import { formatNumberWithThousandSeparator } from '../../modules/utils/formatters'
import { getMonthlyConsumptionOperator, getSummaryConsumptionOperator } from '../../services/consumptionOperator'

export const GET_RPM_DOWNLOAD = 'GET_RPM_DOWNLOAD'
export const GET_RPM_DOWNLOAD_ERROR = 'GET_RPM_DOWNLOAD_ERROR'
export const GET_RPM_DOWNLOAD_SUCCESS = 'GET_RPM_DOWNLOAD_SUCCESS'
export const onGetRpmDownload = makeActionCreator(GET_RPM_DOWNLOAD)
export const onGetRpmDownloadError = makeActionCreator(GET_RPM_DOWNLOAD_ERROR, 'payload')
export const onGetRpmDownloadSuccess = makeActionCreator(GET_RPM_DOWNLOAD_SUCCESS, 'payload')
export const onGetRpmDownloadThunk = () => async dispatch => {
  dispatch(onGetRpmDownload())

  try {
    const {
      data: { data, month, year }
    } = await getSummaryConsumptionOperator({
      actionType: GET_RPM_DOWNLOAD,
      type: CONSUMPTION_OPERATOR_TYPE.RPM.toLowerCase()
    })

    const groups = data
      .map(group => {
        return {
          id: group.group_id,
          name: group.group_name,
          rpmRecommendedDownload: formatNumberWithThousandSeparator(Math.trunc(group.rpm_recommend_download || 0)),
          p8QuantityHighDownload: formatNumberWithThousandSeparator(Math.trunc(group.p8_quantity_high_download || 0)),
          sumLitersOverConsumtion: formatNumberWithThousandSeparator(Math.trunc(group.sum_liters_overconsumption || 0))
        }
      })
      .sort((a, b) => a.name.localeCompare(b.name))

    const initialGroups = data.reduce(
      (obj, group) => ({
        ...obj,
        [`group-${group.group_id}`]: {
          data: [],
          error: false,
          loading: true,
          page: 1
        }
      }),
      {}
    )

    return batch(() => {
      dispatch(
        onGetRpmDownloadSuccess({
          consultationDate: new Date(`${year}-${String(month).padStart(2, '0')}-01T00:00:00`),
          groups,
          ...initialGroups
        })
      )
      dispatch(onChangeRpmDownloadGroupSelectedThunk({ groupId: `group-${groups[0]?.id}` }))
    })
  } catch (error) {
    return dispatch(onGetRpmDownloadError({ error }))
  }
}

export const CHANGE_RPM_DOWNLOAD_GROUP_SELECTED = 'CHANGE_RPM_DOWNLOAD_GROUP_SELECTED'
export const CHANGE_RPM_DOWNLOAD_GROUP_SELECTED_ERROR = 'CHANGE_RPM_DOWNLOAD_GROUP_SELECTED_ERROR'
export const CHANGE_RPM_DOWNLOAD_GROUP_SELECTED_SUCCESS = 'CHANGE_RPM_DOWNLOAD_GROUP_SELECTED_SUCCESS'
export const onChangeRpmDownloadGroupSelected = makeActionCreator(CHANGE_RPM_DOWNLOAD_GROUP_SELECTED, 'payload')
export const onChangeRpmDownloadGroupSelectedError = makeActionCreator(
  CHANGE_RPM_DOWNLOAD_GROUP_SELECTED_ERROR,
  'payload'
)
export const onChangeRpmDownloadGroupSelectedSuccess = makeActionCreator(
  CHANGE_RPM_DOWNLOAD_GROUP_SELECTED_SUCCESS,
  'payload'
)
export const onChangeRpmDownloadGroupSelectedThunk = ({ groupId }) => (dispatch, getState) => {
  dispatch(onChangeRpmDownloadGroupSelected({ selectedGroupId: groupId }))

  try {
    const {
      [groupId]: { data }
    } = getState().rpmDownload

    // Validate if the requested page is in the redux store
    if (data && data.length > 0) {
      return dispatch(onChangeRpmDownloadGroupSelectedSuccess({ selectedGroupId: groupId }))
    }

    // Dispatch action to fetch group info
    return dispatch(onGetRpmDownloadGroupThunk({ groupId }))
  } catch (error) {
    return dispatch(
      onChangeRpmDownloadGroupSelectedError({
        error,
        selectedGroupId: groupId
      })
    )
  }
}

export const GET_RPM_DOWNLOAD_GROUP = 'GET_RPM_DOWNLOAD_GROUP'
export const GET_RPM_DOWNLOAD_GROUP_ERROR = 'GET_RPM_DOWNLOAD_GROUP_ERROR'
export const GET_RPM_DOWNLOAD_GROUP_SUCCESS = 'GET_RPM_DOWNLOAD_GROUP_SUCCESS'
export const onGetRpmDownloadGroup = makeActionCreator(GET_RPM_DOWNLOAD_GROUP, 'payload')
export const onGetRpmDownloadGroupError = makeActionCreator(GET_RPM_DOWNLOAD_GROUP_ERROR, 'payload')
export const onGetRpmDownloadGroupSuccess = makeActionCreator(GET_RPM_DOWNLOAD_GROUP_SUCCESS, 'payload')
export const onGetRpmDownloadGroupThunk = ({ groupId }) => async (dispatch, getState) => {
  dispatch(onGetRpmDownloadGroup({ selectedGroupId: groupId }))

  try {
    const { consultationDate } = getState().rpmDownload

    const {
      data: { data }
    } = await getMonthlyConsumptionOperator({
      actionType: GET_RPM_DOWNLOAD_GROUP,
      groupId: groupId.replace('group-', ''),
      month: getMonth(consultationDate) + 1,
      type: CONSUMPTION_OPERATOR_TYPE.RPM.toLowerCase(),
      year: getYear(consultationDate)
    })

    const groupData = data.sort((a, b) => b.sum_download_rpm_elevate - a.sum_download_rpm_elevate)

    return dispatch(
      onGetRpmDownloadGroupSuccess({
        selectedGroupId: groupId,
        data: groupData.map(item => {
          return {
            operatorId: item.operator_id,
            operatorName: item.operator,
            sumDownload: formatNumberWithThousandSeparator(Math.trunc(item.sum_download)),
            sumDownloadRpmElevate: formatNumberWithThousandSeparator(Math.trunc(item.sum_download_rpm_elevate)),
            impactLitersConsumed: formatNumberWithThousandSeparator(Math.trunc(item.impact_liters_consumed)),
            hasReinforcement: item.reinforcement_id !== null,
            reinforcementId: item.reinforcement_id === null ? undefined : item.reinforcement_id
          }
        })
      })
    )
  } catch (error) {
    return dispatch(
      onGetRpmDownloadGroupError({
        error,
        selectedGroupId: groupId
      })
    )
  }
}

export const CHANGE_RPM_DOWNLOAD_GROUP_PAGE = 'CHANGE_RPM_DOWNLOAD_GROUP_PAGE'
export const onChangeRpmDownloadGroupPage = makeActionCreator(CHANGE_RPM_DOWNLOAD_GROUP_PAGE, 'payload')

export const UPDATE_OPERATORS_RPM_DOWNLOAD = 'UPDATE_OPERATORS_RPM_DOWNLOAD'
export const onUpdateOperatorsRpmDownload = makeActionCreator(UPDATE_OPERATORS_RPM_DOWNLOAD, 'payload')
