import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import AutoSizer from 'react-virtualized/dist/commonjs/AutoSizer'
import KeplerGl from 'kepler.gl'
import { onGetMapboxThunk } from '../VisualizadorEspacial/VisualizadorEspacial.actions'
import LoadingContent from '../../components/Placeholders/LoadingContent'
import NoData from '../../components/Placeholders/NodataContent'
import DisconnectedData from '../FuelLevel/DisconnectedData.container'
import { Root } from './VisualizadorEspacial.styles'
// Kepler.gl Data processing APIs
// import Processors from kepler.gl/processors
// Kepler.gl actions
import { addDataToMap } from 'kepler.gl/actions'
// sample data
// import gps from ../../data/gps.csv
import ralentiConfig from '../../data/ralenti-config.json'
import Processors from 'kepler.gl/processors'

const MAPBOX_TOKEN = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN

const VisualizadorEspacialRalentiContainer = () => {
  const dispatch = useDispatch()
  const { loading, error, urlData } = useSelector(state => state.mapbox)

  useEffect(() => {
    if (urlData.length === 0) {
      dispatch(onGetMapboxThunk())
    }
  }, [])

  useEffect(() => {
    if (urlData.length > 0) {
      const data = Processors.processCsvData(urlData)
      // Create dataset structure
      const dataset = {
        data,
        info: {
          id: 'my_data_ralenti',
          label: 'gps'
        }
      }
      dispatch(addDataToMap({ datasets: dataset, config: ralentiConfig }))
    }
  }, [urlData])

  return (
    <>
      {loading && !error && <LoadingContent />}
      {error && !loading && <DisconnectedData />}
      {!loading && !error && (
        <Root>
          <>
            {urlData.length === 0 ? (
              <NoData />
            ) : (
              <AutoSizer>
                {({ height, width }) => (
                  <KeplerGl mapboxApiAccessToken={MAPBOX_TOKEN} id="my_data_ralenti" width={width} height={height} />
                )}
              </AutoSizer>
            )}
          </>
        </Root>
      )}
    </>
  )
}

export default VisualizadorEspacialRalentiContainer
