// FIXME: Need to be refactored
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Grid, Paper, Popper } from '@material-ui/core'
import help from '../../constants/icons/helpCircleOutline.svg'
import useStyles from '../../styles/stationStyle'

export const RowResumenComponent = ({ title, tooltipContent, icon, measurement }) => {
  const classes = useStyles()
  const [openTooltip, setOpenTooltip] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)
  const [placement, setPlacement] = useState()

  const tooltip = tooltipContent => {
    return (
      <Popper open={openTooltip} anchorEl={anchorEl} placement={placement} transition>
        <Paper className={classes.tooltipEvaluation}>{tooltipContent}</Paper>
      </Popper>
    )
  }

  const hoverEvaluation = newPlacement => event => {
    setAnchorEl(event.currentTarget)
    setOpenTooltip(prev => placement !== newPlacement || !prev)
    setPlacement(newPlacement)
  }

  return (
    <>
      <Grid container direction="row" justify="space-around" alignItems="center">
        <Grid item xs={7}>
          <p style={{ fontSize: '14px', textAlign: 'left' }}>{title}</p>
        </Grid>
        <Grid item xs={1}>
          <img
            src={help}
            alt="help"
            onMouseOver={hoverEvaluation('bottom')}
            onMouseOut={() => {
              setOpenTooltip(false)
            }}
            onClick={hoverEvaluation('bottom')}
          />
          {tooltip(tooltipContent)}
        </Grid>
        <Grid item xs={1} style={{ textAlign: 'right' }}>
          <img src={icon} alt="icon" />
        </Grid>
        <Grid item xs={2}>
          <p style={{ textAlign: 'left' }}>
            <strong>{measurement}</strong>
          </p>
        </Grid>
      </Grid>
    </>
  )
}

RowResumenComponent.propTypes = {
  title: PropTypes.string,
  tooltipContent: PropTypes.any,
  icon: PropTypes.string,
  measurement: PropTypes.string
}
