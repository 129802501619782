import { batch } from 'react-redux'
import { makeActionCreator } from '../../config/store/utils'
import { getTankersAWS, getTrucksAWS, getHistoricalOrpak } from '../../services/fuelLevelService'
import { reloadDone } from '../../store/actions/screenActions'

export const FETCH_DATA_TRUCKS = 'FETCH_DATA_TRUCKS'
export const FETCH_DATA_TRUCKS_ERROR = 'FETCH_DATA_TRUCKS_ERROR'
export const FETCH_DATA_TRUCKS_SUCCESS = 'FETCH_DATA_TRUCKS_SUCCESS'
export const onFetchDataTrucks = makeActionCreator(FETCH_DATA_TRUCKS)
export const onFetchDataTrucksError = makeActionCreator(FETCH_DATA_TRUCKS_ERROR, 'payload')
export const onFetchDataTrucksSuccess = makeActionCreator(FETCH_DATA_TRUCKS_SUCCESS, 'payload')
export const onFetchDataTrucksThunk = () => async dispatch => {
  dispatch(onFetchDataTrucks())

  try {
    const trucks = await getTrucksAWS()
    const lowLevelGas = trucks.filter(truck => truck.percent < 20)
    const posibleError = lowLevelGas.length > 300

    return batch(() => {
      dispatch(
        onFetchDataTrucksSuccess({
          trucks,
          posibleError
        })
      )
      dispatch(reloadDone())
    })
  } catch (error) {
    return dispatch(onFetchDataTrucksError({ error }))
  }
}

export const FETCH_DATA_TANKERS = 'FETCH_DATA_TANKERS'
export const FETCH_DATA_TANKERS_ERROR = 'FETCH_DATA_TANKERS_ERROR'
export const FETCH_DATA_TANKERS_SUCCESS = 'FETCH_DATA_TANKERS_SUCCESS'
export const onFetchDataTankers = makeActionCreator(FETCH_DATA_TANKERS)
export const onFetchDataTankersError = makeActionCreator(FETCH_DATA_TANKERS_ERROR, 'payload')
export const onFetchDataTankersSuccess = makeActionCreator(FETCH_DATA_TANKERS_SUCCESS, 'payload')
export const onFetchDataTankersThunk = () => async dispatch => {
  dispatch(onFetchDataTankers())

  try {
    const trucks = await getTankersAWS()

    return batch(() => {
      dispatch(
        onFetchDataTankersSuccess({
          trucks
        })
      )
      dispatch(reloadDone())
    })
  } catch (error) {
    return dispatch(onFetchDataTankersError({ error }))
  }
}

export const REQUEST_FULL_SCREEN_SUCCESS = 'REQUEST_FULL_SCREEN_SUCCESS'
export const onRequestFullScreenSuccess = makeActionCreator(REQUEST_FULL_SCREEN_SUCCESS)

export const GET_HISTORICAL_ORPAK = 'GET_HISTORICAL_ORPAK'
export const GET_HISTORICAL_ORPAK_ERROR = 'GET_HISTORICAL_ORPAK_ERROR'
export const GET_HISTORICAL_ORPAK_SUCCESS = 'GET_HISTORICAL_ORPAK_SUCCESS'
export const onGetHistoricalOrpak = makeActionCreator(GET_HISTORICAL_ORPAK)
export const onGetHistoricalOrpakError = makeActionCreator(GET_HISTORICAL_ORPAK_ERROR, 'payload')
export const onGetHistoricalOrpakSuccess = makeActionCreator(GET_HISTORICAL_ORPAK_SUCCESS, 'payload')
export const onGetHistoricalOrpakThunk = () => async (dispatch, getState) => {
  dispatch(onGetHistoricalOrpak())

  try {
    const {
      apis: {
        base: { apiKey, apiName }
      }
    } = getState().auth

    const payload = {
      apiKey: apiKey,
      apiName: apiName
    }

    const { data } = await getHistoricalOrpak(payload)

    const historicOrpakData = [data]

    return batch(() => {
      dispatch(
        onGetHistoricalOrpakSuccess({
          historicOrpakData
        })
      )
    })
  } catch (error) {
    return dispatch(onGetHistoricalOrpakError({ error }))
  }
}

export const GET_MONTHLY_ORPAK = 'GET_MONTHLY_ORPAK'
export const GET_MONTHLY_ORPAK_ERROR = 'GET_MONTHLY_ORPAK_ERROR'
export const GET_MONTHLY_ORPAK_SUCCESS = 'GET_MONTHLY_ORPAK_SUCCESS'
export const onGetMonthlyOrpak = makeActionCreator(GET_MONTHLY_ORPAK)
export const onGetMonthlyOrpakError = makeActionCreator(GET_MONTHLY_ORPAK_ERROR, 'payload')
export const onGetMonthlyOrpakSuccess = makeActionCreator(GET_MONTHLY_ORPAK_SUCCESS, 'payload')
export const onGetMonthlyOrpakThunk = () => async (dispatch, getState) => {
  dispatch(onGetMonthlyOrpak())

  try {
    const {
      apis: {
        base: { apiKey, apiName }
      }
    } = getState().auth

    const payload = {
      apiKey: apiKey,
      apiName: apiName,
      lastMonth: true
    }

    const { data } = await getHistoricalOrpak(payload)

    const monthlyOrpakData = [data]

    return batch(() => {
      dispatch(
        onGetMonthlyOrpakSuccess({
          monthlyOrpakData
        })
      )
    })
  } catch (error) {
    return dispatch(onGetMonthlyOrpakError({ error }))
  }
}

export const GET_DATE_ORPAK = 'GET_DATE_ORPAK'
export const GET_DATE_ORPAK_ERROR = 'GET_DATE_ORPAK_ERROR'
export const GET_DATE_ORPAK_SUCCESS = 'GET_DATE_ORPAK_SUCCESS'
export const onGetDateOrpak = makeActionCreator(GET_DATE_ORPAK)
export const onGetDateOrpakError = makeActionCreator(GET_DATE_ORPAK_ERROR, 'payload')
export const onGetDateOrpakSuccess = makeActionCreator(GET_DATE_ORPAK_SUCCESS, 'payload')
export const onGetDateOrpakThunk = (startDate, endDate) => async (dispatch, getState) => {
  dispatch(onGetDateOrpak())

  try {
    const {
      apis: {
        base: { apiKey, apiName }
      }
    } = getState().auth

    const payload = {
      apiKey: apiKey,
      apiName: apiName,
      startDate: startDate,
      endDate: endDate
    }
    console.log(payload)
    const { data } = await getHistoricalOrpak(payload)

    const dateOrpakData = [data]

    return batch(() => {
      dispatch(
        onGetDateOrpakSuccess({
          dateOrpakData
        })
      )
    })
  } catch (error) {
    console.log(error)
    return dispatch(onGetDateOrpakError({ error }))
  }
}
