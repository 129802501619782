import { getMonth, getYear } from 'date-fns'
import { batch } from 'react-redux'
import { CONSUMPTION_OPERATOR_TYPE } from '../../config/settings/constant'
import { makeActionCreator } from '../../config/store/utils'
import { formatNumberWithThousandSeparator } from '../../modules/utils/formatters'
import {
  getMonthlyConsumptionOperator,
  getSummaryConsumptionOperator,
  getCsvDownloadDataHard
} from '../../services/consumptionOperator'

export const GET_HARD_BRAKING = 'GET_HARD_BRAKING'
export const GET_HARD_BRAKING_ERROR = 'GET_HARD_BRAKING_ERROR'
export const GET_HARD_BRAKING_SUCCESS = 'GET_HARD_BRAKING_SUCCESS'
export const onGetHardBraking = makeActionCreator(GET_HARD_BRAKING)
export const onGetHardBrakingError = makeActionCreator(GET_HARD_BRAKING_ERROR, 'payload')
export const onGetHardBrakingSuccess = makeActionCreator(GET_HARD_BRAKING_SUCCESS, 'payload')
export const onGetHardBrakingThunk = date => async dispatch => {
  dispatch(onGetHardBraking())

  try {
    let endpointParams = {}

    if (date) {
      endpointParams = {
        actionType: GET_HARD_BRAKING,
        type: CONSUMPTION_OPERATOR_TYPE.FRENADOS.toLowerCase(),
        month: date.getMonth() + 1,
        year: date.getFullYear()
      }
    } else {
      endpointParams = {
        actionType: GET_HARD_BRAKING,
        type: CONSUMPTION_OPERATOR_TYPE.FRENADOS.toLowerCase()
      }
    }

    const {
      data: { data, month, year }
    } = await getSummaryConsumptionOperator(endpointParams)

    const groups = data
      .map(group => {
        return {
          id: group.group_id,
          name: group.group_name,
          p8QuantityHardBraking: formatNumberWithThousandSeparator(Math.trunc(group.p8_quantity_hard_braking || 0)),
          sumLitersOverConsumtion: formatNumberWithThousandSeparator(Math.trunc(group.sum_liters_overconsumption || 0))
        }
      })
      .sort((a, b) => a.name.localeCompare(b.name))

    const initialGroups = data.reduce(
      (obj, group) => ({
        ...obj,
        [`group-${group.group_id}`]: {
          data: [],
          error: false,
          loading: true,
          page: 1
        }
      }),
      {}
    )

    return batch(() => {
      dispatch(
        onGetHardBrakingSuccess({
          consultationDate: new Date(`${year}-${String(month).padStart(2, '0')}-01T00:00:00`),
          groups,
          ...initialGroups
        })
      )
      dispatch(onChangeHardBrakingGroupSelectedThunk({ groupId: `group-${groups[0]?.id}` }))
    })
  } catch (error) {
    return dispatch(onGetHardBrakingError({ error }))
  }
}

export const CHANGE_HARD_BRAKING_GROUP_SELECTED = 'CHANGE_HARD_BRAKING_GROUP_SELECTED'
export const CHANGE_HARD_BRAKING_GROUP_SELECTED_ERROR = 'CHANGE_HARD_BRAKING_GROUP_SELECTED_ERROR'
export const CHANGE_HARD_BRAKING_GROUP_SELECTED_SUCCESS = 'CHANGE_HARD_BRAKING_GROUP_SELECTED_SUCCESS'
export const onChangeHardBrakingGroupSelected = makeActionCreator(CHANGE_HARD_BRAKING_GROUP_SELECTED, 'payload')
export const onChangeHardBrakingGroupSelectedError = makeActionCreator(
  CHANGE_HARD_BRAKING_GROUP_SELECTED_ERROR,
  'payload'
)
export const onChangeHardBrakingGroupSelectedSuccess = makeActionCreator(
  CHANGE_HARD_BRAKING_GROUP_SELECTED_SUCCESS,
  'payload'
)
export const onChangeHardBrakingGroupSelectedThunk = ({ groupId }) => (dispatch, getState) => {
  dispatch(onChangeHardBrakingGroupSelected({ selectedGroupId: groupId }))

  try {
    const {
      [groupId]: { data }
    } = getState().hardBraking

    // Validate if the requested page is in the redux store
    if (data && data.length > 0) {
      return dispatch(onChangeHardBrakingGroupSelectedSuccess({ selectedGroupId: groupId }))
    }

    // Dispatch action to fetch group info
    return dispatch(onGetHardBrakingGroupThunk({ groupId }))
  } catch (error) {
    return dispatch(
      onChangeHardBrakingGroupSelectedError({
        error,
        selectedGroupId: groupId
      })
    )
  }
}

export const GET_HARD_BRAKING_GROUP = 'GET_HARD_BRAKING_GROUP'
export const GET_HARD_BRAKING_GROUP_ERROR = 'GET_HARD_BRAKING_GROUP_ERROR'
export const GET_HARD_BRAKING_GROUP_SUCCESS = 'GET_HARD_BRAKING_GROUP_SUCCESS'
export const onGetHardBrakingGroup = makeActionCreator(GET_HARD_BRAKING_GROUP, 'payload')
export const onGetHardBrakingGroupError = makeActionCreator(GET_HARD_BRAKING_GROUP_ERROR, 'payload')
export const onGetHardBrakingGroupSuccess = makeActionCreator(GET_HARD_BRAKING_GROUP_SUCCESS, 'payload')
export const onGetHardBrakingGroupThunk = ({ groupId }) => async (dispatch, getState) => {
  dispatch(onGetHardBrakingGroup({ selectedGroupId: groupId }))

  try {
    const { consultationDate } = getState().hardBraking

    const {
      data: { data }
    } = await getMonthlyConsumptionOperator({
      actionType: GET_HARD_BRAKING_GROUP,
      groupId: groupId.replace('group-', ''),
      month: getMonth(consultationDate) + 1,
      type: CONSUMPTION_OPERATOR_TYPE.FRENADOS.toLowerCase(),
      year: getYear(consultationDate)
    })

    const groupData = data.sort((a, b) => b.impact_liters_consumed_total - a.impact_liters_consumed_total)

    return dispatch(
      onGetHardBrakingGroupSuccess({
        selectedGroupId: groupId,
        data: groupData.map(item => {
          return {
            operatorId: item.operator_id,
            operatorName: item.operator,
            sumHardBraking: formatNumberWithThousandSeparator(Math.trunc(item.sum_hard_braking)),
            quantityHardBrakingLoaded: formatNumberWithThousandSeparator(Math.trunc(item.quantity_hard_braking_loaded)),
            quantityHardBrakingEmpty: formatNumberWithThousandSeparator(Math.trunc(item.quantity_hard_braking_empty)),
            impactLitersConsumedLoaded: formatNumberWithThousandSeparator(
              Math.trunc(item.impact_liters_consumed_loaded)
            ),
            impactLitersConsumedEmpty: formatNumberWithThousandSeparator(Math.trunc(item.impact_liters_consumed_empty)),
            impactLitersConsumedTotal: formatNumberWithThousandSeparator(Math.trunc(item.impact_liters_consumed_total)),
            hasReinforcement: item.reinforcement_id !== null,
            reinforcementId: item.reinforcement_id === null ? undefined : item.reinforcement_id
          }
        })
      })
    )
  } catch (error) {
    return dispatch(
      onGetHardBrakingGroupError({
        error,
        selectedGroupId: groupId
      })
    )
  }
}

export const GET_HARD_BRAKE_REPORT = 'GET_HARD_BRAKE_REPORT'
export const GET_HARD_BRAKE_REPORT_SUCCESS = 'GET_HARD_BRAKE_REPORT_SUCCESS'
export const GET_HARD_BRAKE_REPORT_ERROR = 'GET_HARD_BRAKE_REPORT_ERROR'
export const onGetHardBrakeReport = makeActionCreator(GET_HARD_BRAKE_REPORT)
export const onGetHardBrakeReportSuccess = makeActionCreator(GET_HARD_BRAKE_REPORT_SUCCESS, 'payload')
export const onGetHardBrakeReportError = makeActionCreator(GET_HARD_BRAKE_REPORT_ERROR, 'payload')
export const onGetHardBrakeReportThunk = () => async dispatch => {
  dispatch(onGetHardBrakeReport())

  try {
    const { data } = await getCsvDownloadDataHard()

    const reportData = [data]

    return batch(() => {
      dispatch(
        onGetHardBrakeReportSuccess({
          reportData
        })
      )
    })
  } catch (error) {
    return dispatch(onGetHardBrakeReportError({ error }))
  }
}

export const CHANGE_HARD_BRAKING_GROUP_PAGE = 'CHANGE_HARD_BRAKING_GROUP_PAGE'
export const onChangeHardBrakingGroupPage = makeActionCreator(CHANGE_HARD_BRAKING_GROUP_PAGE, 'payload')

export const UPDATE_OPERATORS_HARD_BRAKING = 'UPDATE_OPERATORS_HARD_BRAKING'
export const onUpdateOperatorsHardBraking = makeActionCreator(UPDATE_OPERATORS_HARD_BRAKING, 'payload')
