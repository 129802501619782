import React from 'react'
import { useDispatch } from 'react-redux'
import { Root, Wrapper, Dates } from './FuelManagementTopbar.styles'
import Button from '../../components/Buttons/Button'
import DatePicker from '../../components/Inputs/DatePicker'
import { useDatePicker } from '../../components/Inputs/Inputs.hooks'
import Typography from '../../components/Basics/Typography'
import { onGetOrpakDispatchGraphThunk } from './FuelManagement.actions'

/**
 * The Fuel management topbar' container
 */

const FuelManagementTopbar = () => {
  const dispatch = useDispatch()
  const now = new Date()

  const { error: pastDateError, value: pastDateValue, onChange: handlePastDateChange } = useDatePicker({
    initialValue: now.setDate(now.getDate() - 1)
  })

  const { error: dateError, value: dateValue, onChange: handleDateChange } = useDatePicker({
    initialValue: new Date()
  })

  const getData = () => {
    if (dateValue && pastDateValue) {
      dispatch(onGetOrpakDispatchGraphThunk(pastDateValue, dateValue))
    }
  }

  return (
    <Root>
      <Wrapper>
        <Typography variant="body2" fontWeight="bold" margin="0 20px">
          Seleccione un periodo:
        </Typography>
        <Dates>
          <DatePicker
            error={pastDateError}
            value={pastDateValue}
            width="170px"
            variant="inline"
            maxDate={dateValue}
            onChange={handlePastDateChange}
            disableFuture
            margin="0 20px 0 0"
          />
          <DatePicker
            error={dateError}
            value={dateValue}
            variant="inline"
            width="170px"
            minDate={pastDateValue}
            onChange={handleDateChange}
            margin="0 20px 0 0"
            disableFuture
          />
        </Dates>
        <Button color="primary" onClick={getData} size="small">
          Go
        </Button>
      </Wrapper>
    </Root>
  )
}

export default FuelManagementTopbar
