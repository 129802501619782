import React from 'react'
import { useDispatch, batch } from 'react-redux'
import { useHistory, useRouteMatch } from 'react-router'
import { useTabs } from '../../components/Navigation/Tab.hooks'
import { VISUALIZADOR_TYPE } from '../../config/settings/constant'
import {
  REACT_APP_FEATURE_FLAG_VISUALIZADOR_DIA,
  REACT_APP_FEATURE_FLAG_VISUALIZADOR_HISTORICO,
  REACT_APP_FEATURE_FLAG_VISUALIZADOR_HARD_BRAKING,
  REACT_APP_FEATURE_FLAG_VISUALIZADOR_RALENTI
} from '../../config/settings/environment'
import { Root, Wrapper, Dates, TextWrapper } from './VisualizadorEspacialTopbar.styles'
import Button from '../../components/Buttons/Button'
import DatePicker from '../../components/Inputs/DatePicker'
import { useDatePicker } from '../../components/Inputs/Inputs.hooks'
import { onGetMapboxThunk } from '../VisualizadorEspacial/VisualizadorEspacial.actions'
import Typography from '../../components/Basics/Typography'
import moment from 'moment'
import routes from '../../config/settings/routes'
import Tab from '../../components/Navigation/Tab'
import Tabs from '../../components/Navigation/Tabs'
import { usePathParam } from '../../modules/hooks/route'

/**
 * The anomaly detection topbar' container.
 */
const VisualizadorEspacialTopbar = () => {
  const prefix = 'visualizador'
  const dispatch = useDispatch()
  const minDate = moment('2022-05-31', 'YYYY-MM-DD')
  const history = useHistory()

  const options = [
    (REACT_APP_FEATURE_FLAG_VISUALIZADOR_DIA === true || REACT_APP_FEATURE_FLAG_VISUALIZADOR_DIA === 'true') && {
      label: 'Velocidad',
      current: useRouteMatch(routes.visualizador)?.isExact || false,
      route: routes.visualizador
    },
    (REACT_APP_FEATURE_FLAG_VISUALIZADOR_HISTORICO === true ||
      REACT_APP_FEATURE_FLAG_VISUALIZADOR_HISTORICO === 'true') && {
      label: 'Anomalias',
      current: useRouteMatch(routes.visualizadorHistorico)?.isExact || false,
      route: routes.visualizadorHistorico
    },
    (REACT_APP_FEATURE_FLAG_VISUALIZADOR_HARD_BRAKING === true ||
      REACT_APP_FEATURE_FLAG_VISUALIZADOR_HARD_BRAKING === 'true') && {
      label: 'Frenados',
      current: useRouteMatch(routes.visualizadorHardBraking)?.isExact || false,
      route: routes.visualizadorHardBraking
    },
    (REACT_APP_FEATURE_FLAG_VISUALIZADOR_RALENTI === true ||
      REACT_APP_FEATURE_FLAG_VISUALIZADOR_RALENTI === 'true') && {
      label: 'Ralenti',
      current: useRouteMatch(routes.visualizadorRalenti)?.isExact || false,
      route: routes.visualizadorRalenti
    }
  ].filter(Boolean)

  const { value, onChange: handleChangeTab } = useTabs({
    initialValue: options.findIndex(tab => tab.current),
    changeCallback: newValue => history.push(options[newValue].route)
  })

  const { error: dateError, value: dateValue, onChange: handleDateChange } = useDatePicker({
    initialValue: new Date()
  })

  const onChange = () => {
    if (dateValue) {
      batch(() => {
        dispatch(onGetMapboxThunk({ dateValue }))
      })
    }
  }

  const type = usePathParam('type').toUpperCase()

  const tabProps = index => {
    return {
      id: `${prefix}-tab-${index}`,
      'aria-controls': `${prefix}-tabpanel-${index}`,
      key: `${prefix}-tab-${index}`,
      value: index
    }
  }

  return (
    <Root>
      <Tabs aria-label="Maps tabs" value={value} variant="scrollable" onChange={handleChangeTab}>
        {options.map((option, index) => {
          return <Tab label={option.label} key={`${prefix}-tab-${index}`} {...tabProps(index)} />
        })}
      </Tabs>
      {VISUALIZADOR_TYPE[type] === 'FRENADOS' ? (
        <>
          <TextWrapper>
            <Typography variant="body2" fontWeight="bold" margin="0 20px">
              Noviembre
            </Typography>
          </TextWrapper>
        </>
      ) : (
        <>
          <Wrapper>
            <Typography variant="body2" fontWeight="bold" margin="0 20px">
              Seleccione un día:
            </Typography>
            <Dates>
              <DatePicker
                error={dateError}
                value={dateValue}
                variant="inline"
                width="170px"
                minDate={minDate}
                onChange={handleDateChange}
                disableFuture
                margin="0 20px 0 0"
              />
            </Dates>
            <Button color="primary" onClick={onChange} size="small">
              Go
            </Button>
          </Wrapper>
        </>
      )}
    </Root>
  )
}

export default VisualizadorEspacialTopbar
