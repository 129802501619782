import {
  CLOSE_ERROR_SUCCESS,
  GET_MANUAL,
  GET_MANUAL_ERROR,
  GET_MANUAL_SUCCESS,
  SEND_FORM,
  SEND_FORM_ERROR,
  SEND_FORM_SUCCESS,
  SET_FULLSCREEN,
  UPDATE_FORM_VALUE
} from './Help.actions'

const helpState = {
  contactForm: {
    email: '',
    message: '',
    name: ''
  },
  error: undefined,
  fullScreen: false,
  linkUserGuide: '',
  loading: true,
  sending: false,
  showSuccess: false,
  submitError: undefined
}

/**
 * The help reducer.
 */
const helpReducer = (state = helpState, { payload, type }) => {
  switch (type) {
    case CLOSE_ERROR_SUCCESS: {
      return { ...state, showSuccess: false, submitError: undefined }
    }
    case GET_MANUAL: {
      return { ...state, error: undefined, loading: true }
    }
    case GET_MANUAL_ERROR: {
      return { ...state, error: payload.error, loading: false }
    }
    case GET_MANUAL_SUCCESS: {
      return { ...state, linkUserGuide: payload.linkUserGuide, loading: false }
    }
    case SEND_FORM: {
      return { ...state, submitError: undefined, sending: true }
    }
    case SEND_FORM_ERROR: {
      return { ...state, submitError: payload.error, sending: false }
    }
    case SEND_FORM_SUCCESS: {
      return { ...state, contactForm: helpState.contactForm, sending: false, showSuccess: true }
    }
    case SET_FULLSCREEN: {
      return { ...state, fullScreen: !state.fullScreen }
    }
    case UPDATE_FORM_VALUE: {
      return {
        ...state,
        contactForm: {
          ...state.contactForm,
          [payload.name]: payload.value
        }
      }
    }
    default: {
      return state
    }
  }
}

export default helpReducer
