import React from 'react'
import StationManagementContent from '../containers/StationManagement/StationManagementContent.container'
import ProtectByFeatures from '../components/Security/ProtectByFeatures'
import ProtectBySession from '../components/Security/ProtectBySession'
import NotFoundPage from './NotFound'

/**
 * The StationManagement' page.
 */
const StationManagement = () => {
  return (
    <ProtectBySession rule={(hasSession, signedIn) => !!(hasSession && signedIn)}>
      <ProtectByFeatures rule={features => features['com.cosmos/station.managment']} fallback={() => <NotFoundPage />}>
        <StationManagementContent />
      </ProtectByFeatures>
    </ProtectBySession>
  )
}

export default StationManagement
