import React from 'react'
import Amplify from 'aws-amplify'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import {
  REACT_APP_USER_POOL_CLIENT_ID,
  REACT_APP_USER_POOL_ID,
  REACT_APP_USER_IDENTITY_POOL_ID
} from './config/settings/environment'
import routes from './config/settings/routes'
import NewAnomalyPage from './pages/NewAnomaly'
import ConsumptionOperatorPage from './pages/ConsumptionOperator'
import ExamplePage from './pages/Example'
import FuelLevelPage from './pages/FuelLevel'
import GlobalComparePage from './pages/GlobalCompare'
import HelpPage from './pages/Help'
import HomePage from './pages/Home'
import LoginPage from './pages/Login'
import NotFoundPage from './pages/NotFound'
import ReinforcementPage from './pages/Reinforcement'
import StationManagementPage from './pages/StationManagement'
import QuickSightPage from './pages/QuickSight'
import VisualizadorEspacial from './pages/VisualizadorEspacial'
import PredictionsPage from './pages/PredictionGraph'
import Tires from './pages/Tires'
import FuelManagement from './pages/FuelManagement'
import PasswordRecovery from './pages/PasswordRecovery'
import DiningPage from './pages/Dining'

/**
 * The Routes' component.
 */
const Routes = () => {
  const manualAWSconfig = {
    Auth: {
      identityPoolId: REACT_APP_USER_IDENTITY_POOL_ID,
      userPoolId: REACT_APP_USER_POOL_ID,
      region: 'us-east-1',
      userPoolWebClientId: REACT_APP_USER_POOL_CLIENT_ID
    },
    Storage: {
      AWSS3: {
        bucket: 'cosmos-antucoya-amplify-develop',
        identityPoolId: REACT_APP_USER_IDENTITY_POOL_ID
      }
    }
  }
  Amplify.configure(manualAWSconfig)

  return (
    <BrowserRouter>
      <Switch>
        <Route exact path={routes.home} component={HomePage} />
        <Route exact path={routes.login} component={LoginPage} />
        <Route exact path={routes.fuelLevel} component={FuelLevelPage} />
        <Route exact path={routes.consumptionPerOperator} component={ConsumptionOperatorPage} />
        <Route exact path={[routes.reinforcement, routes.showReinforcement]} component={ReinforcementPage} />
        <Route exact path={routes.example} component={ExamplePage} />
        <Route exact path={routes.newAnomaly} component={NewAnomalyPage} />
        <Route exact path={routes.quicksight} component={QuickSightPage} />
        <Route exact path={routes.stationManagement} component={StationManagementPage} />
        <Route exact path={routes.visualizadorOperaciones} component={VisualizadorEspacial} />
        <Route exact path={routes.predictionsGraphs} component={PredictionsPage} />
        <Route exact path={routes.dining} component={DiningPage} />
        <Route exact path={routes.tireOperaciones} component={Tires} />
        <Route exact path={routes.fuelManagement} component={FuelManagement} />
        <Route exact path={routes.globalComparative} component={GlobalComparePage} />
        <Route exact path={routes.passwordRecovery} component={PasswordRecovery} />
        <Route exact path={routes.help} component={HelpPage} />
        <Route component={NotFoundPage} path="*" />
      </Switch>
    </BrowserRouter>
  )
}

export default Routes
