import React from 'react'
import { useDatePicker } from '../../components/Inputs/Inputs.hooks'
import DatePicker from '../../components/Inputs/DatePicker'
import Typography from '../../components/Basics/Typography'
import { Root } from './Examples.styles'

/**
 * The DatePickerExample's container.
 */
const DatePickerExample = () => {
  const { error: pastDateError, value: pastDateValue, onChange: handlePastDateChange } = useDatePicker({
    initialValue: new Date()
  })
  const { error: futureDateError, value: futureDateValue, onChange: handleFutureDateChange } = useDatePicker({
    initialValue: new Date()
  })
  const { error: dateError, value: dateValue, onChange: handleDateChange } = useDatePicker({
    initialValue: new Date()
  })

  return (
    <Root>
      <Typography variant="body1">
        El componente incluye validación simple de fechas (futuro o pasado). Tiene dos variantes: dialog e inline. Falta
        por implementar: TimePicker (para horas), selección de rango de fechas con validación.
      </Typography>
      <br />
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap' }}>
        <div style={{ padding: '0 10px', width: '30%' }}>
          <DatePicker
            error={pastDateError}
            label="Disable future - dialog"
            value={pastDateValue}
            width="100%"
            onChange={handlePastDateChange}
            disableFuture
          />
        </div>
        <div style={{ padding: '0 10px', width: '30%' }}>
          <DatePicker
            error={futureDateError}
            label="Disable past - dialog"
            value={futureDateValue}
            width="100%"
            onChange={handleFutureDateChange}
            disablePast
          />
        </div>
        <div style={{ padding: '0 10px', width: '30%' }}>
          <DatePicker
            views={['year', 'month']}
            format="MMMM yyyy"
            disableToolbar
            error={dateError}
            label="Any date - inline"
            value={dateValue}
            variant="inline"
            width="100%"
            onChange={handleDateChange}
          />
        </div>
      </div>
    </Root>
  )
}

export default DatePickerExample
