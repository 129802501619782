import React from 'react'
import PropTypes from 'prop-types'
import { Root } from './TableRow.styles'

/**
 * The TableRow's component.
 */
const TableRow = props => {
  const { children, head, onClick, hover } = props

  return (
    <Root hover={hover} onClick={onClick} head={head ? 1 : 0}>
      {children}
    </Root>
  )
}

TableRow.defaultProps = {
  head: false,
  hover: false,
  onClick: () => undefined
}
TableRow.propTypes = {
  children: PropTypes.node.isRequired,
  head: PropTypes.bool,
  hover: PropTypes.bool,
  onClick: PropTypes.func
}

export default TableRow
