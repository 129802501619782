import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { IFrameReport } from './Reports.styles'
import { onGetReportThunk } from './Reports.actions'
import LoadingContent from '../../components/Placeholders/LoadingContent'

const ReportContainer = () => {
  const dispatch = useDispatch()
  const { loading, error, data } = useSelector(state => state.htmlReport)
  console.log(data)
  useEffect(() => {
    dispatch(onGetReportThunk())
  }, [dispatch])

  if (loading) return <LoadingContent />
  if (error) return <p>Error loading report.</p>

  return <IFrameReport src={data.presigned_url} title="Weekly Report" />
}

export default ReportContainer
