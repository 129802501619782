import moment from 'moment'

export const dateToDayString = (date = new Date()) => {
  const day = date.getDay()
  const month = date.getMonth()
  const year = date.getFullYear()
  return '' + (day < 10 && '0') + day.toString() + '-' + (month < 10 && '0') + month.toString() + '-' + year.toString()
}

export const dateToHoursString = (date = new Date(), showSeconds = false) => {
  const hours = date.getHours()
  const minutes = date.getMinutes()
  const seconds = date.getSeconds()
  return (
    '' +
    (hours < 10 && '0') +
    hours.toString() +
    ':' +
    (minutes < 10 && '0') +
    minutes.toString() +
    (showSeconds && ':' + (seconds < 10 && '0') + seconds.toString())
  )
}

export const convertDateFormat = date => {
  const dateSplit = date.split('/')
  const dateFormat = new Date(`${dateSplit[2]}/${dateSplit[1]}/${dateSplit[0]}`)
  return dateFormat
}

export const getMonthString = monthNumber => {
  switch (parseInt(monthNumber)) {
    case 0:
      return 'Enero'
    case 1:
      return 'Febrero'
    case 2:
      return 'Marzo'
    case 3:
      return 'Abril'
    case 4:
      return 'Mayo'
    case 5:
      return 'Junio'
    case 6:
      return 'Julio'
    case 7:
      return 'Agosto'
    case 8:
      return 'Septiembre'
    case 9:
      return 'Octubre'
    case 10:
      return 'Noviembre'
    case 11:
      return 'Diciembre'
    default:
      return 'Fail'
  }
}

export const getGroupName = groupNumber => {
  // change groupNumber to string
  // change string to uppercase
  const group = groupNumber.toString().toUpperCase()

  if (group.startsWith('G')) {
    return 'Grupo ' + group.slice(1)
  } else {
    return 'Grupo ' + group
  }
}

export const setDataLocalStorage = (key, value) => {
  const data = { time: moment().toISOString(), data: value }
  localStorage.setItem(key, JSON.stringify(data))
  return true
}

export const getDataLocalStorage = (key, hours) => {
  const values = JSON.parse(localStorage.getItem(key)) || null
  if (values !== null && moment(values.time).diff(moment(), 'hour') > hours) {
    localStorage.removeItem(key)
    return null
  }
  return values.data
}
