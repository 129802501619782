import React from 'react'
import styled from 'styled-components'
import { Typography } from '@material-ui/core'

export const Root = styled(({ color, fontSize, fontWeight, italic, lineHeight, margin, padding, width, ...props }) => (
  <Typography {...props} />
))`
  color: ${({ color, theme }) => {
    switch (color) {
      case 'black':
        return theme.palette.brand.black
      case 'black2':
        return theme.palette.brand.gray[15]
      case 'dark':
        return theme.palette.brand.gray[20]
      case 'dark2':
        return theme.palette.brand.gray[30]
      case 'light-dark':
        return theme.palette.brand.gray[35]
      case 'disabled-gray':
        return theme.palette.brand.gray[50]
      case 'gray':
        return theme.palette.brand.gray[75]
      case 'silver':
        return theme.palette.brand.gray[90]
      case 'white':
        return theme.palette.brand.white
      case 'primary':
      case 'success':
      case 'error':
      case 'warning':
        return theme.palette.brand[color][50]
      default:
        return theme.palette.brand.black
    }
  }};
  display: ${props => props.display};
  font-style: ${props => (props.italic ? 'italic' : 'initial')};
  font-weight: ${({ fontWeight, theme }) => {
    switch (fontWeight) {
      case 'black':
        return theme.fontWeights[900]
      case 'bold':
        return theme.fontWeights[700]
      case 'regular':
        return theme.fontWeights[400]
      case 'light':
        return theme.fontWeights[300]
      default:
        return theme.fontWeights[400]
    }
  }};
  margin: ${props => props.margin || '0'};
  padding: ${props => props.padding || '0'};
  width: ${props => props.width};

  &.MuiTypography-h1 {
    font-size: ${props => props.fontSize ?? '25px'};
    line-height: ${props => props.lineHeight ?? '31px'};
  }

  &.MuiTypography-h2 {
    font-size: ${props => props.fontSize ?? '23px'};
    line-height: ${props => props.lineHeight ?? '28px'};
  }

  &.MuiTypography-h3 {
    font-size: ${props => props.fontSize ?? '22px'};
    line-height: ${props => props.lineHeight ?? '26px'};
  }

  &.MuiTypography-h4 {
    font-size: ${props => props.fontSize ?? '18px'};
    line-height: ${props => props.lineHeight ?? '22px'};
  }

  &.MuiTypography-body1 {
    font-size: ${props => props.fontSize ?? '16px'};
    line-height: ${props => props.lineHeight ?? '20px'};
  }

  &.MuiTypography-body2 {
    font-size: ${props => props.fontSize ?? '14px'};
    line-height: ${props => props.lineHeight ?? '18px'};
  }

  &.MuiTypography-subtitle1 {
    color: ${({ color, theme }) => {
      switch (color) {
        case 'black':
          return theme.palette.brand.black
        case 'black2':
          return theme.palette.brand.gray[15]
        case 'dark':
          return theme.palette.brand.gray[20]
        case 'dark2':
          return theme.palette.brand.gray[30]
        case 'light-dark':
          return theme.palette.brand.gray[35]
        case 'disabled-gray':
          return theme.palette.brand.gray[50]
        case 'gray':
          return theme.palette.brand.gray[75]
        case 'silver':
          return theme.palette.brand.gray[90]
        case 'white':
          return theme.palette.brand.white
        case 'primary':
        case 'success':
        case 'error':
        case 'warning':
          return theme.palette.brand[color][50]
        default:
          return theme.palette.brand.gray[50]
      }
    }};
    font-size: ${props => props.fontSize ?? '12px'};
    line-height: ${props => props.lineHeight ?? '16px'};
  }

  &.MuiTypography-caption {
    color: ${({ color, theme }) => {
      switch (color) {
        case 'black':
          return theme.palette.brand.black
        case 'black2':
          return theme.palette.brand.gray[15]
        case 'dark':
          return theme.palette.brand.gray[20]
        case 'dark2':
          return theme.palette.brand.gray[30]
        case 'light-dark':
          return theme.palette.brand.gray[35]
        case 'disabled-gray':
          return theme.palette.brand.gray[50]
        case 'gray':
          return theme.palette.brand.gray[75]
        case 'silver':
          return theme.palette.brand.gray[90]
        case 'white':
          return theme.palette.brand.white
        case 'primary':
        case 'success':
        case 'error':
        case 'warning':
          return theme.palette.brand[color][50]
        default:
          return theme.palette.brand.gray[35]
      }
    }};
    display: block;
    font-size: ${props => props.fontSize ?? '12px'};
    letter-spacing: 1.1px;
    line-height: ${props => props.lineHeight ?? '16px'};
    text-transform: uppercase;
  }
`
