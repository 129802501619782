import styled from 'styled-components'
import { hexToRGBA } from '../../modules/utils/color'
import MenuIcon from '@material-ui/icons/Menu'
import { ButtonBase, IconButton as BaseIconButton } from '@material-ui/core'

export const HamburgerIcon = styled(MenuIcon)`
  color: ${props => props.theme.palette.brand.white};
  cursor: pointer;
`

export const IconButton = styled(BaseIconButton)`
  background-color: ${props => props.theme.palette.brand.primary[50]};
  border-radius: 4px;
  height: 40px;
  width: 40px;

  &:hover {
    background-color: ${props => props.theme.palette.brand.primary[50]};
  }
`

export const LogoffButton = styled(ButtonBase)`
  border-radius: 4px;
  padding: 0 12px 0 8px;

  &:hover {
    background-color: ${props => props.theme.palette.brand.primary[50]};
  }
`

export const LogoffIcon = styled.img`
  fill: inherit;
  width: 32px;
`

export const LogoIcon = styled.img`
  width: 50px;
`

export const Root = styled.div`
  background-color: ${props => props.theme.palette.brand.gray[50]};
  border-bottom: 1px solid ${props => hexToRGBA(props.theme.palette.brand.black, 0.12)};
  color: ${props => props.theme.palette.brand.white};
  display: flex;
  justify-content: space-between;
  padding: 10px;
  width: 100%;
  align-items: center;
`
