import React from 'react'
import { useDispatch } from 'react-redux'
import { useViewportSize } from '../../modules/hooks/viewport'
import { onToggleLogoutModalSuccess } from '../Authentication/Authentication.actions'
import { onShowManagementPanelSuccess, onToggleManagmentPanelSuccess } from '../ManagementMenu/ManagementMenu.actions'
import LogoutModal from '../Authentication/Logout.container'
import { HamburgerIcon, IconButton, LogoffButton, LogoffIcon, LogoIcon, Root } from './Navbar.styles'
import Typography from '../../components/Basics/Typography'
import { useSession } from '../../modules/hooks/security'

/**
 * The Navbar's container.
 */
const Navbar = () => {
  const dispatch = useDispatch()
  const { isTiny } = useViewportSize()
  const { username } = useSession()

  const handleShowSmallScreenPanel = () => dispatch(onShowManagementPanelSuccess())
  const handleToggleManagmentPanel = () => dispatch(onToggleManagmentPanelSuccess())
  const handleLogout = () => dispatch(onToggleLogoutModalSuccess())

  return (
    <Root>
      <IconButton onClick={isTiny ? handleShowSmallScreenPanel : handleToggleManagmentPanel}>
        <HamburgerIcon />
      </IconButton>

      {!isTiny && (
        <>
          <Root style={{ borderBottom: 0, justifyContent: 'flex-end' }}>
            <Typography fontSize={isTiny ? '18px' : '20px'} variant="h3" color="white">
              {username}
            </Typography>
            <LogoffButton onClick={handleLogout}>
              <LogoffIcon src="/assets/icons/logoff.svg" alt="Cerrar Sesión" />
              Cerrar sesión
            </LogoffButton>
          </Root>
        </>
      )}

      {isTiny && <LogoIcon src="/assets/images/cosmos.svg" alt="Universal Intelligence" />}
      <LogoutModal />
    </Root>
  )
}

export default Navbar
