import { createMuiTheme } from '@material-ui/core/styles'
import '@fontsource/saira'
import '@ibm/plex'

const theme = createMuiTheme({
  fontWeights: {
    300: 300,
    400: 400,
    500: 500,
    600: 600,
    700: 700,
    900: 900
  },
  palette: {
    brand: {
      primary: {
        95: '#E8FDFD', // Light, background
        90: '#D1FAFA', // Tag color
        75: '#0047CE', // background disable
        70: '#75F0F0', // Selected menu item
        65: '#0047CE', // Selected pagination item
        50: '#0047CE', // Main '
        40: '#13B9B9',
        35: '#0047CE' // Hover
      },
      gray: {
        100: '#FFFFFF',
        95: '#F2F2F2',
        90: '#E5E5E5', // silver
        85: '#E6E3DF', // Table border color, and separators
        75: '#BFBFBF', // gray
        65: '#A6A6A6',
        60: '#BDBDBD', // campos y formularios
        50: '#828282', // disabled gray
        35: '#595959', // light dark
        30: '#4F4F4F',
        20: '#333333', // dark
        15: '#262626'
      },
      error: {
        95: '#FFE6E8',
        90: '#FFCCD0',
        75: '#FF808A',
        65: '#FF6673',
        50: '#AD000F',
        35: '#660008'
      },
      success: {
        95: '#E7F9E7',
        90: '#D4F3D3',
        75: '#99E396',
        65: '#89DF86',
        50: '#2FA12B',
        35: '#237920'
      },
      warning: {
        95: '#FEF4DD',
        90: '#FCEBBF',
        75: '#FAD780',
        65: '#F8C954',
        50: '#F7C137',
        35: '#AB7D07'
      },
      black: '#000000',
      background: '#E6E3DF',
      backgroundDark: '#333333',
      ghost: '#CBD2D9',
      oxfordBlue: '#323F4B',
      pomegranate: '#F43737',
      white: '#FFFFFF'
    },
    background: {
      default: '#E6E3DF'
    },
    blackGrayBrand: {
      black: '#000000',
      black1: '#263238', // dark
      black2: '#828282', // disabled_gray
      // black3: '#768AA2',
      // gray1: '#B3BECB',
      // gray2: '#D1D8E0',
      gray3: '#E5E5E5', // silver
      white: '#FFFFFF'
    },
    primary: {
      light: '#E8FDFD',
      main: '#0047CE',
      dark: '#DC5B04'
    },
    secondary: {
      light: '#E1E5EA', // TODO
      main: '#263238',
      dark: '#151B1E' // TODO
    },
    error: {
      light: '#F9D2D2', // TODO
      main: '#AD000F',
      dark: '#660008' // TODO
    },
    fuelLevelIndicators: {
      critical: '#AD000F',
      lowest: '#A6008C',
      low: '#4100A0',
      god: '#00359A',
      veryGood: '#009486',
      full: '#008E17'
    },
    scores: {
      good: '#346DFF',
      bad: '#F43737',
      mean: '#BDBDBD'
    }
  },
  typography: {
    fontFamily: ['IBM Plex Sans', 'Saira'].join(','),
    fonts: {
      1: 'IBM Plex Sans',
      2: 'Saira'
    },
    // FIXME: Remove this comments when all styles has replaced
    // htmlFontSize: 10,
    // useNextVariants: true,
    // FIXME: Remove this style and create Typography component
    h1: {
      fontFamily: 'Saira',
      fontSize: '2.4rem',
      fontWeight: 'bold',
      color: '#000000',
      margin: 10
    },
    // FIXME: Remove this and create button component
    button: {
      textTransform: 'none',
      fontSize: '16px'
    }
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '@font-face': 'Saira',
        'min-height': '100vh'
      }
    },
    MuiGrid: {
      root: {
        margin: 0,
        padding: 0
      }
    },
    MuiCard: {
      root: {
        padding: 0,
        margin: 0
      }
    },
    MuiCardHeader: {
      root: {
        margin: 0,
        padding: 0,
        paddingLeft: 5
      }
    },
    MuiCardActions: {
      root: {
        padding: 3,
        margin: 0
      }
    },
    MuiCardContent: {
      root: {
        padding: 0,
        margin: 0
      }
    },
    /* MuiCollapse: {
      root: {
        margin: 0,
        padding: 3
      }
    }, */
    MuiButton: {
      root: {
        margin: 5,
        textDecorationLine: 'none'
      },
      containedPrimary: {
        background: '#FF9045',
        color: '#FFFFFF'
      }
    }
  }
})

export default theme
