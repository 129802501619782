import React from 'react'
import { useSelector } from 'react-redux'
import LoadingContent from '../../components/Placeholders/LoadingContent'
import DisconnectedData from '../FuelLevel/DisconnectedData.container'
import Typography from '../../components/Basics/Typography'
import DriverDataContent from './DiningTable.container'
/**
 * The Dining's container.
 */
const DiningContentHistoric = () => {
  const { loadingHistoric, errorHistoric, drivers } = useSelector(state => state.dining)
  return (
    <>
      {loadingHistoric && <LoadingContent />}
      {!loadingHistoric && errorHistoric && <DisconnectedData />}
      {!loadingHistoric && !errorHistoric && (
        <>
          {drivers.length === 0 ? (
            <Typography align="center" color="primary">
              Por favor seleccione fecha
            </Typography>
          ) : (
            <>
              <DriverDataContent title="Conductores" data={drivers} />
            </>
          )}
        </>
      )}
    </>
  )
}

export default DiningContentHistoric
