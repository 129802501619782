// FIXME: Need to be refactored
import React, { useState, useEffect } from 'react'
import LoadingContent from '../../components/Placeholders/LoadingContent'
import { getKpis, getDateAvailables, getHistoryData } from '../../services/globalCompareService'
import KpiRowDesktop from './KpiRowDesktop.container'
import {
  TableHead,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  Grid,
  Typography,
  Select,
  MenuItem
} from '@material-ui/core'

export const parameters = [
  // {
  //   name: 'Número de abastecimientos',
  //   key: 'fueling_count'
  // },
  {
    name: 'Tonelaje transportado',
    key: 'tons_transported'
  },
  {
    name: 'Distancia recorrida [Km]',
    key: 'km_traveled'
  },
  {
    name: 'Tiempo efectivo de operación [Hr]',
    key: 'hrs_in_route'
  },
  {
    name: 'Combustible abastecidos [L]',
    key: 'fuel_supplied'
  },
  // {
  //   name: 'Tiempo Ciclo Combustible ([min]',
  //   key: 'fuel_cycle_time'
  // },
  {
    name: 'Nivel promedio de estanque al abastecer',
    key: 'refueling_porcentage'
  },
  {
    name: 'Tasa de consumo (L/Hr)',
    key: 'fuel_rate'
  },
  {
    name: 'Ciclo carguío mineral [min]',
    key: 'loading_cycle_minutes'
  },
  {
    name: 'Litros anómalos [L]',
    key: 'anomalies_lts'
  }
  // {
  //   name: 'Cantidad de abastecimientos entre: 0-10%',
  //   key: 'p_0_10'
  // },
  // {
  //   name: 'Cantidad de abastecimientos entre: 10-20%',
  //   key: 'p_10_20'
  // },
  // {
  //   name: 'Cantidad de abastecimientos entre: 20-30%',
  //   key: 'p_20_30'
  // },
  // {
  //   name: 'Cantidad de abastecimientos entre: 30-40%',
  //   key: 'p_30_40'
  // },
  // {
  //   name: 'Cantidad de abastecimientos entre: 40-50%',
  //   key: 'p_40_50'
  // },
  // {
  //   name: 'Cantidad de abastecimientos entre: 50-60%',
  //   key: 'p_50_60'
  // },
  // {
  //   name: 'Cantidad de abastecimientos entre: 60-70%',
  //   key: 'p_60_70'
  // },
  // {
  //   name: 'Cantidad de abastecimientos entre: 70-80%',
  //   key: 'p_70_80'
  // },
  // {
  //   name: 'Cantidad de abastecimientos entre: 80-90%',
  //   key: 'p_80_90'
  // },
  // {
  //   name: 'Cantidad de abastecimientos entre: 90-100%',
  //   key: 'p_90_100'
  // }
]

/**
 * The KpiDesktop' container.
 */
const KpiDesktop = () => {
  const [kpis, setKpis] = useState([])
  const [loading, setLoading] = useState(true)
  const [datesAvailables, setDatesAvailables] = useState([])
  const [dateSelected, setDateSelected] = useState('actual')
  const [historyData, setHistoryData] = useState({})

  useEffect(() => {
    init()
  }, [])

  const init = async () => {
    Promise.all([getDateAvailables(), getKpis(), getHistoryData()])
      .then(values => {
        setDatesAvailables(values[0])
        setKpis(values[1])
        setHistoryData(values[2])
        setTimeout(() => {
          setLoading(false)
        }, 500)
      })
      .catch(error => {
        console.error(error)
      })
  }

  const refreshData = async date => {
    setLoading(true)
    let newData = []
    if (date === 'actual') {
      newData = await getKpis()
    } else {
      const selected = datesAvailables.find(period => period.value === date)
      const { month, year } = selected
      newData = await getKpis(month, year)
    }
    setKpis(newData)
    setTimeout(() => {
      setLoading(false)
    }, 1000)
  }

  const handleSelectChange = e => {
    setDateSelected(e.target.value)
    refreshData(e.target.value)
  }

  return (
    <>
      {loading && <LoadingContent />}
      {!loading && kpis.length > 0 && (
        <>
          <Grid
            container
            direction="row"
            justify="flex-end"
            alignItems="center"
            style={{ marginTop: '1rem', marginBottom: '1rem' }}
          >
            <Grid item xs={4} md={2}>
              <Typography>Seleccione un periodo: </Typography>
            </Grid>
            <Grid item xs={4} md={2}>
              <Select labelId="selectTime" id="selectTime" value={dateSelected} onChange={handleSelectChange}>
                {datesAvailables.map(period => {
                  return (
                    <MenuItem key={period.value} value={period.value}>
                      {period.value}
                    </MenuItem>
                  )
                })}
              </Select>
            </Grid>
          </Grid>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <strong>Métricas</strong>
                  </TableCell>
                  <TableCell align="right">
                    <strong>Grupo 1</strong>
                  </TableCell>
                  <TableCell align="right">
                    <strong>Grupo 2</strong>
                  </TableCell>
                  <TableCell align="right">
                    <strong>Grupo 3</strong>
                  </TableCell>
                  <TableCell align="right">
                    <strong>Grupo 4</strong>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {parameters.map(row => {
                  return <KpiRowDesktop data={row} historyData={historyData} kpis={kpis} key={row.key} />
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
    </>
  )
}

export default KpiDesktop
