import { makeActionCreator } from '../../config/store/utils'
import { batch } from 'react-redux'
import { getTiresAnomalyData, getTireAnomalyImage } from '../../services/tireAnomaly'
import { formatDate } from '../../modules/utils/formatters'
import { roundWithPrecision } from '../../modules/utils/math'
import { capitalizeNames } from '../../modules/utils/helpers'

export const GET_TIRE_ANOMALY_DATA = 'GET_TIRE_ANOMALY_DATA'
export const GET_TIRE_ANOMALY_DATA_ERROR = 'GET_TIRE_ANOMALY_DATA_ERROR'
export const GET_TIRE_ANOMALY_DATA_SUCCESS = 'GET_TIRE_ANOMALY_DATA_SUCCESS'
export const onGetTireAnomaly = makeActionCreator(GET_TIRE_ANOMALY_DATA)
export const onGetTireAnomalyError = makeActionCreator(GET_TIRE_ANOMALY_DATA_ERROR, 'payload')
export const onGetTireAnomalySuccess = makeActionCreator(GET_TIRE_ANOMALY_DATA_SUCCESS, 'payload')
export const onGetTireAnomalySuccessThunk = () => async (dispatch, getState) => {
  dispatch(onGetTireAnomaly())
  try {
    const {
      apis: {
        base: { apiKey, apiName }
      }
    } = getState().auth
    const { data } = await getTiresAnomalyData({
      apiKey: apiKey,
      apiName: apiName
    })
    const allAnomalies = JSON.parse(data.all_anomalies)
    const groupedCaex = JSON.parse(data.grouped_caex)
    const operatorData = JSON.parse(data.grouped_operador)

    const anomalyData = allAnomalies.map(item => {
      const {
        camion,
        anomaly,
        pos_neu: posNeu,
        date_min: dateMin,
        date_max: dateMax,
        group,
        max_v_5m: maxVelocityPast,
        tonelajemean: tons,
        Grupomode: grupoOperador
      } = item

      let { operador: Operator } = item

      if (Operator === null || Operator === 'nan' || Operator === 'NR') {
        Operator = 'Sin operador'
      }

      return {
        camion,
        anomaly,
        posNeu,
        dateMin: formatDate(dateMin),
        dateMax: formatDate(dateMax),
        group,
        duration: (new Date(dateMax) - new Date(dateMin)) / 1000,
        maxVelocityPast: roundWithPrecision(maxVelocityPast),
        tons: tons == null ? 0 : roundWithPrecision(tons),
        grupoOperador,
        Operator: capitalizeNames(Operator)
      }
    })

    const groupedOperator = operatorData.map(item => {
      const { group: total, camion: trucks } = item

      let { operador: operator } = item

      if (operator === null || operator === 'nan' || operator === 'Nan' || operator === 'NR') {
        operator = 'Sin operador'
      }

      return {
        operator: capitalizeNames(operator),
        total,
        trucks: trucks.toString()
      }
    })

    return batch(() => {
      dispatch(
        onGetTireAnomalySuccess({
          anomalyData,
          groupedCaex,
          groupedOperator
        })
      )
    })
  } catch (error) {
    console.log(error)
    return dispatch(onGetTireAnomalyError({ error }))
  }
}

export const GET_TIRE_MAP = 'GET_TIRE_MAP'
export const GET_TIRE_MAP_ERROR = 'GET_TIRE_MAP_ERROR'
export const GET_TIRE_MAP_SUCCESS = 'GET_TIRE_MAP_SUCCESS'
export const onGetTireMap = makeActionCreator(GET_TIRE_MAP)
export const onGetTireMapError = makeActionCreator(GET_TIRE_MAP_ERROR, 'payload')
export const onGetTireMapSuccess = makeActionCreator(GET_TIRE_MAP_SUCCESS, 'payload')
export const onGetTireMapThunk = fileKey => async (dispatch, getState) => {
  dispatch(onGetTireMap())
  try {
    const imagesData = await getTireAnomalyImage({
      key: fileKey
    })
    console.log(imagesData)
    return batch(() => {
      dispatch(
        onGetTireMapSuccess({
          imagesData
        })
      )
    })
  } catch (error) {
    console.log(error)
    return dispatch(onGetTireMapError({ error }))
  }
}

export const TOGGLE_TIRE_ANOMALY_MODAL_SUCCESS = 'TOGGLE_TIRE_ANOMALY_MODAL_SUCCESS'
export const onToggleTireAnomalyModalSuccess = makeActionCreator(TOGGLE_TIRE_ANOMALY_MODAL_SUCCESS)

export const ADD_TIRE_ANOMALY_MODAL = 'ADD_TIRE_ANOMALY_MODAL'
export const onAddTireAnomalyModalSuccess = makeActionCreator(ADD_TIRE_ANOMALY_MODAL, 'payload')
export const onAddTireAnomalyModalThunk = anomaly => async dispatch => {
  if (anomaly !== undefined) {
    return dispatch(
      onAddTireAnomalyModalSuccess({
        anomalyselected: [anomaly]
      })
    )
  }
}

export const OPERATOR_ROW_MODAL_SUCCESS = 'OPERATOR_ROW_MODAL_SUCCESS'
export const onToggleOperatorRowModalSuccess = makeActionCreator(OPERATOR_ROW_MODAL_SUCCESS)

export const ADD_OPERATOR_ROW_MODAL = 'ADD_OPERATOR_ROW_MODAL'
export const onAddOperatorRowModalSuccess = makeActionCreator(ADD_OPERATOR_ROW_MODAL, 'payload')
export const onAddOperatorRowModalThunk = row => async (dispatch, getState) => {
  const { anomalyData } = getState().anomalyTires
  // Validate if data in redux
  if (anomalyData && anomalyData.length > 0) {
    const rowOperator = row.operator
    const rowOperatorSelected = anomalyData.filter(
      currRow => currRow.Operator.toLowerCase() === rowOperator.toLowerCase()
    )
    return dispatch(
      onAddOperatorRowModalSuccess({
        rowOperator: rowOperator,
        rowOperatorSelected: rowOperatorSelected
      })
    )
  }
}
