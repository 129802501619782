import React from 'react'
import WidgetBot from '@widgetbot/react-embed'

const QuickSight = () => {
  return (
    <>
      <WidgetBot width="100%" height="1000px" server="875047103771050055" channel="1156683169769205870" />
    </>
  )
}

export default QuickSight
