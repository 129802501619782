import { API } from 'aws-amplify'
import { REACT_APP_BASE_KEY, REACT_APP_BASE_NAME, REACT_APP_CLIENT_NAME } from '../config/settings/environment'

/**
 * To get the URL of pdf manual.
 */
export const getURLPDF = async () => {
  const paths = {
    amsa: '/get-manual',
    anglo: '/v2/manual/get-manual',
    zaldivar: '/v2/manual/get-manual'
  }
  const path = paths[REACT_APP_CLIENT_NAME] || '/v2/manual/get-manual'

  const response = await API.get(REACT_APP_BASE_NAME, path, {
    headers: { 'x-api-key': REACT_APP_BASE_KEY },
    response: true
  })

  return {
    data: response.data,
    headers: response.headers,
    request: response.config,
    status: response.status
  }
}
