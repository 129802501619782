import React, { forwardRef } from 'react'
import styled, { css } from 'styled-components'
import { hexToRGBA } from '../../modules/utils/color'
import { InputBase, MenuItem, Select } from '@material-ui/core'
import { ExpandMore } from '@material-ui/icons'

export const Root = styled.div`
  display: inline-flex;
  flex-direction: column;
  font-family: ${props => props.theme.typography.fontFamily};
  margin: ${props => props.margin};
  padding: ${props => props.padding};
  width: 100%;
`

export const Label = styled.label`
  color: ${props => props.theme.palette.brand.black};
  font-size: 16px;
  font-weight: ${props => props.theme.fontWeights[600]};
  letter-spacing: 1px;
  line-height: 17px;
  margin: 0 0 0.5rem;
`

export const Required = styled(({ ...props }) => <span {...props}>*</span>)`
  color: ${props => props.theme.palette.brand.gray[50]};
  font-size: 1rem;
`

export const Wrapper = styled.section`
  position: relative;
  width: 100%;
`

export const Placeholder = styled.section`
  align-items: center;
  box-sizing: border-box;
  color: ${props => props.theme.palette.brand.gray[50]};
  display: flex;
  font-family: ${props => props.theme.typography.fontFamily};
  font-size: 15px;
  height: 100%;
  justify-content: ${props => {
    if (props.textAlign === 'end') {
      return 'flex-end'
    }

    if (props.textAlign === 'start') {
      return 'flex-start'
    }

    return props.textAlign
  }};
  left: 0;
  line-height: 1.5;
  opacity: 0.42;
  padding: 0 30px 0 0.75rem;
  pointer-events: none;
  position: absolute;
  top: 0;
  transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  user-select: none;
  width: 100%;
`

export const BaseSelect = styled(({ className, maxWidth, ...props }) => (
  <Select {...props} MenuProps={{ ...props.MenuProps, classes: { paper: className } }} />
))`
  &.MuiPopover-paper {
    ${props =>
      props.maxWidth &&
      css`
        max-width: ${props.maxWidth};
        min-width: initial !important;
        width: 100%;
      `}

    ${props => props.theme.breakpoints.down('sm')} {
      max-width: calc(100% - 32px);
    }
  }

  &.MuiPopover-paper ul {
    border-radius: 4px;
    padding: 0;
  }
`

// eslint-disable-next-line react/prop-types
export const BaseInput = styled(forwardRef(({ textAlign, ...props }, ref) => <InputBase {...props} ref={ref} />))`
  &.MuiInputBase-root {
    background-clip: padding-box;
    background-color: ${props => props.theme.palette.brand.white};
    border: 1px solid ${props => props.theme.palette.brand.gray[85]};
    border-radius: 0.25rem;
    box-sizing: border-box;
    color: ${props => props.theme.palette.brand.gray[20]};
    display: block;
    font-size: 15px;
    height: calc(1.5em + 0.75rem + 2px);
    line-height: 1.5;
    position: relative;
    text-align: ${props => props.textAlign};
    -webkit-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    width: 100%;
  }

  &.MuiInputBase-root input {
    height: 100%;
    padding: 0;
    width: 100%;
  }

  &.MuiInputBase-root.Mui-focused {
    border: 1px solid ${props => props.theme.palette.brand.primary[50]};
    box-shadow: 0 0 0 0.2rem ${props => hexToRGBA(props.theme.palette.brand.primary[50], 0.25)};
  }

  &.MuiInputBase-root.Mui-disabled {
    background-color: rgba(46, 91, 255, 0.05);
    border: 1px solid ${props => props.theme.palette.brand.gray[85]};
    color: ${props => props.theme.palette.brand.gray[50]};
    cursor: not-allowed;
  }

  &.MuiInputBase-root.Mui-disabled input {
    cursor: not-allowed;
  }

  &.MuiInputBase-root.Mui-error {
    border: 1px solid ${props => props.theme.palette.brand.pomegranate};
  }

  &.MuiInputBase-root.Mui-error.Mui-focused {
    box-shadow: 0 0 0 0.2rem ${props => hexToRGBA(props.theme.palette.brand.pomegranate, 0.5)};
  }

  &.MuiInputBase-root .MuiSelect-select {
    box-sizing: border-box;
    padding: 0.375rem 30px 0.375rem 0.75rem;

    &:focus {
      background-color: ${props => props.theme.palette.brand.white};
      border-radius: 0.25rem;
    }

    &.Mui-disabled {
      cursor: not-allowed;
    }
  }
`

export const DropDown = styled.section`
  align-items: center;
  bottom: 0;
  display: flex;
  pointer-events: none;
  position: absolute;
  right: 7px;
  top: 0;
  user-select: none;
`

export const DropDownIcon = styled(ExpandMore)`
  color: ${props => (props.error ? props.theme.palette.brand.pomegranate : props.theme.palette.brand.gray[50])};
  font-size: 24px;
  pointer-events: none;
  user-select: none;
`

export const Option = styled(MenuItem)`
  &.MuiMenuItem-root {
    background-color: ${props => props.theme.palette.brand.white};
    color: ${props => props.theme.palette.brand.gray[20]};
    font-family: ${props => props.theme.typography.fontFamily};
    font-size: 15px;
    padding: 6px 12px;
    transition: none;
    width: 100%;
  }

  &.MuiMenuItem-root:focus-visible,
  &.MuiMenuItem-root:hover,
  &.MuiMenuItem-root.Mui-focusVisible,
  &.MuiMenuItem-root.Mui-selected {
    background-color: ${props => props.theme.palette.brand.primary[75]};
  }

  &.MuiMenuItem-root > .MuiTouchRipple-root {
    display: none;
  }
`

export const OptionContent = styled.div`
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

export const BottomMessage = styled.section`
  font-family: ${props => props.theme.typography.fontFamily};
  font-size: 12px;
  letter-spacing: 0;
  line-height: 16px;
  margin: 3px 0 0;
  min-height: 20px;
`

export const Info = styled.section`
  color: ${props => props.theme.palette.brand.gray[50]};
  font-style: italic;
`

export const Error = styled.section`
  color: ${props => props.theme.palette.brand.pomegranate};
`
