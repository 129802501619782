import {
  GET_NEW_ANOMALIES,
  GET_NEW_ANOMALIES_ERROR,
  GET_NEW_ANOMALIES_SUCCESS,
  GET_NEW_ANOMALIES_SUMMARY,
  GET_NEW_ANOMALIES_SUMMARY_ERROR,
  GET_NEW_ANOMALIES_SUMMARY_SUCCESS,
  TOGGLE_NEW_ANOMALY_MODAL_SUCCESS,
  ADD_NEW_ANOMALY_MODAL,
  TOGGLE_ANOMALY_GRAPH,
  GET_NEW_ANOMALIES_REPORT,
  GET_NEW_ANOMALIES_REPORT_ERROR,
  GET_NEW_ANOMALIES_REPORT_SUCCESS
} from './NewAnomaly.actions'

const newAnomalyState = {
  originalAnomalies: {},
  newAnomalies: {
    anomalies: []
  },
  anomaliesTable: [],
  anomaliesSummary: [],
  error: false,
  errorMsg: undefined,
  loadingNew: true,
  loadingSummary: true,
  showNewAnomalyModal: false,
  selectedAnomaly: [],
  showBar: true,
  anomaliesQuantityTotal: {},
  anomaliesQuantitySeparated: {},
  anomaliesFuelTotal: {},
  anomaliesFuelSeparated: {},
  anomaliesBarFilter1: {},
  anomaliesBarFilter2: {},
  totalAnomalies: 0,
  totalFuelLiters: 0,
  totalExceden: 0,
  totalCargaPequena: 0,
  firstDate: undefined,
  lastDate: undefined,
  csvArray: [],
  reportSuccess: false,
  loadingReport: false,
  errorReport: false,
  tableFilter: 'value-1'
}

/**
 * The new anomaly reducer.
 */
const newAnomalyReducer = (state = newAnomalyState, { payload, type }) => {
  switch (type) {
    case GET_NEW_ANOMALIES:
    case GET_NEW_ANOMALIES_SUMMARY: {
      return { ...state, loadingNew: true, loadingSummary: true, error: false }
    }
    case GET_NEW_ANOMALIES_ERROR:
    case GET_NEW_ANOMALIES_SUMMARY_ERROR: {
      return { ...state, loadingNew: false, loadingSummary: false, error: true, errorMsg: payload.error }
    }
    case GET_NEW_ANOMALIES_SUCCESS: {
      return {
        ...state,
        originalAnomalies: payload.originalAnomalies,
        loadingNew: false,
        newAnomalies: payload.newAnomalies,
        anomaliesTable: payload.anomaliesTable,
        anomaliesQuantityTotal: payload.anomaliesQuantityTotal,
        anomaliesQuantitySeparated: payload.anomaliesQuantitySeparated,
        anomaliesFuelTotal: payload.anomaliesFuelTotal,
        anomaliesFuelSeparated: payload.anomaliesFuelSeparated,
        totalAnomalies: payload.totalAnomalies,
        totalFuelLiters: payload.totalFuelLiters,
        totalExceden: payload.totalExceden,
        totalCargaPequena: payload.totalCargaPequena,
        firstDate: payload.firstDate,
        lastDate: payload.lastDate,
        tableFilter: payload.tableFilter
      }
    }
    case GET_NEW_ANOMALIES_SUMMARY_SUCCESS: {
      return {
        ...state,
        loadingSummary: false,
        anomaliesSummary: payload.anomaliesSummary,
        anomaliesBarFilter1: payload.anomaliesBarFilter1,
        anomaliesBarFilter2: payload.anomaliesBarFilter2
      }
    }
    case TOGGLE_NEW_ANOMALY_MODAL_SUCCESS: {
      return { ...state, showNewAnomalyModal: !state.showNewAnomalyModal }
    }
    case ADD_NEW_ANOMALY_MODAL: {
      return { ...state, selectedAnomaly: payload.selectedAnomaly }
    }
    case TOGGLE_ANOMALY_GRAPH: {
      return { ...state, showBar: !state.showBar }
    }
    case GET_NEW_ANOMALIES_REPORT: {
      return { ...state, loadingReport: true, errorReport: false }
    }
    case GET_NEW_ANOMALIES_REPORT_ERROR: {
      return { ...state, loadingReport: false, errorReport: true }
    }
    case GET_NEW_ANOMALIES_REPORT_SUCCESS: {
      return { ...state, loadingReport: false, errorReport: false, reportSuccess: true, csvArray: payload.csvArray }
    }
    default: {
      return state
    }
  }
}

export default newAnomalyReducer
