// FIXME: Need to be refactored
import React from 'react'
import PropTypes from 'prop-types'
import GroupStationMobile from './GroupStationMobile.container'
import gas from '../../constants/icons/gasStation.svg'
import time from '../../constants/icons/timeOutline.svg'
import fuel from '../../constants/icons/fuel.svg'
import engineOutline from '../../constants/icons/engineOutline.svg'
import { GridList, GridListTile, Paper, Grid } from '@material-ui/core'

/**
 * The MobileStationContent' container.
 */
const MobileStationContent = ({ groups }) => {
  const symbology = () => {
    return (
      <Grid container direction="row" justify="flex-start" alignItems="flex-start" style={{ marginBottom: '20px' }}>
        <Grid item xs={10}>
          <p style={{ marginTop: '10px', marginBottom: '0px', marginRight: '20px', display: 'flex' }}>
            {/* <img style={{ marginLeft: '10px' }} src={indicator} alt={'indicator'} /> Tipo óptimo entre abastecimientos */}
            {/* <img style={{ marginLeft: '10px' }} src={fuel} alt={'fuel'} /> CAEX y otros */}
            <img style={{ marginLeft: '10px' }} src={gas} alt="gas" /> CAEX
            <img style={{ marginLeft: '10px' }} src={engineOutline} alt="engineOutline" /> Otras maquinarias
            {/* <img style={{ marginLeft: '10px' }} src={sm} alt={'sm'} /> Sin movimientos */}
          </p>
        </Grid>
      </Grid>
    )
  }

  return (
    <div>
      {symbology()}
      <GridList cellHeight="auto" spacing={10}>
        {groups.map(group => {
          return (
            <GridListTile cols={1} rows={1} key={'card-' + group.name}>
              <Paper style={{ padding: 10 }} elevation={5} square={false} variant="outlined">
                <Grid container direction="row" justify="center" alignItems="center">
                  <Grid item xs={12} style={{ margin: '5px' }}>
                    <h2 style={{ marginBottom: '10px' }}>
                      <strong>{group.name}</strong>
                    </h2>
                    <h4 style={{ marginBottom: '10px', marginTop: '0px', display: 'flex' }}>
                      <img src={fuel} alt="fuel" style={{ marginRight: '5px' }} />{' '}
                      {`${group.truck_total_charges} abastecimientos`}{' '}
                      <img src={time} alt="time" style={{ marginLeft: '10px', marginRight: '5px' }} />{' '}
                      {`${parseInt(group.mean_queue_time)} min promedio entre abastecimientos`}
                    </h4>
                  </Grid>
                </Grid>
                <GroupStationMobile key={'Card-' + group.name} mobileStations={group} />
              </Paper>
            </GridListTile>
          )
        })}
      </GridList>
    </div>
  )
}

MobileStationContent.propTypes = {
  groups: PropTypes.array
}

export default MobileStationContent
