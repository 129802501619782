import React from 'react'
import styled from 'styled-components'
import { Grid as BaseGrid } from '@material-ui/core'

export const Grid = styled(({ children, textAlign, ...props }) => <BaseGrid {...props}>{children}</BaseGrid>)`
  margin: ${props => props.margin};
  text-align: ${props => props.textAlign};
`

export const Message = styled.h3``
