import { format } from 'date-fns'
import { es } from 'date-fns/locale'
import { ALLOW_ONLY_NUMBERS, CLEAN_NUMBERS, CURRENCY_DOTS, ESCAPE_HTML, ESCAPE_JS } from '../../config/settings/regexes'
import { cleanDNI } from './helpers'

/**
 * To decorate the given value by the date format pattern.
 */
export const formatDate = (value, pattern = 'dd/MM/yyyy HH:mm') => format(new Date(value), pattern, { locale: es })

/**
 * To decorate the given value to a percentage format.
 */
export const formatPercentage = (value, decimals = 2) => {
  if (typeof value !== 'number' && typeof value !== 'string') {
    return value
  }

  const number = Number.parseFloat(value)
  if (decimals > 0 && number.toString().includes('.')) {
    decimals = Math.min(number.toString().split('.')[1].length, decimals)
  } else {
    decimals = 0
  }

  return `${number.toFixed(decimals).replace('.', ',')}%`
}

/**
 * To permit only numbers.
 */
export const formatToAllowOnlyNumbers = value =>
  ALLOW_ONLY_NUMBERS.test(value) ? value : value.replace(CLEAN_NUMBERS, '')

/**
 * Format an amount to Chilean Pesos.
 */
export const formatPrice = value => {
  const numberFormat = new Intl.NumberFormat('en-US', {
    currency: 'CLP',
    style: 'currency'
  })

  return numberFormat.format(value)
}

/**
 * To format to HTML encode.
 */
export const formatHTMLEncode = value =>
  String(value)
    .replace(ESCAPE_JS, '')
    .replace(ESCAPE_HTML, character => `&#${character.charCodeAt(0)};`)

/**
 * To format to unicode encode.
 */
export const formatUnicodeEncode = value =>
  String(value)
    .replace(ESCAPE_JS, '')
    .replace(ESCAPE_HTML, character => `\\u${`0000${character.charCodeAt(0).toString(16)}`.slice(-4)}`)

/**
 * To format by the given regular expression.
 */
export const formatRegEx = regex => value => {
  if (typeof value !== 'string') {
    return value
  }

  return value.replace(regex, '')
}

/**
 * To decorate the given value to a chilean DNI format.
 * The type parameter can have the values 1 or 2.
 * Type 1 means the given value is formatted as 11.111.111-1.
 * Type 2 means the given value is formatted as 11111111-1.
 */
export const formatDNI = (value, type = 1) => {
  const updatedValue = cleanDNI(value?.toString())

  if (updatedValue.length === 0) return updatedValue
  if (updatedValue.length === 1) return updatedValue

  const dv = updatedValue.substring(updatedValue.length - 1)
  const dni = updatedValue.substring(0, updatedValue.length - 1)

  return type === 1 ? `${dni.replace(CURRENCY_DOTS, '$1.')}-${dv}` : `${dni}-${dv}`
}

/**
 * To decorate the given value to a number with a thousands separator.
 */
export const formatNumberWithThousandSeparator = value => {
  if (value.toString().includes('.')) {
    const integerPart = value.toString().split('.')[0]
    const decimalPart = value.toString().split('.')[1]
    return `${integerPart.replace(CURRENCY_DOTS, '$1.')},${decimalPart}`
  }
  return value.toString().replace('.', ',').replace(CURRENCY_DOTS, '$1.')
}
