import React from 'react'
import { useSelector } from 'react-redux'
import { usePagination } from '../../components/Tables/Pagination.hooks'
import Pagination from '../../components/Tables/Pagination'
import { Root } from './Tires.styles'
import Table from '../../components/Tables/Table'
import TableCell from '../../components/Tables/TableCell'
import TableRow from '../../components/Tables/TableRow'
/**
 * The Tire Anomalie's container
 */

const TireCaexContainer = () => {
  const { groupedCaex } = useSelector(state => state.anomalyTires)
  const { page, totalPages, onChangePage, onGetCurrentPage } = usePagination({
    data: groupedCaex,
    initialPage: 1,
    rowsPerPage: 10
  })

  return (
    <>
      <Root>
        <Table accessibility={{ label: 'Caex grouped' }}>
          <TableRow head>
            <TableCell>Equipo</TableCell>
            <TableCell>Total Anomalías</TableCell>
            <TableCell>Neumático pos. 1</TableCell>
            <TableCell>Neumático pos. 2</TableCell>
            <TableCell>Neumático pos. 3</TableCell>
            <TableCell>Neumático pos. 4</TableCell>
            <TableCell>Neumático pos. 5</TableCell>
            <TableCell>Neumático pos. 6</TableCell>
          </TableRow>

          {onGetCurrentPage().map((row, index) => {
            return (
              <TableRow key={`operador-${index}`}>
                <TableCell align="center">{row.equipo}</TableCell>
                <TableCell align="center">{row.total}</TableCell>
                <TableCell align="center">{row.pos1}</TableCell>
                <TableCell align="center">{row.pos2}</TableCell>
                <TableCell align="center">{row.pos3}</TableCell>
                <TableCell align="center">{row.pos4}</TableCell>
                <TableCell align="center">{row.pos5}</TableCell>
                <TableCell align="center">{row.pos6}</TableCell>
              </TableRow>
            )
          })}
        </Table>
        <Pagination alignment="right" count={totalPages} page={page} onChange={onChangePage} />
      </Root>
    </>
  )
}

export default TireCaexContainer
