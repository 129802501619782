class GroupsEfficiencyModel {
  constructor() {
    this.month = 1
    this.year = 2020
    this.groupId = ''
    this.groupName = ''
    this.status = 1 // 1 to 3
    this.activity = 'off' // day, night, off
    this.stadistics = [
      {
        percentage: 0,
        count: 0.0
      }
    ]
  }

  formatStatisticsOfGroup = data => {
    return [
      {
        percentage: 15,
        count: data['p0-15'],
        score: data['p0-15_percent']
      },
      {
        percentage: 25,
        count: data['p15-25'],
        score: data['p15-25_percent']
      },
      {
        percentage: 40,
        count: data['p25-40'],
        score: data['p25-40_percent']
      },
      {
        percentage: 50,
        count: data['p40-50'],
        score: data['p40-50_percent']
      },
      {
        percentage: 100,
        count: data['p50-100'],
        score: data['p50-100_percent']
      }
      // {
      //   percentage: 100,
      //   count: data['p50-100'],
      //   score: data['p50-100_percent']
      // },
    ]
  }

  fromV1 = (group, data, month, year) => {
    this.month = month
    this.year = year
    this.groupId = group.toString()
    this.groupName = 'Grupo ' + group.toString()
    this.status = data.status
    this.activity = data.time
    this.stadistics = this.formatStatisticsOfGroup(data)
    return this
  }
}

export default GroupsEfficiencyModel
