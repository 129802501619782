import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Grid, Button } from '@material-ui/core'
import Modal from '../../components/Basics/Modal'
import useStyles from '../../styles/modalsStyle'
import { onToggleNewAnomalyModalSuccess } from './NewAnomaly.actions'
import { Separator, ModalRow, ModalWrapper, ModalBlock, TableLine } from './NewAnomaly.styles'
import Typography from '../../components/Basics/Typography'
import moment from 'moment'

/**
 * The NewAnomalieModal's container.
 * preguntar vale que es id
 */
const NewAnomalieModal = () => {
  const dispatch = useDispatch()
  const { showNewAnomalyModal, selectedAnomaly } = useSelector(state => state.newAnomaly)
  const handleClose = () => dispatch(onToggleNewAnomalyModalSuccess())
  const classes = useStyles()
  const isConcatenated = selectedAnomaly[0].isConcatenated
  const anomalyType = selectedAnomaly[0].fuelLoadType
  const firstTime = moment(selectedAnomaly[0].hourAnomaly[0], 'HH:mm:ss')
  const lateTime = moment(selectedAnomaly[0].hourAnomaly[1], 'HH:mm:ss')
  const deltaTime = lateTime.diff(firstTime, 'minutes')

  const bodyForm = (
    <>
      <Grid container direction="row" spacing={4} alignItems="center">
        <Grid item md={3}>
          <ModalBlock>
            <Typography variant="body2" fontWeight="black" color="black">
              Fecha
            </Typography>
          </ModalBlock>
          <ModalBlock>
            <Typography variant="body1">{selectedAnomaly[0].anomalyDate.format('YYYY/MM/DD')}</Typography>
          </ModalBlock>
          <TableLine />
        </Grid>
        <Grid item md={1} />
        <Grid item md={8}>
          <ModalBlock>
            <Typography variant="body2" fontWeight="black" color="black">
              Tipo de anomalía:
            </Typography>
          </ModalBlock>
          <ModalBlock>
            {anomalyType === 'Excede capacidad' && (
              <Typography variant="body1">Litros abastecidos superan la capacidad del estanque.</Typography>
            )}
            {anomalyType === 'Carga Pequeña' && <Typography variant="body1">Abastecimiento pequeño.</Typography>}
          </ModalBlock>
          <TableLine />
        </Grid>
        <Grid item md={3}>
          <ModalBlock>
            <Typography variant="body2" fontWeight="black" color="black">
              Hora de inicio
            </Typography>
          </ModalBlock>
          <ModalBlock>
            <Typography variant="body1">{selectedAnomaly[0].hourAnomaly[0]}</Typography>
          </ModalBlock>
          <TableLine />
        </Grid>
        <Grid item md={1} />
        <Grid item md={8}>
          <ModalRow>
            <ModalWrapper>
              <ModalBlock>
                <Typography variant="body2" fontWeight="black" color="black">
                  Capacidad Max:
                </Typography>
              </ModalBlock>
              <ModalBlock>
                <Typography variant="body1">{selectedAnomaly[0].tankCapacity} lts</Typography>
              </ModalBlock>
            </ModalWrapper>
            <ModalWrapper>
              <ModalBlock>
                <Typography variant="body2" fontWeight="black" color="black">
                  Lts abastecidos:
                </Typography>
              </ModalBlock>
              <ModalBlock>
                <Typography variant="body1">{selectedAnomaly[0].quantity[0]} lts</Typography>
              </ModalBlock>
            </ModalWrapper>
            <ModalWrapper>
              {anomalyType === 'Excede capacidad' ? (
                <>
                  <ModalBlock>
                    <Typography variant="body2" fontWeight="black" color="black">
                      Lts anomalos
                    </Typography>
                  </ModalBlock>
                  <ModalBlock>
                    <Typography variant="body1" fontWeight="black" color="black">
                      {selectedAnomaly[0].fuelLitres} lts
                    </Typography>
                  </ModalBlock>
                </>
              ) : (
                <>
                  <ModalBlock>
                    <Typography variant="body2" fontWeight="black" color="black">
                      % de llenado
                    </Typography>
                  </ModalBlock>
                  <ModalBlock>
                    <Typography variant="body1" fontWeight="bold" color="black2">
                      {Math.round((selectedAnomaly[0].quantity[0] * 100) / selectedAnomaly[0].tankCapacity)}%
                    </Typography>
                  </ModalBlock>
                </>
              )}
            </ModalWrapper>
          </ModalRow>
          <TableLine />
        </Grid>
        <Grid item md={3}>
          <ModalBlock>
            <Typography variant="body2" fontWeight="black" color="black">
              Equipo
            </Typography>
          </ModalBlock>
          <ModalBlock>
            <Typography variant="body1">CAEX {selectedAnomaly[0].truck}</Typography>
          </ModalBlock>
          <TableLine />
        </Grid>
        <Grid item md={1} />
        <Grid item md={8}>
          <ModalRow>
            <ModalWrapper>
              <ModalBlock>
                <Typography variant="body2" fontWeight="black" color="black">
                  Tipo de Carga
                </Typography>
              </ModalBlock>
              <ModalBlock>
                <Typography variant="body1">{isConcatenated === 'Concatenada' ? 'Concatenada*' : 'Normal'}</Typography>
              </ModalBlock>
            </ModalWrapper>
            <ModalWrapper>
              <ModalBlock>
                <Typography variant="body2" fontWeight="black" color="black">
                  ID Transacción
                </Typography>
              </ModalBlock>
              <ModalBlock>
                <Typography variant="body1">
                  {isConcatenated === 'Concatenada'
                    ? selectedAnomaly[0].transactions.join(' - ')
                    : selectedAnomaly[0].transactions}
                </Typography>
              </ModalBlock>
            </ModalWrapper>
            <ModalWrapper />
          </ModalRow>
          <TableLine />
        </Grid>
        <Grid item md={3}>
          <ModalBlock>
            <Typography variant="body2" fontWeight="black" color="black">
              Punto de carga
            </Typography>
          </ModalBlock>
          <ModalBlock>
            <Typography variant="body1">
              {selectedAnomaly[0].stations[0]} - ID {selectedAnomaly[0].transactions[0]}
            </Typography>
          </ModalBlock>
          <TableLine />
        </Grid>
        <Grid item md={1} />
        {isConcatenated === 'Concatenada' ? (
          <>
            <Grid item md={8}>
              <ModalRow>
                <ModalWrapper>
                  <ModalBlock>
                    <Typography variant="body2" fontWeight="black" color="black">
                      Horas de Inicio
                    </Typography>
                  </ModalBlock>
                  <ModalBlock>
                    <Typography variant="body1">{selectedAnomaly[0].hourAnomaly.join(' - ')}</Typography>
                  </ModalBlock>
                </ModalWrapper>
                <ModalWrapper>
                  <ModalBlock>
                    <Typography variant="body2" fontWeight="black" color="black">
                      Cantidades
                    </Typography>
                  </ModalBlock>
                  <ModalBlock>
                    <Typography variant="body1">{selectedAnomaly[0].quantity.join(' - ')}</Typography>
                  </ModalBlock>
                </ModalWrapper>
                <ModalWrapper>
                  <ModalBlock>
                    <Typography variant="body2" fontWeight="black" color="black">
                      Tpo entre transacciones
                    </Typography>
                  </ModalBlock>
                  <ModalBlock>
                    <Typography variant="body1">{deltaTime} min</Typography>
                  </ModalBlock>
                </ModalWrapper>
              </ModalRow>
              <TableLine />
            </Grid>
          </>
        ) : (
          <>
            <Grid item md={8} />
          </>
        )}
        <Grid item md={3}>
          <ModalBlock>
            <Typography variant="body2" fontWeight="black" color="black">
              Último abastecimiento
            </Typography>
          </ModalBlock>
          <ModalBlock>
            <Typography variant="body1">{selectedAnomaly[0].stations[0]}</Typography>
          </ModalBlock>
        </Grid>
        <Grid item md={1} />
        {isConcatenated === 'Concatenada' ? (
          <>
            <Grid item md={8}>
              <ModalRow>
                <ModalWrapper>
                  <ModalBlock>
                    <Typography variant="subtitle">
                      * Una carga concatenada corresponde a abastecimientos que se registran en 2 o más transacciones
                      pero que, sin embargo, corresponden a un único abastecimiento.
                    </Typography>
                  </ModalBlock>
                </ModalWrapper>
              </ModalRow>
            </Grid>
          </>
        ) : (
          <>
            <Grid item md={8} />
          </>
        )}
      </Grid>
    </>
  )

  const header = (
    <Grid container direction="row" justify="space-between">
      <Typography className={classes.headerText}>Detalles de transacción</Typography>
    </Grid>
  )

  const footer = (
    <Grid container direction="row" justify="flex-end">
      <Button variant="contained" color="primary" onClick={handleClose}>
        Cerrar
      </Button>
    </Grid>
  )

  return (
    <Modal open={showNewAnomalyModal} onClose={handleClose}>
      {header}
      <Separator />
      {bodyForm}
      <Separator />
      {footer}
    </Modal>
  )
}

export default NewAnomalieModal
