import React from 'react'
import PropTypes from 'prop-types'
import { Root } from './Tooltip.styles'

/**
 * The Tooltip' component.
 */
const Tooltip = props => {
  const { children, content, ...restProps } = props

  return (
    <Root {...restProps} arrow title={content}>
      {children}
    </Root>
  )
}

Tooltip.defaultProps = {
  content: '',
  height: 'auto',
  maxWidth: '500px'
}

Tooltip.propTypes = {
  children: PropTypes.node.isRequired,
  content: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  maxWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
}

export default Tooltip
