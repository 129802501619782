import React from 'react'
import styled from 'styled-components'
import { InputBase } from '@material-ui/core'
import { Help as HelpIcon } from '@material-ui/icons'
import { hexToRGBA } from '../../modules/utils/color'

export const Root = styled.div`
  display: inline-flex;
  flex-direction: column;
  font-family: ${props => props.theme.typography.fontFamily};
  margin: ${props => props.margin};
  padding: ${props => props.padding};
  width: 100%;
`

export const Label = styled.label`
  color: ${props => props.theme.palette.brand.black};
  font-size: 16px;
  font-weight: ${props => props.theme.fontWeights[600]};
  letter-spacing: 1px;
  line-height: 17px;
  margin: 0 0 0.5rem;
`

export const Counter = styled.label`
  color: ${props => props.theme.palette.brand.gray[50]};
  font-size: 12px;
  font-weight: ${props => props.theme.fontWeights[500]};
  line-height: 16px;
`

export const Details = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  margin: 3px 0 0;
`

export const Required = styled(({ ...props }) => <span {...props}>*</span>)`
  color: ${props => props.theme.palette.brand.gray[50]};
  font-size: 1rem;
  margin: 0 0 0 4px;
`

export const TooltipIcon = styled(HelpIcon)`
  &.MuiSvgIcon-root {
    fill: ${props => props.theme.palette.brand.gray[50]};
    font-size: 0.8rem;
    margin: 0 0 0 4px;
  }
`

export const BaseInput = styled(({ textAlign, ...props }) => <InputBase {...props} />)`
  &.MuiInputBase-root {
    background-clip: padding-box;
    background-color: ${props => props.theme.palette.brand.white};
    border: 1px solid ${props => props.theme.palette.brand.gray[85]};
    border-radius: 0.25rem;
    box-sizing: border-box;
    color: ${props => props.theme.palette.brand.gray[20]};
    display: flex;
    font-size: 15px;
    height: calc(1.5em + 0.75rem + 2px);
    line-height: 1.5;
    overflow: hidden;
    -webkit-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }

  &.MuiInputBase-root .MuiInputBase-input {
    box-sizing: border-box;
    flex-grow: 1;
    height: 100%;
    overflow: hidden;
    padding: 0.375rem 0.75rem;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: auto;
    text-align: ${props => props.textAlign};

    &::placeholder {
      color: ${props => props.theme.palette.brand.gray[50]};
      font-family: ${props => props.theme.typography.fontFamily};
      font-size: 15px;
      line-height: 1.5;
      opacity: 0.42;
      transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    }
  }

  &.MuiInputBase-root.Mui-focused {
    border: 1px solid ${({ reverse, theme }) => (reverse ? theme.palette.brand.white : theme.palette.brand.primary[50])};
    box-shadow: 0 0 0 0.2rem
      ${({ reverse, theme }) =>
        reverse ? hexToRGBA(theme.palette.brand.white, 0.25) : hexToRGBA(theme.palette.brand.primary[50], 0.25)};
  }

  &.MuiInputBase-root.Mui-disabled {
    background-color: rgba(46, 91, 255, 0.05);
    border: 1px solid ${props => props.theme.palette.brand.gray[85]};
    color: ${props => props.theme.palette.brand.gray[50]};
    cursor: not-allowed;
  }

  &.MuiInputBase-root.Mui-disabled .MuiInputBase-input {
    cursor: not-allowed;
  }

  &.MuiInputBase-root.Mui-error {
    border: 1px solid ${({ reverse, theme }) => (reverse ? theme.palette.brand.white : theme.palette.brand.pomegranate)};
  }

  &.MuiInputBase-root.Mui-error.Mui-focused {
    box-shadow: 0 0 0 0.2rem
      ${({ reverse, theme }) =>
        reverse ? hexToRGBA(theme.palette.brand.white, 0.25) : hexToRGBA(theme.palette.brand.pomegranate, 0.5)};
  }
`

export const BottomMessage = styled.section`
  font-family: ${props => props.theme.typography.fontFamily};
  font-size: 12px;
  line-height: 16px;
  min-height: 20px;
`

export const Info = styled.section`
  color: ${props => props.theme.palette.brand.gray[50]};
  font-style: italic;
`

export const Error = styled.section`
  color: ${({ reverse, theme }) => (reverse ? theme.palette.brand.white : theme.palette.brand.pomegranate)};
`

export const Prefix = styled.div`
  align-items: center;
  background-color: ${props => props.theme.palette.brand.gray[95]};
  display: flex;
  height: 100%;
  justify-content: center;
  width: 54px;
`

export const Suffix = styled.div`
  align-items: center;
  background-color: ${props => props.theme.palette.brand.gray[95]};
  cursor: pointer;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 40px;
`
