import { formatNumberWithThousandSeparator } from '../../modules/utils/formatters'
import { roundWithPrecision } from '../../modules/utils/math'
import { getDispatchOrpak } from '../../services/newanomaliesServices'
import { makeActionCreator } from '../../config/store/utils'
import { batch } from 'react-redux'
import { getMonthString } from '../../utils'

export const GET_ORPAK_DISPATCH = 'GET_ORPAK_DISPATCH'
export const GET_ORPAK_DISPATCH_ERROR = 'GET_ORPAK_DISPATCH_ERROR'
export const GET_ORPAK_DISPATCH_SUCCESS = 'GET_ORPAK_DISPATCH_SUCCESS'
export const onGetOrpakDispatch = makeActionCreator(GET_ORPAK_DISPATCH)
export const onGetOrpakDispatchError = makeActionCreator(GET_ORPAK_DISPATCH_ERROR, 'payload')
export const onGetOrpakDispatchSuccess = makeActionCreator(GET_ORPAK_DISPATCH_SUCCESS, 'payload')
export const getOrpakDispatchThunk = () => async (dispatch, getState) => {
  const { dispatchOrpak } = getState().dispatchOrpak

  if (dispatchOrpak.dispatchOrpakData.length > 0) {
    return
  }

  dispatch(onGetOrpakDispatch())

  try {
    const {
      apis: {
        base: { apiKey, apiName }
      }
    } = getState().auth

    const { data } = await getDispatchOrpak({
      apiKey,
      apiName
    })

    const length = data.length
    const sumLitersDispatch = data.reduce((a, b) => a + b.Litros_Dispatch, 0)
    const sumLitersOrpak = data.reduce((a, b) => a + b.Litros_Orpak, 0)
    const totalDiffDispatchOrpak = data.reduce((a, b) => a + b.difference_dispatch_orpak, 0)
    const tableTotal = {
      totalCountDis: data.reduce((a, b) => a + b.Abastecimientos_Dispatch, 0),
      totalLitersDis: formatNumberWithThousandSeparator(Math.trunc(sumLitersDispatch)),
      totalCountOrpak: data.reduce((a, b) => a + b.Abastecimientos_Orpak, 0),
      totalLitersOrpak: formatNumberWithThousandSeparator(Math.trunc(sumLitersOrpak)),
      totalDiff: formatNumberWithThousandSeparator(Math.trunc(totalDiffDispatchOrpak))
    }

    const tableMean = {
      meanCountDis: roundWithPrecision(tableTotal.totalCountDis / length),
      meanLiterDis: formatNumberWithThousandSeparator(roundWithPrecision(Math.trunc(sumLitersDispatch) / length, 2)),
      meanTruckDis: roundWithPrecision(data.reduce((a, b) => a + b.Camiones_Dispatch, 0) / length, 2),
      meanCountOrpak: roundWithPrecision(tableTotal.totalCountOrpak / length),
      meanLitersOrpak: formatNumberWithThousandSeparator(roundWithPrecision(Math.trunc(sumLitersOrpak) / length, 2)),
      meanTruckOrpak: roundWithPrecision(data.reduce((a, b) => a + b.Camiones_Orpak, 0) / length, 2),
      meanDiff: formatNumberWithThousandSeparator(roundWithPrecision(Math.trunc(totalDiffDispatchOrpak) / length, 2))
    }

    const tableFooter = {
      total: tableTotal,
      mean: tableMean
    }

    const dispatchOrpakData = data.map(item => {
      const {
        year: año,
        month: mes,
        Abastecimientos_Dispatch: countDispatch,
        Abastecimientos_Orpak: countOrpak,
        Camiones_Dispatch: camionesDispatch,
        Camiones_Orpak: camionesOrpak,
        Litros_Dispatch: ltrsDispatch,
        Litros_Orpak: ltrsOrpak,
        difference_dispatch_orpak: diffDispatchOrpak
      } = item

      return {
        año,
        mes: getMonthString(mes - 1),
        countDispatch,
        countOrpak,
        camionesDispatch,
        camionesOrpak,
        ltrsDispatch: formatNumberWithThousandSeparator(Math.trunc(ltrsDispatch)),
        ltrsOrpak: formatNumberWithThousandSeparator(Math.trunc(ltrsOrpak)),
        diffDispatchOrpak: formatNumberWithThousandSeparator(Math.trunc(diffDispatchOrpak))
      }
    })

    return batch(() => {
      dispatch(
        onGetOrpakDispatchSuccess({
          dispatchOrpak: {
            dispatchOrpakData: dispatchOrpakData
          },
          tableFooter
        })
      )
    })
  } catch (error) {
    return dispatch(onGetOrpakDispatchError({ error }))
  }
}
