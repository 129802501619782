import { API } from 'aws-amplify'
import { REACT_APP_BASE_KEY, REACT_APP_CLIENT_NAME } from '../config/settings/environment'

/**
 * Fetch HTML report.
 */
export const getReportHTML = async ({ apiKey, apiName }) => {
  // Define paths for different clients
  const paths = {
    amsa: '/get-html-report',
    anglo: '/get-html-report',
    antucoya: '/get-html-report'
  }
  // Set the path based on the client name or use a default path
  const path = paths[REACT_APP_CLIENT_NAME] || '/get-html-report'

  // Initialize the request with headers, including the API key
  const init = {
    headers: {
      'x-api-key': REACT_APP_BASE_KEY
    },
    response: true
  }

  // Perform the API call
  const response = await API.get(apiName, path, init)

  // Return the response details
  return {
    data: response.data,
    headers: response.headers,
    request: response.config,
    status: response.status
  }
}
