import { batch } from 'react-redux'
import Amplify, { Auth, Analytics } from 'aws-amplify'
import {
  REACT_APP_API_BASE_URL,
  REACT_APP_API_BASE_URL_V2,
  REACT_APP_BASE_KEY,
  REACT_APP_BASE_NAME,
  REACT_APP_BASE_SUB_PATH,
  REACT_APP_CLIENT_NAME,
  REACT_APP_PATH_WEB_SOCKET,
  REACT_APP_REGION,
  REACT_APP_REINFORCEMENT_KEY,
  REACT_APP_REINFORCEMENT_NAME,
  REACT_APP_REINFORCEMENT_SUB_PATH,
  REACT_APP_USER_POOL_CLIENT_ID,
  REACT_APP_USER_POOL_ID,
  REACT_APP_PINPOINT_ID
} from '../../config/settings/environment'
import { makeActionCreator } from '../../config/store/utils'
import { removeCookie } from '../../modules/storages/cookies'
import { signIn, signOut, signUp, forgotPassword, forgotPasswordSubmit } from '../../services/authAWS'

export const LOG_IN = 'LOG_IN'
export const LOG_IN_ERROR = 'LOG_IN_ERROR'
export const LOG_IN_SUCCESS = 'LOG_IN_SUCCESS'
export const onLogIn = makeActionCreator(LOG_IN)
export const onLogInError = makeActionCreator(LOG_IN_ERROR, 'payload')
export const onLogInSuccess = makeActionCreator(LOG_IN_SUCCESS, 'payload')
export const onLogInThunk = ({ username, password, onSuccessCallback = () => undefined }) => async dispatch => {
  dispatch(onLogIn())

  try {
    const response = await signIn({
      actionType: LOG_IN,
      username,
      password
    })

    if (response.challengeName === 'NEW_PASSWORD_REQUIRED') {
      // const loggedInUser = await Auth.completeNewPassword(response, newPassword)
      const code = 400
      const reason = 'POR FAVOR CREE UNA NUEVA CONTRASEÑA'
      return batch(() => {
        dispatch(onLogInError({ error: { code, reason } }))
        dispatch(onCloseChangePasswordModal())
      })
    } else {
      console.log('no es necesario nueva contraseña')
    }

    const data = {
      apis: {
        [REACT_APP_BASE_NAME]: {
          apiName: REACT_APP_BASE_NAME,
          subPath: REACT_APP_BASE_SUB_PATH,
          apiKey: REACT_APP_BASE_KEY
        },
        [REACT_APP_REINFORCEMENT_NAME]: {
          apiName: REACT_APP_REINFORCEMENT_NAME,
          subPath: REACT_APP_REINFORCEMENT_SUB_PATH,
          apiKey: REACT_APP_REINFORCEMENT_KEY
        }
      },
      baseUrl: REACT_APP_API_BASE_URL,
      baseUrlV2: REACT_APP_API_BASE_URL_V2,
      clientName: REACT_APP_CLIENT_NAME,
      signedIn: true,
      startDatetime: new Date().getTime(),
      userId: response.username,
      username,
      websocketPath: REACT_APP_PATH_WEB_SOCKET
    }
    console.log(data)
    removeCookie('redirect_path')

    return batch(() => {
      dispatch(onInitializeAWS(response.username))
      dispatch(onLogInSuccess({ ...data }))
      onSuccessCallback()
    })
  } catch (error) {
    console.log(error)
    let code
    let reason
    switch (error?.code) {
      case 'UserNotFoundException':
      case 'NotAuthorizedException':
        code = 400
        reason = 'INCORRECT_USERNAME_OR_PASSWORD_ERROR'
        break
      default:
        code = 500
        reason = 'SOMETHING_WENT_WRONG_ERROR'
        break
    }

    return dispatch(onLogInError({ error: { code, reason } }))
  }
}

export const CLOSE_CHANGEPASSWORD_MODAL = 'CLOSE_CHANGEPASSWORD_MODAL'
export const onCloseChangePasswordModal = makeActionCreator(CLOSE_CHANGEPASSWORD_MODAL)

export const onChangePasswordThunk = ({
  username,
  password,
  newPassword,
  onSuccessCallback = () => undefined
}) => async dispatch => {
  dispatch(onLogIn())
  try {
    const response = await signIn({
      actionType: LOG_IN,
      username,
      password
    })
    let loggedInUser

    if (response.challengeName === 'NEW_PASSWORD_REQUIRED') {
      loggedInUser = await Auth.completeNewPassword(response, newPassword)
    } else {
      // user already verified
      console.log('user already verified, login in')
      loggedInUser = response
    }

    const data = {
      apis: {
        [REACT_APP_BASE_NAME]: {
          apiName: REACT_APP_BASE_NAME,
          subPath: REACT_APP_BASE_SUB_PATH,
          apiKey: REACT_APP_BASE_KEY
        },
        [REACT_APP_REINFORCEMENT_NAME]: {
          apiName: REACT_APP_REINFORCEMENT_NAME,
          subPath: REACT_APP_REINFORCEMENT_SUB_PATH,
          apiKey: REACT_APP_REINFORCEMENT_KEY
        }
      },
      baseUrl: REACT_APP_API_BASE_URL,
      baseUrlV2: REACT_APP_API_BASE_URL_V2,
      clientName: REACT_APP_CLIENT_NAME,
      signedIn: true,
      startDatetime: new Date().getTime(),
      userId: loggedInUser.username,
      username,
      websocketPath: REACT_APP_PATH_WEB_SOCKET
    }
    console.log(data)
    removeCookie('redirect_path')

    return batch(() => {
      dispatch(onInitializeAWS(loggedInUser.username))
      dispatch(onLogInSuccess({ ...data }))
      onSuccessCallback()
    })
  } catch (error) {
    let code
    let reason
    switch (error?.code) {
      case 'UserNotFoundException':
      case 'NotAuthorizedException':
        code = 400
        reason = 'INCORRECT_USERNAME_OR_PASSWORD_ERROR'
        break
      case 'InvalidPasswordException':
        code = 400
        reason = 'PASSWORD_DOES_NOT_CONFORM_TO_POLICY'
        break
      default:
        code = 500
        reason = 'SOMETHING_WENT_WRONG_ERROR'
        break
    }

    return dispatch(onLogInError({ error: { code, reason } }))
  }
}

export const SILENT_LOG_IN = 'SILENT_LOG_IN'
export const SILENT_LOG_IN_ERROR = 'SILENT_LOG_IN_ERROR'
export const SILENT_LOG_IN_SUCCESS = 'SILENT_LOG_IN_SUCCESS'
export const onSilentLogIn = makeActionCreator(SILENT_LOG_IN)
export const onSilentLogInError = makeActionCreator(SILENT_LOG_IN_ERROR, 'payload')
export const onSilentLogInSuccess = makeActionCreator(SILENT_LOG_IN_SUCCESS, 'payload')
export const onSilentLogInThunk = ({ userId, username }) => async dispatch => {
  dispatch(onSilentLogIn())

  try {
    const data = {
      apis: {
        [REACT_APP_BASE_NAME]: {
          apiName: REACT_APP_BASE_NAME,
          subPath: REACT_APP_BASE_SUB_PATH,
          apiKey: REACT_APP_BASE_KEY
        },
        [REACT_APP_REINFORCEMENT_NAME]: {
          apiName: REACT_APP_REINFORCEMENT_NAME,
          subPath: REACT_APP_REINFORCEMENT_SUB_PATH,
          apiKey: REACT_APP_REINFORCEMENT_KEY
        }
      },
      baseUrl: REACT_APP_API_BASE_URL,
      baseUrlV2: REACT_APP_API_BASE_URL_V2,
      clientName: REACT_APP_CLIENT_NAME,
      signedIn: true,
      startDatetime: new Date().getTime(),
      userId,
      username,
      websocketPath: REACT_APP_PATH_WEB_SOCKET
    }
    if (username) {
      const now = new Date().getTime()
      Analytics.record({
        name: 'Login',
        attributes: { user: username, time: now }
      })
    }

    return batch(() => {
      dispatch(onInitializeAWS(username))
      dispatch(onSilentLogInSuccess({ ...data }))
    })
  } catch (error) {
    return dispatch(onSilentLogInError({ error: { code: 500, reason: 'SILENT_LOG_IN_ERROR' } }))
  }
}

export const LOG_OUT = 'LOG_OUT'
export const LOG_OUT_ERROR = 'LOG_OUT_ERROR'
export const LOG_OUT_SUCCESS = 'LOG_OUT_SUCCESS'
export const onLogOut = makeActionCreator(LOG_OUT)
export const onLogOutError = makeActionCreator(LOG_OUT_ERROR, 'payload')
export const onLogOutSuccess = makeActionCreator(LOG_OUT_SUCCESS)
export const onLogOutThunk = ({ onCallback = () => undefined }) => async (dispatch, state) => {
  dispatch(onLogOut())

  try {
    const { username } = state().auth

    if (username) {
      const now = new Date().getTime()
      Analytics.record({
        name: 'Logout',
        attributes: { user: username, time: now }
      })
    }

    await signOut({ actionType: LOG_OUT })
    localStorage.clear()

    return batch(() => {
      dispatch(onLogOutSuccess())
      onCallback()
    })
  } catch (error) {
    console.log(error)
    localStorage.clear()
    return batch(() => {
      dispatch(onLogOutError({ error }))
      onCallback()
    })
  }
}

export const SIGN_UP = 'SIGN_UP'
export const SIGN_UP_ERROR = 'SIGN_UP_ERROR'
export const SIGN_UP_SUCCESS = 'SIGN_UP_SUCCESS'
export const onSignUp = makeActionCreator(SIGN_UP)
export const onSignUpError = makeActionCreator(SIGN_UP_ERROR, 'payload')
export const onSignUpSuccess = makeActionCreator(SIGN_UP_SUCCESS)
export const onSignUpThunk = payload => async dispatch => {
  dispatch(onSignUp())

  try {
    await signUp({
      actionType: SIGN_UP,
      username: payload.username,
      password: payload.password
    })

    return dispatch(onSignUpSuccess())
  } catch (error) {
    return dispatch(onSignUpError({ error }))
  }
}

export const FORGOT_PASSWORD_MODAL = 'FORGOT_PASSWORD_MODAL'
export const onForgotPasswordModal = makeActionCreator(FORGOT_PASSWORD_MODAL)

export const FORGOT_PASSWORD = 'FORGOT_PASSWORD'
export const FORGOT_PASSWORD_ERROR = 'FORGOT_PASSWORD_ERROR'
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS'
export const onForgotPassword = makeActionCreator(FORGOT_PASSWORD)
export const onForgotPasswordError = makeActionCreator(FORGOT_PASSWORD_ERROR, 'payload')
export const onForgotPasswordSuccess = makeActionCreator(FORGOT_PASSWORD_SUCCESS, 'payload')
export const onForgotPasswordThunk = username => async dispatch => {
  dispatch(onForgotPassword())

  try {
    const data = await forgotPassword(username)
    let message
    if (data) {
      message = 'Se ha enviado a su correo su clave de reinicio de contraseña'
    } else {
      message = 'Hubo un problema'
    }
    return dispatch(onForgotPasswordSuccess({ message: message }))
  } catch (error) {
    let code
    let reason
    switch (error?.code) {
      case 'UserNotFoundException':
      case 'NotAuthorizedException':
        code = 400
        reason = 'INCORRECT_USERNAME_OR_PASSWORD_ERROR'
        break
      case 'InvalidPasswordException':
        code = 400
        reason = 'PASSWORD_DOES_NOT_CONFORM_TO_POLICY'
        break
      case 'LimitExceededException':
        code = 400
        reason = 'LIMIT_EXCEEDED_EXCEPTION'
        break
      default:
        code = 500
        reason = 'SOMETHING_WENT_WRONG_ERROR'
        break
    }

    if (error.message === 'Username cannot be empty') {
      code = 500
      reason = 'USERNAME_CANNOT_BE_EMPTY'
    }

    return dispatch(onForgotPasswordError({ error: { code, reason } }))
  }
}

export const onRecoverPasswordThunk = ({
  username,
  code,
  newPassword,
  onSuccessCallback = () => undefined
}) => async dispatch => {
  dispatch(onForgotPassword())

  try {
    const data = await forgotPasswordSubmit(username, code, newPassword)
    console.log(data)
    const message = 'Contraseña cambiada, redirigiendo a inicio de sesión'
    return batch(() => {
      dispatch(onForgotPasswordSuccess({ message: message }))
      onSuccessCallback()
    })
  } catch (error) {
    console.log(error)
    let code
    let reason
    switch (error?.code) {
      case 'UserNotFoundException':
      case 'NotAuthorizedException':
        code = 400
        reason = 'INCORRECT_USERNAME_OR_PASSWORD_ERROR'
        break
      case 'InvalidPasswordException':
        code = 400
        reason = 'PASSWORD_DOES_NOT_CONFORM_TO_POLICY'
        break
      case 'LimitExceededException':
        code = 400
        reason = 'LIMIT_EXCEEDED_EXCEPTION'
        break
      case 'ExpiredCodeException':
        code = 400
        reason = 'EXPIRED_CODE'
        break
      case 'CodeMismatchException':
        code = 400
        reason = 'CODE_MISMATCH'
        break
      default:
        code = 500
        reason = 'SOMETHING_WENT_WRONG_ERROR'
        break
    }

    if (error.message === 'Username cannot be empty') {
      code = 500
      reason = 'USERNAME_CANNOT_BE_EMPTY'
    }

    return dispatch(onForgotPasswordError({ error: { code, reason } }))
  }
}

export const TOGGLE_LOGOUT_MODAL_SUCCESS = 'TOGGLE_LOGOUT_MODAL_SUCCESS'
export const onToggleLogoutModalSuccess = makeActionCreator(TOGGLE_LOGOUT_MODAL_SUCCESS)

export const onInitializeAWS = username => () => {
  const hasReinforcementAPI =
    REACT_APP_REINFORCEMENT_KEY && REACT_APP_REINFORCEMENT_NAME && REACT_APP_REINFORCEMENT_SUB_PATH

  const manualAWSconfig = {
    Auth: {
      userPoolId: REACT_APP_USER_POOL_ID,
      region: 'us-east-1',
      userPoolWebClientId: REACT_APP_USER_POOL_CLIENT_ID
    },
    Storage: {
      AWSS3: {
        bucket: 'cosmos-antucoya-amplify-develop',
        region: 'us-east-1'
      }
    },
    API: {
      endpoints: [
        {
          name: REACT_APP_BASE_NAME,
          endpoint: `${REACT_APP_API_BASE_URL}${REACT_APP_BASE_SUB_PATH}`,
          headers: {
            'Access-Control-Allow-Headers':
              'x-api-key,Content-Type,X-Amz-Date,X-Amz-Security-Token,Authorization,X-Api-Key,X-Requested-With,Accept,Access-Control-Allow-Methods,Access-Control-Allow-Origin,Access-Control-Allow-Headers',
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Methods': 'DELETE,GET,HEAD,OPTIONS,PATCH,POST,PUT',
            'X-Requested-With': '*'
          },
          region: REACT_APP_REGION,
          custom_header: async () => {
            return { Authorization: `Bearer ${(await Auth.currentSession())?.getIdToken()?.getJwtToken()}` }
          }
        },
        hasReinforcementAPI && {
          name: REACT_APP_REINFORCEMENT_NAME,
          endpoint: `${REACT_APP_API_BASE_URL}${REACT_APP_REINFORCEMENT_SUB_PATH}`,
          headers: {
            'Access-Control-Allow-Headers':
              'x-api-key,Content-Type,X-Amz-Date,X-Amz-Security-Token,Authorization,X-Api-Key,X-Requested-With,Accept,Access-Control-Allow-Methods,Access-Control-Allow-Origin,Access-Control-Allow-Headers',
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Methods': 'DELETE,GET,HEAD,OPTIONS,PATCH,POST,PUT',
            'X-Requested-With': '*'
          },
          region: REACT_APP_REGION,
          custom_header: async () => {
            return { Authorization: `Bearer ${(await Auth.currentSession())?.getIdToken()?.getJwtToken()}` }
          }
        },
        {
          name: `${REACT_APP_BASE_NAME}_V2`,
          endpoint: `${REACT_APP_API_BASE_URL_V2}${REACT_APP_BASE_SUB_PATH}`,
          headers: {
            'Access-Control-Allow-Headers':
              'x-api-key,Content-Type,X-Amz-Date,X-Amz-Security-Token,Authorization,X-Api-Key,X-Requested-With,Accept,Access-Control-Allow-Methods,Access-Control-Allow-Origin,Access-Control-Allow-Headers',
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Methods': 'DELETE,GET,HEAD,OPTIONS,PATCH,POST,PUT',
            'X-Requested-With': '*'
          },
          region: REACT_APP_REGION,
          custom_header: async () => {
            return { Authorization: `Bearer ${(await Auth.currentSession())?.getIdToken()?.getJwtToken()}` }
          }
        }
      ].filter(Boolean)
    },
    AWSPinpoint: {
      appId: REACT_APP_PINPOINT_ID,
      region: 'us-east-1',
      mandatorySignIn: false
    }
  }
  Analytics.autoTrack('session', {
    enable: false
  })

  Amplify.configure(manualAWSconfig)
}
