import React from 'react'
import routes from '../config/settings/routes'
import { TIRE_TYPE } from '../config/settings/constant'
import ManagementSectionContainer from '../containers/ManagementMenu/ManagementMenu.container'
import TireVelocityContainer from '../containers/TiresVelocity/TiresVelocityContent.container'
import NavbarContainer from '../containers/Navbar/Navbar.container'
import TiresTopbar from '../containers/TiresTopbar/TiresTopbar.container'
import TiresContainer from '../containers/Tires/TiresContent.container'
import BrandLayout from '../components/Layouts/BrandLayout'
import Heading from '../components/Layouts/Heading'
import ProtectByFeatures from '../components/Security/ProtectByFeatures'
import ProtectBySession from '../components/Security/ProtectBySession'
import NotFoundPage from './NotFound'
import { usePathParam } from '../modules/hooks/route'

/**
 * The tire's page.
 */
const Tires = () => {
  const title = 'Neumáticos'
  const type = usePathParam('type').toUpperCase()

  const tiresPerOption = features => features['com.cosmos/tires_table'] || features['com.cosmos/tires_velocity']

  return (
    <ProtectBySession rule={(hasSession, signedIn) => !!(hasSession && signedIn)}>
      <ProtectByFeatures rule={tiresPerOption} fallback={() => <NotFoundPage />}>
        <BrandLayout
          navbar={<NavbarContainer />}
          header={<Heading title={title} topbar={TiresTopbar} />}
          sidebar={<ManagementSectionContainer current={routes.tireOperaciones} />}
          fullSection
        >
          {TIRE_TYPE[type] === 'TABLA' && <TiresContainer />}
          {TIRE_TYPE[type] === 'VELOCIDAD' && <TireVelocityContainer />}
        </BrandLayout>
      </ProtectByFeatures>
    </ProtectBySession>
  )
}

export default Tires
