import React from 'react'
import routes from '../config/settings/routes'
import ManagementSectionContainer from '../containers/ManagementMenu/ManagementMenu.container'
import NavbarContainer from '../containers/Navbar/Navbar.container'
import BrandLayout from '../components/Layouts/BrandLayout'
import Heading from '../components/Layouts/Heading'
import ProtectByFeatures from '../components/Security/ProtectByFeatures'
import ProtectBySession from '../components/Security/ProtectBySession'
import NotFoundPage from './NotFound'
import DiningContent from '../containers/DiningDriver/DiningDriver.container'
import DiningTopbar from '../containers/DiningDriver/DiningDriverTopBar.container'
import DiningContentHistoric from '../containers/DiningDriver/DiningDriverHistoric.container'
import Tab from '../components/Navigation/Tab'
import Tabs from '../components/Navigation/Tabs'
import { useTabs } from '../components/Navigation/Tab.hooks'
import { Wrapper, Root } from './NewAnomaly.styles'

/**
 * The Predictions' page.
 */
const DiningPage = () => {
  const title = 'Casino'
  const { value: optionSelected, onChange: handleChangeTab } = useTabs({ initialValue: 'historic' })

  const renderTopbar = () => {
    return (
      <>
        <Root>
          <Wrapper>
            <Tabs
              aria-label="Example tabs"
              scrollButtons="on"
              value={optionSelected}
              variant="scrollable"
              onChange={handleChangeTab}
            >
              <Tab label="Histórico" value="historic" />
            </Tabs>
          </Wrapper>
          <Wrapper>
            <DiningTopbar currentTab={optionSelected} />
          </Wrapper>
        </Root>
      </>
    )
  }
  return (
    <ProtectBySession rule={(hasSession, signedIn) => !!(hasSession && signedIn)}>
      <ProtectByFeatures rule={features => features['com.cosmos/dining']} fallback={() => <NotFoundPage />}>
        <BrandLayout
          navbar={<NavbarContainer />}
          header={<Heading title={title} topbar={renderTopbar} />}
          sidebar={<ManagementSectionContainer current={routes.dining} />}
        >
          {optionSelected === 'real_time' && <DiningContent />}
          {optionSelected === 'historic' && <DiningContentHistoric />}
        </BrandLayout>
      </ProtectByFeatures>
    </ProtectBySession>
  )
}

export default DiningPage
