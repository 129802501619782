import React from 'react'
import styled from 'styled-components'
import { Paper } from '@material-ui/core'
import { Cancel, CheckCircle, Info, Help, RemoveCircle } from '@material-ui/icons'

export const Root = styled(({ children, ...props }) => <Paper {...props}>{children}</Paper>)`
  &.MuiPaper-root {
    align-items: center;
    background-color: ${({ color, theme }) => theme.palette.brand[color][65]};
    border-radius: 50px;
    border: 2px solid ${({ color, theme }) => theme.palette.brand[color][65]};
    box-sizing: border-box;
    color: ${props => props.theme.palette.brand.white};
    display: flex;
    height: 32px;
    letter-spacing: 1.12px;
    margin: ${props => props.margin};
    padding: 8px 15px;

    .MuiSvgIcon-root {
      color: inherit;
      height: 18px;
      padding-right: 5px;
      width: auto;
    }

    ${props => props.theme.breakpoints.down('sm')} {
      height: 27px;
      padding: 8px 11px;
    }
  }
`

export const Span = styled(({ children, textWeight, ...props }) => <span {...props}>{children}</span>)`
  font-size: 18px;
  font-weight: ${({ textWeight, theme }) => {
    switch (textWeight) {
      case 'black':
        return theme.fontWeights[900]
      case 'bold':
        return theme.fontWeights[700]
      case 'regular':
        return theme.fontWeights[400]
      case 'light':
        return theme.fontWeights[300]
      default:
        return theme.fontWeights[400]
    }
  }};
  line-height: 18px;
  text-align: center;
  text-transform: uppercase;

  ${props => props.theme.breakpoints.down('sm')} {
    font-size: 14px;
    line-height: 16px;
  }
`

export const SuccessIcon = styled(CheckCircle)``

export const ErrorIcon = styled(Cancel)``

export const InfoIcon = styled(Info)``

export const WarningIcon = styled(Help)``

export const DefaultIcon = styled(RemoveCircle)``
