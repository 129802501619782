import {
  GET_ORPAK_DISPATCH_GRAPH,
  GET_ORPAK_DISPATCH_GRAPH_ERROR,
  GET_ORPAK_DISPATCH_GRAPH_SUCCESS
} from './FuelManagement.actions'

const FuelManagementState = {
  loading: false,
  error: false,
  orpak: [],
  thisDispatch: []
}

const fuelManagementReducer = (state = FuelManagementState, { payload, type }) => {
  switch (type) {
    case GET_ORPAK_DISPATCH_GRAPH: {
      return { ...state, loading: true, error: false }
    }
    case GET_ORPAK_DISPATCH_GRAPH_ERROR: {
      return { ...state, loading: false, error: payload.error }
    }
    case GET_ORPAK_DISPATCH_GRAPH_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        orpak: payload.orpakSerie,
        thisDispatch: payload.dispatchSerie
      }
    }
    default: {
      return state
    }
  }
}

export default fuelManagementReducer
