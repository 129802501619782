import React from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { CONSUMPTION_OPERATOR_MODAL_TITLE } from '../../config/settings/constant'
import { usePathParam } from '../../modules/hooks/route'
import { useViewportSize } from '../../modules/hooks/viewport'
import ReinforcementContentContainer from './ReinforcementContent.container'
import ReinforcementFooterContainer from './ReinforcementFooter.container'
import ReinforcementHeaderContainer from './ReinforcementHeader.container'
import Modal from '../../components/Basics/Modal'
import Typography from '../../components/Basics/Typography'
import { Separator } from './ReinforcementModal.styles'

/**
 * The Reinforcement Modal' container.
 */
const ReinforcementModal = props => {
  const { showModal, onToggleModal } = props
  const { sending } = useSelector(state => state.reinforcement)
  const type = usePathParam('type').toUpperCase()
  const { isTiny } = useViewportSize()

  const handleToggleModal = () => {
    if (!sending) {
      onToggleModal()
    }
  }

  return (
    <Modal maxWidth={isTiny ? '90%' : '776px'} open={showModal} onClose={handleToggleModal}>
      <Typography fontSize="20px" fontWeight="bold" variant="h3">
        {`Registrar reforzamiento - ${CONSUMPTION_OPERATOR_MODAL_TITLE[type]}`}
      </Typography>

      <Separator />

      <ReinforcementHeaderContainer />
      <ReinforcementContentContainer />

      <Separator />

      <ReinforcementFooterContainer onToggleModal={handleToggleModal} />
    </Modal>
  )
}

ReinforcementModal.defaultProps = {
  showModal: false,
  onToggleModal: () => undefined
}
ReinforcementModal.propTypes = {
  showModal: PropTypes.bool,
  onToggleModal: PropTypes.func
}

export default ReinforcementModal
