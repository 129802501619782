import { GET_GRAPH_DATA, GET_GRAPH_DATA_ERROR, GET_GRAPH_DATA_SUCCESS } from './BoxPlotGraph.actions'

const fuelLevelGraphsState = {
  loading: false,
  error: false,
  orpak: [],
  fuelRate: [],
  outliers: [],
  turnFuelLevelA: [],
  turnFuelLevelB: [],
  orpakGraphData: {},
  nivelEstanqueTablaA: [],
  nivelEstanqueTablaB: []
}

/**
 * The fuel level' graphs.
 */
const fuelLevelGraphReducer = (state = fuelLevelGraphsState, { payload, type }) => {
  switch (type) {
    case GET_GRAPH_DATA: {
      return { ...state, loading: true, error: false }
    }
    case GET_GRAPH_DATA_ERROR: {
      return { ...state, loading: false, error: true }
    }
    case GET_GRAPH_DATA_SUCCESS: {
      return {
        ...state,
        loading: false,
        orpak: payload.orpak,
        fuelRate: payload.fuelRate,
        outliers: payload.outliers,
        orpakGraphData: payload.orpakGraphData,
        turnFuelLevelA: payload.turnFuelLevelA,
        turnFuelLevelB: payload.turnFuelLevelB,
        nivelEstanqueTablaA: [payload.nivelEstanqueTabla[0]],
        nivelEstanqueTablaB: [payload.nivelEstanqueTabla[1]]
      }
    }
    default: {
      return state
    }
  }
}

export default fuelLevelGraphReducer
