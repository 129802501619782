import { makeStyles } from '@material-ui/styles'
import colors from './colors'

const useStyles = makeStyles(theme => ({
  disabled: {
    color: colors.hoverBlack
  },
  label: {
    fontSize: '0.8rem',
    color: colors.hoverBlack
  },
  headCell: {
    backgroundColor: colors.hoverPrimary,
    color: colors.white,
    fontWeight: 'bold'
  },
  nameCell: {
    fontSize: '14px'
    // fontWeight: 'bold'
  },
  tooltipEvaluation: {
    padding: 10,
    color: colors.white,
    background: colors.black,
    margin: 3,
    width: 250
  },
  nameZero: {
    color: colors.secondary_gray,
    fontSize: '14px'
  },
  icons: {
    marginRight: '5px'
  },
  nameZeroMobile: {
    fontWeight: 'bold',
    fontSize: '14px'
  },
  titleResumen: {
    fontWeight: '400',
    fontSize: '22px',
    color: colors.black,
    marginBottom: '10px'
  }
}))
export default useStyles
