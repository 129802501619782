// FIXME: Need to be refactored
import React, { useState, useEffect } from 'react'
import {
  REACT_APP_FEATURE_FLAG_FIX_STATIONS,
  REACT_APP_FEATURE_FLAG_MOBILE_STATIONS
} from '../../config/settings/environment'
import routes from '../../config/settings/routes'
import { useTabs } from '../../components/Navigation/Tab.hooks'
import { getDataStation } from '../../services/stationsManagementServices'
import FixStationScreen from './FixStationScreen.container'
import MobileStationScreen from './MobileStationScreen.container'
import ResumenStationScreen from './ResumenStationScreen.container'
import NavbarContainer from '../Navbar/Navbar.container'
import ManagementSectionContainer from '../ManagementMenu/ManagementMenu.container'
import BrandLayout from '../../components/Layouts/BrandLayout'
import Heading from '../../components/Layouts/Heading'
import Tab from '../../components/Navigation/Tab'
import Tabs from '../../components/Navigation/Tabs'
import { Container, Grid } from '@material-ui/core'

/**
 * The StationManagementContent' container.
 */
const StationManagementContent = () => {
  const title = 'Gestión de estaciones de combustible'
  const [dataMobile, setDataMobile] = useState({})
  const [dataFix, setDataFix] = useState({})
  const [loading, setLoading] = useState(true)
  const { value: stationSelected, onChange: handleChangeTab } = useTabs({ initialValue: 'resumen' })

  useEffect(() => {
    getData()
  }, [])

  const getData = async () => {
    if (REACT_APP_FEATURE_FLAG_FIX_STATIONS === 'true') {
      const responseFix = await getDataStation('fija')
      setDataFix(responseFix)
    }
    if (REACT_APP_FEATURE_FLAG_MOBILE_STATIONS === 'true') {
      const responseMobile = await getDataStation('movil')
      setDataMobile(responseMobile)
    }
    setLoading(false)
  }

  const renderTopbar = () => {
    return (
      <Grid container direction="row" justify="space-between" alignItems="flex-end" style={{ marginTop: '-8px' }}>
        <Tabs
          value={stationSelected}
          onChange={handleChangeTab}
          aria-label="Station managment tabs"
          variant="scrollable"
        >
          <Tab label="Resumen Estaciones" value="resumen" />
          {REACT_APP_FEATURE_FLAG_FIX_STATIONS === 'true' && <Tab label="Estaciones fijas" value="fix" />}
          {REACT_APP_FEATURE_FLAG_MOBILE_STATIONS === 'true' && <Tab label="Estaciones móviles" value="mobile" />}
        </Tabs>
        {!loading && (
          <Grid item xs={12} md={5}>
            <p style={{ textAlign: 'right' }}>
              <strong>Periodo observado: </strong> {`${dataMobile.start_date} al ${dataMobile.end_date}`}{' '}
            </p>
          </Grid>
        )}
      </Grid>
    )
  }

  const renderContent = () => {
    return (
      <Container style={{ background: 'white', padding: 10 }}>
        {stationSelected === 'resumen' && (
          <ResumenStationScreen dataFix={dataFix} dataMobile={dataMobile} loading={loading} />
        )}
        {stationSelected === 'mobile' && <MobileStationScreen data={dataMobile} loading={loading} />}
        {stationSelected === 'fix' && <FixStationScreen data={dataFix} loading={loading} />}
      </Container>
    )
  }

  return (
    <BrandLayout
      navbar={<NavbarContainer />}
      header={<Heading title={title} topbar={renderTopbar} />}
      sidebar={<ManagementSectionContainer current={routes.stationManagement} />}
    >
      {renderContent()}
    </BrandLayout>
  )
}

export default StationManagementContent
