import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { addMonths } from 'date-fns'
import PropTypes from 'prop-types'
import { useViewportSize } from '../../modules/hooks/viewport'
import { formatDate } from '../../modules/utils/formatters'
import { onChangeRpmDownloadGroupSelectedThunk, onGetRpmDownloadThunk } from './RpmDownload.actions'
import ConsumptionOperatorAlert from '../ConsumptionOperatorAlert/ConsumptionOperatorAlert.component'
import RpmDownloadTable from './RpmDownload.table'
import Accordion from '../../components/Surfaces/Accordion'
import AccordionSummary from '../../components/Surfaces/AccordionSummary'
import Alert from '../../components/Basics/Alert'
import Skeleton from '../../components/Placeholders/Skeleton'
import Tag from '../../components/Basics/Tag'
import Typography from '../../components/Basics/Typography'
import { AccordionDetails, Arrow, Root, Separator, Strong, SummaryWrapper, Wrapper } from './RpmDownload.styles'

/**
 * The Rpm download' container.
 */
const RpmDownload = props => {
  const dispatch = useDispatch()
  const { onToggleModal } = props
  const { isTiny } = useViewportSize()
  const { consultationDate, error, groups, loading, selectedGroupId } = useSelector(state => state.rpmDownload)
  const [expanded, setExpanded] = useState(selectedGroupId)
  const currentDate = addMonths(new Date(), -1)

  useEffect(() => {
    if (!loading) {
      return
    }

    dispatch(onGetRpmDownloadThunk())
  }, [])

  useEffect(() => {
    setExpanded(selectedGroupId)
  }, [selectedGroupId])

  const handleChange = panel => (_, isExpanded) => {
    setExpanded(isExpanded ? panel : false)
    dispatch(onChangeRpmDownloadGroupSelectedThunk({ groupId: isExpanded ? panel : false }))
  }

  return (
    <Root>
      {consultationDate && formatDate(consultationDate, 'yyyy-MM') !== formatDate(currentDate, 'yyyy-MM') && (
        <ConsumptionOperatorAlert />
      )}

      <Wrapper>
        <Typography margin="0 0 10px" variant="h3">
          <Strong>RPM Descarga -</Strong> Operadores con RPM de descarga superiores al óptimo
        </Typography>
        <Typography fontSize="12px" lineHeight="16px" variant="body2">
          Las RPM óptimas para la maniobra de descarga están basadas en la recomendación del fabricante. La tabla a
          continuación muestra a los operadores que exceden dicha recomendación con mayor frecuencia.
        </Typography>
      </Wrapper>
      <Separator />

      {loading && !error && (
        <Wrapper>
          <Skeleton height="63px" margin="0 0 10px" variant="rect" />
          <Skeleton height="63px" margin="0 0 10px" variant="rect" />
          <Skeleton height="63px" margin="0 0 10px" variant="rect" />
          <Skeleton height="63px" margin="0 0 10px" variant="rect" />
        </Wrapper>
      )}

      {!loading && !error && (
        <Wrapper>
          {groups.map((group, index) => {
            return (
              <Accordion
                key={`accordeon-${index}`}
                expanded={expanded === `group-${group.id}`}
                onChange={handleChange(`group-${group.id}`)}
              >
                <AccordionSummary
                  aria-controls={`group-content-${group.id}`}
                  flexWrap="wrap"
                  id={`group-content-${group.id}`}
                >
                  <Typography fontWeight="bold" variant="h4">
                    {group.name.toUpperCase()}
                  </Typography>

                  <Arrow />

                  {!isTiny && (
                    <>
                      <SummaryWrapper margin="2px 30px 2px 0">
                        <Tag color="primary" margin="0 10px 0 0" text={`${group.rpmRecommendedDownload} RPM`} />
                        <Typography variant="body2">Descarga recomendada</Typography>
                      </SummaryWrapper>

                      <SummaryWrapper margin="2px 30px 2px 0">
                        <Tag color="primary" margin="0 10px 0 0" text={group.p8QuantityHighDownload} />
                        <Typography variant="body2">P80 cantidad descargas elevadas</Typography>
                      </SummaryWrapper>

                      <SummaryWrapper margin="2px 0">
                        <Tag color="primary" margin="0 10px 0 0" text={group.sumLitersOverConsumtion} />
                        <Typography variant="body2">Litros sobreconsumo</Typography>
                      </SummaryWrapper>
                    </>
                  )}
                </AccordionSummary>

                <AccordionDetails>
                  <RpmDownloadTable group={group} groupId={`group-${group.id}`} onToggleModal={onToggleModal} />
                </AccordionDetails>
              </Accordion>
            )
          })}
        </Wrapper>
      )}

      {!loading && error && (
        <Alert severity="default">
          <Strong>Lo sentimos, estamos presentando problemas con nuestro servidor</Strong>
          <br />
          En estos momentos tenemos problemas para disponibilizar la información. Por favor, consulta en unos minutos
          más.
        </Alert>
      )}
    </Root>
  )
}

RpmDownload.defaultProps = {
  onToggleModal: () => undefined
}
RpmDownload.propTypes = {
  onToggleModal: PropTypes.func
}

export default RpmDownload
