// FIXME: Need to be refactored
import React from 'react'
import PropTypes from 'prop-types'
import { Grid, Paper, Typography } from '@material-ui/core'
import stationTime from '../../constants/icons/stationTime.svg'
import fuel from '../../constants/icons/fuel.svg'
import gasFill from '../../constants/icons/gasFill.svg'
import engineFill from '../../constants/icons/engineFill.svg'
import { CurrentShiftItem } from '../../components/CurrentShiftItem'
import useStyles from '../../styles/stationStyle'
import { RowResumenComponent } from './RowResumenComponent'

/**
 * The ResumenMobileStations' container.
 */
const ResumenMobileStations = ({ total, chartsData }) => {
  const classes = useStyles()

  const tooltipAll = (
    <Typography style={{ fontSize: 12, fontWeight: 'bold' }}>
      Todos los abastecimientos de <strong>CAEX y otras maquinarias</strong> realizados en{' '}
      <strong>estaciones móviles</strong>, de los <strong>4 grupos</strong>, durante el periodo observado.
    </Typography>
  )

  const tooltipCAEX = (
    <Typography style={{ fontSize: 12, fontWeight: 'bold' }}>
      Abastecimientos de <strong>CAEX</strong> en <strong>estaciones fijas</strong>, durante el periodo observado.
    </Typography>
  )

  const tooltipOther = (
    <Typography style={{ fontSize: 12, fontWeight: 'bold' }}>
      Abastecimientos de <strong>otras maquinarias</strong> en <strong>estaciones fijas</strong>, durante el periodo
      observado.
    </Typography>
  )

  const tooltipTime = (
    <Typography style={{ fontSize: 12, fontWeight: 'bold' }}>
      <strong>Tiempo promedio entre abastecimientos</strong> de estaciones móviles durante el periodo observado.
    </Typography>
  )

  const resumenContentCard = () => {
    return (
      <Paper elevation={0} variant="outlined">
        <Grid container direction="column" justify="center" alignItems="stretch" style={{ padding: '10px' }}>
          <Grid item xs={12} style={{ textAlign: 'center' }}>
            <RowResumenComponent
              title="Número de abastecimientos totales en estaciones móviles (CAEX y otros equipos)"
              tooltipContent={tooltipAll}
              icon={fuel}
              measurement={Intl.NumberFormat('de-DE').format(total.total_charges)}
            />
          </Grid>
          <Grid item xs={12} style={{ textAlign: 'center' }}>
            <RowResumenComponent
              title="Número de abastecimientos de CAEX en estaciones móviles"
              tooltipContent={tooltipCAEX}
              icon={gasFill}
              measurement={Intl.NumberFormat('de-DE').format(total.truck_total_charges)}
            />
          </Grid>
          <Grid item xs={12} style={{ textAlign: 'center' }}>
            <RowResumenComponent
              title="Número de abastecimientos de otros equipos en estaciones móviles"
              tooltipContent={tooltipOther}
              icon={engineFill}
              measurement={Intl.NumberFormat('de-DE').format(total.total_others_charges)}
            />
          </Grid>
          <Grid item xs={12} style={{ textAlign: 'center' }}>
            <RowResumenComponent
              title="Tiempo entre abastecimientos"
              tooltipContent={tooltipTime}
              icon={stationTime}
              measurement={`${Intl.NumberFormat('de-DE').format(parseInt(total.mean_queue_time))} min `}
            />
          </Grid>
        </Grid>
      </Paper>
    )
  }

  const resumenContentCircle = () => {
    return (
      <Paper elevation={0}>
        <Grid container direction="row" justify="center" alignItems="center">
          {chartsData.map(station => (
            <Grid key={station.percent} item xs={4}>
              <CurrentShiftItem type="station" data={station} />
            </Grid>
          ))}
        </Grid>
      </Paper>
    )
  }

  return (
    <div style={{ marginTop: '10px', marginBottom: '10px', marginLeft: '20px', marginRight: '20px' }}>
      <Typography className={classes.titleResumen}>Todos los abastecimientos en estaciones móviles</Typography>
      <hr />
      <Grid container direction="row" justify="space-evenly" alignItems="center" style={{ marginTop: '20px' }}>
        <Grid item xs={4}>
          {resumenContentCard()}
        </Grid>
        <Grid item xs={7}>
          {resumenContentCircle()}
          <Typography style={{ textAlign: 'center', marginTop: '20px', fontSize: '13px', fontStyle: 'italic' }}>
            Comparador de número de cargas CAEX según equipos
          </Typography>
        </Grid>
      </Grid>
    </div>
  )
}

ResumenMobileStations.propTypes = {
  total: PropTypes.object,
  chartsData: PropTypes.array
}

export default ResumenMobileStations
