import React, { forwardRef } from 'react'
import styled, { css } from 'styled-components'
import { hexToRGBA } from '../../modules/utils/color'
import { InputBase } from '@material-ui/core'
import { Close as BaseClose } from '@material-ui/icons'
import { Autocomplete as AutocompleteBase } from '@material-ui/lab'

export const Root = styled.div`
  display: inline-flex;
  flex-direction: column;
  font-family: ${props => props.theme.typography.fontFamily};
  margin: ${props => props.margin};
  padding: ${props => props.padding};
  width: 100%;
`

export const Label = styled.label`
  color: ${props => props.theme.palette.brand.black};
  font-size: 16px;
  font-weight: ${props => props.theme.fontWeights[600]};
  letter-spacing: 1px;
  line-height: 17px;
  margin: 0 0 0.5rem;
`

export const Required = styled(({ ...props }) => <span {...props}>*</span>)`
  color: ${props => props.theme.palette.brand.gray[50]};
  font-size: 1rem;
`

export const BaseAutocomplete = styled(({ className, maxWidth, ...props }) => (
  <AutocompleteBase {...props} classes={{ paper: className }} />
))`
  &.MuiAutocomplete-paper {
    background-color: ${props => props.theme.palette.brand.white};
    border: 1px solid ${props => props.theme.palette.brand.gray[85]};
    border-radius: 3px;
    box-shadow: none;
    display: ${props => (props.options.length > 0 || props.waiting ? 'block' : 'none')};
    margin: 3px 0;

    ${props =>
      props.maxWidth &&
      css`
        max-width: ${props.maxWidth};
        min-width: initial !important;
        width: 100%;
      `}

    & .MuiAutocomplete-listbox {
      max-height: 40vh;
      padding: 4px 0;
    }

    & .MuiAutocomplete-loading {
      color: ${props => props.theme.palette.brand.gray[75]};
      font-family: ${props => props.theme.typography.fontFamily};
      font-size: 14px;
      letter-spacing: 0;
      padding: 4px 16px;
    }

    & .MuiAutocomplete-option {
      color: ${props => props.theme.palette.brand.gray[20]};
      font-family: ${props => props.theme.typography.fontFamily};
      font-size: 15px;
      letter-spacing: 0;
    }
  }
`

export const ClearIcon = styled(BaseClose)`
  color: ${props => props.theme.palette.brand.gray[50]};
  font-size: 22px;
`

// eslint-disable-next-line react/prop-types
export const BaseInput = styled(forwardRef(({ textAlign, ...props }, ref) => <InputBase {...props} ref={ref} />))`
  &.MuiInputBase-root {
    background-clip: padding-box;
    background-color: ${props => props.theme.palette.brand.white};
    border: 1px solid ${props => props.theme.palette.brand.gray[85]};
    border-radius: 0.25rem;
    box-sizing: border-box;
    color: ${props => props.theme.palette.brand.gray[20]};
    display: flex;
    font-size: 15px;
    height: calc(1.5em + 0.75rem + 2px);
    line-height: 1.5;
    overflow: hidden;
    -webkit-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    width: 100%;
  }

  &.MuiInputBase-root input {
    box-sizing: border-box;
    flex-grow: 1;
    height: 100%;
    overflow: hidden;
    padding: 0.375rem 0.75rem;
    text-align: ${props => props.textAlign};
    text-overflow: ellipsis;
    white-space: nowrap;
    width: auto;

    &::placeholder {
      color: ${props => props.theme.palette.brand.gray[50]};
      font-family: ${props => props.theme.typography.fontFamily};
      font-size: 15px;
      line-height: 1.5;
      opacity: 0.42;
      transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    }
  }

  &.MuiInputBase-root.Mui-focused {
    border: 1px solid ${props => props.theme.palette.brand.primary[50]};
    box-shadow: 0 0 0 0.2rem ${props => hexToRGBA(props.theme.palette.brand.primary[50], 0.25)};
  }

  &.MuiInputBase-root.Mui-disabled {
    background-color: rgba(46, 91, 255, 0.05);
    border: 1px solid ${props => props.theme.palette.brand.gray[85]};
    color: ${props => props.theme.palette.brand.gray[50]};
    cursor: not-allowed;
  }

  &.MuiInputBase-root.Mui-disabled input {
    cursor: not-allowed;
  }

  &.MuiInputBase-root.Mui-error {
    border: 1px solid ${props => props.theme.palette.brand.pomegranate};
  }

  &.MuiInputBase-root.Mui-error.Mui-focused {
    box-shadow: 0 0 0 0.2rem ${props => hexToRGBA(props.theme.palette.brand.pomegranate, 0.5)};
  }

  &.MuiInputBase-root .MuiButtonBase-root:hover {
    background-color: transparent;
  }

  &.MuiInputBase-root .MuiTouchRipple-root {
    display: none;
  }

  &.MuiInputBase-root .MuiAutocomplete-endAdornment {
    top: initial;
  }
`

export const BottomMessage = styled.section`
  font-family: ${props => props.theme.typography.fontFamily};
  font-size: 12px;
  letter-spacing: 0;
  line-height: 16px;
  margin: 3px 0 0;
  min-height: 20px;
`

export const Info = styled.section`
  color: ${props => props.theme.palette.brand.gray[50]};
  font-style: italic;
`

export const Error = styled.section`
  color: ${props => props.theme.palette.brand.pomegranate};
`
