import React from 'react'
import { Grid, Message } from './LoadingContent.styles'

/**
 * The Loading Content' component.
 */
const LoadingContent = () => {
  return (
    <Grid container alignItems="center" direction="row" justify="center">
      <Grid
        item
        className="animate__animated animate__flash animate__infinite infinite"
        margin="3rem"
        textAlign="center"
        xs={12}
      >
        <img className="logo" src="/assets/images/cosmos-black-logo.svg" alt="Universal Intelligence" />
        <br />
        <Message>Obteniendo datos...</Message>
      </Grid>
    </Grid>
  )
}

export default LoadingContent
