import styled from 'styled-components'
import { Grid as BaseGrid } from '@material-ui/core'

export const Root = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  width: 100%;
`
export const ModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: inherit;
  justify-content: space-evenly;
  flex: 1;
`
export const Grid = styled(BaseGrid)`
  margin: ${props => props.margin};
  padding: ${props => props.padding};
  width: ${props => props.width};
`
