import { makeActionCreator } from '../../config/store/utils'
import { batch } from 'react-redux'
import { getData, fetchLatestData } from '../../services/operatorDining'
import format from 'date-fns/format'

export const GET_GENERAL_DATA = 'GET_GENERAL_DATA'
export const GET_GENERAL_DATA_ERROR = 'GET_GENERAL_DATA_ERROR'
export const GET_GENERAL_DATA_SUCCESS = 'GET_GENERAL_DATA_SUCCESS'
export const onGetGeneralData = makeActionCreator(GET_GENERAL_DATA)
export const onGetGeneralDataError = makeActionCreator(GET_GENERAL_DATA_ERROR, 'payload')
export const onGetGeneralDataSuccess = makeActionCreator(GET_GENERAL_DATA_SUCCESS, 'payload')

export const onGetGeneralDataThunk = filterDate => async (dispatch, getState) => {
  dispatch(onGetGeneralData())

  try {
    const {
      apis: {
        base: { apiKey, apiName }
      }
    } = getState().auth
    console.log(filterDate)
    const date = format(filterDate, 'yyyy-MM-dd')
    const { data } = await getData({
      apiKey,
      apiName: `${apiName}_V2`,
      filterDate: date
    })
    console.log(data)
    // Filter out drivers with NaN or "nan" names
    const drivers = data.filter(driver => driver.operator && driver.operator.trim().toLowerCase() !== 'nan')

    return batch(() => {
      dispatch(onGetGeneralDataSuccess({ drivers }))
    })
  } catch (error) {
    console.log(error)
    return dispatch(onGetGeneralDataError({ error }))
  }
}

export const FETCH_LATEST_DATA = 'FETCH_LATEST_DATA'
export const FETCH_LATEST_DATA_ERROR = 'FETCH_LATEST_DATA_ERROR'
export const FETCH_LATEST_DATA_SUCCESS = 'FETCH_LATEST_DATA_SUCCESS'
export const onFetchLatestData = makeActionCreator(FETCH_LATEST_DATA)
export const onFetchLatestDataError = makeActionCreator(FETCH_LATEST_DATA_ERROR, 'payload')
export const onFetchLatestDataSuccess = makeActionCreator(FETCH_LATEST_DATA_SUCCESS, 'payload')

export const onFetchLatestDataThunk = () => async (dispatch, getState) => {
  dispatch(onFetchLatestData())

  try {
    const {
      apis: {
        base: { apiKey, apiName }
      }
    } = getState().auth

    const { data } = await fetchLatestData({
      apiKey,
      apiName: `${apiName}_V2`
    })
    // Filter out drivers with NaN or "nan" names
    const drivers = data.data.filter(driver => driver.operator && driver.operator.trim().toLowerCase() !== 'nan')

    return batch(() => {
      dispatch(onFetchLatestDataSuccess({ drivers }))
    })
  } catch (error) {
    console.log(error)
    let code
    let reason

    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      const status = error.response.status
      if (status === 404) {
        code = 404
        reason = 'NOT_FOUND'
      } else if (status === 401) {
        code = 401
        reason = 'UNAUTHORIZED'
      } else {
        code = status
        reason = 'UNKNOWN_ERROR'
      }
    } else if (error.request) {
      // The request was made but no response was received
      code = 'NO_RESPONSE'
      reason = 'The request was made but no response was received'
    } else {
      // Something happened in setting up the request that triggered an Error
      code = error.code
      reason = error.message
    }

    return dispatch(onFetchLatestDataError({ error: { code, reason } }))
  }
}
