import React, { useEffect } from 'react'
import { batch, useSelector, useDispatch } from 'react-redux'
import { Root } from './Tires.styles'
import { onGetTireAnomalySuccessThunk } from './Tires.actions'
import LoadingContent from '../../components/Placeholders/LoadingContent'
import DisconnectedData from '../FuelLevel/DisconnectedData.container'
import TireAnomalyContainer from './TiresAnomalyTable.container'
import TireCaexContainer from './TiresCaexContent.container'
import TireOperatorContainer from './TiresOperatorContent.container'

const TiresContainer = () => {
  const dispatch = useDispatch()
  const { loading, error } = useSelector(state => state.anomalyTires)
  useEffect(() => {
    batch(() => {
      dispatch(onGetTireAnomalySuccessThunk())
    })
  }, [])

  return (
    <>
      {loading && <LoadingContent />}
      {!loading && error && <DisconnectedData />}
      {!loading && !error && (
        <>
          <Root>
            <TireAnomalyContainer />
            <TireCaexContainer />
            <TireOperatorContainer />
          </Root>
        </>
      )}
    </>
  )
}

export default TiresContainer
