import styled from 'styled-components'
import { Box as BaseBox, Grid as BaseGrid } from '@material-ui/core'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'

export const Box = styled(BaseBox)``

export const Grid = styled(BaseGrid)``

export const Separator = styled.hr`
  border: 1px solid;
  color: ${props => props.theme.palette.brand.gray[85]};
  margin: 20px 0px 20px 0px;
`

export const Summary = styled.div`
  align-items: baseline;
  display: flex;
  flex-wrap: wrap;
`

export const SummaryWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  margin: ${props => props.margin};
`

export const Arrow = styled(ArrowForwardIosIcon)`
  height: auto;
  margin: 0 10px 0 15px;
  width: 11.76px;
`

export const ModalRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: left;
`

export const ModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-evenly;
  flex: 1;
`

export const TableLine = styled.hr`
  border: 1px solid;
  color: ${props => props.theme.palette.brand.gray[85]};
  margin: 20px 0px -10px 0px;
`

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 10px 0 0 0;
`

export const ModalBlock = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0px 0px 10px 0px;
`

export const Frame = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  height: 300px;
  align-items: center;
`

export const Tablediv = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  grid-column-gap: 10px;
  align-items: end;
  margin: 0 0 10px;
`
