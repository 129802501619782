import React from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { Footer, Navbar, PageHeading, PageWrapper, Root, Section, SidebarWrapper } from './BrandLayout.styles'
import { useViewportSize } from '../../modules/hooks/viewport'

/**
 * The BrandLayout's component.
 */
const BrandLayout = props => {
  const { children, footer, header, navbar, sidebar, fullSection } = props
  const { isTiny } = useViewportSize()
  const { collapsedManagmentPanel } = useSelector(state => state.managementMenu)

  return (
    <Root>
      {sidebar && (
        <SidebarWrapper isTiny={isTiny} sidebarMini={collapsedManagmentPanel}>
          {sidebar}
        </SidebarWrapper>
      )}
      <PageWrapper sidebarMini={collapsedManagmentPanel} hasSidebar={!isTiny && sidebar !== null}>
        {navbar && <Navbar>{navbar}</Navbar>}
        {header && <PageHeading>{header}</PageHeading>}
        <Section fullSection={fullSection}>{children}</Section>
        {footer && <Footer>{footer}</Footer>}
      </PageWrapper>
    </Root>
  )
}

BrandLayout.defaultProps = {
  footer: null,
  header: null,
  navbar: null,
  sidebar: null,
  fullSection: false
}
BrandLayout.propTypes = {
  children: PropTypes.node.isRequired,
  footer: PropTypes.node,
  header: PropTypes.node,
  navbar: PropTypes.node,
  sidebar: PropTypes.node,
  fullSection: PropTypes.bool
}

export default BrandLayout
