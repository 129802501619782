import {
  ADD_OPERATOR_SUCCESS,
  CLOSE_FEEDBACK_MODAL,
  GET_INSTRUCTORS,
  GET_INSTRUCTORS_ERROR,
  GET_INSTRUCTORS_SUCCESS,
  GET_REINFORCEMENT,
  GET_REINFORCEMENT_ERROR,
  GET_REINFORCEMENT_SUCCESS,
  LOAD_OPERATORS_SUCCESS,
  REMOVE_OPERATOR_SUCCESS,
  SEND_REINFORCEMENT,
  SEND_REINFORCEMENT_ERROR,
  SEND_REINFORCEMENT_SUCCESS,
  SET_INITIAL_STATE,
  UPDATE_FORM_VALUE
} from './Reinforcement.actions'

const reinforcementState = {
  error: undefined,
  groupId: '',
  groupReinforcement: false,
  instructorEmail: '',
  instructorName: '',
  instructors: [],
  loading: false,
  operators: [],
  reinforcementDate: new Date(),
  reinforcementFinished: false,
  reinforcementId: undefined,
  reportPeriod: '',
  selectedOperators: [],
  sending: false,
  showFeedbackModal: false,
  submitError: undefined,
  submitSuccess: false,
  type: ''
}

/**
 * The reinforcement' reducer.
 */
const reinforcementReducer = (state = reinforcementState, { payload, type }) => {
  switch (type) {
    case GET_INSTRUCTORS: {
      return { ...state, loading: true, error: false }
    }
    case GET_REINFORCEMENT: {
      return {
        ...state,
        loading: true,
        error: false,
        groupReinforcement: false,
        instructorEmail: '',
        instructorName: '',
        reinforcementId: payload.reinforcementId,
        reinforcementDate: '',
        reportPeriod: '',
        selectedOperators: []
      }
    }
    case GET_INSTRUCTORS_ERROR:
    case GET_REINFORCEMENT_ERROR: {
      return { ...state, loading: false, error: payload.error }
    }
    case GET_INSTRUCTORS_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        instructors: payload.instructors
      }
    }
    case GET_REINFORCEMENT_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        instructorEmail: payload.instructorEmail,
        instructorName: payload.instructorName,
        reinforcementDate: payload.reinforcementDate,
        reinforcementId: payload.reinforcementId,
        reportPeriod: payload.reportPeriod,
        groupReinforcement: payload.groupReinforcement,
        selectedOperators: payload.selectedOperators
      }
    }
    case SET_INITIAL_STATE: {
      return {
        ...state,
        groupId: payload.groupId,
        groupReinforcement: false,
        instructorEmail: '',
        instructorName: '',
        reinforcementDate: new Date(),
        reinforcementFinished: false,
        reinforcementId: undefined,
        reportPeriod: payload.reportPeriod,
        selectedOperators: [],
        sending: false,
        submitSuccess: false,
        submitError: undefined,
        type: payload.type
      }
    }
    case UPDATE_FORM_VALUE: {
      return {
        ...state,
        [payload.name]: payload.value
      }
    }
    case LOAD_OPERATORS_SUCCESS: {
      return {
        ...state,
        operators: payload.operators,
        selectedOperators: payload.selectedOperators
      }
    }
    case ADD_OPERATOR_SUCCESS:
    case REMOVE_OPERATOR_SUCCESS: {
      return { ...state, selectedOperators: payload.selectedOperators }
    }
    case CLOSE_FEEDBACK_MODAL: {
      return { ...state, showFeedbackModal: false }
    }
    case SEND_REINFORCEMENT: {
      return { ...state, sending: true, submitSuccess: false, submitError: undefined }
    }
    case SEND_REINFORCEMENT_ERROR: {
      return { ...state, sending: false, submitError: payload.error, showFeedbackModal: true }
    }
    case SEND_REINFORCEMENT_SUCCESS: {
      return { ...state, sending: false, submitSuccess: true, showFeedbackModal: true }
    }
    default: {
      return state
    }
  }
}

export default reinforcementReducer
