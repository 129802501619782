import { makeActionCreator } from '../../config/store/utils'
import { customError } from '../../modules/errors/custom'
import { REACT_APP_CLIENT_NAME } from '../../config/settings/environment'
import { getURLPDF } from '../../services/help'

export const SEND_FORM = 'SEND_FORM'
export const SEND_FORM_ERROR = 'SEND_FORM_ERROR'
export const SEND_FORM_SUCCESS = 'SEND_FORM_SUCCESS'
export const onSendForm = makeActionCreator(SEND_FORM)
export const onSendFormError = makeActionCreator(SEND_FORM_ERROR, 'payload')
export const onSendFormSuccess = makeActionCreator(SEND_FORM_SUCCESS, 'payload')
export const onSendFormThunk = () => async (dispatch, getState) => {
  dispatch(onSendForm())

  try {
    const {
      contactForm: { email, message, name }
    } = getState().help
    const body = {
      nombre: name,
      email,
      mensaje: message
    }

    // FIXME: For zaldivar client, we are currently using amsa email sending
    const urls = {
      amsa: 'https://cosmos-amsa-api.weareunit.ai/cosmos-support/email',
      anglo: 'https://cosmos-anglo-api.weareunit.ai/cosmos-support/email'
    }
    const url = urls[REACT_APP_CLIENT_NAME] || 'https://cosmos-amsa-api.weareunit.ai/cosmos-support/email'

    const response = await fetch(url, { method: 'POST', body: JSON.stringify(body) })

    if (response?.status !== 200) {
      throw customError({}, 400, 'SOMETHING_WENT_WRONG_ERROR')
    }

    const data = await Promise.resolve()
      .then(() => response.json())
      .catch(() => {
        throw customError({}, 400, 'SOMETHING_WENT_WRONG_ERROR')
      })

    if (data?.msg !== 'Mensaje enviado exitosamente') {
      throw customError({}, 400, 'SOMETHING_WENT_WRONG_ERROR')
    }

    return dispatch(onSendFormSuccess())
  } catch (error) {
    return dispatch(
      onSendFormError({
        error: customError(error?.originalError || error, error?.code || 400, 'SOMETHING_WENT_WRONG_ERROR').toObject()
      })
    )
  }
}

export const GET_MANUAL = 'GET_MANUAL'
export const GET_MANUAL_ERROR = 'GET_MANUAL_ERROR'
export const GET_MANUAL_SUCCESS = 'GET_MANUAL_SUCCESS'
export const onGetManual = makeActionCreator(GET_MANUAL)
export const onGetManualError = makeActionCreator(GET_MANUAL_ERROR, 'payload')
export const onGetManualSuccess = makeActionCreator(GET_MANUAL_SUCCESS, 'payload')
export const onGetManualThunk = () => async dispatch => {
  dispatch(onGetManual())

  try {
    const { data: urlPDF } = await getURLPDF()

    return dispatch(
      onGetManualSuccess({
        linkUserGuide: urlPDF
      })
    )
  } catch (error) {
    return dispatch(
      onGetManualError({
        error: customError(error?.originalError || error, error?.code || 400, 'SOMETHING_WENT_WRONG_ERROR').toObject()
      })
    )
  }
}

export const CLOSE_ERROR_SUCCESS = 'CLOSE_ERROR_SUCCESS'
export const onCloseErrorSuccess = makeActionCreator(CLOSE_ERROR_SUCCESS)

export const SET_FULLSCREEN = 'SET_FULLSCREEN'
export const onSetFullscreen = makeActionCreator(SET_FULLSCREEN)

export const UPDATE_FORM_VALUE = 'UPDATE_FORM_VALUE'
export const onUpdateFormValue = makeActionCreator(UPDATE_FORM_VALUE, 'payload')
