import React from 'react'
import { Paper, Chip, Popper, Typography } from '@material-ui/core'
import PropTypes from 'prop-types'
// eslint-disable-next-line
import { default as tankerImg } from '../constants/images/tanker.svg'
// eslint-disable-next-line
import { default as tankerOffImg } from '../constants/images/FrameTankerDisabled.svg'

const TankerVehicle = ({ tankerData, color }) => {
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [open, setOpen] = React.useState(false)
  const [placement, setPlacement] = React.useState()

  const onHover = newPlacement => event => {
    setAnchorEl(event.currentTarget)
    setOpen(prev => placement !== newPlacement || !prev)
    setPlacement(newPlacement)
  }

  return (
    <div style={{ paddingLeft: 5 }}>
      <div
        onMouseOver={onHover('bottom')}
        onMouseOut={() => {
          setOpen(false)
        }}
        aria-disabled={!tankerData.active}
      >
        <Chip
          variant={open ? 'default' : 'outlined'}
          style={open ? { color: 'white', backgroundColor: color } : { color: color, borderColor: color }}
          size="small"
          label={tankerData.patent}
          disabled={!tankerData.active}
        />
        <div>
          <img src={!tankerData.active ? tankerImg : tankerOffImg} alt={tankerData.patent} style={{ width: '45px' }} />
        </div>
      </div>
      <Popper open={open} anchorEl={anchorEl} placement={placement} transition>
        <Paper style={{ padding: 10, color: 'white', background: 'black', margin: 3 }}>
          <Typography style={{ fontSize: 12 }}>
            {'Aljibe ' + tankerData.patent}
            <br />
            {Math.round(tankerData.fuel_level) + ' litros en estanque.'}
            <br />
            {'Última carga: ' + tankerData.last_charge_day + ' a las ' + tankerData.last_charge_hour}
          </Typography>
        </Paper>
      </Popper>
    </div>
  )
}

TankerVehicle.propTypes = {
  tankerData: PropTypes.object,
  color: PropTypes.string
}

export default TankerVehicle
