import React from 'react'
import NavbarContainer from '../containers/Navbar/Navbar.container'
import ManagementSectionContainer from '../containers/ManagementMenu/ManagementMenu.container'
import BrandLayout from '../components/Layouts/BrandLayout'
import ProtectBySession from '../components/Security/ProtectBySession'
import Typography from '../components/Basics/Typography'
import { Root } from './NotFound.styles.js'

/**
 * The NotFound' page.
 */
const NotFound = () => {
  return (
    <ProtectBySession rule={(hasSession, signedIn) => !!(hasSession && signedIn)}>
      <BrandLayout navbar={<NavbarContainer />} sidebar={<ManagementSectionContainer current="" />}>
        <Root container alignItems="center" direction="column" justify="center">
          <img src="/assets/images/cosmos-black-logo.svg" alt="Universal Intelligence" />
          <hr />
          <Typography align="center" variant="body1">
            La página solicitada no existe
          </Typography>
        </Root>
      </BrandLayout>
    </ProtectBySession>
  )
}

export default NotFound
