import { CHANGE_FIRST_FILTER, CHANGE_SECOND_FILTER } from './NewAnomalyFilter.actions'

const newAnomalyFilterState = {
  filter1: '1',
  filter2: '1'
}

const newAnomalyFilterReducer = (state = newAnomalyFilterState, { payload, type }) => {
  switch (type) {
    case CHANGE_FIRST_FILTER: {
      return { ...state, filter1: payload.filter1 }
    }
    case CHANGE_SECOND_FILTER: {
      return { ...state, filter2: payload.filter2 }
    }
    default: {
      return state
    }
  }
}

export default newAnomalyFilterReducer
