import { GET_GERENCIA_DATA, GET_GERENCIA_DATA_ERROR, GET_GERENCIA_DATA_SUCCESS } from './Gerencia.actions'

const gerenciaState = {
  loading: true,
  error: false,
  data: {}
}

const gerenciaReducer = (state = gerenciaState, { payload, type }) => {
  switch (type) {
    case GET_GERENCIA_DATA: {
      return { ...state, loading: true, error: false }
    }
    case GET_GERENCIA_DATA_ERROR: {
      return { ...state, loading: false, error: true }
    }
    case GET_GERENCIA_DATA_SUCCESS: {
      return { ...state, loading: false, error: false, data: payload.data }
    }
    default: {
      return state
    }
  }
}

export default gerenciaReducer
