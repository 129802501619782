import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import LoadingContent from '../../components/Placeholders/LoadingContent'
import DisconnectedData from '../FuelLevel/DisconnectedData.container'
import DriverVehicle from './DriverVehicle'
import {
  Typography,
  TextField,
  Grid,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  Box
} from '@material-ui/core'

const DriverDataContent = props => {
  const { title, data } = props
  const { error, loading } = useSelector(state => state.dining)
  const [searchDriver, setSearchDriver] = useState('')
  const [selectedDrivers, setSelectedDrivers] = useState([])

  const rows = [
    {
      id: 1,
      bottom: 61,
      top: Infinity,
      color: '#FF0345',
      topMessage: 'DURACIÓN EXTENDIDA',
      summary: 'MAYOR A 61 MINUTOS (SIN JUSTIFICAR)',
      minValue: 61,
      maxValue: Infinity,
      backgroundColor: 'rgba(173,0,15,0.12)',
      fontWeight: 'normal',
      fontSize: '14px'
    },
    {
      id: 2,
      bottom: 51,
      top: 60,
      color: '#FFB710',
      topMessage: 'FINALIZANDO COLACIÓN',
      summary: 'ENTRE 51 Y 60 MINUTOS',
      minValue: 51,
      maxValue: 60,
      backgroundColor: 'rgba(52,109,255,0.05)',
      fontWeight: 'bold',
      fontSize: '14px'
    },
    {
      id: 3,
      bottom: 31,
      top: 50,
      color: '#37B68A',
      topMessage: 'DURACIÓN NORMAL',
      summary: 'ENTRE 31 Y 50 MINUTOS',
      minValue: 31,
      maxValue: 50,
      backgroundColor: 'rgba(255,255,255,1)',
      fontWeight: 'normal',
      fontSize: '14px'
    },
    {
      id: 4,
      bottom: 0,
      top: 30,
      color: '#0074CE',
      topMessage: 'INGRESANDO A COLACIÓN',
      summary: 'MENOR A 30 MINUTOS',
      minValue: 0,
      maxValue: 30,
      backgroundColor: 'rgba(255,255,255,1)',
      fontWeight: 'normal',
      fontSize: '14px'
    }
  ]

  useEffect(() => {
    // Dispatch fetch action for drivers data if needed
    // dispatch(onFetchDataDriversThunk())
  }, [title])

  useEffect(() => {
    filterDrivers(searchDriver)
  }, [data])

  const filterDrivers = searchValue => {
    if (searchValue === '') {
      setSelectedDrivers(data)
    } else {
      const filteredDrivers = data.filter(driver => driver.operator.toUpperCase().includes(searchValue))
      setSelectedDrivers(filteredDrivers)
    }
  }

  const handleSearchChange = event => {
    const value = event.target.value.toUpperCase()
    setSearchDriver(value)
    filterDrivers(value)
  }

  const renderRow = scale => {
    const scaleDrivers = selectedDrivers.filter(driver => {
      return scale.bottom < driver.duration_minutes && driver.duration_minutes <= scale.top
    })

    return (
      <TableRow
        key={scale.id}
        style={{
          marginLeft: '50px',
          marginRight: '50px',
          background: `${scale.backgroundColor}`
        }}
      >
        <TableCell style={{ width: '20%', paddingLeft: '20px' }}>
          <Typography
            style={{
              color: `${scale.color}`,
              fontWeight: `${scale.fontWeight}`
            }}
          >
            {`${scale.topMessage}`}
          </Typography>
          <Typography style={{ fontSize: '12px', color: '#828282', fontWeight: scale.fontWeight }}>
            {scale.summary}
          </Typography>
        </TableCell>
        <TableCell align="center" style={{ width: '80%', paddingRight: '50px' }}>
          <Grid container direction="row" justify="flex-start" alignItems="center" style={{ flexWrap: 'wrap' }}>
            {scaleDrivers.map((driver, index) => (
              <Grid item key={`${driver.operator}-${index}`}>
                <DriverVehicle driverData={driver} color={scale.color} />
              </Grid>
            ))}
          </Grid>
        </TableCell>
      </TableRow>
    )
  }

  return (
    <>
      {loading && <LoadingContent />}
      {!loading && error && <DisconnectedData />}
      {!loading && !error && (
        <>
          <Box style={{ minWidth: '100%' }}>
            <Grid container direction="row" justify="space-evenly" alignItems="center">
              <Grid item xs={12} md={5}>
                <Typography style={{ fontWeight: '600', fontSize: '20', paddingLeft: '20px' }}>
                  {`Driver Data - ${title}`}
                </Typography>
              </Grid>
              <Grid item xs={12} md={2} style={{ textAlign: 'right' }}>
                <TextField
                  id="outlined-secondary"
                  label={`Search ${title}`}
                  variant="outlined"
                  size="small"
                  onChange={handleSearchChange}
                  value={searchDriver}
                />
              </Grid>
            </Grid>
            <hr />
            <TableContainer style={{ overflowY: 'visible' }}>
              <Table size="small">
                <TableBody>
                  {rows.map(linea => {
                    return renderRow(linea)
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </>
      )}
    </>
  )
}

DriverDataContent.propTypes = {
  title: PropTypes.string,
  data: PropTypes.array.isRequired
}

export default DriverDataContent
