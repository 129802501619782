import React from 'react'
import { useDispatch, useSelector, batch } from 'react-redux'
import { Root, Wrapper, Dates } from './NewAnomalyTopbar.styles'
// import { useTabs } from '../../components/Navigation/Tab.hooks'
import Button from '../../components/Buttons/Button'
import DatePicker from '../../components/Inputs/DatePicker'
import { useDatePicker } from '../../components/Inputs/Inputs.hooks'
import { onFilterByDate } from './NewAnomalyTopbar.actions'
import Typography from '../../components/Basics/Typography'
// import Tab from '../../components/Navigation/Tab'
// import Tabs from '../../components/Navigation/Tabs'

/**
 * The anomaly detection topbar' container.
 */
const NewAnomalyTopbar = () => {
  const dispatch = useDispatch()
  // const { showDashboard } = useSelector(state => state.newAnomalyTopbar)
  const { originalAnomalies, tableFilter } = useSelector(state => state.newAnomaly)
  // const { value: optionSelected, onChange: handleChangeTab } = useTabs({ initialValue: 'dashboard' })

  // const toggleRegistry = () => {
  //   if (!showDashboard) {
  //     dispatch(onToggleNewAnomalyDashboardSuccess())
  //   }
  // }

  // const toggleDashboard = () => {
  //   if (showDashboard) {
  //     dispatch(onToggleNewAnomalyDashboardSuccess())
  //   }
  // }

  const { error: pastDateError, value: pastDateValue, onChange: handlePastDateChange } = useDatePicker({
    initialValue: new Date()
  })

  const { error: dateError, value: dateValue, onChange: handleDateChange } = useDatePicker({
    initialValue: new Date('2021-05-05')
  })

  const onChange = () => {
    if (dateValue && pastDateValue) {
      batch(() => {
        dispatch(onFilterByDate([dateValue, pastDateValue], originalAnomalies, tableFilter))
      })
    }
  }

  return (
    <Root>
      <Wrapper>
        <Typography variant="body2" fontWeight="bold" margin="0 20px">
          Seleccione un periodo:
        </Typography>
        <Dates>
          <DatePicker
            error={dateError}
            value={dateValue}
            variant="inline"
            width="170px"
            onChange={handleDateChange}
            disableFuture
            margin="0 20px 0 0"
          />
          <DatePicker
            error={pastDateError}
            value={pastDateValue}
            width="170px"
            variant="inline"
            onChange={handlePastDateChange}
            disableFuture
            margin="0 20px 0 0"
          />
        </Dates>
        <Button color="primary" onClick={onChange} size="small">
          Go
        </Button>
      </Wrapper>
    </Root>
  )
}

export default NewAnomalyTopbar
