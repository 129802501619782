import React from 'react'
import styled from 'styled-components'
import { TableCell } from '@material-ui/core'

export const Root = styled(({ minWidth, padding, verticalAlign, width, color, ...restProps }) => (
  <TableCell {...restProps} />
))`
  &.MuiTableCell-root {
    min-width: ${props => props.minWidth};
    background-color: ${props => props.color};
    padding: ${props => props.padding || '10px 8px '};
    text-align: ${props => props.align} !important;
    vertical-align: ${props => props.verticalAlign} !important;
    width: ${props => props.width};
  }
`
