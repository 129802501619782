import { API } from 'aws-amplify'
import { REACT_APP_CLIENT_NAME } from '../config/settings/environment'

/**
 * To get general data.
 */
export const getData = async ({ apiKey, apiName, filterDate }) => {
  const paths = {
    amsa: '/get-lunch-historic',
    anglo: '/get-lunch-historic',
    antucoya: '/get-lunch-historic'
  }
  const path = paths[REACT_APP_CLIENT_NAME] || '/get-lunch-historic'

  const newPath = `${path}?date=${filterDate}`
  console.log(newPath)

  const response = await API.get(apiName, newPath, { headers: { 'x-api-key': apiKey }, response: true, body: {} })

  return {
    data: response.data,
    headers: response.headers,
    request: response.config,
    status: response.status
  }
}

/**
 * To get latest data.
 */
export const fetchLatestData = async ({ apiKey, apiName, fileKey, filterDate }) => {
  const paths = {
    antucoya: '/get-latest-data'
  }
  const path = paths[REACT_APP_CLIENT_NAME] || '/get-latest-data'

  const newPath = `${path}`

  const response = await API.get(apiName, newPath, { headers: { 'x-api-key': apiKey }, response: true, body: {} })

  return {
    data: response.data,
    headers: response.headers,
    request: response.config,
    status: response.status
  }
}
