import { API, Storage } from 'aws-amplify'
import { REACT_APP_CLIENT_NAME } from '../config/settings/environment'

/**
 * To get Tires anomaly data.
 */
export const getTiresAnomalyData = async ({ apiKey, apiName }) => {
  const paths = {
    amsa: '/v2/anom-tires',
    anglo: '/v2/anom-tires',
    antucoya: '/v2/anom-tires'
  }
  const path = paths[REACT_APP_CLIENT_NAME] || '/v2/anom-tires'

  const response = await API.get(apiName, path, { headers: { 'x-api-key': apiKey }, response: true, body: {} })

  return {
    data: response.data,
    headers: response.headers,
    request: response.config,
    status: response.status
  }
}

/**
 * To get Tires anomaly images.
 */
export const getTireAnomalyImage = async ({ apiKey, apiName, key }) => {
  const fileKey = 'images/' + key
  const result = await Storage.get(fileKey, { level: 'public' })
  return result
}
