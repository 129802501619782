import { getMonth, getYear } from 'date-fns'
import { batch } from 'react-redux'
import { CONSUMPTION_OPERATOR_TYPE } from '../../config/settings/constant'
import { makeActionCreator } from '../../config/store/utils'
import { formatNumberWithThousandSeparator, formatPercentage } from '../../modules/utils/formatters'
import { roundWithPrecision } from '../../modules/utils/math'
import {
  getMonthlyConsumptionOperator,
  getSummaryConsumptionOperator,
  getCsvDownloadData
} from '../../services/consumptionOperator'

export const GET_IDLE_RPM = 'GET_IDLE_RPM'
export const GET_IDLE_RPM_ERROR = 'GET_IDLE_RPM_ERROR'
export const GET_IDLE_RPM_SUCCESS = 'GET_IDLE_RPM_SUCCESS'
export const onGetIdleRpm = makeActionCreator(GET_IDLE_RPM)
export const onGetIdleRpmError = makeActionCreator(GET_IDLE_RPM_ERROR, 'payload')
export const onGetIdleRpmSuccess = makeActionCreator(GET_IDLE_RPM_SUCCESS, 'payload')
export const onGetIdleRpmThunk = date => async dispatch => {
  dispatch(onGetIdleRpm())

  try {
    let endpointParams = {
      actionType: GET_IDLE_RPM,
      type: CONSUMPTION_OPERATOR_TYPE.RALENTI.toLowerCase()
    }

    if (date) {
      endpointParams = {
        actionType: GET_IDLE_RPM,
        type: CONSUMPTION_OPERATOR_TYPE.RALENTI.toLowerCase(),
        month: date.getMonth() + 1,
        year: date.getFullYear()
      }
    }

    const {
      data: { data, month, year, totalLittersGroup, meanIdleRpm, totalP80 }
    } = await getSummaryConsumptionOperator(endpointParams)

    const totalLitersOperators = formatNumberWithThousandSeparator(Math.trunc(totalLittersGroup || 0))
    const meanIdleRpmTotal = formatPercentage(meanIdleRpm, 1)
    const p80Total = formatPercentage(totalP80, 1)

    const groups = data
      .map(group => {
        return {
          id: group.group_id,
          name: group.group_name,
          p8IdleRpm: formatPercentage(group.p8_idle_rpm || 0, 1),
          sumLitersOverConsumtion: formatNumberWithThousandSeparator(Math.trunc(group.sum_liters_overconsumption || 0))
        }
      })
      .sort((a, b) => a.name.localeCompare(b.name))

    const initialGroups = data.reduce(
      (obj, group) => ({
        ...obj,
        [`group-${group.group_id}`]: {
          data: [],
          error: false,
          loading: true,
          page: 1
        }
      }),
      {}
    )

    return batch(() => {
      dispatch(
        onGetIdleRpmSuccess({
          consultationDate: new Date(`${year}-${String(month).padStart(2, '0')}-01T00:00:00`),
          totalLitersOperators,
          meanIdleRpmTotal,
          p80Total,
          groups,
          ...initialGroups
        })
      )
      dispatch(onChangeIdleRpmGroupSelectedThunk({ groupId: `group-${groups[0]?.id}` }))
    })
  } catch (error) {
    return dispatch(onGetIdleRpmError({ error }))
  }
}

export const CHANGE_IDLE_RPM_GROUP_SELECTED = 'CHANGE_IDLE_RPM_GROUP_SELECTED'
export const CHANGE_IDLE_RPM_GROUP_SELECTED_ERROR = 'CHANGE_IDLE_RPM_GROUP_SELECTED_ERROR'
export const CHANGE_IDLE_RPM_GROUP_SELECTED_SUCCESS = 'CHANGE_IDLE_RPM_GROUP_SELECTED_SUCCESS'
export const onChangeIdleRpmGroupSelected = makeActionCreator(CHANGE_IDLE_RPM_GROUP_SELECTED, 'payload')
export const onChangeIdleRpmGroupSelectedError = makeActionCreator(CHANGE_IDLE_RPM_GROUP_SELECTED_ERROR, 'payload')
export const onChangeIdleRpmGroupSelectedSuccess = makeActionCreator(CHANGE_IDLE_RPM_GROUP_SELECTED_SUCCESS, 'payload')
export const onChangeIdleRpmGroupSelectedThunk = ({ groupId }) => (dispatch, getState) => {
  dispatch(onChangeIdleRpmGroupSelected({ selectedGroupId: groupId }))

  try {
    const {
      [groupId]: { data }
    } = getState().idleRpm

    // Validate if the requested page is in the redux store
    if (data && data.length > 0) {
      return dispatch(onChangeIdleRpmGroupSelectedSuccess({ selectedGroupId: groupId }))
    }

    // Dispatch action to fetch group info
    return dispatch(onGetIdleRpmGroupThunk({ groupId }))
  } catch (error) {
    return dispatch(
      onChangeIdleRpmGroupSelectedError({
        error,
        selectedGroupId: groupId
      })
    )
  }
}

export const GET_IDLE_RPM_GROUP = 'GET_IDLE_RPM_GROUP'
export const GET_IDLE_RPM_GROUP_ERROR = 'GET_IDLE_RPM_GROUP_ERROR'
export const GET_IDLE_RPM_GROUP_SUCCESS = 'GET_IDLE_RPM_GROUP_SUCCESS'
export const onGetIdleRpmGroup = makeActionCreator(GET_IDLE_RPM_GROUP, 'payload')
export const onGetIdleRpmGroupError = makeActionCreator(GET_IDLE_RPM_GROUP_ERROR, 'payload')
export const onGetIdleRpmGroupSuccess = makeActionCreator(GET_IDLE_RPM_GROUP_SUCCESS, 'payload')
export const onGetIdleRpmGroupThunk = ({ groupId }) => async (dispatch, getState) => {
  dispatch(onGetIdleRpmGroup({ selectedGroupId: groupId }))

  try {
    const { consultationDate } = getState().idleRpm

    const {
      data: { data }
    } = await getMonthlyConsumptionOperator({
      actionType: GET_IDLE_RPM_GROUP,
      groupId: groupId.replace('group-', ''),
      month: getMonth(consultationDate) + 1,
      type: CONSUMPTION_OPERATOR_TYPE.RALENTI.toLowerCase(),
      year: getYear(consultationDate)
    })

    const groupData = data.sort((a, b) => b.percentage_operative_hours_idle_rpm - a.percentage_operative_hours_idle_rpm)

    return dispatch(
      onGetIdleRpmGroupSuccess({
        selectedGroupId: groupId,
        data: groupData.map(item => {
          return {
            operatorId: item.operator_id,
            operatorName: item.operator,
            sumOperativeShifts: formatNumberWithThousandSeparator(Math.trunc(item.sum_operative_shifts)),
            sumHoursEngineOn: formatNumberWithThousandSeparator(roundWithPrecision(item.sum_hours_engine_on, 1)),
            sumHoursIdleRpm: formatNumberWithThousandSeparator(roundWithPrecision(item.sum_hours_idle_rpm, 1)),
            percentageOperativeHoursIdleRpm: formatPercentage(item.percentage_operative_hours_idle_rpm, 1),
            impactLitersConsumed: formatNumberWithThousandSeparator(Math.trunc(item.impact_liters_consumed)),
            impactLitersConsumedOperator: formatNumberWithThousandSeparator(
              Math.trunc(item.impact_liters_consumed_operator)
            ),
            hasReinforcement: item.reinforcement_id !== null,
            reinforcementId: item.reinforcement_id === null ? undefined : item.reinforcement_id
          }
        })
      })
    )
  } catch (error) {
    return dispatch(
      onGetIdleRpmGroupError({
        error,
        selectedGroupId: groupId
      })
    )
  }
}

export const GET_IDLE_RPM_REPORT = 'GET_IDLE_RPM_REPORT'
export const GET_IDLE_RPM_REPORT_SUCCESS = 'GET_IDLE_RPM_REPORT_SUCCESS'
export const GET_IDLE_RPM_REPORT_ERROR = 'GET_IDLE_RPM_REPORT_ERROR'
export const onGetIdleRpmReport = makeActionCreator(GET_IDLE_RPM_REPORT)
export const onGetIdleRpmReportSuccess = makeActionCreator(GET_IDLE_RPM_REPORT_SUCCESS, 'payload')
export const onGetIdleRpmReportError = makeActionCreator(GET_IDLE_RPM_REPORT_ERROR, 'payload')
export const onGetIdleRpmReportThunk = () => async dispatch => {
  dispatch(onGetIdleRpmReport())

  try {
    const { data } = await getCsvDownloadData()

    const reportData = [data]

    return batch(() => {
      dispatch(
        onGetIdleRpmReportSuccess({
          reportData
        })
      )
    })
  } catch (error) {
    return dispatch(onGetIdleRpmReportError({ error }))
  }
}

export const CHANGE_IDLE_RPM_GROUP_PAGE = 'CHANGE_IDLE_RPM_GROUP_PAGE'
export const onChangeIdleRpmGroupPage = makeActionCreator(CHANGE_IDLE_RPM_GROUP_PAGE, 'payload')

export const UPDATE_OPERATORS_IDLE_RPM = 'UPDATE_OPERATORS_IDLE_RPM'
export const onUpdateOperatorsIdleRpm = makeActionCreator(UPDATE_OPERATORS_IDLE_RPM, 'payload')
