import React from 'react'
import styled from 'styled-components'

export const Footer = styled.section`
  width: 100%;
`

export const Navbar = styled.section`
  align-items: inherit;
  display: flex;
  padding: 0;
  width: 100%;
`

export const PageHeading = styled.section`
  background-color: ${props => props.theme.palette.brand.gray[60]};
  display: flex;
  padding: 0 22px;
  padding-top: 30px;
  min-height: 114px;
  width: 100%;
`

export const PageWrapper = styled(({ children, hasSidebar, sidebarMini, ...props }) => (
  <div {...props}>{children}</div>
))`
  background-color: ${props => props.theme.palette.background};
  flex-shrink: 1;
  min-height: 100vh;
  position: relative;
  width: ${({ sidebarMini, hasSidebar }) => (hasSidebar ? `calc(100% - ${sidebarMini ? '73px' : '320px'})` : '100%')};
`

export const Root = styled.div`
  box-sizing: border-box;
  display: flex;
  overflow-x: hidden;
  width: 100%;
`

export const Section = styled.section`
  background-color: ${props => props.theme.palette.brand.white};
  margin: ${props => (props.fullSection ? '0px' : '29px 22px 40px')};
  padding: ${props => (props.fullSection ? '0px' : '20px')};
`

export const SidebarWrapper = styled(({ children, isTiny, sidebarMini, ...props }) => <div {...props}>{children}</div>)`
  display: block;
  width: ${props => (props.isTiny ? 'initial' : props.sidebarMini ? '73px' : '320px')};
`
