import styled from 'styled-components'
import {
  AccordionDetails as BaseAccordionDetails,
  IconButton as BaseIconButton,
  TableContainer as BaseTableContainer
} from '@material-ui/core'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'
import ErrorOutline from '@material-ui/icons/ErrorOutline'

export const AccordionDetails = styled(BaseAccordionDetails)`
  &.MuiAccordionDetails-root {
    flex-direction: column;
  }
`

export const TitleInfo = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`

export const Arrow = styled(ArrowForwardIosIcon)`
  height: auto;
  margin: 0 10px 0 15px;
  width: 11.76px;
`

export const FooterWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  margin: ${props => props.margin};

  ${props => props.theme.breakpoints.down('xs')} {
    flex-direction: column;
    text-align: center;

    > .MuiPagination-root {
      margin: auto;
    }
  }
`

export const IconButton = styled(BaseIconButton)`
  background-color: ${props => props.theme.palette.brand.white};
  border-radius: 4px;
  height: 34px;
  width: 64px;

  &:hover {
    background-color: ${props => props.theme.palette.brand.gray[95]};
  }
`

export const Root = styled.div`
  display: flex;
  flex-direction: column;
  padding: 8px 44px 8px 28px;
  width: 100%;

  ${props => props.theme.breakpoints.down('sm')} {
    padding: 0;
  }
`

export const Separator = styled.hr`
  border: 0;
  border-top: 1px solid ${props => props.theme.palette.brand.gray[85]};
  box-sizing: content-box;
  height: 0;
  margin: 8px 0 30px;
  overflow: visible;
`

export const Strong = styled.span`
  font-weight: bold;
`

export const SummaryWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  margin: ${props => props.margin};
`

export const TableContainer = styled(BaseTableContainer)``

export const Wrapper = styled.div`
  margin: ${props => props.margin};
  padding: 0 65px 0 7px;

  ${props => props.theme.breakpoints.down('sm')} {
    padding: 0 0 0 7px;
  }
`

export const WarningIcon = styled(ErrorOutline)`
  color: ${props => props.theme.palette.brand.gray[50]};
  margin-right: 10px;
  position: relative;
  top: 7px;
`
