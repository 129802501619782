import React from 'react'
import PropTypes from 'prop-types'
import { Root } from './Slider.styles'

/**
 * The Slider's component.
 */
const Slider = props => {
  const { disabled, margin, markPosition, marks, max, min, name, padding, step, value, onChange, ...rest } = props

  return (
    <Root
      {...rest}
      color="primary"
      disabled={disabled}
      margin={margin}
      markPosition={markPosition}
      marks={marks}
      max={max}
      min={min}
      name={name}
      orientation="horizontal"
      padding={padding}
      step={step}
      value={value}
      onChange={onChange}
    />
  )
}

Slider.defaultProps = {
  disabled: false,
  markPosition: 'bottom',
  marks: false,
  max: 100,
  min: 0,
  step: 1
}

Slider.propTypes = {
  disabled: PropTypes.bool,
  margin: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  markPosition: PropTypes.oneOf(['bottom', 'top']),
  marks: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.arrayOf(PropTypes.shape({ label: PropTypes.string, value: PropTypes.number }))
  ]),
  max: PropTypes.number,
  min: PropTypes.number,
  name: PropTypes.string,
  padding: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  step: PropTypes.number,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.arrayOf(PropTypes.number)]).isRequired,
  onChange: PropTypes.func
}

export default Slider
