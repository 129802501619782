import {
  HIDE_MANAGEMENT_PANEL_SUCCESS,
  SHOW_MANAGEMENT_PANEL_SUCCESS,
  TOGGLE_MANAGMENT_PANEL_SUCCESS
} from './ManagementMenu.actions'

const managementMenuState = {
  collapsedManagmentPanel: true,
  showManagementPanel: false
}

/**
 * The management menu's reducer.
 */
const managementMenuReducer = (state = managementMenuState, { type }) => {
  switch (type) {
    case HIDE_MANAGEMENT_PANEL_SUCCESS: {
      return { ...state, showManagementPanel: false }
    }
    case SHOW_MANAGEMENT_PANEL_SUCCESS: {
      return { ...state, showManagementPanel: true }
    }
    case TOGGLE_MANAGMENT_PANEL_SUCCESS: {
      return { ...state, collapsedManagmentPanel: !state.collapsedManagmentPanel }
    }
    default: {
      return state
    }
  }
}

export default managementMenuReducer
