import React from 'react'
import { useSelector } from 'react-redux'
import LoadingContent from '../../components/Placeholders/LoadingContent'
import DisconnectedData from '../FuelLevel/DisconnectedData.container'
import { AgChartsReact } from 'ag-charts-react'
import Typography from '../../components/Basics/Typography'

/**
 * The Predictions's container.
 */
const PredictionContent = () => {
  const { predictions, orpak, dispatch, distance, truck, loading, error } = useSelector(state => state.predictionsGraph)

  const options = {
    options: {
      autoSize: true,
      title: {
        text: `Camión: ${truck}`
      },
      series: [
        {
          type: 'scatter',
          data: predictions,
          xKey: 'time',
          yKey: 'prediccion',
          yName: 'Consumo Combustible (Cosmos) (L)',
          stroke: '#03a9f4',
          marker: {
            fill: '#03a9f4',
            stroke: '#0276ab'
          }
        },
        {
          type: 'scatter',
          data: orpak,
          xKey: 'time',
          yKey: 'cantidad',
          yName: 'Registro Abastecimiento (Orpak) (L)',
          stroke: '#03f40b',
          marker: {
            fill: '#03f40b',
            stroke: '#02ab27'
          }
        },
        {
          type: 'scatter',
          data: dispatch,
          xKey: 'time',
          yKey: 'cantidad',
          yName: 'Registro Abastecimiento (Jigsaw) (L)',
          stroke: '#db0b1c',
          marker: {
            fill: '#db0b1c',
            stroke: '#02ab27'
          }
        }
      ],
      axes: [
        {
          type: 'time',
          position: 'bottom'
        },
        {
          type: 'number',
          position: 'left',
          label: {
            format: '#{.1f}'
          }
        }
      ],
      legend: {
        position: 'bottom'
      }
    }
  }

  const optionDistance = {
    options: {
      autoSize: true,
      title: {
        text: 'Distancia'
      },
      data: distance,
      series: [
        {
          type: 'scatter',
          xKey: 'time',
          yKey: 'distancia',
          yName: 'Distancia (m)',
          showInLegend: true
        }
      ],
      axes: [
        {
          type: 'time',
          position: 'bottom'
        },
        {
          type: 'number',
          position: 'left',
          label: {
            format: '#{.1f}'
          }
        }
      ],
      legend: {
        position: 'bottom'
      }
    }
  }

  return (
    <>
      {loading && <LoadingContent />}
      {!loading && error && <DisconnectedData />}
      {!loading && !error && (
        <>
          {predictions.length === 0 ? (
            <Typography align="center" color="primary">
              Por favor seleccione equipo y fecha
            </Typography>
          ) : (
            <>
              <AgChartsReact options={options.options} />
              <AgChartsReact options={optionDistance.options} />
            </>
          )}
        </>
      )}
    </>
  )
}

export default PredictionContent
