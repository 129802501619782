import styled, { createGlobalStyle } from 'styled-components'
import { Close } from '@material-ui/icons'
import { hexToRGBA } from '../../modules/utils/color'

export const Root = styled.div`
  align-items: center;
  background-color: ${props => hexToRGBA(props.theme.palette.brand.black, 0.5)};
  display: ${props => (props.show ? 'flex' : 'none')};
  height: 100vh;
  justify-content: center;
  width: 100%;
`

export const Content = styled.div`
  background-color: ${props => props.theme.palette.brand.white};
  border-color: transparent;
  border-radius: 10px;
  box-shadow: 0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 5px 8px 0px rgb(0 0 0 / 14%), 0px 1px 14px 0px rgb(0 0 0 / 12%);
  border-width: 0;
  margin: 100px auto;
  max-width: ${props => props.maxWidth};
  outline: none;
  padding: ${props => props.padding || '30px'};
  position: ${props => props.position || 'relative'};
  top: 0;
  width: 100%;
`

export const CloseIcon = styled(Close)`
  color: ${props => hexToRGBA(props.theme.palette.brand.black, 0.87)};
  cursor: pointer;
  font-size: 1.5rem;
  position: absolute;
  right: 13px;
  top: 12px;
`

export const GlobalStyles = createGlobalStyle`
  html {
    overflow: ${props => (props.show ? 'hidden' : 'inherit')};
  }
`
