import React, { useState } from 'react'
import { useViewportSize } from '../../modules/hooks/viewport'
import Button from '../../components/Buttons/Button'
import Modal from '../../components/Basics/Modal'
import Tag from '../../components/Basics/Tag'
import Typography from '../../components/Basics/Typography'
import { Event as EventIcon } from '@material-ui/icons'
import { Root, Separator, Wrapper } from './Examples.styles'

/**
 * The BasicsExample's container.
 */
const BasicsExample = () => {
  const { isTiny } = useViewportSize()
  const [show, setShow] = useState(false)
  const togleModal = () => setShow(!show)

  const margin = '5px'

  return (
    <Root>
      <Typography variant="body1">Has click en el boton de abajo para mostrar un hermoso modal :)</Typography>
      <br />
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap' }}>
        <Button color="primary" variant="contained" width="auto" onClick={togleModal}>
          Click Me!
        </Button>
      </div>
      <br />

      <Typography variant="body1">
        TAG con diferentes colores. font-size: 18px, line-height: 18px, text-align: center, text-transform: uppercase,
        letter-spacing: 1.12px, height: 32px, padding: 8px 15px
      </Typography>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <Tag color="primary" margin={margin} text="17,5%" />
        <Tag color="primary" margin={margin} text="primary" />
        <Tag color="default" margin={margin} text="default" />
        <Tag color="success" margin={margin} text="success" />
        <Tag color="error" margin={margin} text="error" />
        <Tag color="warning" margin={margin} text="warning" />
      </div>
      <br />

      <Typography variant="body1">TAG con icono. Tambien se puede cambiar el icono.</Typography>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <Tag color="primary" margin={margin} text="primary" withIcon />
        <Tag color="default" margin={margin} text="default" withIcon />
        <Tag color="success" margin={margin} text="success" withIcon />
        <Tag color="error" margin={margin} text="error" withIcon />
        <Tag color="warning" margin={margin} text="warning" withIcon />
        <Tag color="success" icon={<EventIcon />} margin={margin} text="custom icon" />
      </div>

      <Modal maxWidth={isTiny ? '90%' : '726px'} open={show} onClose={togleModal}>
        <Typography fontSize={isTiny ? '18px' : '20px'} fontWeight="bold" variant="h3">
          Simple modal
        </Typography>

        <Separator />

        <Wrapper container alignItems="center" direction="column" margin="50px 0">
          <Typography fontSize={isTiny ? '22px' : '25px'} lineHeight="33px" variant="h4">
            Soy un modal inofensivo
          </Typography>
          <Typography variant="body2">
            Me puedes cerrar en la X, en el boton cerrar de abajo, o haciendo click fuera de mi
          </Typography>
        </Wrapper>

        <Separator />

        <Wrapper container justify="flex-end">
          <Button onClick={togleModal}>Cerrar</Button>
          <Button color="primary" margin="0 0 0 30px">
            Acción (no hace nada)
          </Button>
        </Wrapper>
      </Modal>
    </Root>
  )
}

export default BasicsExample
