import React from 'react'
import styled from 'styled-components'
import { Tooltip as BaseTooltip } from '@material-ui/core'

export const Root = styled(({ className, maxWidth, ...props }) => (
  <BaseTooltip {...props} classes={{ ...props.classes, popper: className }} />
))`
  &.MuiTooltip-popper .MuiTooltip-tooltip {
    background-color: #000000;
    border-radius: 4px;
    box-shadow: 0 3px 6px #00000029;
    color: ${props => props.theme.palette.brand.white};
    height: ${props => props.height};
    font-family: ${props => props.theme.typography.fontFamily};
    font-size: 12px;
    font-weight: ${props => props.theme.fontWeights[300]};
    letter-spacing: 0.36px;
    max-width: ${props => props.maxWidth};
    padding: 11px 16px;
  }

  &.MuiTooltip-popper .MuiTooltip-tooltip .MuiTooltip-arrow {
    color: ${props => props.theme.palette.brand.gray[20]};
  }
`
