import React from 'react'
import routes from '../config/settings/routes'
import NavbarContainer from '../containers/Navbar/Navbar.container'
import ManagementSectionContainer from '../containers/ManagementMenu/ManagementMenu.container'
import BrandLayout from '../components/Layouts/BrandLayout'
import ProtectBySession from '../components/Security/ProtectBySession'
import Typography from '../components/Basics/Typography'
import { Root, Strong } from './ServiceUnavailable.styles'

/**
 * The ServiceUnavailable' page.
 */
const ServiceUnavailable = () => {
  return (
    <ProtectBySession rule={(hasSession, signedIn) => !!(hasSession && signedIn)}>
      <BrandLayout navbar={<NavbarContainer />} sidebar={<ManagementSectionContainer current={routes.fuelLevel} />}>
        <Root container alignItems="center" direction="column" justify="center">
          <img src="/assets/images/cosmos-black-logo.svg" alt="Universal Intelligence" />
          <hr />
          <Typography align="center" variant="body1">
            Estimados/as la funcionalidad "Nivel de Combutible" esta deshabilitada.
            <br />
            Estamos a la espera de la normalización de los datos de entrada.
            <br />
            <br />
            <br />
            <Strong>
              Las funcionalidades "Reporte Operador", "Gestión de estaciones de combustible" y "Detección de anomalías"
              estan habilitadas.
            </Strong>
          </Typography>
        </Root>
      </BrandLayout>
    </ProtectBySession>
  )
}

export default ServiceUnavailable
