import { useEffect, useState } from 'react'

/**
 * The Tabs' custom hook.
 */
export const useTabs = ({ changeCallback = () => undefined, initialValue = '' } = {}) => {
  const [value, setValue] = useState(initialValue)
  const handleChange = (_, newValue) => {
    if (value !== newValue) {
      setValue(newValue)
      changeCallback(newValue)
    }
  }

  useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  return { value, onChange: handleChange }
}
