import {
  GET_MAPBOX_HISTORIC,
  GET_MAPBOX_HISTORIC_SUCCESS,
  GET_MAPBOX_HISTORIC_ERROR
} from './VisualizadorEspacialHistorico.actions'

const mapboxState = {
  loading: true,
  error: false,
  urlData: []
}

const mapboxHistoricReducer = (state = mapboxState, { payload, type }) => {
  switch (type) {
    case GET_MAPBOX_HISTORIC: {
      return { ...state, loading: true, error: false }
    }
    case GET_MAPBOX_HISTORIC_SUCCESS: {
      return { ...state, loading: false, error: false, urlData: payload.urlData }
    }
    case GET_MAPBOX_HISTORIC_ERROR: {
      return { ...state, loading: false, error: payload.error }
    }
    default: {
      return state
    }
  }
}

export default mapboxHistoricReducer
