import React from 'react'
import styled from 'styled-components'
import { FormControlLabel, Radio, RadioGroup } from '@material-ui/core'
import BaseSkeleton from '../Placeholders/Skeleton'

export const Root = styled.div`
  display: inline-flex;
  flex-direction: column;
  font-family: ${props => props.theme.typography.fontFamily};
  margin: ${props => props.margin};
  padding: ${props => props.padding};
  width: 100%;
`

export const Label = styled.label`
  color: ${props => props.theme.palette.brand.black};
  font-size: 16px;
  font-weight: ${props => props.theme.fontWeights[600]};
  letter-spacing: 1px;
  line-height: 17px;
  margin: 0 0 0.5rem;
`

export const Required = styled(({ ...props }) => <span {...props}>*</span>)`
  color: ${props => props.theme.palette.brand.gray[50]};
  font-size: 1rem;
`

export const Group = styled(RadioGroup)`
  &.MuiFormGroup-root {
    align-items: flex-start;
    display: grid;
    grid-template-columns: ${props =>
      props.direction === 'horizontal' ? 'repeat(auto-fit, minmax(100px, 1fr))' : 'auto'};
    grid-template-rows: auto;
    grid-column-gap: 80px;
    margin: 0 0 0 9px;
  }
`

export const Skeleton = styled(BaseSkeleton)`
  &.MuiSkeleton-root {
    margin: ${props => (props.direction === 'horizontal' ? 0 : '0 0 12px 0')};

    &:last-child {
      margin: 0;
    }
  }
`

export const Item = styled(FormControlLabel)`
  &.MuiFormControlLabel-root {
    cursor: pointer;
    margin: ${props => (props.direction === 'horizontal' ? 0 : '0 0 12px 0')};

    &:last-child {
      margin: 0;
    }
  }

  &.MuiFormControlLabel-root.Mui-disabled {
    cursor: not-allowed;
  }

  &.MuiFormControlLabel-root .MuiFormControlLabel-label {
    color: ${props => props.theme.palette.brand.black};
    font-family: ${props => props.theme.typography.fontFamily};
    font-size: 15px;
    letter-spacing: 0;
    margin: 0 0 0 11px;
    user-select: none;
  }

  &.MuiFormControlLabel-root.Mui-disabled .MuiFormControlLabel-label {
    color: ${props => props.theme.palette.brand.gray[75]};
  }
`

export const BaseRadio = styled(({ active, ...props }) => <Radio {...props} />)`
  &.MuiButtonBase-root {
    color: ${props => props.theme.palette.brand.gray[50]};
    padding: 0;
  }

  &.MuiButtonBase-root .MuiSvgIcon-root {
    font-size: 18px;
  }

  &.MuiButtonBase-root.Mui-checked {
    color: ${props => props.theme.palette.brand.primary[50]};
  }

  &.MuiButtonBase-root.Mui-disabled {
    color: ${props => props.theme.palette.brand.gray[85]};
  }
`

export const Error = styled.section`
  color: ${props => props.theme.palette.brand.pomegranate};
  font-size: 12px;
  letter-spacing: 0;
  line-height: 16px;
  margin: 3px 0 0;
  min-height: 20px;
`
