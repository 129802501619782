import { API } from 'aws-amplify'
import { REACT_APP_BASE_KEY, REACT_APP_BASE_NAME, REACT_APP_CLIENT_NAME } from '../config/settings/environment'
import { getGroupName } from '../utils'

export const getDataStation = type => {
  const paths = {
    amsa: `/tankers-management/report?stationtype=${type}`,
    anglo: `/v2/tankers-management/report?stationtype=${type}`,
    zaldivar: `/v2/tankers-management/report?stationtype=${type}`
  }
  const path = paths[REACT_APP_CLIENT_NAME] || `/v2/tankers-management/report?stationtype=${type}`

  const init = {
    headers: {
      'x-api-key': REACT_APP_BASE_KEY
    },
    response: true
  }

  return API.get(REACT_APP_BASE_NAME, path, init)
    .then(response => {
      const rawTrucks = response.data
      const newGroups = groupToName(rawTrucks.group_tables)
      const newResponse = { ...rawTrucks, group_tables: newGroups }
      return newResponse
    })
    .catch(error => {
      console.error('[API][GET][TRUCKS] ' + JSON.stringify(error))
    })
}

const groupToName = groups => {
  const newGroups = []
  for (const group of groups) {
    const name = getGroupName(group.group)
    newGroups.push({ ...group, name })
  }
  return newGroups
}
