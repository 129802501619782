import { useEffect, useMemo, useState } from 'react'

/**
 * Custom hook to get the current session.
 */
export const useSession = () => {
  const [idToken, setIdToken] = useState(null)
  const [accessToken, setAccessToken] = useState(null)
  const [lastAuthUser, setLastAuthUser] = useState(null)
  const [loading, setLoading] = useState(true)
  const [userData, setUserData] = useState([])

  const hasSession = useMemo(() => Boolean(accessToken) && Boolean(idToken) && Boolean(lastAuthUser), [
    accessToken,
    idToken,
    lastAuthUser
  ])

  const username = useMemo(() => userData?.filter(x => x.Name === 'email')[0]?.Value, [userData])
  const userId = useMemo(() => userData?.filter(x => x.name === 'sub')[0]?.Value, [userData])

  useEffect(() => {
    for (var i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i)
      if (key.startsWith('CognitoIdentityServiceProvider') && key.endsWith('idToken')) {
        setIdToken(localStorage.getItem(key))
      } else if (key.startsWith('CognitoIdentityServiceProvider') && key.endsWith('accessToken')) {
        setAccessToken(localStorage.getItem(key))
      } else if (key.startsWith('CognitoIdentityServiceProvider') && key.endsWith('LastAuthUser')) {
        setLastAuthUser(localStorage.getItem(key))
      } else if (key.startsWith('CognitoIdentityServiceProvider') && key.endsWith('userData')) {
        setUserData(JSON.parse(localStorage.getItem(key)).UserAttributes)
      }
    }
    setLoading(false)
  }, [])

  return { hasSession, loading, userId, username }
}
