// FIXME: Need to be refactored
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import useStyles from '../../styles/stationStyle'
import {
  LinearProgress,
  TableCell,
  Typography,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableContainer
} from '@material-ui/core'

/**
 * The GroupStation' container.
 */
const GroupStation = ({ fixStations }) => {
  const [stationOrdened, setStationOrdened] = useState([])
  const [maxValueProgressLinear, setMaxValueProgressLinear] = useState(0)
  const classes = useStyles()

  useEffect(() => {
    dataOrder()
  }, [])

  const orderDesc = (a, b) => {
    if (a.time_queue_server > b.time_queue_server) {
      return -1
    } else if (a.time_queue_server < b.time_queue_server) {
      return 1
    } else {
      return 0
    }
  }

  const dataOrder = () => {
    const tops = fixStations.table.sort(orderDesc)
    setStationOrdened(tops)
    setMaxValueProgressLinear(tops[0].time_queue_server)
  }

  return (
    <TableContainer style={{ margin: 5, height: 'auto', paddingRight: 10 }}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell className={classes.headCell}>Estación</TableCell>
            <TableCell className={classes.headCell} align="center">
              Tiempo en cola
            </TableCell>
            <TableCell className={classes.headCell} align="center">
              Abastecimientos (Nº)
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {stationOrdened.map(station => {
            return (
              <TableRow key={station.station}>
                <TableCell size="small">
                  <Typography className={station.time_queue_server !== 0 ? classes.nameZeroMobile : classes.nameCell}>
                    {station.station}
                  </Typography>
                </TableCell>
                <TableCell align="center" size="small">
                  <Typography
                    className={station.time_queue_server !== 0 ? classes.nameZeroMobile : classes.nameCell}
                    style={{ textAlign: 'left' }}
                  >
                    {/* <img src={time} alt={'time'} className={classes.icons} /> */}
                    {parseInt(station.time_queue_server)} min
                  </Typography>
                  <LinearProgress
                    variant="determinate"
                    value={((station.time_queue_server - 0) * 100) / (maxValueProgressLinear - 0)}
                  />
                </TableCell>
                <TableCell align="center" size="small">
                  <Typography className={station.time_queue_server !== 0 ? classes.nameZeroMobile : classes.nameCell}>
                    {/* <img src={gas} alt={'gas'} className={classes.icons} /> */}
                    {station.total_fuel_truck_charges_server}
                  </Typography>
                </TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

GroupStation.propTypes = {
  fixStations: PropTypes.object
}

export default GroupStation
