import React, { useState } from 'react'
import { batch, useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'
import { CONSUMPTION_OPERATOR_MODAL_TITLE } from '../../config/settings/constant'
import { usePathParam } from '../../modules/hooks/route'
import { useViewportSize } from '../../modules/hooks/viewport'
import { onCloseFeedbackModal, onSendReinforcementThunk } from '../Reinforcement/Reinforcement.actions'
import Button from '../../components/Buttons/Button'
import Modal from '../../components/Basics/Modal'
import Spinner from '../../components/Basics/Spinner'
import Typography from '../../components/Basics/Typography'
import { Separator, Wrapper } from './ReinforcementFeedbackModal.styles'

/**
 * The Reinforcement feedback Modal's container.
 */
const ReinforcementFeedbackModal = ({ onToggleModal }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const [retry, setRetry] = useState(false)
  const type = usePathParam('type').toUpperCase()
  const { showFeedbackModal, submitSuccess } = useSelector(state => state.reinforcement)
  const { isTiny } = useViewportSize()

  const handleClose = () => {
    if (!retry) {
      if (isTiny) {
        history.push(`/consumo-operador/${type}`)
      } else {
        dispatch(onCloseFeedbackModal())
      }
    }
  }
  const handleBack = () =>
    batch(() => {
      dispatch(onCloseFeedbackModal())
      if (!isTiny) {
        onToggleModal()
      }
    })
  const handleRetry = () => {
    batch(() => {
      setRetry(true)
      dispatch(onSendReinforcementThunk({ callback: () => setRetry(false) }))
    })
  }

  return (
    <Modal maxWidth={isTiny ? '90%' : '726px'} open={showFeedbackModal} onClose={handleClose}>
      <Typography fontSize={isTiny ? '18px' : '20px'} fontWeight="bold" variant="h3">
        {`Reporte de Recomendación - ${CONSUMPTION_OPERATOR_MODAL_TITLE[type]}`}
      </Typography>

      <Separator />

      <Wrapper container alignItems="center" direction="column" margin="80px 0">
        {submitSuccess && !retry && <img src="/assets/icons/checkbox-marked-circle-outline.svg" alt="Cerrar sesión" />}
        {!submitSuccess && !retry && <img src="/assets/icons/alert-circle-outline.svg" alt="Cerrar sesión" />}

        {!retry && (
          <Typography
            align="center"
            fontSize={isTiny ? '22px' : '25px'}
            lineHeight="33px"
            margin="25px 0 0"
            variant="h4"
          >
            {submitSuccess && 'El reporte de recomendación fue ingresado con éxito'}
            {!submitSuccess && (
              <>
                Hubo un error al enviar el registro de reporte,
                <br />
                por favor intentelo de nuevo
              </>
            )}
          </Typography>
        )}

        {retry && <Spinner />}
      </Wrapper>

      {!retry && <Separator />}

      {!retry && (
        <Wrapper container justify="flex-end">
          {submitSuccess && (
            <Button color="primary" onClick={handleClose}>
              Cerrar
            </Button>
          )}
          {!submitSuccess && (
            <>
              <Button onClick={handleBack}>Volver</Button>
              <Button color="primary" margin="0 0 0 30px" onClick={handleRetry}>
                Reintentar
              </Button>
            </>
          )}
        </Wrapper>
      )}
    </Modal>
  )
}

ReinforcementFeedbackModal.defaultProps = {
  onToggleModal: () => undefined
}
ReinforcementFeedbackModal.propTypes = {
  onToggleModal: PropTypes.func
}

export default ReinforcementFeedbackModal
