class TruckModel {
  constructor() {
    this.patent = ''
    this.fuel_level = 0.0
    this.percent = 0.0
    this.last_charge_day = ''
    this.last_charge_hour = ''
    this.active = false
    this.dataLoss = false
    this.disconnected_sources = false
    this.full_connected = false
    this.go_supply = false
    this.loading = false
    this.all_disconnected_sources = false
    this.charge_station = []
    this.diff_hrs = 0
    this.name_disconnected_source = []
    this.truck_last_charge = 0
    this.source = ''
    this.fuel_rate = 0
    this.cycles = 0
    this.distancia_gps = 0
    this.t_encendido = 0
  }

  fromV1 = truck => {
    this.patent = truck.truck_patent
    this.fuel_level = truck.truck_actual_litres
    this.percent = truck.truck_percent
    this.last_charge_day = truck.truck_date_last_charge_day
    this.last_charge_hour = truck.truck_date_last_charge_time
    this.charge_station = truck.charge_station
    this.name_disconnected_source = truck.name_disconnected_source
    this.truck_last_charge = parseInt(truck.truck_last_charge)
    this.diff_hrs = parseInt(truck.diff_hrs)
    this.source = truck.source
    this.active = truck.truck_status === 1
    this.fuel_rate = truck.tasa_consumo
    this.distancia_gps = (truck.distancia_gps / 1000).toFixed(0)
    this.t_encendido = truck.t_encendido.toFixed(1)
    // eslint-disable-next-line
    if (truck.hasOwnProperty('dataLoss')) {
      this.dataLoss = truck.dataLoss === 1
    }
    // eslint-disable-next-line
    if (truck.hasOwnProperty('disconnected_sources')) {
      this.disconnected_sources = truck.disconnected_sources === 1
    }
    // eslint-disable-next-line
    if (truck.hasOwnProperty('all_disconnected_sources')) {
      this.all_disconnected_sources = truck.all_disconnected_sources === 1
    }
    // eslint-disable-next-line
    if (truck.hasOwnProperty('full_connected')) {
      this.full_connected = truck.full_connected === 1
    }
    // eslint-disable-next-line
    if (truck.hasOwnProperty('go_supply')) {
      this.go_supply = truck.go_supply === 1
    }
    // eslint-disable-next-line
    if (truck.hasOwnProperty('loading')) {
      this.loading = truck.loading === 1
    }
    return this
  }
}

export default TruckModel
