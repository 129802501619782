import styled from 'styled-components'
import { Chip } from '@material-ui/core'
import { hexToRGBA } from '../../modules/utils/color'

export const Root = styled(Chip)`
  &.MuiChip-root {
    background-color: ${props => props.theme.palette.brand.gray[90]};
    color: ${props => props.theme.palette.brand.gray[20]};
    margin: ${props => props.margin};

    & .MuiChip-deleteIcon {
      color: ${props => props.theme.palette.brand.gray[70]};

      &:hover,
      &:active {
        color: ${props => props.theme.palette.brand.gray[50]};
      }
    }
  }

  &.MuiChip-colorPrimary {
    background-color: ${props => props.theme.palette.brand.primary[50]};
    color: ${props => props.theme.palette.brand.white};

    & .MuiChip-deleteIcon {
      color: ${props => hexToRGBA(props.theme.palette.brand.white, 0.7)};

      &:hover,
      &:active {
        color: ${props => props.theme.palette.brand.white};
      }
    }
  }

  &.MuiChip-outlined {
    background-color: ${props => props.theme.palette.brand.white};
    border: 1px solid ${props => props.theme.palette.brand.gray[50]};
    color: ${props => props.theme.palette.brand.gray[50]};

    & .MuiChip-deleteIcon {
      color: ${props => props.theme.palette.brand.gray[70]};

      &:hover,
      &:active {
        color: ${props => props.theme.palette.brand.gray[50]};
      }
    }
  }

  &.MuiChip-outlinedPrimary {
    background-color: ${props => props.theme.palette.brand.white};
    border: 1px solid ${props => props.theme.palette.brand.primary[50]};
    color: ${props => props.theme.palette.brand.primary[50]};

    & .MuiChip-deleteIcon {
      color: ${props => props.theme.palette.brand.primary[70]};

      &:hover,
      &:active {
        color: ${props => props.theme.palette.brand.primary[50]};
      }
    }
  }

  &.MuiChip-root.Mui-disabled {
    cursor: not-allowed;
    pointer-events: none;
    user-select: none;
  }
`
