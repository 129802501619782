import { GET_EMBED_URL, GET_EMBED_URL_ERROR, GET_EMBED_URL_SUCCESS } from './QuickSight.actions'

const newAnomalyFilterState = {
  error: false,
  loading: true,
  url: ''
}

const quickSightReducer = (state = newAnomalyFilterState, { payload, type }) => {
  switch (type) {
    case GET_EMBED_URL: {
      return { ...state, loading: true, error: false }
    }
    case GET_EMBED_URL_ERROR: {
      return { ...state, loading: false, error: true }
    }
    case GET_EMBED_URL_SUCCESS: {
      return { ...state, loading: false, error: false, url: payload.url }
    }
    default: {
      return state
    }
  }
}

export default quickSightReducer
