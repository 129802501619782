import React from 'react'
import PropTypes from 'prop-types'
import { useViewportSize } from '../../modules/hooks/viewport'

/**
 * The ViewportLayout's component.
 */
const ViewportLayout = props => {
  const { children, mediumMatched, rule, smallMatched, tinyMatched } = props
  const viewportFlags = useViewportSize()
  const result = rule(viewportFlags)

  if (!result) {
    return null
  }

  if (viewportFlags.isTiny && tinyMatched) {
    return tinyMatched(viewportFlags)
  }

  if (viewportFlags.isSmall && smallMatched) {
    return smallMatched(viewportFlags)
  }

  if (viewportFlags.isMedium && mediumMatched) {
    return mediumMatched(viewportFlags)
  }

  return <>{children}</>
}

ViewportLayout.defaultProps = {
  children: null,
  mediumMatched: null,
  smallMatched: null,
  rule: () => true,
  tinyMatched: null
}
ViewportLayout.propTypes = {
  children: PropTypes.node,
  mediumMatched: PropTypes.oneOfType([PropTypes.func, PropTypes.number]),
  rule: PropTypes.func,
  smallMatched: PropTypes.oneOfType([PropTypes.func, PropTypes.number]),
  tinyMatched: PropTypes.oneOfType([PropTypes.func, PropTypes.number])
}

export default ViewportLayout
