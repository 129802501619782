import React, { useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import DisconnectedData from './DisconnectedData.container'
import Pagination from '../../components/Tables/Pagination'
import LoadingContent from '../../components/Placeholders/LoadingContent'
import { usePagination } from '../../components/Tables/Pagination.hooks'
import Table from '../../components/Tables/Table'
import TableCell from '../../components/Tables/TableCell'
import TableRow from '../../components/Tables/TableRow'
import { Paper, Box, Grid } from '@material-ui/core'
import Typography from '../../components/Basics/Typography'
import { onGetHistoricalOrpakThunk, onGetMonthlyOrpakThunk, onGetDateOrpakThunk } from './FuelLevel.actions'
import Button from '../../components/Buttons/Button'
import { CSVLink } from 'react-csv'
import { useDatePicker } from '../../components/Inputs/Inputs.hooks'
import DatePicker from '../../components/Inputs/DatePicker'
import { checkErrorRequired } from '../../modules/errors/validations'

const FuelLevelOrpak = () => {
  const csvLink = useRef()
  const dispatch = useDispatch()
  const { orpakError, orpakLoading, orpakData } = useSelector(state => state.countdown)

  const { errorHistoricOrpak, loadingHistoricOrpak, historicOrpakData, monthlyOrpakData, dateOrpakData } = useSelector(
    state => state.fuelLevel
  )

  const { page, totalPages, onChangePage, onGetCurrentPage } = usePagination({
    data: orpakData.data,
    initialPage: 1,
    rowsPerPage: 15
  })

  const { error: pastDateError, value: pastDateValue, onChange: handlePastDateChange } = useDatePicker({
    initialValue: new Date('2023-01-02'),
    errorCallbacks: [checkErrorRequired()]
  })

  const { error: dateError, value: dateValue, onChange: handleDateChange } = useDatePicker({
    initialValue: new Date(),
    errorCallbacks: [checkErrorRequired()]
  })

  const fetchHistoricalOrpak = () => {
    return dispatch(onGetHistoricalOrpakThunk())
  }

  const fetchMonthlyOrpak = monthly => {
    return dispatch(onGetMonthlyOrpakThunk(monthly))
  }

  const fetchDatesOrpak = () => {
    if (dateValue && pastDateValue) {
      console.log(dateValue, pastDateValue)
      return dispatch(onGetDateOrpakThunk(pastDateValue, dateValue))
    }
  }

  return (
    <>
      {orpakLoading && <LoadingContent />}
      {!orpakLoading && orpakError && <DisconnectedData />}
      {!orpakLoading && !orpakError && (
        <>
          <Box>
            <Typography variant="h2" margin="0 0 20px 0">
              Abastecimientos registrados en Orpak las ultimas 24 horas. El dato de Orpak puede tardar un tiempo antes
              de quedar registrado (entre 20 minutos y 3 horas).
            </Typography>
            <Paper style={{ overflow: 'auto' }}>
              {orpakData.data.length === 0 ? (
                <>
                  <Table accesibility={{ label: 'Orpak' }}>
                    <TableRow head>
                      <TableCell>Equipo</TableCell>
                      <TableCell>Fecha</TableCell>
                      <TableCell>Hora Abastecimiento</TableCell>
                      <TableCell>Cantidad</TableCell>
                      <TableCell>Diferencia</TableCell>
                    </TableRow>
                  </Table>
                  <Grid container spacing={0} direction="column" alignItems="center">
                    <Typography variant="h1">No hay datos de Orpak en las últimas 24 horas</Typography>
                  </Grid>
                </>
              ) : (
                <Table accesibility={{ label: 'Orpak' }}>
                  <TableRow head>
                    <TableCell>Equipo</TableCell>
                    <TableCell>Fecha</TableCell>
                    <TableCell>Hora Abastecimiento</TableCell>
                    <TableCell>Turno</TableCell>
                    <TableCell>Cantidad</TableCell>
                    <TableCell>Capacidad</TableCell>
                    <TableCell>Diferencia</TableCell>
                    <TableCell>Limite inferior</TableCell>
                    <TableCell>Limite Superior</TableCell>
                    <TableCell>% Cumplimiento</TableCell>
                  </TableRow>

                  {onGetCurrentPage().map((row, index) => {
                    return (
                      <TableRow key={`orpak-${index}`}>
                        <TableCell align="center" minWidth="150px">
                          {row.camion}
                        </TableCell>
                        <TableCell align="center" minWidth="150px">
                          {row.fecha.getUTCFullYear()}-{row.fecha.getUTCMonth() + 1}-{row.fecha.getUTCDate()}
                        </TableCell>
                        <TableCell align="center" minWidth="150px">
                          {row.fechaInicio}
                        </TableCell>
                        <TableCell align="center" minWidth="150px">
                          {row.turno}
                        </TableCell>
                        <TableCell align="center" minWidth="150px">
                          {row.cantidadAbastecida}
                        </TableCell>
                        <TableCell align="center" minWidth="150px">
                          {row.capacidad}
                        </TableCell>
                        <TableCell align="center" minWidth="150px">
                          {row.diferencia}
                        </TableCell>
                        <TableCell align="center" minWidth="150px">
                          {row.limInf}
                        </TableCell>
                        <TableCell align="center" minWidth="150px">
                          {row.limSup}
                        </TableCell>
                        <TableCell
                          align="center"
                          minWidth="150px"
                          color={row.cumplimiento < 25 ? 'red' : row.cumplimiento > 30 ? 'red' : ''}
                        >
                          {row.cumplimiento + '%'}
                        </TableCell>
                      </TableRow>
                    )
                  })}
                </Table>
              )}
              <Pagination alignment="right" count={totalPages} page={page} onChange={onChangePage} />
              <Grid container spacing={1} direction="row">
                <Grid item xs={2} md={2} />
                <Grid item xs={1} md={1} />
                <Grid item xs={3} md={3}>
                  {!loadingHistoricOrpak && historicOrpakData.length < 1 && (
                    <Button onClick={fetchHistoricalOrpak} color="primary" size="medium" width="100%">
                      ORPAK HISTÓRICO
                    </Button>
                  )}
                  {loadingHistoricOrpak && (
                    <Button loading color="primary" size="medium">
                      Cargando
                    </Button>
                  )}
                  {!loadingHistoricOrpak && errorHistoricOrpak && <>error</>}
                  {!loadingHistoricOrpak && !errorHistoricOrpak && historicOrpakData.length > 0 && (
                    <>
                      <CSVLink
                        data={historicOrpakData[0]}
                        filename="historicalOrpakData.csv"
                        className="hidden"
                        ref={csvLink}
                        target="_blank"
                      >
                        <Button color="success" size="medium">
                          Listo
                        </Button>
                      </CSVLink>
                    </>
                  )}
                </Grid>
                <Grid item xs={3} md={3}>
                  {!loadingHistoricOrpak && monthlyOrpakData.length < 1 && (
                    <Button onClick={fetchMonthlyOrpak} color="primary" size="medium" width="100%">
                      ORPAK MES EN CURSO
                    </Button>
                  )}
                  {loadingHistoricOrpak && (
                    <Button loading color="primary" size="medium">
                      Cargando
                    </Button>
                  )}
                  {!loadingHistoricOrpak && errorHistoricOrpak && <>error</>}
                  {!loadingHistoricOrpak && !errorHistoricOrpak && monthlyOrpakData.length > 0 && (
                    <>
                      <CSVLink
                        data={monthlyOrpakData[0]}
                        filename="monthlyOrpakData.csv"
                        className="hidden"
                        ref={csvLink}
                        target="_blank"
                      >
                        <Button color="success" size="medium">
                          Listo
                        </Button>
                      </CSVLink>
                    </>
                  )}
                </Grid>
                <Grid item xs={3} md={3}>
                  {!loadingHistoricOrpak && dateOrpakData.length < 1 && (
                    <Button onClick={fetchDatesOrpak} color="primary" size="medium" width="100%">
                      ORPAK FECHA ESPECÍFICA
                    </Button>
                  )}
                  {loadingHistoricOrpak && (
                    <Button loading color="primary" size="medium">
                      Cargando
                    </Button>
                  )}
                  {!loadingHistoricOrpak && errorHistoricOrpak && <>error</>}
                  {!loadingHistoricOrpak && !errorHistoricOrpak && dateOrpakData.length > 0 && (
                    <>
                      <CSVLink
                        data={dateOrpakData[0]}
                        filename="dateOrpakData.csv"
                        className="hidden"
                        ref={csvLink}
                        target="_blank"
                      >
                        <Button color="success" size="medium">
                          Listo
                        </Button>
                      </CSVLink>
                    </>
                  )}
                </Grid>
              </Grid>
              <Grid sx={{ mt: 5 }} container spacing={1} direction="row">
                <Grid item xs={2} md={2} />
                <Grid item xs={1} md={1} />
                <Grid item xs={3} md={3} />
                <Grid item xs={3} md={3} />
                <Grid item xs={3} md={3}>
                  <DatePicker
                    error={pastDateError}
                    value={pastDateValue}
                    width="47%"
                    variant="inline"
                    maxDate={dateValue}
                    onChange={handlePastDateChange}
                    disableFuture
                    margin="0 6% 0 0"
                  />
                  <DatePicker
                    error={dateError}
                    value={dateValue}
                    variant="inline"
                    width="47%"
                    minDate={pastDateValue}
                    onChange={handleDateChange}
                    disableFuture
                  />
                </Grid>
              </Grid>
            </Paper>
          </Box>
        </>
      )}
    </>
  )
}

export default FuelLevelOrpak
